export default {
  Error_Unhandled: 'Något gick fel. Vänligen försök igen eller kontakta Skanetrafikens support.',

  PayEx_Loading: 'Laddar PayEx...',

  Start_Header: 'Köp kort och biljett.',
  Start_BuyCard_Header: 'Köpa ett nytt kort',
  Start_BuyCard_Description: 'Skickas inom 7 dagar.',
  Start_BuyCard_Alt_Text: 'Ett reskort',
  Start_BuyTicket_Header: 'Köpa biljett med ditt kort',
  Start_BuyTicket_Description: 'Köp biljett och lägg på ditt kort',
  Start_BuyTicket_Alt_Text: 'En biljett',
  Start_Card_Header: 'Aktivera biljett',
  Start_Card_Description: 'Välj när du vill aktivera dina befintliga biljetter',
  Start_Card_Alt_Text: 'En biljett med en bock markering',

  BuyCard_Header: 'Köpa nytt kort',
  BuyCard_Text:
    'För att kunna köpa biljett via vår web måste du ha ett kort att lägga biljetten på.<br />Det kostar 30 kr och har en leveranstid på högst 5 arbetsdagar.',

  BuyCardForm_NumberOfCards_Label: 'Antal kort',
  BuyCardForm_NumberOfCards_Increase_Button_Alt: 'Öka antalet kort',
  BuyCardForm_NumberOfCards_Decrease_Button_Alt: 'Minska antalet kort',

  BuyCardForm_FirstName_Label: 'Förnamn',
  BuyCardForm_FirstName_PlaceHolder: 'Förnamn',
  BuyCardForm_FirstName_Required_Validation: 'Förnamn är obligatoriskt.',
  BuyCardForm_FirstName_MaxLength_Validation: 'Förnamn får max vara 50 tecken långt.',

  BuyCardForm_LastName_Label: 'Efternamn',
  BuyCardForm_LastName_PlaceHolder: 'Efternamn',
  BuyCardForm_LastName_Required_Validation: 'Efternamn är obligatoriskt.',
  BuyCardForm_LastName_MaxLength_Validation: 'Efternamn får max vara 50 tecken långt.',

  BuyCardForm_Address_Label: 'Adress',
  BuyCardForm_Address_Placeholder: 'Gatuadress',
  BuyCardForm_Address_Required_Validation: 'Adress är obligatoriskt.',
  BuyCardForm_Address_MaxLength_Validation: 'Adress får max vara 255 tecken långt.',

  BuyCardForm_PostalCode_Label: 'Postnummer',
  BuyCardForm_PostalCode_Placeholder: '123 45',
  BuyCardForm_PostalCode_Required_Validation: 'Postnummer är obligatoriskt.',
  BuyCardForm_PostalCode_PostalCodeFormat_Validation:
    'Felaktigt format på postnummer. Ange postnummer med 5 siffror.',

  BuyCardForm_City_Label: 'Postort',
  BuyCardForm_City_Placeholder: 'stad',
  BuyCardForm_City_Required_Validation: 'Postort är obligatoriskt.',
  BuyCardForm_City_MaxLength_Validation: 'Postort får max vara 80 tecken långt.',

  BuyCardForm_ReceiptViaEmail_Checkbox: 'Jag vill få faktura via mejl.',

  BuyCardForm_Email_Label: 'Mejladress för kvitto',
  BuyCardForm_Email_Placeholder: 'namn@gmail.com',
  BuyCardForm_Email_Email_Validation:
    'Felaktigt format på mejladress. Ange mejladress som dittnamn@mail.com',
  BuyCardForm_Email_RequiredIf_Validation: 'Mejladress är obligatoriskt.',
  BuyCardForm_Email_MaxLength_Validation: 'Mejladress får max vara 100 tecken långt.',

  BuyCardForm_ToPay_Label: 'Att betala',

  BuyCardForm_AcceptTerms_Checkbox:
    'Jag har läst och godkänt Skånetrafikens <b><a href="https://www.skanetrafiken.se/sa-reser-du-med-oss/villkor/" target="_blank">användarvillkor</a></b>',

  StopAreaPicker_Label: 'Välj hållplatser att åka mellan (inkl. hållplatser att byta på)',
  StopAreaPicker_Placeholder: 'Malmö C',
  StopAreaPicker_NoResult: 'Inga hållplatser hittades.',
  StopAreaPicker_AddressResult_NearestStopArea: 'Hållplatser närmst angiven adress',
  StopAreaPicker_AddressResult_NearestStopArea_Dropdown: 'Hållplats närmst angiven adress',
  StopAreaPicker_AddressResult_NearestAddress_Dropdown: 'Adress närmst angiven adress',
  StopAreaPicker_AddressResult_NearestPointOfInterest_Dropdown:
    'Intressepunkt närmst angiven adress',
  StopAreaPicker_AddressResult_Distance: 'Avstånd',
  StopAreaPicker_Query_Required_Validation: 'Hållplats är obligatoriskt.',

  BuyCardForm_Submit_Button: 'Köp',

  BuyTicket_Header: 'Skapa en biljett',

  BuyTicketForm_Offer_Label: 'Biljettyp',

  VerifyEmail_Header: 'Verifiera e-postadress',
  VerifyEmail_Body: 'Tryck på knappen verifiera för att verifiera e-postadressen.',
  VerifyEmail_Button: 'Verifiera e-postadress',
  VerifyEmail_Header_OK: 'Verifierat',
  VerifyEmail_Body_OK: 'Din e-postadress är nu verifierad. Tryck på knappen för komma till login.',
  VerifyEmail_Button_OK: 'Logga in',
  VerifyEmail_Header_BAD: 'Något gick fel',
  VerifyEmail_Body_BAD:
    'Något gick fel med verifiera din e-postadress. Din e-postadress kanske redan är verifierad.',
  VerifyEmail_Button_BAD: 'Försök igen',

  CardNumber_Label: 'Kortnummer',
  CardNumber_Placeholder: '1234 1234 1234',
  CardNumber_Required_Validation: 'Kortnummer är obligatoriskt.',
  CardNumber_Format_Validation:
    'Felaktigt format på kortnummer. Ange reskortsnummer med 12 siffror.',
  CardNumber_Link_No_Card: 'Jag har inget kort',

  BuyTicketForm_ReceiptViaEmail_Checkbox: 'Jag vill få faktura via mejl.',

  BuyTicketForm_Email_Label: 'Mejladress för kvitto',
  BuyTicketForm_Email_Placeholder: 'namn@gmail.com',
  BuyTicketForm_Email_Email_Validation:
    'Felaktigt format på mejladress. Ange mejladress som dittnamn@mail.com',
  BuyTicketForm_Email_RequiredIf_Validation: 'Mejladress är obligatoriskt.',
  BuyTicketForm_Email_MaxLength_Validation: 'Mejladress får max vara 100 tecken långt.',

  BuyTicketForm_ToPay_Label: 'Att betala',

  BuyTicketForm_AcceptTerms_Checkbox:
    'Jag har läst och godkänt Skånetrafikens <strong><a href="https://www.skanetrafiken.se/sa-reser-du-med-oss/villkor/" id="buy-ticket-accept-terms" target="_blank">användarvillkor</a></strong>',
  BuyTicketForm_AcceptTerms_Required_Validation: 'Det är obligatoriskt att godkänna villkoren.',
  BuyTicketForm_Submit_Button: 'Köp',

  BuyTicketConfirm_Header: 'Bekräfta och betala',
  BuyTicketConfirm_Description:
    'Betalning kommer ske via PayEx. Du kommer där kunna välja att betala med betalkort eller Swish.',
  BuyTicketConfirm_Ticket_Header: 'Biljettköp (1 biljett)',

  BuyTicketConfirm_Button_Back: 'Tillbaka',
  BuyTicketConfirm_Button_Pay: 'Betala',
  BuyTicketConfirm_Button_Pay_And_Activate: 'Betala och aktivera',

  CardInfoModal_Header: 'Här anger du kortnumret på Skånetrafiken kortet',
  CardInfoModal_CardNumber_Description:
    'Du hittar kortnumret nere i vänstra hörnet på baksidan av kortet.',
  CardInfoModal_Description:
    'Tänk på att du inte använda ditt Jojo-kort här.<br> Gå till <a href="https://www.skanetrafiken.se/e-tjanster/" id="card-info-modal-link" target="_blank">Jojo-tjänster</a> på startsidan.',
  CardInfoModel_Button_Close: 'Stäng',

  PhoneNumberInfoModal_Header:
    'Här knappar du in mobilnumret till den som ska ta emot biljetten i Skånetrafikens app.',
  PhoneNumberInfoModal_Description:
    'Tänk på att mottagaren behöver ha appen installerad i sin mobil och sitt mobilnummer registrerat i appen innan du skickar biljetten. Mottagaren aktiverar sedan själv biljetten i sin app inför resan.',
  PhoneNumberInfoModel_Button_Close: 'Stäng',

  SuccessfulPayment_Header: 'Köp genomfört',
  SuccessfulPayment_Button: 'OK',
  SuccessfulPayment_Cards_Description: 'Köpet lyckades och ditt/dina kort kommer skickas till dig.',
  SuccessfulPayment_Tickets_Description:
    'Köpet lyckades och biljetten har skickats till ditt kort.',
  SuccessfulPayment_Tickets_App_Description:
    'Köpet är godkänt och biljetten är nu skickad till det mobilnummer du angett. Kvittot är skickat till din mejladress.',
  SuccessfulPayment_ReferenceId:
    'Referens id för din order är: {referenceId}. Använd detta vi kontakt med Skanetrafiken',
  SuccessfulPayment_Check_Icon_Title: 'Vit bock markering',
  SuccessfulPayment_Check_Icon_Description: 'Vit bock markering som indikerar lyckat köp',

  Error_CreditNotApproved: 'Negativ kreditvärdering',
  Error_Created_Account_Failed: 'Misslyckades med skapa konto.',

  Edit_MK_Epost_Label: 'Ny e-postadress',
  Edit_MK_Epost_Verify_Label: 'Bekräfta ny e-postadress',
  Edit_MK_Current_Password_Label: 'Nuvarande lösenord',
  Edit_MK_New_Password_Label: 'Nytt lösenord',

  ActivateTicketModal_Header: 'Vill du aktivera den här biljetten nu?',
  ActivateTicketModal_Description: 'Den kommer vara giltig från och med {from} till {to}',
  ActivateTicketModal_Button_No: 'Aktivera senare',
  ActivateTicketModal_Button_Yes: 'Aktivera nu',

  ActivateTicketOnPurchaseRequiredModal_Header:
    'Du kan inte ha olika ej aktiverade biljetter på kortet',
  ActivateTicketOnPurchaseRequiredModal_Description1: 'Ej aktiverade biljetter på kortet är:',
  ActivateTicketOnPurchaseRequiredModal_Description2:
    'Du måste antingen aktivera denna biljetten nu eller köpa denna biljetten via Skånetrafiken appen',
  ActivateTicketOnPurchaseRequiredModal_Button_No: 'Aktivera senare',
  ActivateTicketOnPurchaseRequiredModal_Button_Yes: 'Aktivera nu',

  PayEx_Description_Card: 'Köp av kort',
  PayEx_Description_Ticket: 'Köp av biljett',

  ZonePicker_Journey_Error: 'Ingen resa hittades med de angivna hållplatserna.',

  TravellerPicker_Label_NumberOfTravellers: 'Antal resenärer på biljetten',
  TravellerPicker_Increase_Button_Alt: 'Lägg till resenär ',
  TravellerPicker_Decrease_Button_Alt: 'Ta bort resenär ',

  Traveller_Type_Adult: 'Vuxen',
  Traveller_Type_Child: 'Barn',
  Traveller_Type_Student: 'Student',
  Traveller_Type_Senior: 'Senior',
  Traveller_Type_Youth: 'Ungdom',
  Traveller_Type_Adult_Alt_Text: 'En vuxen',
  Traveller_Type_Child_Alt_Text: 'Ett barn',
  Traveller_Type_Student_Alt_Text: 'En student',
  Traveller_Type_Senior_Alt_Text: 'En senior',
  Traveller_Type_Required_Validation: 'Resenär är obligatoriskt.',

  Passage_Direction_Sweden_Denmark: 'Från Sverige till Danmark',
  Passage_Direction_Denmark_Sweden: 'Från Danmark till Sverige',

  TicketList_Header_Created: 'Köpdatum',
  TicketList_Header_Zone: 'Zon',
  TicketList_Header_Offer: 'Biljettyp',
  TicketList_Header_Status: 'Status',
  TicketList_Header_TicketId: 'Biljett-id',

  Ticket_Status_Active: 'Aktiv',
  Ticket_Status_Blocked: 'Blockerad',
  Ticket_Status_Unused: 'Oanvänd',
  Ticket_Status_Consumed: 'Förbrukad',
  Ticket_Status_Expired: 'Utgången',
  Ticket_Status_Dot_Active: 'En grön cirkel som indikerar aktiv biljett',
  Ticket_Status_Dot_Consumed: 'En röd cirkel som indikerar förbrukad biljett',
  Ticket_Status_Dot_Blocked: 'En röd cirkel som indikerar att kortet är spärrat',

  Ticket_Status_Validity_Unused: 'Aktivera senast',
  Ticket_Status_Validity_Active: 't.o.m',
  Ticket_Status_Validity_Consumed: 'Gick ut',
  Ticket_Status_Validity_Expired: 'Gick ut',
  Ticket_Status_Validity_Blocked: 'Ring KundCenter för mer info',

  TicketInfo_Label_Created: 'Köpdatum',
  TicketInfo_Label_Travellers: 'Resenärer',
  TicketInfo_Label_Addons: 'Tillägg',
  TicketInfo_Label_Zone: 'Zon',
  TicketInfo_Label_Offer: 'Biljettyp',
  TicketInfo_Label_Valid: 'Giltig',
  TicketInfo_Label_Price: 'Pris',
  TicketInfo_Button_Close: 'Stäng',

  CardDetailsInfo_Label_CardNumber: 'Kortnummer',
  CardDetailsInfo_Label_CardStatus: 'Kortstatus',
  CardDetailsInfo_CardStatus_Active: 'Aktivt',
  CardDetailsInfo_CardStatus_Revoked: 'Spärrat',
  CardDetailsInfo_Button_Revoke: 'Har du tappat kortet?',
  CardDetailsInfo_Button_Remove: 'Avregistrera kortet',
  CardDetailsInfo_Button_MoveTickets: 'Flytta biljetter',

  CardDetailsTicketList_Button_ActivateTicket: 'Aktivera',
  CardDetailsTicketList_Arrow_Down: 'En pil som pekar neråt för att visa mer',
  CardDetailsTicketList_Arrow_Up: 'En pil som pekar uppåt för att visa mindre',

  RemoveCardModal_Header: 'Vill du avregistrera detta kortet?',
  RemoveCardModal_Description:
    'Kortet kan inte användas efter att du spärrat innehållet på det men du kan flytta över innehållet till ett annat Skå-kort eller till Skånetrafiken appen på din mobil.',
  RemoveCardModal_Button_No: 'Avbryt',
  RemoveCardModal_Button_Yes: 'Avregistrera',

  CardList_Header: 'Dina reskort',
  CardList_Table_Type: 'Typ',
  CardList_Table_Name: 'Kortnamn',
  CardList_Table_CardNumber: 'Nummer',
  CardList_Table_Content: 'Innehåll',
  CardList_Table_Status: 'Status',

  MittKonto_Header_Welcome: 'välkommen!',

  Register_Card_Button: 'Registrera nytt kort',
  CardInformation_Header: 'Så här funkar det nya kortet',
  CardInformation_Description:
    'Börja med att Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure voluptatum, praesentium iste quisquam tenetur delectus quasi officiis veniam nam eius vel aspernatur voluptates ipsa et quam, sit tempore recusandae minus!',

  CardRegistration_Header: 'Registrera kort',
  CardRegistration_Description:
    'Genom attregistrera dina kort har du dem alltid lättillgängliga när du behöver. Du kan snabbt och enkelt se saldo och ladda. När du spärrar ditt kort får du ersättning med samma värde eller period som fanns på kortet när du spärrade det.',

  CardRegistrationForm_Name_Label: 'Namn',
  CardRegistrationForm_Name_PlaceHolder: 'Mitt kort',
  CardRegistrationForm_Name_Required_Validation: 'Namn är obligatoriskt.',
  CardRegistrationForm_Name_MaxLength_Validation: 'Namn får inte vara mer än 30 tecken långt.',

  CardRegistrationForm_Button_Submit: 'Registrera',

  Campaign_Ticket_Header: 'Kampanjbiljett',
  Campaign_Ticket_Offer_Header: 'Sommarbiljetten',
  Campaign_Ticket_Offer_Info_Text:
    'Du hittar {campaignName} under biljettyp eller genom att trycka på knappen härunder',
  Campaign_Ticket_Offer_Submit: 'Välj {campaignName}',
  Campaign_Ticket_Offer_Read_More: 'Läs om biljetten',
  Campaign_Ticket_Info_Box_Text:
    'En sommarbiljett gäller för max tre personer, och högst en i sällskapet får ha fyllt 20 år. Till exempel kan en vuxen resa med två barn/ungdomar. Eller så kan tre ungdomar resa tillsammans, så länge bara en av dem har fyllt 20 år. Kom ihåg att ni måste resa tillsammans hela vägen.',
  Campaign_Ticket_Offer_Img_Alt_Text: 'Sommar',
  Campaign_Ticket_Offer_Img_Alt_Text_BT: 'Blekinge - all inclusive',

  Checkbox_Icon_Title: 'En checkbox',
  Checkbox_Icon_Description: 'En checkbox för att kryssa i eller kryssa av',

  Error_CardConflict: 'Detta kortnummer finns redan registrerat.',
  Error_MoveTickets_CardTicketConflict:
    'Konflikt med befintlig biljett på kort. Flytt av biljetter kunde inte genomföras.',
  Error_MoveTickets_CardRevoked: 'Kortet är spärrat. Flytt av biljetter kunde inte genomföras.',
  Error_MoveTickets_MaxAllowedMoves:
    'Maximal antal flyttar är uppnått. Flytt av biljetter kunde inte genomföras.',
  Error_BuyTicket_CardTicketConflict: 'Konflikt med befintlig biljett på kort.',
  Error_BuyTicket_Alt_Text: 'En varningstriangel',
  Error_CardNotFound: 'Inget kort hittades med det angivna kortnumret.',
  Error_Blocked_Nbr: 'Tjänsten är spärrad. Kontakta kundtjänst för mer information.',

  GetCardForm_Button_GetTickets: 'Hämta biljetter',

  GoogleMap_Iframe_Title: 'Karta över valda hållplatser',

  PRICE_MODEL_CITY: 'Stadszon',
  PRICE_ID_SHORT: 'Liten zon',
  PRICE_ID_MEDIUM: 'Mellanzon',
  PRICE_ID_LONG: 'Hela Skåne',
  PRICE_ID_LONG_CAMPAIGN_TICKET_SUMMER: 'Sommarbiljetten gäller hela Skåne',
  PRICE_MODEL_ORESUND: 'Öresundszon',
  PRICE_MODEL_CITY_ADDON: 'inkl. stadszon',
  PRICE_ID_COUNTYOT: 'Hela Östergötland',

  RevokeCardModal_Header: 'Vill du spärra innehållet på kortet?',
  RevokeCardModal_Body:
    'Kortet kan inte användas efter att dy spärrat innehållet på det men du kan flytta över innehållet till ett annat Skå-kort eller tille Skånetrafiken appen på din mobil.',
  RevokeCardModal_Button_Cancel: 'Avbryt',
  RevokeCardModal_Button_Ok: 'Spärra',

  MoveTicketsModal_Header: 'Flytta biljetter',
  MoveTicketsModal_Description: 'Ange kortnummer på de reskort dit du vill flytta dina biljetter.',
  MoveTicketsModal_Button_No: 'Avbryt',
  MoveTicketsModal_Button_Yes: 'Flytta',

  MultiSelect_Pin_Alt_text: 'En knappnål som visar vald hållplats',
  MultiSelect_Remove_Pin_Alt_text: 'Knapp för att ta bort vald hållplats',

  LoginForm_Email_Label: 'E-postadress',
  LoginForm_Email_Placeholder: 'Din e-postadress',
  LoginForm_Email_Required_Validation: 'E-postadress är obligatoriskt.',
  LoginForm_Email_Email_Validation:
    'Felaktigt format på e-postadress. Ange mejladress som dittnamn@mail.com',

  LoginForm_Password_Label: 'Lösenord',
  LoginForm_Password_Placeholder: 'Ditt lösenord',
  LoginForm_Password_Required_Validation: 'Lösenord är obligatoriskt.',
  LoginForm_Button_Submit: 'Logga in',

  LoginDataForm_Change_LoginData_Text:
    'Vid sparandet av ny e-postadress kommer ett mejl skickas till din nya e-postadress. Klicka då på länken i mejlet. Du kommer då till en sida där du måste verifiera din nya e-postadress.',
  LoginDataForm_Change_Password_LoginData_Text:
    'Lösenordet måste minst bestå av 8 tecken, varav minst 1 siffra och 1 bokstav.',

  ResetPasswordForm_Email_Label: 'E-postadress',
  ResetPasswordForm_Email_Placeholder: 'Din e-postadress',
  ResetPasswordForm_Email_Required_Validation: 'E-postadress är obligatoriskt.',
  ResetPasswordForm_Email_Email_Validation:
    'Felaktigt format på e-postadress. Ange mejladress som dittnamn@mail.com',
  ResetPasswordForm_ResetPasswordMedia_Label:
    'Vill du ha din återställningskod skickad via e-post eller SMS?',
  ResetPasswordForm_ResetPasswordMedia_SMS: 'SMS',
  ResetPasswordForm_ResetPasswordMedia_Email: 'E-post',
  ResetPasswordForm_Button_Submit: 'Skicka',

  SetPasswordForm_Token_Label: 'Pinkod',
  SetPasswordForm_Token_Placeholder: 'Den pinkod du fick i SMS',
  SetPasswordForm_Token_Required_Validation: 'Pinkod är obligatoriskt.',
  SetPasswordForm_Token_Format_Validation:
    'Felaktigt format på pinkod. Pinkoden ska vara 6 siffror lång.',
  SetPasswordForm_Password_Label: 'Nytt lösenord',
  SetPasswordForm_Password_Placeholder: 'Lösenord',
  SetPasswordForm_Password_Required_Validation: 'Lösenord är obligatoriskt.',
  SetPasswordForm_Password_Format_Validation:
    'Lösenord ska innehålla minst 8 tecken, 1 siffra och 1 bokstav',
  SetPasswordForm_RepeatPassword_Label: 'Upprepa nytt lösenord',
  SetPasswordForm_RepeatPassword_Placeholder: 'Upprepa lösenordet',
  SetPasswordForm_RepeatPassword_SameAs_Validation: 'Lösenorden stämmer inte överens.',
  SetPasswordForm_Button_Submit: 'Återställ',

  SignupForm_Mobile_Label: 'Mobilnummer',
  SignupForm_Mobile_Placeholder: 'Ditt mobilnummer',
  SignupForm_Mobile_Required_Validation: 'Mobilnummer är obligatoriskt.',
  SignupForm_Mobile_Format_Validation:
    'Felaktigt format på mobilnummer. Ange mobilnummer med eller utan landsnummer, max 15 siffror.',
  SignupForm_Mobile_MaxLength_Validation: 'Mobilnummer får inte vara mer än 30 tecken långt.',
  SignupForm_Email_Label: 'E-postadress',
  SignupForm_Email_Placeholder: 'Din e-postadress',
  SignupForm_Email_Required_Validation: 'E-postadress är obligatoriskt.',
  SignupForm_Email_Email_Validation:
    'Felaktigt format på e-postadress. Ange mejladress som dittnamn@mail.com.',
  SignupForm_Email_MaxLength_Validation: 'E-postadress får inte vara mer än 100 tecken långt.',
  SignupForm_RepeatEmail_Label: 'Bekräfta din e-postadress',
  SignupForm_RepeatEmail_Placeholder: 'Din e-postadress',
  SignupForm_RepeatEmail_SameAs_Validation: 'E-postadresserna stämmer inte överens.',
  SignupForm_Password_Label: 'Lösenord',
  SignupForm_Password_Placeholder: 'Lösenord',
  SignupForm_Password_Required_Validation: 'Lösenord är obligatoriskt.',
  SignupForm_Password_Format_Validation:
    'Lösenord ska innehålla minst 8 tecken, 1 siffra och 1 bokstav.',
  SignupForm_FirstName_Label: 'Förnamn',
  SignupForm_FirstName_Placeholder: 'Ditt förnamn',
  SignupForm_FirstName_Required_Validation: 'Förnamn är obligatoriskt.',
  SignupForm_FirstName_MaxLength_Validation: 'Förnamn får inte vara mer än 50 tecken långt.',
  SignupForm_LastName_Label: 'Efternamn',
  SignupForm_LastName_Placeholder: 'Ditt efternamn',
  SignupForm_LastName_Required_Validation: 'Efternamn är obligatoriskt.',
  SignupForm_LastName_MaxLength_Validation: 'Efternamn får inte vara mer än 50 tecken långt.',
  SignupForm_CareOf_Label: 'C/o',
  SignupForm_CareOf_Placeholder: '',
  SignupForm_CardOf_MaxLength_Validation: 'C/o frår inte vara mer än 50 tecken långt. ',
  SignupForm_Street_Label: 'Adress',
  SignupForm_Street_Placeholder: 'Din adress',
  SignupForm_Street_Required_Validation: 'Adress är obligatoriskt.',
  SignupForm_Street_MaxLength_Validation: 'Adress får inte vara mer än 50 tecken långt.',
  SignupForm_PostalCode_Label: 'Postnummer',
  SignupForm_PostalCode_Placeholder: 'Ditt postnummer',
  SignupForm_PostalCode_Required_Validation: 'Postnummer är obligatoriskt.',
  SignupForm_PostalCode_Format_Validation:
    'Felaktigt format på postnummer. Ange postnummer med 5 siffror.',
  SignupForm_City_Label: 'Ort',
  SignupForm_City_Placeholder: 'Din ort',
  SignupForm_City_Required_Validation: 'Ort är obligatoriskt.',
  SignupForm_City_MaxLength_Validation: 'Ort får inte vara mer än 50 tecken långt.',
  SignupForm_Country_Label: 'Land',
  SignupForm_Country_Placeholder: 'Välj ett lan',
  SignupForm_Country_Required_Validation: 'Land är obligatoriskt.',
  SignupForm_Country_MaxLength_Validation: 'Land får inte vara mer än 50 tecken långt.',
  SignupForm_AcceptTerms_Checkbox:
    'Jag har läst <b><a href="https://www.skanetrafiken.se/sa-reser-du-med-oss/villkor/" target="_blank">kontovillkoren</a></b> och accepterar dem',
  SignupForm_AcceptNewsletters_Checkbox: 'Jag vill ta del av nyhetsbrev och kampanjerbjudanden.',
  SignupForm_Button_Cancel: 'Avbryt',
  SignupForm_Button_Submit: 'Fortsätt',

  Spinner_Alt_Text: 'En laddningssnurra',

  Login_Header: 'Logga in',
  Login_Description: 'Fyll i din e-postadress och dett lösenord för att logga in.',
  Login_Link_Signup: 'Skapa nytt konto',
  Login_Links_Divider: 'eller',
  Login_Link_RequestResetPassword: 'Glömt lösenord',
  Login_SuccessfulSignup_Message:
    '<h3>Ditt konto har spapats.</h3><p>Ett meddelande har skickats till din e-postadress med länk för att bekräfta e-postadressen.</p>',

  RequestResetPassword_Header: 'Begär nytt lösenord',
  RequestResetPassword_Description:
    'Fyll i din e-postadress för att begära ett nytt lösenord. Välj om du vill få återställningsinstruktioner via e-post eller SMS.',
  RequestResetPassword_Email_Information:
    'Om e-postadressen finns i systemet så kommer ett mail inom kort med instruktioner om hur du gör för att återställa lösenordet.',

  ResetPassword_Header: 'Återställ lösenord',
  ResetPassword_SMS_Information:
    'Om du skrivit in rätt e-postadressen så ska du snart få ett sms med en pinkod som du använder här för att välja ett nytt lösenord',
  Signup_Header: 'Skapa nytt konto',

  Error_Login_Unauthorized: 'Felaktigt användarnamn eller lösenord.',
  Error_Change_Password_Unauthorized: 'Lyckades inte uppdatera. Vänligen kontrollera lösenord.',

  TopNavigation_Back: 'Tillbaka',

  CardDetails_Header: 'Mitt kort',
  CardLookup_Header: 'Ange kortnummer',
  CardLookup_Description: 'Här kan du se och aktivera de biljetter som är kopplade till ditt kort.',

  AddonSelectedList_Label_Addons: 'Tillägg',
  AddonSelectedList_Button_SelectAddon: 'Välj tillägg',
  AddonPickerModal_Header: 'Vilka tillägg vill du köpa?',
  AddonPickerModal_Button_Cancel: 'Avbryt',
  AddonPickerModal_Button_Ok: 'Lägg till',
  AddonPicker_Remove_Addon: 'Ta bort tillägg',
  AddonPicker_Add_Addon: 'Lägg till tillägg',
  AddonPicker_Checkbox: 'En kryssruta för att lägga till eller ta bort tillägg',
  AddonPickerModal_Checkbox_Add_Addon: 'Lägg till',

  Information_Icon_Alt_Text: 'En informationsikon',
  Information_Icon_Clickable_Alt_Text: 'En klickbar informationsikon',

  Passage_Type_oresundBridge: 'Via Öresundsbron',
  Passage_Type_hhFerry: 'Via färjan mellan Helsingborg - Helsingör',
  TapnRide_Card_Details: 'Kortdetaljer (Kortnummer, giltighetsperiod samt kortstatus) visas här',
  TapnRide_Debt_Recovery:
    'Vi försökte dra {0} kr från kortet men misslyckades. Vidare köp är därför spärrade.',
  TapnRide_Link_TransactionHistory: 'Korthistorik',
  TapnRide_Link_TransactionHistory_More: 'Visa mer historik',
  TapnRide_Link_TravelHistory: 'Reshistorik',
  TapnRide_Payment_Action: 'Dra pengar nu',
  TapnRide_Payment_Status_Pending: 'Inväntar betalning',
  TapnRide_Payment_Status_Debited: 'Betald',
  TapnRide_Payment_Status_CaptureFailed: 'Obetalt',
  TapnRide_Payment_receipt: 'Kvitto',
  TapnRide_TransactionHistory_NoData: 'Ingen historik att visa.',
  TapnRide_TravelHistory_NoData: 'Ingen historik att visa.',
  TapnRide_TravelHistory_TableColumn_DateTime: 'Datum & Tid',
  TapnRide_TravelHistory_TableColumn_Zone: 'Zon',
  TapnRide_TravelHistory_TableColumn_Sum: 'Summa',
  TapnRide_TravelHistory_TableColumn_TicketId: 'Referens ID',

  TapnRide_Print_TableHeader_DateTime: 'Datum & Tid',
  TapnRide_Print_TableHeader_Zone: 'Zon',
  TapnRide_Print_TableHeader_Reference: 'Referens ID',
  TapnRide_Print_TableHeader_Sum: 'Summa',
  TapnRide_Print_Summary_Description: 'Betalat',
  TapnRide_Print_Summary_Vat_Description: 'moms',
  TapnRide_Print_Summary_CompanyInfo:
    'Betalningsmottagare Skånetrafiken, momsregistreringsnummer 555-55555. Innehar F-skatt sedel',
  TapnRide_Print_Summary_CompanyInfo_Address1: 'Skånetrafiken',
  TapnRide_Print_Summary_CompanyInfo_Address2: 'SE 281 83 Hässleholm',
  TapnRide_Print_Summary_CompanyInfo_Contact: 'Vid frågor kontakta kundtjänst på 0771-77 77 77',

  Denmark_InspectionReciept_info:
    'När du kopplar Danmarks-biljetter till reskortet behöver du komplettera med ett så kallat inspektionskvitto. Detta kan du få i våra biljettautomater, hos föraren på de gula regionbussarna, eller hos våra fyra kundcenter i Skåne.',

  Confirm_Purchase_Voucher_Checkbox: 'Använd värdekod',
  Confirm_Purchase_Vouchers: 'Värdekoder',
  Voucher_Input_Label: 'Ange din värdekod',
  Voucher_Input_Placeholder: 'Fyll i din värdekod',
  Use_Voucher_Button: 'Använd',
  Voucher_Error_Numeric: 'En värdekod kan bara innehålla siffror.',
  Voucher_Error_8_or_12_Digits: 'Ange värdekod med 8 eller 12 siffror.',
  Voucher_Error_8_Digits:
    'Just nu har vi inte stöd för den här typen av värdekod, endast 12 siffriga koder kan användas.',
  ERROR_VOUCHER_EXPIRED: 'Värdekoden har gått ut.',
  ERROR_VOUCHER_ZERO_BALANCE: 'Värdekoden har 0 kr i saldo.',
  ERROR_VOUCHER_INACTIVE: 'Värdekoden är inte aktiv.',
  ERROR_VOUCHER_NOT_FOUND:
    'Vi hittade ingen värdekod med detta nummer. Kontrollera koden och försök igen.',

  Denmark_InspectionReciept_info:
    'När du kopplar Danmarks-biljetter till reskortet behöver du komplettera med ett så kallat inspektionskvitto. Detta kan du få i våra biljettautomater, hos föraren på de gula regionbussarna, eller hos våra fyra kundcenter i Skåne.',

  PhoneNumber_Format_Validation:
    'Felaktigt format på mobilnummer. Mobilnummer utan landskod anges med 10 siffror. Mobilnummer med landskod inleds med t. ex. +46 eller 0046 och utan inledande nolla i mobilnumret.',
  PhoneNumber_Required_Validation: 'Mobilnummer är obligatoriskt.',
  PhoneNumber_MaxLength_Validation: 'Mobilnummer får max vara 15 tecken långt.',
  PhoneNumber_Placeholder: '0701234567',
  BuyTicketForm_Bearer_Label: 'Välj hur du vill ha din biljett',
  BuyTicketForm_Bearer_App: 'Till app',
  BuyTicketForm_Bearer_Card: 'Till reskort',
  BuyTicketForm_PhoneNumber_Label: 'Mobilnummer',

  ZonePicker_Ferry_Passage_Info:
    'Vi slutar sälja biljetter till färjan mellan Helsingborg och Helsingör den 1 januari 2024. Därför kommer vi automatiskt välja Öresundsbron som överfart till Danmark.<br><strong><a href="https://www.skanetrafiken.se/nyhetsarkiv/farjan-helsingborg---helsingor/" target="_blank">Information om försäljning av färjebiljetter</a></strong>'
};
