<template>
  <div class="stop-area-picker">
    <st-form-group
      :validation="$v.selectedStopAreas"
      :validation-errors="validationErrors.searchStopQuery"
      :alert-error-messages="false"
      :aria-described-by="ariaDescribedBy"
      required
    >
      <template v-slot:label>
        <label id="stopAreaPicker" for="stopAreaPickerInput" tabindex="-1">
          {{ $t('StopAreaPicker_Label') }}
        </label>
      </template>
      <template v-slot:default="{ ariaDescribedBy }">
        <st-autocomplete-input
          id="stopAreaPicker"
          :placeholder="StopAreaPicker_Placeholder"
          @input="getPoints"
          @select="addItem"
          @esc="clearInput"
          @keydownDelete="clearInput"
          @blur="onBlur"
          :autocomplete-suggestions="searchResult"
          :show-list-headline="showListHeadline"
          required
          :invalid="$v.selectedStopAreas.$error"
          :aria-described-by="ariaDescribedBy"
        />
        <div role="group" aria-label="Valda hållplatser" class="stop-area-picker__stops">
          <div
            v-for="(stopArea, index) in selectedStopAreas"
            :key="stopArea.name"
            class="stop-area-picker__stops__stop"
          >
            <span class="stop-area-picker__stops__stop__pin">
              <st-icon role="presentation" name="zone-pin" useSvg size="md" inline />
              <span class="stop-area-picker__stops__stop__pin__text">{{ index + 1 }}</span>
            </span>
            {{ stopArea.name }}
            <st-icon
              role="button"
              :aria-label="`Ta bort ${stopArea.name}`"
              @click.native="removeItem(stopArea)"
              @keyup.enter.native="removeItem(stopArea)"
              name="remove"
              useSvg
              size="md"
              inline
              tabindex="0"
            />
          </div>
        </div>
        <div class="sr-only" aria-live="polite" ref="srMessage" />
      </template>
    </st-form-group>
  </div>
</template>

<script>
import api from '../../../api';
import stopAreaPickerValidationErrors from './stop-area-picker-validation-errors';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { SET_STOP_AREAS } from '../../../store/actions/ticket-actions';
import { requiredIf } from 'vuelidate/lib/validators';
export default {
  props: {
    ariaDescribedBy: {
      type: String
    }
  },
  data() {
    return {
      validationErrors: stopAreaPickerValidationErrors,
      searchResult: [],
      selectedStopAreas: [],
      showListHeadline: false,
      StopAreaPicker_Placeholder: process.env.VUE_APP_STOPAREAPICKER_PLACEHOLDER
    };
  },
  mounted() {
    this.selectedStopAreas = this.stopAreas;
  },
  computed: {
    ...mapGetters(['wcagErrorHandling']),
    ...mapState({
      stopAreas: (state) => state.ticketModule.stopAreas
    })
  },
  methods: {
    ...mapActions({
      setStopAreas: SET_STOP_AREAS
    }),
    ...mapMutations(['setWcagErrors']),
    getPoints(query) {
      if (query.length > 2) {
        const url = `travel-planner/points?query=${encodeURIComponent(query)}`;
        api.get(url).then((result) => {
          this.searchResult = this.filterResult(result.data);
          this.showListHeadline = false;
        });
      } else {
        this.searchResult = [];
      }
    },
    getStopArea(item) {
      const url = `travel-planner/stop-areas?lat=${item.latitude}&lon=${item.longitude}`;
      api.get(url).then((result) => {
        this.searchResult = this.filterResult(result.data).map((stop) => ({
          ...stop,
          type: 'STOP_AREA'
        }));
        this.showListHeadline = true;
      });
    },
    // need to filter out stops that are already added
    filterResult(result) {
      return result
        .filter((item) => {
          return !this.selectedStopAreas.some((stop) => stop.name === item.name);
        })
        .slice(0, 5);
    },
    clearInput() {
      this.searchResult = [];
    },
    addItem(item) {
      if (item.type === 'ADDRESS' || item.type === 'POI') {
        this.getStopArea(item);
      } else {
        this.selectedStopAreas.push(item);
        this.setStopAreas(this.selectedStopAreas);
        this.searchResult = [];
        this.setScreenReaderMessage(`Lägger till ${item.name}`);
      }
    },
    removeItem(item) {
      this.selectedStopAreas = this.selectedStopAreas.filter(
        (stopArea) => item.name !== stopArea.name
      );
      this.setStopAreas(this.selectedStopAreas);
      this.setScreenReaderMessage(`Tar bort ${item.name}`);
    },
    onBlur() {
      this.searchResult = [];
      this.$v.selectedStopAreas.$touch();
    },
    // need to use a timer to remove the message, otherwise the screen reader will read it again then it comes in the DOM
    setScreenReaderMessage(msg) {
      this.$refs.srMessage.innerHTML = msg;
      setTimeout(() => {
        this.$refs.srMessage.innerHTML = '';
      }, 300);
    }
  },
  validations: {
    selectedStopAreas: {
      requiredIf: requiredIf((val) => {
        return val.selectedStopAreas.length < 1;
      })
    }
  },
  watch: {
    'wcagErrorHandling.shouldValidateAll': function (bool) {
      if (bool) {
        this.$v.$touch();
        if (this.$v.$error) {
          this.setWcagErrors({ attr: 'stopPointsHasError', value: true });
          const $el = document.querySelector('#stopAreaPickerInput');
          $el.focus();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/colors.scss';
.stop-area-picker {
  padding-bottom: 1em;

  &__error {
    font-size: rem-calc(14);
    font-weight: $semi-bold;
    color: $danger-color-primary;
    padding: $sm 0;
  }
  &__stops {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $sm;
    &__stop {
      background-color: $varmvit;
      border-radius: rem-calc(16);
      font-size: rem-calc(14);
      font-weight: $semi-bold;
      padding: $xs $sm;
      margin: $sm $sm 0 0;
      &__pin {
        margin-right: $sm;
        &__text {
          position: relative;
          margin-left: rem-calc(-16);
          color: $white;
          font-weight: $regular;
        }
      }
    }
  }
}
</style>
