<template>
  <div class="st-item-counter" :class="disabled ? 'disabled' : ''">
    <label class="item-count-label">*{{ label }}</label>

    <div class="item-count">
      <div class="row normal-screen">
        <div class="col col-5 col-sm-3 bold">*{{ label }}</div>
        <div class="col col-3 col-sm-6 text-center">
          <div class="count-div">
            <st-button class="count-button" @click="decrease" :disabled="!canDecrease" tabindex="0" :aria-label="altDecreaseText">
              <st-icon role="presentation" name="minus" useSvg inline></st-icon>
            </st-button>
            <span class="count-text notranslate" aria-live="assertive" :aria-label="localValue + itemType + ' valda.'">{{ localValue }}</span>
            <st-button class="count-button" @click="increase" :disabled="!canIncrease" tabindex="0" :aria-label="altIncreaseText">
              <st-icon role="presentation" name="plus" useSvg inline></st-icon>
            </st-button>
          </div>
        </div>
        <div aria-live="polite" :aria-label="(price * localValue).toFixed(0) + ' kronor'" class="col col-4 col-sm-3 text-right notranslate">{{ (price * localValue).toFixed(2) }} kr</div>
      </div>
      <div class="row small-screen">
        <div class="col col-6">
          <div class="count-div">
            <st-button class="count-button" @click="decrease" :disabled="!canDecrease" tabindex="0">
              <st-icon name="minus" useSvg inline :alt="altDecreaseText"></st-icon>
            </st-button>
            <span class="count-text notranslate" aria-live="polite">{{ localValue }}</span>
            <st-button class="count-button" @click="increase" :disabled="!canIncrease" tabindex="0">
              <st-icon name="plus" useSvg inline :alt="altIncreaseText"></st-icon>
            </st-button>
          </div>
        </div>
        <div class="col col-6 text-right notranslate">{{ (price * localValue).toFixed(2) }} kr</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'st-item-counter',
  props: {
    value: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    altIncreaseText: {
      type: String,
      default: ''
    },
    altDecreaseText: {
      type: String,
      default: ''
    },
    onIncrease: {
      type: Function
    },
    onDecrease: {
      type: Function
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: this.value
    };
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localValue = newVal;
      }
    },
    localValue: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal);
      }
    }
  },
  methods: {
    decrease() {
      this.localValue -= 1;
    },
    increase() {
      this.localValue += 1;
    }
  },
  computed: {
    canDecrease() {
      return !this.disabled && this.localValue > 1;
    },
    canIncrease() {
      return !this.disabled && this.localValue < 99;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/focus.scss';
.st-item-counter {
  &.disabled {
    opacity: 0.5;
  }
  .normal-screen {
    @media (max-width: 400px) {
      display: none !important;
    }
  }

  .small-screen {
    @media (min-width: 401px) {
      display: none !important;
    }
  }

  .item-count {
    border: 0.0625em solid #807569;
    border-radius: 0.25em;

    .col-5 {
      @media (min-width: 576px) and (max-width: 621px) {
        padding-right: 0 !important;
      }
    }

    .st-icon {
      height: 2em;
      width: 2.8125em;
    }

    .row {
      height: 3em;
      margin: 0em;
      padding: 0.25em 1em 0.25em 0.75em;
      align-items: center;
    }

    .col {
      padding: 0em;
      color: #4d4845;

      &.bold {
        font-size: 1em;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
      }

      .count-div {
        @media (max-width: 400px) {
          justify-content: left;
        }
        height: 2em;
        display: flex;
        align-items: center;

        justify-content: center;

        .count-text {
          vertical-align: middle;
          padding-right: 0.625em;
          padding-left: 0.625em;
        }

        .st-button.count-button {
          background: transparent;
          border: 0.0625em solid #807569;
          width: 2.75em;
          height: 2em;
          padding: 0em;
          border-radius: 0.25em;
          .st-icon {
            height: auto;
            width: 100%;
          }

          &:disabled {
            opacity: 0.5;
          }

          &:hover:not(:disabled),
          &:focus:not(:disabled) {
            background-color: #faf9f7;
          }
        }
      }

      .count-text {
        min-width: 2em;
        font-size: 1em;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;

        &.dark {
          opacity: 1;
        }
      }
    }
  }

  .item-count-label {
    @media (min-width: 401px) {
      display: none !important;
    }

    height: 1.2375em;
    font-size: 0.875em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    display: inline-block;
    margin-bottom: 0.5em;
    text-align: left;
  }
}
</style>
