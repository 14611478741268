// Responsible for storing all valid journeys
// Get journeys by calling GET_JOERNEYS. This should be done when adding/removing a stop area
import api from '../../api';

import {
  GET_JOURNEYS,
  SET_JOURNEYS,
  SET_JOURNEY,
  SET_JOURNEY_BY_PASSAGE_TYPE,
  SET_JOURNEYS_ERROR,
  GET_JOURNEYS_ERROR,
  GET_JOURNEY_POSSIBLE_JOURNEYS,
  GET_JOURNEY_POSSIBLE_JOURNEYS_OPTIONAL_CITIES,
  CLEAR_JOURNEY_STATE
} from '../actions/journey-actions';
import { SET_PRICE_DATA, SET_STOP_AREAS, CLEAR_TICKET_PRICE_DETAILS } from '../actions/ticket-actions';
import { SET_SELECTED_OFFER_ID } from '../actions/ticket-offers-actions';
import { CLEAR_MAP_DETAILS_STATE } from '../actions/map-details-actions';

const state = {
  journeys: [],
  journey: {},
  error: undefined
};

const actions = {
  [GET_JOURNEYS]: ({ commit, dispatch, rootState }, data) => {
    const offerId = rootState.ticketModule.offerId;

    let request = {
      ticketOfferIssued: rootState.ticketOffersModule.ticketOffers.issued,
      coordinates: data
    };

    const url = 'orchestrator/journey';
    return api
      .post(url, request)
      .then((result) => {
        let journeys = [];
        for (let i = 0; i < result.data.offerJourneys.length; i++) {
          const offerJourney = result.data.offerJourneys[i];
          if (offerJourney.possibleJourneys.length > 0) {
            journeys.push(offerJourney);
          }
        }

        commit(SET_JOURNEYS, journeys);
        commit(SET_JOURNEYS_ERROR, undefined);

        dispatch(SET_JOURNEY, offerId);
      })
      .catch((error) => {
        commit(SET_JOURNEYS_ERROR, error.response);
      });
  },
  [SET_JOURNEY]: ({ commit, dispatch, rootState, getters }, offerId) => {
    let offerMatch = false;
    for (let i = 0; i < state.journeys.length; i++) {
      const journey = state.journeys[i];
      if (journey.offerId === offerId) {
        const { possibleJourneys } = journey;
        offerMatch = true;
        commit(SET_JOURNEY, journey);

        if (possibleJourneys.length === 1) {
          dispatch(SET_PRICE_DATA, possibleJourneys[0]);
        } else if (possibleJourneys.length > 1) {
          const journeyMatchingPassageType = possibleJourneys.find(
            (possibleJourney) => possibleJourney.properties.passageType === rootState.passageTypeModule.selectedPassageType.passageType
          );
          if (journeyMatchingPassageType) {
            dispatch(SET_PRICE_DATA, journeyMatchingPassageType);
          }
        }
        break;
      }
    }

    if (!offerMatch && offerId === process.env.VUE_APP_TIRTHY_DAY_METRO_OFFER.toLowerCase()) {
      commit(SET_SELECTED_OFFER_ID, process.env.VUE_APP_START_OFFER);
    }

    if (!offerMatch && !getters.IS_CAMPAIGN_JOUNREY) {
      commit(CLEAR_JOURNEY_STATE);
      commit(SET_STOP_AREAS, []);
      commit(CLEAR_MAP_DETAILS_STATE);
      commit(CLEAR_TICKET_PRICE_DETAILS);
      rootState.ticketModule.travellers = [];
    }
  },
  [SET_JOURNEY_BY_PASSAGE_TYPE]: ({ dispatch, state }, data) => {
    for (let i = 0; i < state.journey.possibleJourneys.length; i++) {
      const possibleJourney = state.journey.possibleJourneys[i];

      if (possibleJourney.priceDetails.priceId == data.originalPriceId && possibleJourney.properties.passageType == data.passageType) {
        dispatch(SET_PRICE_DATA, possibleJourney);
      }
    }
  }
};

const mutations = {
  [SET_JOURNEYS]: (state, payload) => {
    state.journeys = payload;
  },
  [SET_JOURNEY]: (state, payload) => {
    state.journey = payload;
  },
  [SET_JOURNEYS_ERROR]: (state, payload) => {
    state.error = payload;
  },
  [CLEAR_JOURNEY_STATE]: (state) => {
    state.journeys = [];
    state.journey = {};
    state.error = undefined;
  }
};

const getters = {
  [GET_JOURNEY_POSSIBLE_JOURNEYS]: (state) => {
    return state.journey.possibleJourneys;
  },
  [GET_JOURNEYS_ERROR]: (state) => {
    let data = {
      hasError: state.error !== undefined,
      error: state.error
    };

    return data;
  },
  [GET_JOURNEY_POSSIBLE_JOURNEYS_OPTIONAL_CITIES]: (state) => {
    const journey = state.journey.possibleJourneys ? state.journey.possibleJourneys[0] : undefined;
    if (!journey || !journey.priceDetails.cityAddons) return;

    return journey.priceDetails.cityAddons.optionalCities;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
