import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import Start from '@/views/BuynSend/Start.vue';
import BuyCard from '@/views/BuynSend/BuyCard.vue';
import BuyTicket from '@/views/BuynSend/BuyTicket.vue';
import BuyTicketConfirm from '@/views/BuynSend/BuyTicketConfirm.vue';
import CardLookup from '@/views/BuynSend/CardLookup.vue';
import CardDetails from '@/views/BuynSend/CardDetails.vue';
import Payment from '@/views/BuynSend/Payment.vue';
import PaymentCheckout from '@/views/BuynSend/Payment/PaymentCheckout.vue';
import PaymentCancel from '@/views/BuynSend/Payment/PaymentCancel.vue';
import SuccessfulPayment from '@/views/BuynSend/Payment/PaymentSuccess.vue';

let routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    component: Start
  },
  {
    name: 'buy-card',
    path: '/kop-kort',
    component: BuyCard
  },
  {
    name: 'buy-ticket',
    path: '/kop-biljett',
    component: BuyTicket
  },
  {
    name: 'rePurchase-ticket',
    path: '/kop-biljett/:id',
    component: BuyTicket,
    props: route => ({ticketId: route.params.id})
  },
  {
    name: 'buy-ticket-confirm',
    path: '/kop-biljett/bekrafta',
    component: BuyTicketConfirm,
    props: true
  },

  {
    name: 'card',
    path: '/hamta-kort',
    component: CardLookup
  },
  {
    name: 'card-details',
    path: '/hamta-kort/detaljer',
    component: CardDetails,
    props: true
  },
  {
    name: 'payment',
    path: '/payment',
    component: Payment,
    props: true,
    children: [
      {
        name: 'payment-checkout',
        path: 'checkout/:orderId?',
        component: PaymentCheckout,
        props: true
      },
      {
        name: 'payment-success',
        path: 'success',
        component: SuccessfulPayment,
        props: true
      },
      {
        name: 'payment-cancel',
        path: 'cancel',
        component: PaymentCancel,
        props: true
      }
    ]
  }
];

export default new Router({
  mode: 'hash',
  routes
});
