<template>
  <div class="card-lookup">
    <router-link to="/" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>
    <h1>{{ $t('CardLookup_Header') }}</h1>
    <div class="row">
      <div class="col-md-7 col-12">
        <p>{{ $t('CardLookup_Description') }}</p>
        <get-card-form></get-card-form>
      </div>
      <div class="d-md-block col-md-5 card-info-wrapper">
        <card-info-image />
      </div>
    </div>
  </div>
</template>

<script>
import GetCardForm from '../../components/get-card-form/GetCardForm';
import CardInfoImage from '../../components/CardInfoImage';
import globalMixin from '../../mixins/globalMixin';
export default {
  mixins: [globalMixin],
  components: {
    GetCardForm,
    CardInfoImage
  },
  mounted() {
    this.scrollToTopWithOffset();
    document.getElementById('cardNumberInput').focus();
  }
};
</script>

<style lang="scss" scoped>
.card-lookup {
  h1 {
    margin: 0.55555556em 0em 0.5em !important;
    word-break: break-word;
  }

  .card-info-wrapper {
    @media (max-width: 575px) {
      padding-right: 1.875em !important;
      padding-left: 1.875em !important;
    }
    /deep/ .card-img,
    /deep/ .card-img-info {
      @media (min-width: 768px) and (max-width: 992px) {
        width: 80% !important;
      }
    }
  }
}
</style>
