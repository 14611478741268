import Vue from 'vue';
import Vuelidate from 'vuelidate';

import BuynSendApp from './BuynSendApp.vue';
import buynsendRouter from './router/buynsend-router';

import MittKontoApp from './MittKontoApp.vue';
import mittKontoRouter from './router/mittkonto-router';

import TapnRideApp from './TapnRideApp.vue';
import tapnRideRouter from './router/tapnride-router';

import store from './store/index';

import i18n from './i18n/index';

// load all st components
import './assets/st-components';
import { APP_BUY_N_SEND, APP_MITT_KONTO, APP_TAP_N_RIDE } from './models/constants';

String.prototype.equals = function (s) {
  if (!this) {
    return false;
  }
  if (!s) {
    return false;
  }

  return this.toLowerCase() === s.toLowerCase();
};

Vue.config.productionTip = false;
Vue.use(Vuelidate);

Vue.filter('cardNumberFormat', function (value) {
  if (!value) return '';

  return value
    .substr(0, 12)
    .toString()
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
});

Vue.filter('fixedDecimals', function (value, digits = 2) {
  if (!value || typeof value !== 'number') return '0';

  return value.toFixed(digits);
});

if (document.getElementById(APP_BUY_N_SEND)) {
  new Vue({
    router: buynsendRouter,
    store,
    i18n,
    render: (h) => h(BuynSendApp)
  }).$mount(`#${APP_BUY_N_SEND}`);
}

if (document.getElementById(APP_MITT_KONTO)) {
  new Vue({
    router: mittKontoRouter,
    store,
    i18n,
    render: (h) => h(MittKontoApp)
  }).$mount(`#${APP_MITT_KONTO}`);
}
if (document.getElementById(APP_TAP_N_RIDE)) {
  new Vue({
    router: tapnRideRouter,
    store,
    i18n,
    render: (h) => h(TapnRideApp)
  }).$mount(`#${APP_TAP_N_RIDE}`);
}
