export default {
  get(key) {
    return window.localStorage.getItem(key);
  },

  set(key, value) {
    if (key && value) window.localStorage.setItem(key, value);
  },

  clear(key) {
    let item = {};
    if (key) item = localStorage.getItem(key);
    window.localStorage.clear();
    if (key) localStorage.setItem(key, item);
  }
};
