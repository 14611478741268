<template>
  <div class="st-error" role="alert">
    <div class="row">
      <div class="col-12">
        <div class="error-box">
          <div class="error-box-icon">
            <st-icon size="sm" name="ic-error-filled-large" :alt="alt" useSvg></st-icon>
          </div>
          <div class="error-box-text">{{ formattedMessage }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      default: function () {
        return { hasError: false };
      }
    },
    alt: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedMessage() {
      if (this.error.response && this.error.response.data.message) {
        return this.$t(this.error.response.data.message);
      } else if (this.error && this.error.message) {
        return this.error.message;
      } else {
        return this.$t('Error_Unhandled');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Bitter|Source+Sans+Pro:400,600,700');

$source-sans-pro: 'Source Sans Pro';
.st-error {
  margin-top: 1.5em;

  .error-box {
    border-radius: 0.25em;
    background-color: #f8e4e3;
    border: 1px solid #cc4039;
    min-height: 2.25em;
    height: 100%;
    padding: 0.5em 0.9375em;
    vertical-align: middle;

    font-family: $source-sans-pro;
    font-size: 0.875em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #3e3b39;

    display: flex;

    .error-box-icon {
      width: 1.25em;
      margin-right: 0.625em;
      align-self: center;
    }

    .error-box-text {
      flex: 1;
      height: 100%;
      text-align: left !important;
      align-self: center;
    }
  }
}
</style>
