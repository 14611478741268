<template>
  <st-modal class="phone-number-info-modal" :ariaLabelledby="'phone-number-info-header'" :ariaDescribedby="'ci-modal-body-default'" :onClose="close">
    <div slot="header" class="st-modal-header-default">
      <h1 id="phone-number-info-header" tabindex="0">
        {{ $t('PhoneNumberInfoModal_Header') }}
      </h1>
    </div>
    <div slot="body" id="ci-modal-body-default" class="st-modal-body-default">
      <p v-html="$t('PhoneNumberInfoModal_Description')" class="phone-number-info-description"></p>
    </div>
    <div slot="footer" class="st-modal-footer-default">
      <st-button @click="close" @keydown.tab.prevent.native="onCancelKeyDown" medium block :tabindex="tabindex" id="cancel">{{ $t('PhoneNumberInfoModel_Button_Close') }}</st-button>
    </div>
  </st-modal>
</template>

<script>
import globalMixins from '../mixins/globalMixin';
export default {
  mixins: [globalMixins],
  props: {
    close: {
      type: Function
    },
    tabindex: {
      type: String,
      default: '0'
    }
  },
  mounted() {
    const phoneNumberInfoHeader = document.getElementById('phone-number-info-header');
    phoneNumberInfoHeader.focus();

    phoneNumberInfoHeader.addEventListener('keydown', this.onTabShiftBack);
  },
  beforeDestroy() {
    const phoneNumberInfoHeader = document.getElementById('phone-number-info-header');
    phoneNumberInfoHeader.removeEventListener('keydown', this.onTabShiftBack);
  },
  methods: {
    onCancelKeyDown(e) {
      this.cancelKeyDown(e, 'phone-number-info-header');
    },
    onTabShiftBack(e) {
      this.shiftTabBack(e, 'cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.phone-number-info-modal {
  h1 {
    font-size: 1.9375em !important;
    &:focus {
      outline-color: #4d4846;
    }
  }
  .st-modal-header-default {
    padding: 2.25em 1.5em 1.5em !important;
    @media (min-width: 768px) {
      padding: 2.25em 2.625em !important;
    }
  }
}
</style>
