<template>
  <div class="card-details">
    <router-link to="/" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>

    <h1>{{ card.name }}</h1>
    <card-details-info isAuthenticated></card-details-info>

    <card-details-ticket-list></card-details-ticket-list>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { CLEAR_SELECTED_CARD } from '../../../store/actions/card-actions';
import CardDetailsInfo from '../../../components/card-details-info/CardDetailsInfo';
import CardDetailsTicketList from '../../../components/card-details-info/CardDetailsTicketList';
export default {
  components: {
    CardDetailsInfo,
    CardDetailsTicketList
  },
  mounted() {
    if (!this.card) {
      this.$router.push('/');
    } else {
      document.getElementById('mittkonto').scrollIntoView();
    }
  },
  computed: {
    ...mapState({
      card: (state) => state.cardModule.selectedCard
    })
  },
  methods: {
    ...mapMutations({
      clearSelectedCard: CLEAR_SELECTED_CARD
    })
  },
  beforeRouteLeave(to, from, next) {
    this.clearSelectedCard();

    next();
  }
};
</script>

<style lang="scss" scoped>
.card-details {
  h1 {
    font-size: 2.25em !important;
    margin: 0.55555556em 0em 0.5em !important;
  }

  .card-info {
    span {
      font-size: 1em;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: right;
      color: #4d4845;

      b {
        font-weight: bold;
      }
    }
  }
}
</style>
