<template>
  <div class="st-table-mobile">
    <st-table-mobile-item v-for="(item, i) in filteredItems" :key="i" :item="item" :fields="fields" :itemIndex="i" :formattedText="formattedText" :rowClick="rowClick" :useToggle="useToggle" :onToggle="toggle">
      <template slot="item-header-slot">
        <slot name="item-header-slot" :item="item"></slot>
      </template>
      <template v-for="field in fields" :slot="field.key + '-value'">
        <slot :name="field.key + '-value'" :item="item"></slot>
      </template>
      <template slot="item-footer-slot">
        <slot name="item-footer-slot" :item="item"></slot>
      </template>
    </st-table-mobile-item>
  </div>
</template>

<script>
import tableMixin from './tableMixin.js';

export default {
  data() {
    return {
      openedRows: []
    };
  },
  mixins: [tableMixin],
  props: {
    fields: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    useToggle: {
      type: Boolean,
      default: false
    },
    filterKey: String,
    filters: Object,
    rowClick: {
      type: Function
    },
    isExportable: {
      type: Boolean,
      default: false
    },
    exportData: {
      type: Object
    }
  },
  computed: {
    filteredItems: function() {
      var filterKey = this.filterKey && this.filterKey.toLowerCase();
      var filters = this.filters;
      var items = this.items;
      var fields = this.fields;
      // filters är ett objekt där vi filtrerar på varje property
      // varje filter ska ha en "test" metod som som returnerar true/false om den ska synas eller inte
      if (filters) {
        items = this.propertyFilter(filters, items);
      }

      // filterKey kollar på jämför mot värdet på alla fält som skickats in
      if (filterKey) {
        items = this.searchFilter(items, fields, filterKey);
      }

      return items;
    }
  },
  methods: {
    isObject(field) {
      return !field.isArray;
    },

    isArray(field) {
      return field.isArray;
    },

    formattedText(item, field) {
      return this.formatText(item, field);
    },

    toggle(itemIndex, id) {
      if (!this.useToggle) return;

      if (this.rowClick && id) {
        this.rowClick(id);
        return;
      }

      const index = this.openedRows.indexOf(itemIndex);

      if (index > -1) {
        this.openedRows.splice(index, 1);
      } else {
        this.openedRows.push(itemIndex);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.st-table-mobile {
  .onHover {
    cursor: pointer;
  }
}
</style>