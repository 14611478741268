<template>
  <div id="buynsend" class="buy-n-send">
    <div class="app-loading" v-if="!isLoaded">
      <st-spinner :loadingMessage="'Sidan laddar'"/>
    </div>
    <router-view v-else />
    <div lang="en" class="recapthca-text">
      <p v-if="environment === 'bt'">Denna webbplats är skyddad av reCAPTCHA och Googles <a href="https://policies.google.com/privacy" tabindex="0" target="_blank">sekretesspolicy</a> och <a href="https://policies.google.com/terms" tabindex="0" target="_blank">användarvillkor</a> gäller.</p>
      <p v-else>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" tabindex="0" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" tabindex="0" target="_blank">Terms of Service</a> apply.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_TICKET_OFFERS, HAS_VALID_TICKET_OFFERS } from './store/actions/ticket-offers-actions';
import { GET_TRAVEL_AREAS } from './store/actions/travel-area-actions';
import { IS_LOADED, LOAD_DEPENDENCIES, LANGAUGES_LOADED } from './store';
import { APP_BUY_N_SEND } from './models/constants';
import { GET_CARDS } from './store/actions/card-actions';
import cookieService from './services/cookieService';

export default {
  created() {
    this.$store.dispatch(LOAD_DEPENDENCIES, APP_BUY_N_SEND);

    // try {
    //   var userLang = navigator.language || navigator.userLanguage;
    //   if (userLang.indexOf('-') != -1) userLang = userLang.substr(0, userLang.indexOf('-'));

    //   this.$i18n.locale =
    //     'text' +
    //     (' ' + userLang).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr) {
    //       return chr.toUpperCase();
    //     });
    // } catch (error) {
    //   console.error(error);
    // }

    // Varje minut, kolla om det finns ett giltigt biljetterbjudande
    setInterval(() => {
      if (!this.hasValidTicketOffers) {
        this.getTicketOffers();
      }
    }, 1000 * 60);

    let token = cookieService.getTokenFromCookie('StarJwtTokenCookieName');
    if (token) {
      this.$store.dispatch(GET_CARDS);
    }
  },
  computed: {
    ...mapGetters({
      isLoaded: LANGAUGES_LOADED,
      hasValidTicketOffers: HAS_VALID_TICKET_OFFERS
    }),
    environment() {
      return process.env.VUE_APP_SYSTEM;
    }
  },
  methods: {
    ...mapActions({
      getTicketOffers: GET_TICKET_OFFERS
    })
  }
};
</script>

<style lang="scss" scoped>
#buynsend /deep/ {
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  @import '~bootstrap/scss/reboot';
  @import '~bootstrap/scss/grid';
  @import '~bootstrap/scss/utilities';
  @import '~bootstrap/scss/modal';

  @import './assets/scss/colors.scss';
  @import './assets/scss/fonts.scss';
  @import './assets/scss/base.scss';
  @import './assets/scss/focus.scss';

  font-family: $source-sans-pro;
  overflow-x: hidden;

  .app-loading {
    height: 7.5em;
  }

  .price-box {
    border-radius: 0.25em;
    background-color: #f7f6f2;
    margin-top: 1.5em;
    padding: 1.375em 1.125em 1.25em 1.125em;

    span {
      &.small {
        font-size: 1em;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #6e6054;
        vertical-align: bottom;
      }
      &.large {
        padding-top: 0.5em;
        font-size: 1.5em;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.75;
        letter-spacing: normal;
        text-align: right;
        color: #4d4845;
      }
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: $bitter;
    margin-bottom: 0em;
    color: $grey-dark;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: normal;
  }

  h1 {
    font-size: 2.25em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1.125em;
  }

  p {
    color: $grey-brown1;
    font-size: 1.0625em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e6054;
  }

  a {
    color: #4d4846 !important;

    &:hover {
      text-decoration: none;
    }
  }

  .recapthca-text {
    margin-top: 2.5em;
    color: #4d4845 !important;
    p {
      font-size: 0.75em;
      a {
        font-weight: bolder;
        &:hover {
          text-decoration: underline;
          color: $skåneröd-hover !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}
</style>
