<template>
  <div class="price-box">
    <div class="info-left">
      <st-icon :isHoverable="false" :alt="$t('Information_Icon_Alt_Text')" class="card-info" name="info_on" useSvg inline></st-icon>
    </div>
    <div class="info-right">
      {{ getTicketOfferDescription }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_TICKET_OFFER_DESCRIPTION } from '../../store/actions/ticket-offers-actions';
export default {
  name: 'ticket-info-box',
  computed: {
    ...mapGetters({
      getTicketOfferDescription: GET_TICKET_OFFER_DESCRIPTION
    })
  }
};
</script>

<style lang="scss" scoped>
.price-box {
  display: flex;
  .info-left {
    float: left;
    flex: 0 0 2.5em;
    @media (max-width: 576px) {
      flex: 0 0 1.875em;
      .st-icon {
        width: 1.25em;
        height: 1.25em;
      }
    }
  }
  .info-right {
    float: left;
    flex: 1 1 auto;
  }
}
</style>
