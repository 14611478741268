import api from '../api';

export default {
  ['SET_PAYMENT_ON_CARD']: (orderId, requestData) => {
    const url = `card-orders/${orderId}/set-payment`;
    return api.put(url, requestData);
  },
  ['SET_CARD_ORDER']: (orderId, requestData) => {
    const url = `card-orders/${orderId}/set-order`;
    return api.put(url, requestData);
  },
  ['CREATE_TICKET']: (orderId, requestData) => {
    let data = {
      PayexData: null,
      Instrument: null
    };

    if (requestData) {
      data.PayexData = {
        PspOrderId: requestData.paymentOrderId,
        AcquisitionId: requestData.acquisitionId,
        Instrument: requestData.instrument
      };
    }

    const url = `ticket-orders/${orderId}/create-ticket`;
    return api.post(url, data);
  },

  ['GET_TICKET_ORDER_INFO']: (orderId) => {
    const url = `ticket-orders/ticketorderinfo/${orderId}`;
    return api.get(url);
  }
};
