import i18n from '../../i18n/index';

export default {
  email: [
    {
      key: 'required',
      message: i18n.t('ResetPasswordForm_Email_Required_Validation'),
      id: 'emailrequired'
    },
    {
      key: 'email',
      message: i18n.t('ResetPasswordForm_Email_Email_Validation'),
      id: 'emailFormat'
    }
  ]
};
