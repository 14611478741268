class Addon {
  constructor(id, name, addonType, purchasePerTraveller, sortOrder, discountable) {
    this.id = id;
    this.name = name;
    this.addonType = addonType;
    this.purchasePerTraveller = purchasePerTraveller;
    this.sortOrder = sortOrder;
    this.discountable = discountable;
  }
}

export default Addon;
