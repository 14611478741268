import axios from 'axios'

export default class epiService {
    constructor() {
        this.epiSpaUrlEndpoint = process.env.VUE_APP_EPI_SPA_ENDPOINT;
    }


    async getBuyAndSendSpaUrlAsync() {

        /*
            Until BT and ÖT have implemented the Epi endpoint to fetch the URI (as ST has done) the link to BuyAndSend form is hardcoded as a enviroment variable.
             This has to be updated when and if they decide to move this page in their Epi solution
        */

       if(process.env.VUE_APP_SYSTEM.valueOf() == 'bt' || process.env.VUE_APP_SYSTEM.valueOf() == 'ot')
        return this.epiSpaUrlEndpoint;
        
        try {
            if(this.epiSpaUrlEndpoint && this.epiSpaUrlEndpoint.length > 0) {
                let uri;
                 let res = await axios.get(this.epiSpaUrlEndpoint)
                uri =  res.data.ExternalUrl
                return uri;
            }
            return null
        } catch(e) {
            return null;
        }
    } 
}