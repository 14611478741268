import i18n from '../../i18n/index';

export default {
  cardNumber: [
    {
      key: 'required',
      message: i18n.t('CardNumber_Required_Validation'),
      id: 'cardNumberRequired'
    },
    {
      key: 'numericLength',
      message: i18n.t('CardNumber_Format_Validation'),
      id: 'cardNumberLength'
    }
  ],
  phoneNumber: [
    {
      key: 'required',
      message: i18n.t('PhoneNumber_Required_Validation'),
      id: 'phoneRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('PhoneNumber_MaxLength_Validation'),
      id: 'phoneLength'
    },
    {
      key: 'phoneNumberValidation',
      message: i18n.t('PhoneNumber_Format_Validation'),
      id: 'phoneValidation'
    }
  ],
  email: [
    {
      key: 'email',
      message: i18n.t('BuyTicketForm_Email_Email_Validation'),
      id: 'emailFormat'
    },
    {
      key: 'required',
      message: i18n.t('BuyTicketForm_Email_RequiredIf_Validation'),
      id: 'emailRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('BuyTicketForm_Email_MaxLength_Validation'),
      id: 'emailLength'
    }
  ],
  acceptTerms: [
    {
      key: 'required',
      message: i18n.t('BuyTicketForm_AcceptTerms_Required_Validation'),
      id: 'acceptTermsRequired'
    }
  ]
};
