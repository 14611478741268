import Vue from 'vue';

import Button from './button/Button.vue';
import Card from './card/Card.vue';
import Checkbox from './checkbox/Checkbox.vue';
import Error from './error/Error.vue';
import Fieldset from './fieldset/Fieldset.vue';
import FormGroup from './form-group/FormGroup.vue';
import Icon from './icon/Icon.vue';
import Input from './input/Input.vue';
import Modal from './modal/Modal.vue';
import Multiselect from './multiselect/Multiselect';
import ItemCounter from './item-counter/ItemCounter.vue';
import RadioButton from './radio-button/RadioButton.vue';
import Select from './select/Select.vue';
import Spinner from './spinner/Spinner.vue';
import Status from './status/Status.vue';
import Table from './table/Table.vue';
import Toggle from './toggle/Toggle.vue';
import TableMobile from './table/TableMobile.vue';
import TableMobileItem from './table/TableMobileItem.vue';
import AutocompleteInput from './autocomplete-input/AutocompleteInput.vue';

Vue.component('st-button', Button);
Vue.component('st-card', Card);
Vue.component('st-checkbox', Checkbox);
Vue.component('st-error', Error);
Vue.component('st-fieldset', Fieldset);
Vue.component('st-form-group', FormGroup);
Vue.component('st-icon', Icon);
Vue.component('st-input', Input);
Vue.component('st-modal', Modal);
Vue.component('st-multiselect', Multiselect);
Vue.component('st-item-counter', ItemCounter);
Vue.component('st-radio-button', RadioButton);
Vue.component('st-select', Select);
Vue.component('st-spinner', Spinner);
Vue.component('st-status', Status);
Vue.component('st-table', Table);
Vue.component('st-toggle', Toggle);
Vue.component('st-table-mobile', TableMobile);
Vue.component('st-table-mobile-item', TableMobileItem);
Vue.component('st-autocomplete-input', AutocompleteInput);
