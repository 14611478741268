<template>
  <div class="price-box">
    <div class="row" v-for="discount in discounts" :key="discount.id" role="text" :aria-label="`${discount.name}: -${discount.amount} kronor`">
      <div aria-hidden="true" class="col-6 text-left discount-name">
        <span class="small">{{ discount.name }}</span>
      </div>
      <div aria-hidden="true" class="col-6 text-right">
        <span class="small notranslate">-{{ discount.amount | fixedDecimals }} kr</span>
      </div>
    </div>
    <div role="text" class="row" v-if="voucherAmountUsed" :aria-label="`${$t('Confirm_Purchase_Vouchers')}: ${voucherAmountUsed} kronor`">
      <div aria-hidden="true" class="col-6 text-left">
        <span class="small">{{ $t('Confirm_Purchase_Vouchers') }}</span>
      </div>
      <div aria-hidden="true" class="col-6 text-right">
        <span class="small notranslate">-{{ voucherAmountUsed | fixedDecimals }} kr</span>
      </div>
    </div>
    <div role="text" class="row total-price-row" :aria-label="`${$t('BuyTicketForm_ToPay_Label')}: ${amountDue} kronor`">
      <div aria-hidden="true" class="col col-6 text-left">
        <span class="large">{{ $t('BuyTicketForm_ToPay_Label') }}</span>
      </div>
      <div aria-hidden="true" class="col-6 text-right">
        <span class="large notranslate">{{ amountDue | fixedDecimals }} kr</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { GET_DISCOUNTS, GET_VOUCHER_AMOUNT_USED, GET_AMOUNT_DUE } from '../../store/actions/ticket-actions';

export default {
  computed: {
    ...mapGetters({
      discounts: GET_DISCOUNTS,
      amountDue: GET_AMOUNT_DUE,
      voucherAmountUsed: GET_VOUCHER_AMOUNT_USED
    })
  }
};
</script>

<style lang="scss" scoped>
.price-box {
  .total-price-row {
    margin-top: 0.375em;
  }

  .discount-name {
    word-break: break-word;
  }
}
</style>
