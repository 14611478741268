<template>
  <span class="st-radio">
    <input type="radio" :id="id" :name="name" :value="value" :required="required" :disabled="disabled" @change="onChange" :checked="state" :tabindex="tabindex" />
    <label :for="id">
      <slot name="input-box">
        <span class="input-box">
          <span class="input-box-circle"></span>
        </span>
      </slot>
      <slot>default text</slot>
    </label>
  </span>
</template>

<script>
export default {
  name: 'st-radio',
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return 'st-radio-id-' + this._uid;
      }
    },
    name: {
      type: String,
      default: null
    },
    value: {
      default: ''
    },
    modelValue: {
      default: undefined
    },
    checked: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    model: {}
  },
  computed: {
    classList: function () {
      return ['st-radio'];
    },
    state: function () {
      if (this.modelValue === undefined) {
        return this.checked;
      }

      if (typeof this.modelValue === 'object' && typeof this.value === 'object') {
        return JSON.stringify(this.modelValue) === JSON.stringify(this.value);
      }

      return this.modelValue === this.value;
    }
  },
  methods: {
    onChange: function () {
      this.toggle();
    },
    toggle: function () {
      this.$emit('input', this.state ? '' : this.value);
    }
  },
  watch: {
    checked: function (newValue) {
      if (newValue !== this.state) {
        this.toggle();
      }
    }
  },
  mounted: function () {
    if (this.checked && !this.state) {
      this.toggle();
    }
  }
};
</script>

<style lang="scss" scoped>
.st-radio {
  display: inline;
  padding-right: 1em;
  > input {
    opacity: 0;
    position: absolute;
    + label > .input-box {
      display: inline-block;
      border: solid 0.125em #6e6054;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      width: 1.25em;
      height: 1.25em;
      background: #fff;
      overflow: hidden;
      vertical-align: -12%;
      margin-right: 0.25em;
      user-select: none;
      > .input-box-circle {
        display: block;
        margin: 50%;
        width: 0%;
        height: 0%;
        background: #6e6054;
        border-radius: 50%;
        opacity: 0;
      }
    }
    &:checked + label > .input-box {
      border: solid 0.125em #6e6054;
    }
    &:focus + label > .input-box {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px #6e6054;
    }
    &:checked + label > .input-box > .input-box-circle {
      opacity: 1;
      margin: 10%;
      width: 80%;
      height: 80%;
    }

    &:disabled + label {
      opacity: 0.7;
    }
  }

  label {
    font-size: 1.125em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #4d4845;
    display: inline-block !important;
  }
}
</style>
