import globalFunctionsService from './globalFunctionsService';

import TickerOffersService from './ticketOffersService';
import Price from '../models/price';
import Discount from '../models/discount';

let instance = null;
class PriceService {
  constructor() {
    if (!instance) {
      instance = this;

      this.ticketOffersService = new TickerOffersService();
    }

    return instance;
  }

  calculatePrice = function(travellerType, offerId, priceModel, priceId, cityAddonsIds) {
    let price = this.ticketOffersService.getPrice(offerId, priceModel, priceId);
    let addonPrice = this.ticketOffersService.getAddonPrice(offerId, priceModel, priceId, cityAddonsIds);

    let amountIncludingVat = (price.amountIncludingVat * (100 - travellerType.discountPercentage)) / 100;
    if (addonPrice) {

      if (addonPrice && addonPrice.discountable) {
        amountIncludingVat += (addonPrice.amountIncludingVat * (100 - travellerType.discountPercentage)) / 100;
      } else {
        amountIncludingVat += addonPrice.amountIncludingVat;
      }
    }

    return new Price(amountIncludingVat, price.vatPercentage);
  };

  calculateDiscount = function(travellers, offerId, priceModel, priceId, cityAddonsIds, addons) {
    let discounts = [];

    if (!offerId || offerId === '') return discounts;

    if (!priceModel || priceModel === '') return discounts;

    if (!priceId || priceId === '') return discounts;

    let offer = this.ticketOffersService.getOffer(offerId);
    if (!offer) return discounts;

    if (!offer.priceModels[priceModel]) return discounts;

    const availableGroupDiscountId = offer.priceModels[priceModel].availableGroupDiscountId;

    let ticketOffers = this.ticketOffersService.getTicketOffers();

    let discountGroup;
    for (let i = 0; i < ticketOffers.groupDiscounts.length; i++) {
      if (ticketOffers.groupDiscounts[i].id === availableGroupDiscountId) {
        discountGroup = ticketOffers.groupDiscounts[i];
        break;
      }
    }
    if (!discountGroup) return discounts;

    if (travellers.length >= discountGroup.minimumTravellers) {
      let price = this.ticketOffersService.getPrice(offerId, priceModel, priceId);

      // addonprices är de tilläg man lägger till via addon picker
      let addonPrices = [];
      for (let j = 0; j < addons.length; j++) {
        const addon = addons[j];

        if (addon && addon.discountable) {
          let addonPrice = this.getAddonPrice(addon.id, priceModel, priceId);
          addonPrices.push(addonPrice);
        }
      }

      // Obligatorisk stadszon räknas som ett tillägg
      let addonPrice = this.ticketOffersService.getAddonPrice(offerId, priceModel, priceId, cityAddonsIds);

      let sum = 0;
      let sumNotDiscountable = 0;
      for (let j = 0; j < travellers.length; j++) {
        sum += (price.amountIncludingVat * (100 - travellers[j].travellerType.discountPercentage)) / 100;
        if (addonPrice) {
          if (addonPrice.discountable) {
            sum += (addonPrice.amountIncludingVat * (100 - travellers[j].travellerType.discountPercentage)) / 100;
          } else {
            sumNotDiscountable += addonPrice.amountIncludingVat;
          }
        }
        for (let k = 0; k < addonPrices.length; k++) {
          const addon = addonPrices[k];

          if (addon && addon.discountable) {
            sum += (addon.amountIncludingVat * (100 - travellers[j].travellerType.discountPercentage)) / 100;
          } else {
            sumNotDiscountable += addon.amountIncludingVat;
          }
        }
      }

      let amount = Math.round(sum * discountGroup.percentage) / 100;

      discounts.push(new Discount(discountGroup.id, discountGroup.name.sv, discountGroup.percentage, amount));
    }

    return discounts;
  };

  getAddonPrice = function(addonId, priceModel, priceId) {
    return this.ticketOffersService.getAddonPrice2(addonId, priceModel, priceId);
  };

  getCityAddon = function(offerId, priceModel, priceId, cityAddonsIds) {
    if (!offerId || !priceModel || !priceId || !cityAddonsIds) return;

    return this.ticketOffersService.getCityAddon(offerId, priceModel, priceId, cityAddonsIds);
  };
}

export default PriceService;
