import Vue from 'vue';
import VueI18n from 'vue-i18n';
import languages from './languages/languages';

Vue.use(VueI18n);

//To change locale outside of this class, use ex: "this.$i18n.locale = 'fr'"
export default new VueI18n({
  locale: 'textSv',
  fallbackLocale: 'textSv',
  messages: languages
});
