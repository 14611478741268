<template>
  <div class="reset-password">
    <router-link to="/konto" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>
    <h1>{{ $t('ResetPassword_Header') }}</h1>
    <reset-password-form :email="email" :showPinInput="showPinInput"></reset-password-form>
  </div>
</template>

<script>
import ResetPasswordForm from '../../../components/reset-password-form/ResetPasswordForm.vue';
export default {
  props: ['email', 'showPinInput'],
  components: {
    ResetPasswordForm
  }
};
</script>

<style lang="scss" scoped>
.reset-password {
  h1 {
    font-size: 2.25em !important;
    margin: 0.55555556em 0em 0.5em !important;
  }
}
</style>
