var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"personal-data-from",on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('st-form-group',{attrs:{"id":"firstName","label":"Förnamn","required":"","validation":_vm.$v.model.firstName,"validationErrors":_vm.validationErrors.firstName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"firstName","aria-describedby":ariaDescribedBy,"required":"","invalid":_vm.$v.model.firstName.$error,"autocomplete":"given-name","blur":_vm.$v.model.firstName.$touch,"tabindex":"0"},model:{value:(_vm.model.firstName),callback:function ($$v) {_vm.$set(_vm.model, "firstName", $$v)},expression:"model.firstName"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('st-form-group',{attrs:{"id":"lastName","label":"Efternamn","required":"","validation":_vm.$v.model.lastName,"validationErrors":_vm.validationErrors.lastName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"lastName","aria-describedby":ariaDescribedBy,"required":"","invalid":_vm.$v.model.lastName.$error,"autocomplete":"family-name","blur":_vm.$v.model.lastName.$touch,"tabindex":"0"},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", $$v)},expression:"model.lastName"}})]}}])})],1)]),_c('st-form-group',{attrs:{"id":"street","label":"Adress","required":"","validation":_vm.$v.model.street,"validationErrors":_vm.validationErrors.street},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"street","aria-describedby":ariaDescribedBy,"required":"","invalid":_vm.$v.model.street.$error,"autocomplete":"street-address","blur":_vm.$v.model.street.$touch,"tabindex":"0"},model:{value:(_vm.model.street),callback:function ($$v) {_vm.$set(_vm.model, "street", $$v)},expression:"model.street"}})]}}])}),_c('st-form-group',{attrs:{"id":"co","label":"c/o","validation":_vm.$v.model.co,"validationErrors":_vm.validationErrors.co},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"co","aria-describedby":ariaDescribedBy,"invalid":_vm.$v.model.co.$error,"blur":_vm.$v.model.co.$touch,"tabindex":"0"},model:{value:(_vm.model.co),callback:function ($$v) {_vm.$set(_vm.model, "co", $$v)},expression:"model.co"}})]}}])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('st-form-group',{attrs:{"id":"postalCode","label":"Postnummer","required":"","validation":_vm.$v.model.postalCode,"validationErrors":_vm.validationErrors.postalCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"postalCode","aria-describedby":ariaDescribedBy,"required":"","invalid":_vm.$v.model.postalCode.$error,"autocomplete":"postal-code","blur":_vm.$v.model.postalCode.$touch,"tabindex":"0"},model:{value:(_vm.model.postalCode),callback:function ($$v) {_vm.$set(_vm.model, "postalCode", $$v)},expression:"model.postalCode"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-8"},[_c('st-form-group',{attrs:{"id":"city","label":"Ort","required":"","validation":_vm.$v.model.city,"validationErrors":_vm.validationErrors.city},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"city","aria-describedby":ariaDescribedBy,"required":"","invalid":_vm.$v.model.city.$error,"autocomplete":"address-level2","blur":_vm.$v.model.city.$touch,"tabindex":"0"},model:{value:(_vm.model.city),callback:function ($$v) {_vm.$set(_vm.model, "city", $$v)},expression:"model.city"}})]}}])})],1)]),_c('st-form-group',{attrs:{"id":"country","label":"Välj land","required":"","validation":_vm.$v.model.country,"validationErrors":_vm.validationErrors.country},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-select',{attrs:{"id":"country","aria-describedby":ariaDescribedBy,"items":_vm.countries,"required":"","invalid":_vm.$v.model.country.$error,"blur":_vm.$v.model.country.$touch,"tabindex":"0"},model:{value:(_vm.model.country),callback:function ($$v) {_vm.$set(_vm.model, "country", $$v)},expression:"model.country"}})]}}])}),_c('st-form-group',{attrs:{"id":"mobile","label":"Mobilnummer","required":"","validation":_vm.$v.model.mobile,"validationErrors":_vm.validationErrors.mobile},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"aria-describedby":ariaDescribedBy,"id":"mobile","required":"","invalid":_vm.$v.model.mobile.$error,"autocomplete":"tel","blur":_vm.$v.model.mobile.$touch,"tabindex":"0"},model:{value:(_vm.model.mobile),callback:function ($$v) {_vm.$set(_vm.model, "mobile", $$v)},expression:"model.mobile"}})]}}])}),_c('div',{staticClass:"edit-form-btn-wrapper"},[_c('st-button',{staticClass:"cancel",attrs:{"medium":"","disabled":_vm.isLoading,"tabindex":"0"},on:{"click":_vm.toPersonalDataRoute}},[_vm._v("Avbryt")]),_c('st-button',{ref:"submitPersonalDataBtn",staticClass:"submit",attrs:{"medium":"","primary":"","disabled":_vm.$v.$invalid || _vm.isLoading,"type":"submit","tabindex":"0"}},[_vm._v("Spara")])],1),(_vm.error)?_c('st-error',{attrs:{"error":_vm.error}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }