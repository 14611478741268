export default {
  roundTo: function(n, digits) {
    var negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }

    return parseFloat(n);
  },

  deepClone: function(obj) {
    function clone(obj, traversedObjects) {
      var copy;
      // primitive types
      if (obj === null || typeof obj !== 'object') {
        return obj;
      }

      // dates
      if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
      }
      // arrays
      if (obj instanceof Array) {
        copy = [];
        for (var i = 0; i < obj.length; i++) {
          copy.push(clone(obj[i], traversedObjects.concat(obj)));
        }
        return copy;
      }
      // simple objects
      if (obj instanceof Object) {
        copy = {};
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
            copy[key] = clone(obj[key], traversedObjects.concat(obj));
          }
        }
        return copy;
      }
      throw new Error('Not a cloneable object.');
    }
    return clone(obj, []);
  }
};
