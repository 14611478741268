<template>
  <div class="parent-payment">
    <router-link :to="url" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>

    <router-view></router-view>
  </div>
</template>

<script>
import globalMixin from '../../mixins/globalMixin';
export default {
  mixins: [globalMixin],
  props: {
    backUrl: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      url: this.backUrl
    };
  },
  mounted() {
    this.scrollToTopWithOffset();
  }
};
</script>

<style lang="scss" scoped>
.parent-payment {
  .payment {
    margin: auto;
    max-width: 42.5em;
    text-align: center;
    margin-top: 1.25em;
    min-height: 46.875em;
  }
}
</style>
