<template>
  <div class="st-card-list-mobile">
    <div class="row st-card-row" v-for="(item, index) in items" :key="index" :style="index === items.length - 1 ? 'margin-bottom:0;' : ''">
      <div class="col-12" v-on:click="click(item.id)">
        <div class="st-col-content">
          <div class="st-img-container">
            <img class="st-img-content" :src="cardSandSrc" :alt="$t('Start_BuyCard_Alt_Text')" />
          </div>
          <div class="st-text-container">
            <div class="st-text-content">
              <span class="st-card-text-name">{{ item.name }}</span>
              <span class="st-card-text-number">{{ item.cardNumber | cardNumberFormat }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'st-card-list-mobile',
  props: {
    items: {
      type: Array,
      required: true
    },
    click: {
      type: Function
    }
  },
  data() {
    return {
      cardSandSrc: `${process.env.VUE_APP_BASE_URL}assets/images/${process.env.VUE_APP_SYSTEM}/card-sand.svg`
    };
  }
};
</script>

<style lang="scss" scoped>
.st-card-list-mobile {
  .st-col-content {
    height: 100%;
    display: flex;
    align-items: center;

    .st-text-container {
      .st-text-content {
        .st-card-text-name {
          display: block;
          font-weight: 600;
          letter-spacing: normal;
          color: #4d4845;
        }
      }
    }
    .st-img-container {
      .st-img-content {
        height: auto;
        margin-right: 1em;
      }
    }
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;
    margin-bottom: 0.5em;
  }

  .col-12 {
    word-break: break-all;
  }

  .st-card-row {
    min-height: 5.125em;
    width: 100%;
    background-color: rgba(247, 246, 242, 0.65);
    border: 0.0625em solid rgba(214, 211, 208, 0.65);
    border-radius: 0.375em;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
