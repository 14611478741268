<template>
<div v-if="!ticketOffersNamesLoaded" class="loaderWrapper">
    <st-spinner :loadingMessage="'Sidan laddar'"/>
</div>
  <st-modal v-else class="activate-ticket-on-purchase-modal" :ariaLabelledby="'at-purchase-header'" :ariaDescribedby="'at-purchase-body-default'" :onClose="close">
    <div slot="header" class="st-modal-header-default">
      <h1 id="at-purchase-header">{{ $t('ActivateTicketOnPurchaseRequiredModal_Header') }}</h1>
    </div>
    <div slot="body" class="st-modal-body-default" id="at-purchase-body-default">
      <p class="top-text" v-html="$t('ActivateTicketOnPurchaseRequiredModal_Description1')" />
      <div class="st-modal-body-content-container">
        <div class="desktop-content">
          <div class="desktop d-none d-sm-flex justify-content-between" style="margin-bottom: 0.375em">
            <span class="zone col-sm-6 nopadding">
              {{ journeyName(model.priceModelId, model.priceId, model.cityAddons, model.offerId) }}
              <div class="desktop d-none d-sm-flex">
                <span class="stop-points">{{truncateStopPoints,}}</span>
              </div>
            </span>
            <span class="offer col-sm-3 nopadding text-left">{{ offerName(model.offerId) }}</span>
            <div class="col-sm-3 pl-sm-2 pr-sm-0">
              <traveller-type-list class="desktop d-none d-sm-flex flex-column text-left" :travellers="model.travellers"></traveller-type-list>
            </div>
          </div>
          <div v-if="addons.length > 0" class="addon-wrapper desktop d-none d-sm-flex">
            <div class="col-xs-2">Tillägg:</div>
            <div class="addons col-xs-8 text-left">
              <div v-for="(item, index) in addons" :key="index">
                <span>{{ wordSplitWithLength(item.name, 0, 10) }}</span>
                <span>&nbsp;{{ wordSplitWithLength(item.name, 10) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="mobile">
          <div class="row">
            <div class="col-12">
              <span class="zone">{{ journeyName(model.priceModelId, model.priceId, model.cityAddons, model.offerId) }}</span>
            </div>
            <div class="col-12">
              <span class="stop-points">{{model.stopPoints.map(e => e.name).join(', '),}}</span>
            </div>
            <div class="col-12">
              <span>{{ offerName(model.offerId) }}</span>
            </div>
            <div class="col-12">
              <traveller-type-list class="mobile-travellers d-flex flex-column" :travellers="model.travellers"></traveller-type-list>
            </div>
            <div class="col-12">
              <div v-for="(item, index) in addons" :key="index">{{ item.name }}</div>
            </div>
          </div>
        </div>

        <div class="mobile-small">
          <div class="row">
            <div class="col-12" style="margin-bottom: 0em">
              <span class="zone">{{ journeyName(model.priceModelId, model.priceId, model.cityAddons, model.offerId) }}</span>
            </div>
            <div class="col-12" style="margin-bottom: 0em">
              <span class="stop-points">{{model.stopPoints.map(e => e.name).join(', '),}}</span>
            </div>
            <div class="col-12">
              <span>{{ offerName(model.offerId) }}</span>
            </div>
            <div class="col-12">
              <traveller-type-list class="mobile-travellers d-flex flex-column" :travellers="model.travellers"></traveller-type-list>
            </div>
            <div class="col-12" v-for="(item, index) in addons" :key="index">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <p class="bottom-text" v-html="$t('ActivateTicketOnPurchaseRequiredModal_Description2')"></p>
    </div>
    <div ref="footer" slot="footer" class="st-modal-footer-default">
      <div class="st-modal-footer-content d-none d-sm-flex">
        <st-button @click="close(false)" @keydown.tab.prevent.native="onCancelKeyDown" id="cancel" medium block style="margin-right: 1em" tabindex="1">{{ $t('ActivateTicketOnPurchaseRequiredModal_Button_No') }}</st-button>
        <st-button @click="close(true)" @keydown.tab.prevent.native="onSubmitKeyDown" id="submit" medium block primary tabindex="2">{{ $t('ActivateTicketOnPurchaseRequiredModal_Button_Yes') }}</st-button>
      </div>
      <div class="d-sm-none">
        <div class="row">
          <div class="col-12">
            <st-button @click="close(true)" primary medium block style="margin-bottom: 1em">{{ $t('ActivateTicketOnPurchaseRequiredModal_Button_Yes') }}</st-button>
          </div>
          <div class="col-12">
            <st-button @click="close(false)" medium block>{{ $t('ActivateTicketOnPurchaseRequiredModal_Button_No') }}</st-button>
          </div>
        </div>
      </div>
    </div>
  </st-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_TICKET_OFFERS_OFFERS_NAME } from '../../store/actions/ticket-offers-actions';

import TravellerTypeList from '../TravellerTypeList';
import globalMixins from '../../mixins/globalMixin.js';
export default {
  mixins: [globalMixins],
  props: ['close', 'model'],
  components: {
    TravellerTypeList
  },
  computed: {
    addons() {
      let addons = [];
      if (this.model.bikeAddons.length) {
        for (let j = 0; j < this.model.bikeAddons.length; j++) {
          const bikeAddon = this.model.bikeAddons[j];
          addons.push({ addonType: 1, name: `${bikeAddon.count} ${bikeAddon.name}` });
        }
      }

      if (this.model.firstClassAddons.length) {
        for (let j = 0; j < this.model.firstClassAddons.length; j++) {
          const firstClassAddon = this.model.firstClassAddons[j];
          addons.push({ addonType: 3, name: `${firstClassAddon.name}` });
        }
      }

      return addons;
    },
    truncateStopPoints() {
      let stopPointsToString = this.model.stopPoints.map((e) => e.name).join(', ');
      if (stopPointsToString.length > 50) {
        return stopPointsToString.substring(0, 50) + '...';
      }
      return stopPointsToString;
    }
  },
  methods: {
    wordSplitWithLength(value, start, length) {
      if (!value) return '';
      let values = [];
      let sumLength = 0;
      let startIndex = 0;
      const parts = value.split(' ');
      if (start > 0) {
        for (let i = 0; i < parts.length; i++) {
          const part = parts[i];
          sumLength += part.length;
          if (sumLength >= start) {
            startIndex = i;
            break;
          }
        }
      }
      for (let i = startIndex; i < parts.length; i++) {
        const part = parts[i];
        sumLength += part.length;
        if (length && sumLength >= length) break;
        values.push(part);
      }
      return values.join(' ');
    },
    onCancelKeyDown(e) {
      this.cancelKeyDown(e, 'cancel', 'submit');
    },
    onSubmitKeyDown(e) {
      this.submitKeyDown(e, 'cancel');
    }
  },
  mounted() {
    // Tabbning, focusera på första knappen i dialogen.
    this.$refs.footer.getElementsByTagName('button')[0].focus();
  }
};
</script>

<style lang="scss" scoped>
$font-color-headers: #4d4845;
$font-color-text: #6e6054;

h1 {
  font-size: 1.9375em !important;
}

.activate-ticket-on-purchase-modal {
  .st-modal-body-default {
    .st-modal-body-content-container {
      border-radius: 0.5em;
      padding: 0.5em 0.875em;
      background: #f9f8f5;
      .desktop-content {
        .addon-wrapper {
          border-top: 0.0625em solid #d6d3d0;
          padding-top: 0.625em;
          font-size: 0.875em;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $font-color-text;
          .addons {
            padding-left: 0.3125em;
            span {
              &:first-child {
                font-weight: 600;
              }
            }
          }
        }
      }
      .desktop {
        margin-bottom: 0.375em;
        .offer {
          font-weight: 600;
          color: $font-color-headers;
        }
      }

      .mobile-travellers {
        margin: 0 !important;
      }

      .mobile-small {
        display: none;

        .col-12 {
          text-align: left;
          margin-bottom: 0.375em;
        }

        @media (max-width: 444px) {
          display: block;
        }
      }

      .mobile {
        display: none;

        .col-5,
        .col-7,
        .col-12 {
          margin-bottom: 0em;
        }

        .col-12 {
          text-align: left;
        }

        .col-7 {
          margin-bottom: 0.375em;
          text-align: right;
        }

        .col-5 {
          text-align: left;
          margin-bottom: 0.375em;
        }

        @media (min-width: 445px) and (max-width: 575px) {
          display: block;
        }
      }

      .nopadding {
        padding: 0 !important;
        margin: 0 !important;
      }

      .zone {
        text-align: start;
        font-size: 1em;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $font-color-headers;
      }

      .stop-points {
        font-size: 0.875em;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $font-color-text;
      }
    }

    .top-text {
      margin-bottom: 1em !important;
    }

    .bottom-text {
      margin-top: 1em !important;
    }
  }

  .st-modal-footer-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
}
</style>
