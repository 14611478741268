<template>
  <div class="account">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    document.getElementById('mittkonto').scrollIntoView();
  },
};
</script>