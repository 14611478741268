<template>
  <div class="transaction-details">
    <router-link to="/" class="top-link">{{ $t('TopNavigation_Back') }}</router-link>
    <div class="row">
      <div class="col-12">
        <!-- <p>{{ $t('TapnRide_Card_Details') }}</p> -->
        <!-- <button @click="retrieveTransactions">Testa API</button> -->
        <!-- Actual card info -->
        <!-- <credit-card-details-info :data="data"></credit-card-details-info> -->
      </div>
      <!-- Dummy data -->
      <!-- <div class="col-12 col-md-4 col-lg-3">
        <div class="d-none d-md-block" style="float:left;">
          <img style="width: 10.3125rem;height: 6.8125rem;" :src="`/assets/images/card-sand.svg`" alt />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5">
        <label for="card-number">Kortnummer:</label>
        <span class="card-number">**** **** **** 1111</span>
        <br />
        <label for="card-expiration-date">Giltig t.o.m:</label>
        <span class="card-expiration-date">12/21</span>
        <br />
        <label for="card-status">Kortstatus:</label>
        <span class="card-status">Spärrat</span>
      </div>-->
      <div class="settle-debt" v-if="hasDebt">
        <div class="row">
          <div class="col-md-1">
            <st-icon size="lg" name="varning" class="warning-icon"></st-icon>
          </div>
          <div class="col-12 col-md-8">
            <span class="settle-debt-text">{{ $t('TapnRide_Debt_Recovery', { 0: debtSum.toString() }) }}</span>
          </div>
          <div class="col-12 col-md-3 text-center">
            <st-button class="settle-debt-btn" @click="recoverDebt">{{ $t('TapnRide_Payment_Action') }}</st-button>
          </div>
        </div>
      </div>

      <div class="col-12">
        <!--         <p>Korthistorik för de 7 senaste dagarna skall visas här (Datum, summa, status, ikon)</p>
        <st-icon
          size="md"
          isHoverable
          inline
          useSvg
          name="info"
          @click.native="openHistoryPerDay()"
          class="info-icon"
        />-->

        <div class="row" id="navbar">
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 text-center">
            <router-link class="navigation" id="navigation-transactions" to="/visa-transaktioner">{{ $t('TapnRide_Link_TransactionHistory') }}</router-link>
          </div>
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 text-center">
            <router-link id="navigation-history" to="/visa-transaktioner-per-dag">{{ $t('TapnRide_Link_TravelHistory') }}</router-link>
          </div>
        </div>

        <div v-if="!cardTransactionsData || cardTransactionsData.transactions.length < 1">
          <p>{{ $t('TapnRide_TransactionHistory_NoData') }}</p>
        </div>
        <div v-else>
          <transaction-details-info v-if="cardTransactionsData && hmac" :transactionList="cardTransactionsData.transactions"></transaction-details-info>
        </div>
      </div>
      <div class="col-12 text-center show-more-history-container">
        <span class="show-more-history">{{ $t('TapnRide_Link_TransactionHistory_More') }}</span>
        <img :src="imgSrc" :alt="$t('CardDetailsTicketList_Arrow_Down')" />
      </div>
    </div>
  </div>
</template>

<script>
import CreditCardDetailsInfo from '../../components/transaction-details-info/CreditCardDetailsInfo';
import TransactionDetailsInfo from '../../components/transaction-details-info/TransactionDetailsInfo';
import { mapGetters, mapActions } from 'vuex';
import { GET_TRANSACTION, GET_HMAC, PAY_DEBT } from '../../store/actions/tapnride-actions';

export default {
  props: {
    data: {
      type: Object
    }
  },
  components: {
    CreditCardDetailsInfo,
    TransactionDetailsInfo
  },
  mounted() {
    if (!this.hmac) {
      this.$router.push('/');
    }
  },
  data() {
    return {
      cardTransactionsData: undefined,
      postTransactionData: undefined,
      debtSum: 0,
      receiptData: undefined
    };
  },
  computed: {
    ...mapGetters({
      hmac: GET_HMAC
    }),
    hasDebt: function () {
      if (!this.cardTransactionsData) return false;

      return this.cardTransactionsData.isOnDenyList;
    },
    imgSrc: function () {
      return `${process.env.VUE_APP_BASE_URL}assets/icons/${process.env.VUE_APP_SYSTEM}/down.svg`;
    }
  },
  watch: {
    hmac: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        if (!val) return;

        this.generateTransactionData();
        this.retrieveTransactions();
      }
    }
  },
  methods: {
    ...mapActions({
      getTransaction: GET_TRANSACTION,
      payDebt: PAY_DEBT
    }),
    openHistoryPerDay() {
      this.$router.push('/visa-transaktioner-per-dag');
    },
    generateTransactionData() {
      if (!this.hmac) {
        return;
      }

      this.postTransactionData = {
        TransactionId: this.hmac.transactionId
      };
    },
    retrieveTransactions() {
      let _this = this;

      if (!this.postTransactionData) {
        return;
      }
      this.getTransaction(this.postTransactionData).then((data) => {
        _this.cardTransactionsData = data;
      });
    },
    recoverDebt() {
      let _this = this;
      if (!this.hmac) {
        return;
      }

      this.payDebt(this.postTransactionData).then((result) => {
        if (result.success) {
          _this.hasDebt = false;
          _this.retrieveTransactions();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.transaction-details {
  margin-left: 16px;
  margin-right: 16px;

  h2 {
    margin: 20px 0px 18px 0px !important;
  }

  .card-info {
    span {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: right;
      color: #4d4845;

      b {
        font-weight: bold;
      }
    }
  }
}

li {
  float: left;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

a {
  display: block;
  padding: 8px;
  background-color: #dddddd;
}

.settle-debt-text {
  font-weight: 600;
}

@media (max-width: 576px) {
  .settle-debt-btn {
    margin: 19px 0px 10px 0px !important;
    border: 2px solid #f6bb0a !important;
  }

  .warning-icon {
    display: none !important;
  }
}

.show-more-history-container {
  display: none;
}

.show-more-history {
  font-weight: 600;
}

#navbar {
  border-bottom: 1px solid #e4e3e3;
  margin-bottom: 10px;
}

#navigation-transactions {
  border-bottom: 1px solid #f6bb0a;
}

#navigation-history {
  font-size: 20px;
}

.navigation {
  font-size: 20px;
  font-weight: 600;
}

.card-number {
  padding-top: 10px;
}

.card-expiration-date {
  margin-left: 11px;
}

.card-status {
  margin-left: 16px;
}

.settle-debt-btn {
  border: 2px solid #f6bb0a !important;
  margin-right: 20px !important;
}

.settle-debt {
  border: 2px solid #f6bb0a;
  border-radius: 15px;
  padding: 13px 0px 13px 12px;
  margin-top: 10px;
  margin-right: 5px !important;
  margin-bottom: 10px;
}
</style>
