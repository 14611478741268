<template>
  <div :class="classList">
    <div id="loading-screen" v-if="isLoading">
      <div class="loading-screen-container">
        <st-spinner :loadingMessage="loadingMessage"></st-spinner>
      </div>
    </div>
    <slot>default slot</slot>
    <div class="error-message" v-if="error">
      <p>{{ error | errorhandler(error) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'st-card',

  props: {
    size: {
      type: String,
      default: 'lg',
      validator: function (size) {
        return ['lg', 'md', 'sm', 'xs', 'mega'].includes(size);
      }
    },

    widthSize: {
      type: String,
      default: 'sm-width',
      validator: function (widthSize) {
        return ['xs-width', 'sm-width', 'md-width', 'lg-width', 'mega-width'].includes(widthSize);
      }
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    loadingMessage: {
      type: String,
      default: undefined
    },

    error: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    classList: function () {
      return ['st-card', this.size, this.widthSize, this.isLoading && 'loading'];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/colors.scss';
.st-card {
  h1,
  h2,
  h3,
  h4 {
    font-family: 'Source Sans Pro' !important;
    font-weight: bold !important;
    text-align: center;
  }

  @media (max-width: 575px) {
    h3 {
      font-size: 1.5em !important;
    }
  }

  .st-modal-header-default,
  .st-modal-body-default,
  .st-modal-footer-default {
    justify-content: center;
    text-align: center;
  }

  .st-modal-header-default {
    padding: 2em 1.5em 1em 1.5em;
    @media (min-width: 576px) {
      padding: 2em 2.25em 1em 2.25em;
    }
  }

  .st-modal-body-default {
    padding: 0 2.25em;
    word-break: break-word;

    p {
      margin: 0 !important;
      font-size: 1.125em !important;
      color: #7f7567 !important;
    }

    .row {
      margin-right: 0em;
      margin-left: 0em;
    }

    @media (max-width: 576px) {
      text-align: left;
    }
  }

  .st-modal-footer-default {
    padding: 2em 2.25em;

    button {
      margin-top: 0em !important;
    }
  }

  &.lg {
    padding: 0.75em;
    border-radius: 0.375em;
    background-color: #ffffff;
    box-shadow: 0 1em 1.5em 0 rgba(43, 36, 29, 0.08);
  }

  &.md {
    padding: 0.25em 0.375em;
    border-radius: 0.125em;
    background-color: #f9f8f5;
  }

  &.xs {
    padding: 2em;
    border-radius: 1em;
    background-color: #f9f8f5;
  }

  &.loading {
    position: relative;
  }

  .footer {
    border-bottom-left-radius: 0.375em;
    border-bottom-right-radius: 0.375em;
    margin: 1.75em -0.75em -1.75em -0.75em;
    padding: 0.75em 1em;
    background-color: #f9f8f5;
    display: flex;

    .st-form-group {
      margin-top: 0em;
    }

    button {
      margin-top: 0em;
    }

    > :not(:last-child) {
      margin-right: 0.5em;
    }

    > :not(:first-child) {
      margin-left: 0.5em;
    }
  }

  @media (min-width: 700px) {
    .footer {
      border-bottom-left-radius: 1.5em;
      border-bottom-right-radius: 1.5em;
      margin: 3em -3em -3em -3em;
      padding: 3em;
      flex-wrap: nowrap;
    }
  }

  #loading-screen {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $vit;
    border-radius: 0.375em;
    z-index: 2;

    .loading-screen-container {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    &.lg {
      padding: 2em 3em;
      // border-radius: 1.5em;
      border-radius: 0.5em;
    }

    &.md {
      padding: 1em 1.5em;
      border-radius: 0.5em;
      background-color: #f9f8f5;
    }
  }

  @media (min-width: 992px) {
    &.xs-width {
      max-width: 23.5em;
    }

    &.sm-width {
      max-width: 33.75em;
    }

    &.md-width {
      max-width: 36em;
    }

    &.lg-width {
      max-width: 48.5em;
    }
    &.mega-width {
      max-width: 88.25em;
    }
  }
}

.error-message {
  word-wrap: normal;
  padding: 0em 2em;
  p {
    opacity: 1;
    margin-bottom: 0em;
    margin-top: 0em;
    color: #cc4039;
    font-size: 0.875em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
  }
}
</style>
