<template>
  <form class="login-form" v-on:submit.prevent="submit" autocomplete="on">
    <st-form-group id="email" :label="$t('LoginForm_Email_Label')" required :validation="$v.model.email" :validationErrors="validationErrors.email">
      <template #default="{ ariaDescribedBy }">
        <st-input ref="loginFormEmail" id="email" :aria-describedby="ariaDescribedBy" :placeholder="$t('LoginForm_Email_Placeholder')" required v-model="model.email" @input="handleInputEmail" :invalid="$v.model.email.$error" :blur="$v.model.email.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-form-group id="password" :label="$t('LoginForm_Password_Label')" required :validation="$v.model.password" :validationErrors="validationErrors.password">
      <template #default="{ ariaDescribedBy }">
        <st-input type="password" id="password" :aria-describedby="ariaDescribedBy" :placeholder="$t('LoginForm_Password_Placeholder')" required v-model="model.password" :invalid="$v.model.password.$error" :blur="$v.model.password.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-button type="submit" medium block primary @click.prevent="submit" :disabled="$v.$invalid || isLoading" tabindex="0">{{ $t('LoginForm_Button_Submit') }}</st-button>

    <st-error v-if="error" :error="error" />
  </form>
</template>

<script>
import dayjs from 'dayjs';

import api from '../../api/index';
import intervalService from '../../services/authIntervalService';

import LoginFormValidations from './login-form-validations';
import LoginFormValidationErrors from './login-form-validation-errors';
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      model: {
        email: '',
        password: ''
      },
      isLoading: false,
      error: undefined,
      validationErrors: LoginFormValidationErrors
    };
  },
  validations: {
    model: LoginFormValidations
  },
  methods: {
    ...mapMutations(['setEmail']),
    submit() {
      this.error = undefined;
      this.isLoading = true;
      const url = 'tokens';
      let vm = this;
      grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'loginform' }).then((token) => {
        const payload = this.model;
        payload.recaptchaToken = token;
        api
          .post(url, payload)
          .then(({ data }) => {
            var date = new Date();
            date.setTime(date.getTime() + 1 * 60 * 60 * 1000);

            document.cookie = `StarJwtTokenCookieName=Token=${data}&Created=${dayjs().format('YYYY-MM-DD HH:mm:ss')}; expires=${date.toUTCString()}; path=/`;
            intervalService.createInterval(vm);
            location.reload();
            this.isLoading = false;
          })
          .catch((error) => {
            this.error = error;
            this.isLoading = false;
            this.$nextTick(() => {
              this.$refs.loginFormEmail.$el.focus();
            });
          });
      });
    },
    handleInputEmail(val) {
      this.setEmail(val);
    }
  }
};
</script>
