var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buy-card-form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.submit.apply(null, arguments)}}},[_c('st-item-counter',{attrs:{"itemType":'kort',"label":_vm.$t('BuyCardForm_NumberOfCards_Label'),"price":_vm.pricePerCard,"altDecreaseText":_vm.$t('BuyCardForm_NumberOfCards_Decrease_Button_Alt'),"altIncreaseText":_vm.$t('BuyCardForm_NumberOfCards_Increase_Button_Alt')},model:{value:(_vm.model.numberOfCards),callback:function ($$v) {_vm.$set(_vm.model, "numberOfCards", $$v)},expression:"model.numberOfCards"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('st-form-group',{attrs:{"id":"firstName","label":_vm.$t('BuyCardForm_FirstName_Label'),"required":"","validation":_vm.$v.model.firstName,"validationErrors":_vm.validationErrors.firstName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"firstName","aria-describedby":ariaDescribedBy,"placeholder":_vm.$t('BuyCardForm_FirstName_PlaceHolder'),"required":"","invalid":_vm.$v.model.firstName.$error,"autocomplete":"given-name","blur":_vm.$v.model.firstName.$touch,"tabindex":"0"},model:{value:(_vm.model.firstName),callback:function ($$v) {_vm.$set(_vm.model, "firstName", $$v)},expression:"model.firstName"}})]}}])})],1),_c('div',{staticClass:"col col-12 col-md-6"},[_c('st-form-group',{attrs:{"id":"lastName","label":_vm.$t('BuyCardForm_LastName_Label'),"required":"","validation":_vm.$v.model.lastName,"validationErrors":_vm.validationErrors.lastName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"lastName","aria-describedby":ariaDescribedBy,"placeholder":_vm.$t('BuyCardForm_LastName_PlaceHolder'),"required":"","invalid":_vm.$v.model.lastName.$error,"autocomplete":"family-name","blur":_vm.$v.model.lastName.$touch,"tabindex":"0"},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", $$v)},expression:"model.lastName"}})]}}])})],1)]),_c('st-form-group',{attrs:{"id":"address","label":_vm.$t('BuyCardForm_Address_Label'),"required":"","validation":_vm.$v.model.street,"validationErrors":_vm.validationErrors.street},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"address","aria-describedby":ariaDescribedBy,"placeholder":_vm.$t('BuyCardForm_Address_Label'),"required":"","invalid":_vm.$v.model.street.$error,"autocomplete":"street-address","blur":_vm.$v.model.street.$touch,"tabindex":"0"},model:{value:(_vm.model.street),callback:function ($$v) {_vm.$set(_vm.model, "street", $$v)},expression:"model.street"}})]}}])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-6"},[_c('st-form-group',{attrs:{"id":"postalCode","label":_vm.$t('BuyCardForm_PostalCode_Label'),"required":"","validation":_vm.$v.model.postalCode,"validationErrors":_vm.validationErrors.postalCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"postalCode","aria-describedby":ariaDescribedBy,"placeholder":_vm.$t('BuyCardForm_PostalCode_Placeholder'),"required":"","invalid":_vm.$v.model.postalCode.$error,"autocomplete":"postal-code","blur":_vm.$v.model.postalCode.$touch,"tabindex":"0"},model:{value:(_vm.model.postalCode),callback:function ($$v) {_vm.$set(_vm.model, "postalCode", $$v)},expression:"model.postalCode"}})]}}])})],1),_c('div',{staticClass:"col col-12 col-md-6"},[_c('st-form-group',{attrs:{"id":"city","label":_vm.$t('BuyCardForm_City_Label'),"required":"","validation":_vm.$v.model.city,"validationErrors":_vm.validationErrors.city},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"city","aria-describedby":ariaDescribedBy,"placeholder":_vm.$t('BuyCardForm_City_Placeholder'),"required":"","invalid":_vm.$v.model.city.$error,"autocomplete":"address-level2","blur":_vm.$v.model.city.$touch,"tabindex":"0"},model:{value:(_vm.model.city),callback:function ($$v) {_vm.$set(_vm.model, "city", $$v)},expression:"model.city"}})]}}])})],1)]),_c('st-form-group',{attrs:{"id":"email","label":_vm.$t('BuyCardForm_Email_Label'),"required":"","validation":_vm.$v.model.email,"validationErrors":_vm.validationErrors.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedBy = ref.ariaDescribedBy;
return [_c('st-input',{attrs:{"id":"email","aria-describedby":ariaDescribedBy,"placeholder":_vm.$t('BuyCardForm_Email_Placeholder'),"required":"","invalid":_vm.$v.model.email.$error,"autocomplete":"email","blur":_vm.$v.model.email.$touch,"tabindex":"0"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])}),_c('div',{staticClass:"price-box"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 text-left"},[_c('span',{staticClass:"large"},[_vm._v(_vm._s(_vm.$t('BuyCardForm_ToPay_Label')))])]),_c('div',{staticClass:"col-6 text-right"},[_c('span',{staticClass:"large notranslate"},[_vm._v(_vm._s(_vm._f("fixedDecimals")((_vm.pricePerCard * _vm.model.numberOfCards)))+" kr")])])])]),_c('st-checkbox',{attrs:{"id":"acceptTerms","tabindex":"0","required":""},model:{value:(_vm.model.acceptTerms),callback:function ($$v) {_vm.$set(_vm.model, "acceptTerms", $$v)},expression:"model.acceptTerms"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('BuyCardForm_AcceptTerms_Checkbox'))}})]),_c('div',{staticClass:"text-right"},[_c('st-button',{staticStyle:{"min-width":"11.25em"},attrs:{"type":"submit","primary":"","medium":"","disabled":_vm.$v.$invalid || _vm.isLoading,"tabindex":"0"}},[_vm._v(_vm._s(_vm.$t('BuyCardForm_Submit_Button')))])],1)],1),(_vm.error)?_c('st-error',{attrs:{"error":_vm.error}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }