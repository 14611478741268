import Vue from 'vue';

import api from '../../api';
import { ADD_CARD, DELETE_CARD, REVOKE_CARD, GET_CARDS, GET_CARD, ACTIVATE_CARD_TICKET, SET_SELECTED_CARD, CLEAR_SELECTED_CARD, MOVE_TICKETS_TO_CARD } from '../actions/card-actions';

let state = {
  cards: [],
  selectedCard: undefined
};

let actions = {
  [ADD_CARD]: ({ commit }, data) => {
    const url = 'registered-card';
    return api.post(url, data).then(result => {
      if (result.status === 200) commit(ADD_CARD, result.data);

      return result;
    });
  },
  [DELETE_CARD]: ({ commit }, id) => {
    const url = `registered-card/${id}`;
    return api.delete(url).then(result => {
      commit(DELETE_CARD, id);
    });
  },
  [REVOKE_CARD]: ({ commit }, id) => {
    const url = `registered-card/${id}/revoke`;
    return api.put(url).then(result => {
      commit(REVOKE_CARD, result.data);
    });
  },
  [GET_CARDS]: ({ commit }) => {
    const url = 'registered-card';
    return api.get(url).then(result => {
      for (let i = 0; i < result.data.length; i++) {
        const card = result.data[i];
        commit(ADD_CARD, card);
      }

      return result.data;
    });
  },
  [ACTIVATE_CARD_TICKET]: ({ commit }, data) => {
    const url = `cards/${data.cardNumber}/activate/${data.ticketId}`;
    return api.put(url).then(result => {
      const ticket = result.data;

      let resultData = {
        cardNumber: data.cardNumber,
        ticketId: data.ticketId,
        activated: ticket.activated,
        activeTo: ticket.activeTo,
        status: ticket.status
      };
      commit(ACTIVATE_CARD_TICKET, resultData);

      return result.data;
    });
  }
};

let mutations = {
  [ADD_CARD]: (state, payload) => {
    state.cards.push(payload);
  },
  [MOVE_TICKETS_TO_CARD]: (state, {holderCardId, reciverCardNumber}) => {
      let ticketHolder = state.cards.find(x => x.id === holderCardId);
      state.cards.find(x => x.cardNumber === reciverCardNumber).tickets.push(...ticketHolder.tickets);
      Vue.set( ticketHolder,'tickets',[])
  },
  [DELETE_CARD]: (state, id) => {
    let index = -1;
    for (let i = 0; i < state.cards.length; i++) {
      if (state.cards[i].id == id) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      state.cards.splice(index, 1);
    }
  },
  [REVOKE_CARD]: (state, payload) => {
    let index = -1;
    for (let i = 0; i < state.cards.length; i++) {
      if (state.cards[i].id === payload.id) {
        index = i;
        break;
      }
    }

    for (var property in payload) {
      if (payload[property] !== null) {
        Vue.set(state.cards[index], property, payload[property]);
      }
    }
  },
  [ACTIVATE_CARD_TICKET]: (state, payload) => {
    if (!state.selectedCard) return;
    let ticketIndex = -1;

    ticketIndex = state.selectedCard.tickets.findIndex(ticket => ticket.ticketId === payload.ticketId);

    if (ticketIndex > -1) {
      let ticket = state.selectedCard.tickets[ticketIndex];
      ticket.activated = payload.activated;
      ticket.activeTo = payload.activeTo;
      ticket.status = payload.status;

      Vue.set(state.selectedCard.tickets, ticketIndex, ticket);
    }
  },
  [SET_SELECTED_CARD]: (state, payload) => {
    state.selectedCard = payload;
  },
  [CLEAR_SELECTED_CARD]: state => {
    state.selectedCard = undefined;
  }
};

let getters = {
  [GET_CARDS]: state => {
    return state.cards;
  },
  [GET_CARD]: state => id => {
    for (let i = 0; i < state.cards.length; i++) {
      const card = state.cards[i];
      if (card.id === id) {
        return card;
      }
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
