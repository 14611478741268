export default {
  createAndAddScript(src) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.getElementById('buynsend').appendChild(script);

    return script;
  },

  removeScript(src) {
    var scripts = document.getElementsByTagName('script');
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].src === src) {
        let script = scripts[i];
        document.getElementById('buynsend').removeChild(script);
        break;
      }
    }
  }
};
