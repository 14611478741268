import { numeric } from 'vuelidate/lib/validators';

const is8Or12Chars = function(value) {
  if (this && !this.$v.model.voucher.numeric) return true;

  return value.length === 8 || value.length === 12;
};

export default {
  voucher: {
    numeric,
    is8Or12Chars
  }
};
