export const GET_TRAVELLER_TYPES = 'GET_TRAVELLER_TYPES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const SET_INIT_TRAVELLERTYPES_ST = 'SET_INIT_TRAVELLERTYPES_ST';
export const SET_INIT_TRAVELLERTYPES_BT = 'SET_INIT_TRAVELLERTYPES_BT';
export const SET_INIT_TRAVELLERTYPES_OT = 'SET_INIT_TRAVELLERTYPES_OT';
export const FETCH_BUYANDSEND_URL = 'GET_BUYANDSEND_URL'

// Mutations
export const SET_BUYANDSEND_URL = 'SET_BUYANDSEND_URL'

// Getters
export const GET_BUYANDSEND_URL = 'GET_BUYANDSEND_URL'
