import i18n from '../../../i18n/index';
export default {
  travellerType: [
    {
      key: 'required',
      message: i18n.t('Traveller_Type_Required_Validation'),
      id: 'travellerTypeRequired'
    }
  ]
};
