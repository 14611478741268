import { required, requiredIf, maxLength, email, sameAs } from 'vuelidate/lib/validators';
import phoneNumberService from '../../services/phoneNumberService.js';

function numericLength(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^[0-9]{12}$/;
  return regex.test(value);
}

function phoneNumberValidation(value) {
  if (value) {
    const isValid = phoneNumberService.validate(value);
    return isValid;
  } else {
    return true;
  }
}

export default {
  cardNumber: {
    required: requiredIf((val) => {
      return val.bearerType === 'card';
    }),
    numericLength
  },
  phoneNumber: {
    required: requiredIf((val) => {
      return val.bearerType === 'app';
    }),
    phoneNumberValidation,
    maxLength: maxLength(15)
  },
  email: {
    email,
    required,
    maxLength: maxLength(100)
  },
  acceptTerms: {
    sameAs: sameAs(() => true)
  }
};
