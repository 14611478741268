<template>
  <form @submit.prevent.stop="submit" class="personal-data-from">
    <div class="row">
      <div class="col-12 col-md-6">
        <st-form-group id="firstName" label="Förnamn" required :validation="$v.model.firstName" :validationErrors="validationErrors.firstName">
          <template #default="{ ariaDescribedBy }">
            <st-input id="firstName" :aria-describedby="ariaDescribedBy" required v-model="model.firstName" :invalid="$v.model.firstName.$error" autocomplete="given-name" :blur="$v.model.firstName.$touch" tabindex="0"></st-input>
          </template>
        </st-form-group>
      </div>
      <div class="col-12 col-md-6">
        <st-form-group id="lastName" label="Efternamn" required :validation="$v.model.lastName" :validationErrors="validationErrors.lastName">
          <template #default="{ ariaDescribedBy }">
            <st-input id="lastName" :aria-describedby="ariaDescribedBy" required v-model="model.lastName" :invalid="$v.model.lastName.$error" autocomplete="family-name" :blur="$v.model.lastName.$touch" tabindex="0"></st-input>
          </template>
        </st-form-group>
      </div>
    </div>
    <st-form-group id="street" label="Adress" required :validation="$v.model.street" :validationErrors="validationErrors.street">
      <template #default="{ ariaDescribedBy }">
        <st-input id="street" :aria-describedby="ariaDescribedBy" required v-model="model.street" :invalid="$v.model.street.$error" autocomplete="street-address" :blur="$v.model.street.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>
    <st-form-group id="co" label="c/o" :validation="$v.model.co" :validationErrors="validationErrors.co">
       <template #default="{ ariaDescribedBy }">
        <st-input id="co" :aria-describedby="ariaDescribedBy" v-model="model.co" :invalid="$v.model.co.$error" :blur="$v.model.co.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>
    <div class="row">
      <div class="col-12 col-md-4">
        <st-form-group id="postalCode" label="Postnummer" required :validation="$v.model.postalCode" :validationErrors="validationErrors.postalCode">
          <template #default="{ ariaDescribedBy }">
            <st-input id="postalCode" :aria-describedby="ariaDescribedBy" required v-model="model.postalCode" :invalid="$v.model.postalCode.$error" autocomplete="postal-code" :blur="$v.model.postalCode.$touch" tabindex="0"></st-input>
          </template>
        </st-form-group>
      </div>
      <div class="col-12 col-md-8">
        <st-form-group id="city" label="Ort" required :validation="$v.model.city" :validationErrors="validationErrors.city">
          <template #default="{ ariaDescribedBy }">
            <st-input id="city" :aria-describedby="ariaDescribedBy" required v-model="model.city" :invalid="$v.model.city.$error" autocomplete="address-level2" :blur="$v.model.city.$touch" tabindex="0"></st-input>
          </template>
        </st-form-group>
      </div>
    </div>
    <st-form-group id="country" label="Välj land" required :validation="$v.model.country" :validationErrors="validationErrors.country">
      <template #default="{ ariaDescribedBy }">
        <st-select id="country" :aria-describedby="ariaDescribedBy" :items="countries" required v-model="model.country" :invalid="$v.model.country.$error" :blur="$v.model.country.$touch" tabindex="0" />
      </template>
    </st-form-group>
    <st-form-group id="mobile" label="Mobilnummer" required :validation="$v.model.mobile" :validationErrors="validationErrors.mobile">
      <template #default="{ ariaDescribedBy }">
        <st-input :aria-describedby="ariaDescribedBy" id="mobile" required v-model="model.mobile" :invalid="$v.model.mobile.$error" autocomplete="tel" :blur="$v.model.mobile.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>
    <div class="edit-form-btn-wrapper">
      <st-button medium class="cancel" :disabled="isLoading" tabindex="0" @click="toPersonalDataRoute">Avbryt</st-button>
      <st-button ref="submitPersonalDataBtn" medium primary class="submit" :disabled="$v.$invalid || isLoading" type="submit" tabindex="0">Spara</st-button>
    </div>
    <st-error v-if="error" :error="error" />
  </form>
</template>

<script>
import api from '../../api';
import { mapGetters } from 'vuex';
import { GET_COUNTRIES } from '../../store/actions/base-actions';
import { GET_ACCOUNT } from '../../store/actions/account-actions';

import PersonalDataFormValidations from './personal-data-form-validations';
import PersonalDataFormValidationErrors from './personal-data-form-validation-errors';

export default {
  mounted() {
    this.model = { ...this.account };
    document.getElementById('mittkonto').scrollIntoView();
  },
  data() {
    return {
      model: {
        firstName: '',
        lastName: '',
        street: '',
        co: '',
        city: '',
        country: '',
        mobile: ''
      },
      isLoading: false,
      error: undefined,
      validationErrors: PersonalDataFormValidationErrors
    };
  },
  validations: {
    model: PersonalDataFormValidations
  },
  computed: {
    ...mapGetters({
      account: GET_ACCOUNT,
      countries: GET_COUNTRIES
    })
  },
  methods: {
    submit() {
      let data = Object.assign({}, this.model);
      data.crmId = this.account.crmId;
      data.accountId = this.account.accountId;
      data.userId = this.account.userId;
      data.socialSecurityNumber = this.account.socialSecurityNumber;
      data.email = this.account.email;

      let vm = this;
      this.isLoading = true;
      let url = 'mitt-konto/update-account';

      api
        .put(url, data)
        .then((result) => {
          vm.$store
            .dispatch(GET_ACCOUNT)
            .then(() => {
              vm.$router.go(-1);
              vm.isLoading = false;
            })
            .catch((error) => {
              vm.isLoading = false;
              vm.$nextTick(() => {
                vm.$refs.submitPersonalDataBtn.$el.focus();
              });
            });
        })
        .catch((error) => {
          vm.error = error;
          vm.isLoading = false;
          vm.$nextTick(() => {
            vm.$refs.submitPersonalDataBtn.$el.focus();
          });
        });
    },
    toPersonalDataRoute() {
      this.$router.push('/konto/mina-personuppgifter');
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-form-btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .st-button {
    min-width: 11.25em;
    @media (max-width: 767px) {
      min-width: auto;
      flex: 1;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
    }
  }
}
</style>
