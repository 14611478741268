<template>
  <div class="login-data-edit-email">
    <login-data-form-email></login-data-form-email>
  </div>
</template>

<script>
import LoginDataFormEmail from '../../../components/login-data-form-email/LoginDataFormEmail.vue';
export default {
  components: {
    LoginDataFormEmail
  }
};
</script>