<template>
  <div class="login-data-edit">
    <login-data-form-password></login-data-form-password>
  </div>
</template>

<script>
import LoginDataFormPassword from '../../../components/login-data-form-password/LoginDataFormPassword.vue';
export default {
  components: {
    LoginDataFormPassword
  }
};
</script>