import { required, email, maxLength, sameAs } from 'vuelidate/lib/validators';

function passwordFormat(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^(?=.*\d)(?=.*[a-zåäöA-ZÅÄÖ])[\d\S]{8,}$/;
  return regex.test(value);
}

export default {
  newEmail: {
    required,
    email,
    maxLength: maxLength(100)
  },
  newEmailRepeat: {
    required,
    sameAsEmail: sameAs('newEmail')
  }
};
