import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';

function tokenFormat(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^\d{6,45}$/;
  return regex.test(value);
}

function passwordFormat(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^(?=.*\d)(?=.*[a-zåäöA-ZÅÄÖ])[\d\S]{8,}$/;
  return regex.test(value);
}

export default {
  token: {
    tokenFormat,
    requiredIf: requiredIf(vueInstance => {
      return vueInstance.tokenRequired;
    })
  },
  password: {
    required,
    passwordFormat
  },
  repeatPassword: {
    sameAsPassword: sameAs('password')
  }
};
