<template>
  <st-modal class="move-tickets-modal" :ariaLabelledby="'move-tickets-header'" :onClose="submit" :isLoading="isLoading">
    <div slot="header" class="st-modal-header-default">
      <h1 id="move-tickets-header">{{ $t('MoveTicketsModal_Header') }}</h1>
    </div>
    <div slot="body" class="st-modal-body-default">
      <p>{{ $t('MoveTicketsModal_Description') }}</p>
      <st-form-group class="st-cardNumber-field" required id="cardNumberInput" :label="$t('CardNumber_Label')" :validation="$v.model.cardNumber" :validationErrors="validationErrors.cardNumber">
        <template #default="{ ariaDescribedBy }">
          <card-number-input id="cardNumberInput" :ariaDescribedBy="ariaDescribedBy" v-model="model.cardNumber" @keypress.enter.prevent.native="onKeyEnter(1)" @keydown.tab.native="onTabShiftBack" :blur="$v.model.cardNumber.$touch" :placeholder="$t('CardNumber_Placeholder')" tabindex="1"></card-number-input>
        </template>
      </st-form-group>
    </div>
    <div slot="footer" class="st-modal-footer-default">
      <div class="st-modal-footer-content d-none d-sm-flex">
        <st-button @click="submit(0)" @keydown.tab.prevent.native="onCancelKeyDown" id="cancel" tabindex="2" medium block :disabled="isLoading" style="margin-right: 1em">{{ $t('MoveTicketsModal_Button_No') }}</st-button>
        <st-button @click="submit(1)" @keydown.tab.prevent.native="onSubmitKeyDown" id="submit" tabindex="3" medium block primary :disabled="$v.$invalid || isLoading">{{ $t('MoveTicketsModal_Button_Yes') }}</st-button>
      </div>
      <div class="d-sm-none">
        <div class="row">
          <div class="col-12">
            <st-button @click="submit(1)" medium block primary :disabled="isLoading" style="margin-bottom: 1em">{{ $t('MoveTicketsModal_Button_Yes') }}</st-button>
          </div>
          <div class="col-12">
            <st-button @click="submit(0)" medium block :disabled="isLoading">{{ $t('MoveTicketsModal_Button_No') }}</st-button>
          </div>
        </div>
      </div>
      <st-error v-if="error" :error="error"></st-error>
    </div>
  </st-modal>
</template>

<script>
import api from '../../../api';
import { mapMutations } from 'vuex';
import { DELETE_CARD, MOVE_TICKETS_TO_CARD } from '../../../store/actions/card-actions';

import CardNumberInput from '../../CardNumberInput';

import MoveTicketsModalValidation from './move-tickets-modal-validations';
import MoveTicketsModalValidationErrors from './move-tickets-modal-validation-errors';
import globalMixins from '../../../mixins/globalMixin';

export default {
  name: 'remove-card-modal',
  mixins: [globalMixins],
  props: {
    cardId: {
      type: Number
    },
    close: {
      type: Function
    }
  },
  data() {
    return {
      model: {
        cardNumber: ''
      },
      isLoading: false,
      validationErrors: MoveTicketsModalValidationErrors,
      error: undefined
    };
  },
  components: {
    CardNumberInput
  },
  mounted() {
    let cardNumberInput = document.getElementById('cardNumberInput');
    cardNumberInput.focus();

    cardNumberInput.addEventListener('keydown', this.cancelTabBack);
  },
  beforeDestroy() {
    let cardNumberInput = document.getElementById('cardNumberInput');
    cardNumberInput.removeEventListener('keydown', this.cancelTabBack);
  },
  validations: {
    model: MoveTicketsModalValidation
  },
  methods: {
    ...mapMutations({
      deleteCard: DELETE_CARD,
      moveTickets: MOVE_TICKETS_TO_CARD
    }),
    onKeyEnter(value) {
      if (this.$v.$invalid || this.isLoading) return;
      this.submit(value);
    },
    submit(value) {
      if (value) {
        this.isLoading = true;
        this.error = undefined;

        let vm = this;

        grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'moveticketsmodal' }).then(function(token) {
          vm.model.recaptchaToken = token;

          const url = `registered-card/${vm.cardId}/tickets/move`;
          return api
            .post(url, vm.model)
            .then((result) => {
              vm.moveTickets({ holderCardId: vm.cardId, reciverCardNumber: vm.model.cardNumber });
              vm.deleteCard(vm.cardId);
              vm.$router.push('/');
              vm.isLoading = false;
            })
            .catch((error) => {
              vm.error = error;
              vm.isLoading = false;
              vm.$nextTick(() => {
                document.getElementById('cardNumberInput').focus();
              });
            });
        });
      } else {
        if (this.close) this.close();
      }
    },
    onCancelKeyDown(e) {
      this.cancelKeyDown(e, 'cardNumberInput', 'submit');
    },
    onSubmitKeyDown(e) {
      this.submitKeyDown(e, 'cardNumberInput', 'cancel');
    },
    onTabShiftBack(e) {
      this.shiftTabBack(e, 'cancel', 'submit');
    }
  }
};
</script>

<style lang="scss" scoped>
.move-tickets-modal {
  h1 {
    font-size: 1.9375em !important;
  }
  .st-modal-body-default {
    .st-cardNumber-field {
      text-align: left !important;
    }
  }

  .st-modal-footer-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
}
</style>
