<template>
  <div class="transaction-details-info">
    <!-- <div class="card-details-list-overlay" v-if="isDisabled"></div> -->
    <div class="d-sm-none">
      <st-table-mobile :items="transactionListModified" :fields="transactionMobileFields" useToggle>
        <template slot="transaction-date-value" slot-scope="{ item }">
          <span>{{ item.created }}</span>
        </template>
        <template slot="amount-value" slot-scope="{ item }">
          <span>{{ item.amount }}</span>
        </template>
        <template slot="payment-status-value" slot-scope="{ item }">
          <span>{{ item.status }}</span>
        </template>
      </st-table-mobile>
    </div>
    <div class="d-none d-sm-block">
      <st-table :items="transactionListModified" :fields="transactionFields">
        <template slot="transaction-date-value" slot-scope="{ item }">
          <span>{{ item.created }}</span>
        </template>
        <template slot="amount-value" slot-scope="{ item }">
          <span>{{ item.amount }}</span>
        </template>
        <template slot="payment-status-value" slot-scope="{ item }">
          <span>{{ item.status }}</span>
        </template>
        <!-- 
        <template slot="info" slot-scope="{ item }">
          <st-icon size="md" isHoverable inline useSvg name="info" @click.native="openHistoryPerDay(item)" class="info-icon" />
        </template>-->
      </st-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { GET_TRANSACTION } from '../../store/actions/tapnride-actions';

import dayjs from 'dayjs';
import 'dayjs/locale/sv';
dayjs.locale('sv');

export default {
  props: {
    transactionList: {
      type: Array,
      required: true
    }
  },
  filters: {
    dateFilter: function (value) {
      return moment(value).format('YYYY-MM-DD');
    }
  },
  data() {
    let _this = this;
    return {
      transactionFields: [
        { label: this.$t('Datum'), key: 'created' },
        { label: this.$t('TapnRide_TravelHistory_TableColumn_Sum'), key: 'amount' },
        {
          key: 'status',
          label: this.$t('TicketList_Header_Status'),
          customFormat: function (v) {
            switch (v) {
              case 0:
                return _this.$t('TapnRide_Payment_Status_Debited');
              case 1:
                return _this.$t('TapnRide_Payment_Status_Pending');
              case 2:
                return _this.$t('TapnRide_Payment_Status_CaptureFailed');
              default:
                return '';
            }
          }
        }
      ],
      transactionMobileFields: [
        {
          key: 'created',
          label: this.$t('Datum')
        },
        {
          key: 'amount',
          label: this.$t('TapnRide_TravelHistory_TableColumn_Sum')
        },
        {
          key: 'status',
          label: this.$t('TicketList_Header_Status'),
          customFormat: function (v) {
            switch (v) {
              case 0:
                return _this.$t('TapnRide_Payment_Status_Debited');
              case 1:
                return _this.$t('TapnRide_Payment_Status_Pending');
              case 2:
                return _this.$t('TapnRide_Payment_Status_CaptureFailed');
              default:
                return '';
            }
          }
        }
      ]
    };
  },
  methods: {
    rowClicked(id) {
      let data = this.getCard(id);
      this.setSelectedCard(data);
      this.$router.push('/cards/details');
    },
    openHistoryPerDay(id) {
      let data = this.getTransaction(id);

      this.$router.push('/visa-transaktioner-per-dag');
    },
    dateFormat(d) {
      if (!d) return '-';
      return dayjs(String(d)).format('D MMM, YYYY');
    }
  },
  computed: {
    transactionListModified() {
      if (!this.transactionList) return;

      let transactions = [];
      for (let i = 0; i < this.transactionList.length; i++) {
        let transaction = this.transactionList[i];

        transactions.push({
          created: this.dateFormat(transaction.created),
          amount: parseFloat(transaction.amount).toFixed(2) + ` kr`,
          status: transaction.status
        });
      }

      return transactions;
    }
  }
};
</script>

<style lang="scss" scoped>
.transaction-details-info {
  position: relative;

  h4 {
    margin-top: 31px;
    margin-bottom: 11px !important;
  }

  .card-details-list-overlay {
    position: absolute;
    top: 53px;
    left: 0;
    width: 100%;
    height: calc(100% - 53px);
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
</style>
