import i18n from '../../i18n/index';
import journeyModule from './journey-module';
import {
  GET_PASSAGE_TYPES,
  GET_PASSAGE_DIRECTIONS,
  GET_SELECTED_PASSAGE_TYPE,
  GET_SELECTED_DIRECTION,
  SET_SELECTED_PASSAGE_TYPE,
  SET_SELECTED_DIRECTION } from '../actions/passage-type-actions';

const state = {
  selectedPassageType: {},
  selectedDirection: '',
  passageTypes: [
      {
        passageType: 'oresundBridge'
      },
      {
        passageType: 'hhFerry'
      }
    ],
  directions: [
    {
      name: i18n.t('Passage_Direction_Sweden_Denmark'),
      id: 'fromScaniaToDenmark'
    },
    {
      name: i18n.t('Passage_Direction_Denmark_Sweden'),
      id: 'fromDenmarkToScania'
    }
  ],
};

const getters = {
  [GET_PASSAGE_TYPES]: () => {
    if (!journeyModule || !journeyModule.state.journey.possibleJourneys) return;

    let journey = journeyModule.state.journey;
    let passageTypes = [];

    for (let i = 0; i < journey.possibleJourneys.length; i++) {
      const possibleJourney = journey.possibleJourneys[i];

      let existingPassageType = passageTypes.filter((x) => {
        return x.passageType == possibleJourney.properties.passageType;
      })[0];

      if (possibleJourney.properties.passageType && !existingPassageType) {
        passageTypes.push({
          passageType: possibleJourney.properties.passageType,
          priceId: possibleJourney.priceDetails.priceId
        });
      }
    }

    return passageTypes;
  },
  [GET_PASSAGE_DIRECTIONS]: (state) => {
    return state.directions;
  },
  [GET_SELECTED_PASSAGE_TYPE]: (state) => {
    return state.selectedPassageType
  },
  [GET_SELECTED_DIRECTION]: (state) => {
    return state.selectedDirection
  }
};

const mutations = {
  [SET_SELECTED_PASSAGE_TYPE]: (state, selectedPassageType) => {
    state.selectedPassageType = selectedPassageType;
  },
  [SET_SELECTED_DIRECTION]: (state, selectedDirection) => {
    state.selectedDirection = selectedDirection;
  },
};

export default {
  state,
  mutations,
  getters
};
