import { render, staticRenderFns } from "./CardDetails.vue?vue&type=template&id=ec71393a&scoped=true&"
import script from "./CardDetails.vue?vue&type=script&lang=js&"
export * from "./CardDetails.vue?vue&type=script&lang=js&"
import style0 from "./CardDetails.vue?vue&type=style&index=0&id=ec71393a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec71393a",
  null
  
)

export default component.exports