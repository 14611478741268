<template>
  <!-- <div> -->
  <div class="transaction-details-info-per-day">
    <div class="page-header" style="text-align: center"></div>
    <table id="print-table-reciept">
      <thead>
        <tr>
          <td>
            <!--place holder for the fixed-position header-->
            <div class="print-header"></div>
            <div class="page-header-space"></div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div>
              <h3>Kvitto</h3>
            </div>
            <template v-for="(chunk, chunkIndex) in chunks">
              <table :key="chunkIndex" class="print-table page">
                <th style="width: 31%">{{ $t('TapnRide_Print_TableHeader_DateTime') }}</th>
                <th style="width: 21%">{{ $t('TapnRide_Print_TableHeader_Zone') }}</th>
                <th style="width: 19%">{{ $t('TapnRide_Print_TableHeader_Reference') }}</th>
                <th style="width: 29%">{{ $t('TapnRide_Print_TableHeader_Sum') }}</th>
                <tbody :key="chunkIndex">
                  <template v-for="(item, i) in chunk">
                    <!-- <tr v-if="i % 7 === 0 && i !== 0" :key="i" class="page">page break</tr> -->
                    <tr :key="i">
                      <td>{{ item.created | dateFormat }}</td>
                      <td>{{ item.description.sv }}</td>
                      <td>{{ item.ticketId }}</td>
                      <td>{{ item.amountInclVat }} kr, {{ $t('TapnRide_Print_Summary_Vat_Description') }} {{ (item.amountInclVat - item.amountExclVat) | fixedDecimals }} kr</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </template>
            <div class="print-summary">
              <div class>
                <div class="border">
                  <div class="row">
                    <div class="col-print-8">
                      <div class="receipt-summary-header">{{ $t('TapnRide_Print_Summary_Description') }}</div>
                    </div>
                    <div class="col-print-4 text-right">
                      <div class="receipt-summary-header" v-if="receiptvalue">{{ receiptvalue.sum }} kr</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-print-8">{{ $t('TapnRide_Print_Summary_Vat_Description') }} 6%</div>
                    <div class="col-print-4 text-right" v-if="receiptvalue">{{ receiptvalue.sumVat }} kr</div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space page-footer">
              <p>
                <small>{{ $t('TapnRide_Print_Summary_CompanyInfo') }}</small>
                <br />
                <small>
                  {{ $t('TapnRide_Print_Summary_CompanyInfo_Address1') }}
                  <br />
                  {{ $t('TapnRide_Print_Summary_CompanyInfo_Address2') }}
                </small>
              </p>
              <p>
                <small>{{ $t('TapnRide_Print_Summary_CompanyInfo_Contact') }}</small>
              </p>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import TransactionReceipt from './TransactionReceipt';
import { mapGetters, mapActions } from 'vuex';
import { GET_TICKETS, GET_RECEIPT, GET_HMAC } from '../../store/actions/tapnride-actions';

import dayjs from 'dayjs';
import 'dayjs/locale/sv';
dayjs.locale('sv');

export default {
  watch: {
    hmac: {
      immediate: true,
      handler(val, oldVal) {
        if (!val) return;

        let data = {
          TransactionId: this.hmac.transactionId
        };
        this.getReceiptData(data);
      }
    }
  },
  methods: {
    ...mapActions({
      getReceipt: GET_RECEIPT
    }),
    dateFormat(d) {
      if (!d) return '-';
      return dayjs(String(d)).format('D MMM, YYYY kl. HH:mm');
    },
    getReceiptData(transactionData) {
      let _this = this;

      if (!transactionData) {
        return;
      }
      this.getReceipt(transactionData).then((data) => {
        _this.receiptvalue = data;
      });
    }
  },
  computed: {
    ...mapGetters({
      hmac: GET_HMAC
    }),
    chunks: function () {
      if (!this.receiptvalue) return [];

      var chunks = [],
        index = 0,
        arrayLength = this.receiptvalue.tickets.length,
        chunkSize = 14;

      while (index < arrayLength) {
        chunks.push(this.receiptvalue.tickets.slice(index, (index += chunkSize)));
      }

      return chunks;
    }
  },
  filters: {
    dateFormat(d) {
      if (!d) return '-';
      return dayjs(String(d)).format('D MMM, YYYY kl. HH:mm');
    }
  },
  data() {
    return {
      receiptvalue: undefined
    };
  }
};
</script>

<style lang="scss">
@media print {
  .page-header,
  .page-header-space {
    height: 5px;
  }

  .page-footer,
  .page-footer-space {
    height: 105px;
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #d6d3d0;
  }

  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
  }

  .page {
    page-break-after: always;
  }
  table:last-of-type {
    page-break-after: avoid;
  }
  body * {
    visibility: hidden;
  }

  .transaction-details-info-per-day * {
    visibility: visible;
  }

  .cg-chat-tab-visible {
    display: none;
  }

  #head {
    display: none;
  }

  thead tr th:first-child,
  tbody tr td:first-child {
    width: 15%;
    min-width: 15%;
    max-width: 15%;
    word-break: break-all;
  }

  h3 {
    padding-left: 20px;
  }

  .receipt-summary-header {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-size: 1.4em;
  }

  .border {
    border-radius: 16px;
    border: 1px solid #d6d3d0;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    margin-top: 29px;
  }

  .print-summary {
    page-break-inside: avoid;
  }

  .cg-chat {
    display: none;
  }

  .col-print-4 {
    width: 33%;
    float: left;
  }
  .col-print-8 {
    width: 66%;
    float: left;
  }
  .col-print-12 {
    width: 100%;
    float: left;
  }

  .print-table {
    width: 100%;
    table {
      width: 100%;
      max-width: 100%;
    }
    th {
      background-color: #ffffff;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4d4845;
    }

    tr {
      // background-color: rgba(255, 255, 255, 0.8);
      background-color: #f9f8f5;
      height: 30px;
      border-radius: 6px;
    }

    tr:nth-of-type(even) {
      background-color: #ffffff;
    }

    td {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.8;
      letter-spacing: normal;
      color: #4d4845;
    }

    th {
      padding: 15px 10px 6px 10px;
      min-width: 120px;
    }

    td {
      min-width: 120px;
      padding: 3px 10px;
    }

    tr td:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    tr td:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
</style>
