import * as constants from '../models/constants';
import i18n from '../i18n/index';
import { mapGetters } from 'vuex';
import { GET_TICKET_OFFERS_OFFERS_NAME, GET_TICKET_OFFERS, GET_TICKET_OFFERS_OFFERS_NAME_LOADED } from '../store/actions/ticket-offers-actions';
import { GET_OFFER_ID } from '../store/actions/ticket-actions';

export default {
  computed: {
    ...mapGetters({
      getOfferName: GET_TICKET_OFFERS_OFFERS_NAME,
      getSelectedOfferId: GET_OFFER_ID,
      getTicketOffers: GET_TICKET_OFFERS,
      ticketOffersNamesLoaded: GET_TICKET_OFFERS_OFFERS_NAME_LOADED
    })
  },
  methods: {
    offerName(offerId) {
      return this.getOfferName(offerId);
    },
    getCardStatus(item) {
      let status = { text: '', color: '' };
      if (item.isActive) {
        status.text = i18n.t('CardDetailsInfo_CardStatus_Active');
        status.color = 'green';
      } else {
        status.text = i18n.t('CardDetailsInfo_CardStatus_Revoked');
        status.color = 'red';
      }

      return status;
    },
    getCardTicketStatus(itemStatus) {
      let status = { text: '', color: '' };
      switch (itemStatus) {
        case 2: // oanvänd
          status.text = i18n.t('Ticket_Status_Unused');
          status.color = 'green';
          break;
        case 1: // aktiv
          status.text = i18n.t('Ticket_Status_Active');
          status.color = 'green';
          break;
        case 3: // Förbrukad
          status.text = i18n.t('Ticket_Status_Consumed');
          status.color = 'red';
          break;
        case 4: // Utgången
          status.text = i18n.t('Ticket_Status_Expired');
          status.color = 'red';
          break;
        case 5: // blockerad
          status.text = i18n.t('Ticket_Status_Blocked');
          status.color = 'red';
          break;
        case 6:
          status.text = i18n.t('Ticket_Status_Active');
          status.color = 'green';
          break;
        case 7:
          status.text = i18n.t('Ticket_Status_Active');
          status.color = 'green';
          break;
      }
      return status;
    },
    getAddonIconByType(addonType, id) {
      switch (addonType) {
        case 1:
          return 'bike';
        case 2:
          return 'city';
        case 3:
          // Fullösning då SP ersätter förstaklass med metrotillägget.. Id är addon.Id i prod..
          if (id === '9a02c7d2-825a-405e-aef3-20703ecf4f33') return 'metro';
          else return 'first-class';
        default:
          return '';
      }
    },
    getAddonTranslatedByType(addonType, id) {
      switch (addonType) {
        case 1:
          return 'Cykel';
        case 2:
          return 'Stadszon';
        case 3:
          // Fullösning då SP ersätter förstaklass med metrotillägget.. Id är addon.Id i prod..
          if (id === '9a02c7d2-825a-405e-aef3-20703ecf4f33') return 'Metro';
          else return '1:a klass';
        default:
          return '';
      }
    },
    getPassageTypeByPriceId(priceId) {
      if (!priceId) return;

      let priceIdParts = priceId.split('_');
      if (priceIdParts.length == 1) return;

      if (this.isPassageTypeBridge(priceIdParts[1])) {
        return {
          passageType: 'oresundBridge',
          icon: 'bridge',
          alt: this.$t('Passage_Type_oresundBridge')
        };
      }

      if (this.isPassageTypeFerry(priceIdParts[1])) {
        return {
          passageType: 'hhFerry',
          icon: 'ferry',
          alt: this.$t('Passage_Type_hhFerry')
        };
      }
    },
    isDenmarkJourney(priceId) {
      if (!priceId) return;

      let priceIdParts = priceId.split('_');
      if (priceIdParts.length == 1) return;

      if (this.isPassageTypeFerry(priceIdParts[1]) || this.isPassageTypeBridge(priceIdParts[1])) return true;
      return false;
    },
    isPassageTypeBridge(priceIdPart) {
      if (priceIdPart == 'MK' || priceIdPart == 'KM') return true;
      return false;
    },
    isPassageTypeFerry(priceIdPart) {
      if (priceIdPart == 'HH') return true;
      return false;
    },
    journeyName(priceModelId, priceId, cities = null, offerId = null) {
      let journey = '';

      if (!priceModelId) return journey;

      let vm = this;
      let getOffer = function (offerId) {
        return vm.getTicketOffers.offers.find((offer) => offer.id === offerId);
      };

      if (priceModelId.equals(constants.PRICE_MODEL_CITY)) {
        journey = i18n.t('PRICE_MODEL_CITY');
      } else if (priceModelId.equals(constants.PRICE_MODEL_PERSONAL)) {
        if (priceId.equals(constants.PRICE_ID_SHORT) || priceId.equals(constants.PRICE_ID_SHORT_EXTENDED) || priceId.equals('shortOT')) {
          journey = i18n.t('PRICE_ID_SHORT');
        } else if (priceId.equals(constants.PRICE_ID_MEDIUM) || priceId.equals('mediumOT')) {
          journey = i18n.t('PRICE_ID_MEDIUM');
        } else if (priceId.equals(constants.PRICE_ID_LONG)) {
          journey = i18n.t('PRICE_ID_LONG');
        } else if (priceId.equals('longOT')) {
          journey = 'Stor zon';
        } else if (priceId.equals(constants.PRICE_ID_COUNTYOT)) {
          journey = i18n.t('PRICE_ID_COUNTYOT');
        }

        if (cities) {
          if (cities.length > 0) {
            journey += ' ' + i18n.t('PRICE_MODEL_CITY_ADDON');
          }
        }
      } else if (priceModelId.equals(constants.PRICE_MODEL_ORESUND)) {
        journey = i18n.t('PRICE_MODEL_ORESUND');
      } else if (priceModelId.equals(constants.PRICE_MODEL_ZONES)) {
        var selectedOffer = this.getTicketOffers.offers.find((offer) => offer.id === this.getSelectedOfferId);
        if (offerId !== null && getOffer(offerId)) {
          selectedOffer = getOffer(offerId);
        }
        // Senior ticket
        if (selectedOffer.priceModels.zones == null) {
          return this.journeyName('city', priceId, cities, offerId);
        }
        var prices = selectedOffer.priceModels.zones.prices;
        var price = prices.find((price) => price.id === priceId);
        var samePrice = price;
        if (parseInt(priceId) >= 5) {
          samePrice = prices
            .filter((p) => p.amountIncludingVat === price.amountIncludingVat)
            .sort(function (p1, p2) {
              if (p1.id > p2.id) {
                return -1;
              }
              if (p2.id > p1.id) {
                return 1;
              }
              return 0;
            })[0];
        }

        journey = samePrice.name.sv;
      }

      return journey;
    },

    getAddonAltText(addonType, id) {
      let addonText = this.getAddonTranslatedByType(addonType, id);
      return addonText ? addonText : 'Tilläggsikon';
    },
    calculateTabIndex(tabindex, index, multiplier) {
      if (tabindex < 0) return '-1';
      let result = parseInt(tabindex) + index * multiplier;
      return result.toString();
    },
    shiftTabBack(evt, cancelFocusElement, submitFocusElement) {
      // Metod för att fånga tabbning tillbaka i en modal
      if (evt.keyCode === 9 && evt.shiftKey) {
        evt.preventDefault();

        let submitElement = document.getElementById(submitFocusElement);
        let cancelElement = document.getElementById(cancelFocusElement);

        if (!submitElement) {
          cancelElement.focus();
          return;
        }

        let isSubmitElementDisabled = submitElement.disabled;

        if (isSubmitElementDisabled) {
          cancelElement.focus();
        } else {
          submitElement.focus();
        }
      }
    },
    cancelKeyDown(evt, defaultFocusElement, submitFocusElement, middleFocusElement = '') {
      // Metod för att fånga fokus för tabbning i en modal för cancel-knapp
      if (evt.keyCode !== 9) return;
      let submitElement = document.getElementById(submitFocusElement);
      let defaultElement = document.getElementById(defaultFocusElement);

      if (!defaultElement) {
        defaultElement = document.getElementsByClassName(defaultFocusElement)[0];
      }

      if (!submitElement) {
        defaultElement.focus();
        return;
      }
      let isSubmitElementDisabled = submitElement.disabled;

      if (!evt.shiftKey) {
        if (isSubmitElementDisabled) {
          defaultElement.focus();
          return;
        }
        submitElement.focus();
      } else {
        if (defaultElement === document.activeElement) {
          submitElement.focus();
          return;
        }
        if (middleFocusElement) {
          let middleElement = document.getElementById(middleFocusElement);
          if (!middleElement) {
            middleElement = document.getElementsByClassName(middleFocusElement)[1];
          }
          middleElement.focus();
          return;
        }
        defaultElement.focus();
      }
    },
    submitKeyDown(evt, defaultFocusElement, cancelFocusElement) {
      // Metod för att fånga fokus för tabbning i en modal för submit-knapp
      if (evt.keyCode !== 9) return;

      let defaultElement = document.getElementById(defaultFocusElement);
      let cancelElement = document.getElementById(cancelFocusElement);
      if (!defaultElement) {
        defaultElement = document.getElementsByClassName(defaultFocusElement)[0];
      }

      if (!evt.shiftKey) {
        defaultElement.focus();
      } else {
        if (!cancelElement) {
          defaultElement.focus();
        } else {
          cancelElement.focus();
        }
      }
    },
    scrollToTopWithOffset() {
      const yOffset = -120;
      const element = document.getElementById('buynsend');
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
};
