class Discount {
  constructor(id, name, percentage, amount) {
    this.id = id;
    this.name = name;
    this.percentage = percentage;
    this.amount = amount;
    this.vatPercentage = 6;
  }

  get amountExludingVat() {
    return Math.round((this.amount - this.vat) * 100) / 100;
  }

  get vat() {
    return Math.round(((this.amount * (this.vatPercentage / 100)) / (this.vatPercentage / 100 + 1)) * 100) / 100;
  }
}

export default Discount;
