import _cookieService from './cookieService'
import { CLEAR_ACCOUNT } from '../store/actions/account-actions'

const _interval = []

const AuthInterval = {
  createInterval(vm) {
    const intervalTime = 1000 * 1; //  minutes
    if (_interval.length === 0) {
      let authVm = this;
      let newInterval = window.setInterval(function () {
        if (!_cookieService.getTokenFromCookie('StarJwtTokenCookieName')) {
          let promises = [vm.$store.commit(CLEAR_ACCOUNT)];
          Promise.all(promises)
          .finally(() => {
            vm.$router.push('konto');
            authVm.removeInterval();
          });
        }
      }, intervalTime);
      _interval.push(newInterval);
    }
  },

  removeInterval() {
    if (_interval.length > 0) {
      window.clearInterval(_interval.pop());
    }
  }
};

Object.freeze(AuthInterval);
export default AuthInterval;