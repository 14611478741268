<template>
  <div class="passage-type-picker">
    <st-fieldset legend="Välj överfart" required>
      <st-radio-button
        name="passageType"
        v-model="passageTypeModel"
        required="required"
        v-for="item of passageTypes"
        :key="item.priceId"
        :value="item"
        :id="item.passageType"
      >
        {{ getPassageTypeByPriceId(item.priceId).alt }}
      </st-radio-button>
    </st-fieldset>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import {
  GET_PASSAGE_TYPES,
  SET_SELECTED_PASSAGE_TYPE,
  GET_SELECTED_PASSAGE_TYPE
} from '../../../store/actions/passage-type-actions';
import { SET_JOURNEY_BY_PASSAGE_TYPE } from '../../../store/actions/journey-actions';

import globalMixins from '../../../mixins/globalMixin';
export default {
  mixins: [globalMixins],
  data() {
    return {
      isMounted: false
    };
  },
  beforeMount() {
    this.setInitialValues();
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      passageTypes: GET_PASSAGE_TYPES,
      selectedPassageType: GET_SELECTED_PASSAGE_TYPE
    }),
    ...mapState({
      offerId: (state) => state.ticketModule.offerId
    }),
    passageTypeModel: {
      get() {
        return this.selectedPassageType;
      },
      set(value) {
        this.setSelectedPassageType(value);
      }
    }
  },
  methods: {
    ...mapActions({
      setJourneyByPassageType: SET_JOURNEY_BY_PASSAGE_TYPE
    }),
    ...mapMutations({
      setSelectedPassageType: SET_SELECTED_PASSAGE_TYPE
    }),
    setInitialValues() {
      if (Object.keys(this.selectedPassageType).length === 0) {
        this.setSelectedPassageType(this.passageTypes[0]);
      }
    }
  },
  watch: {
    selectedPassageType: function () {
      if (!this.isMounted) return;

      let priceId = this.selectedPassageType.priceId;
      let originalPriceId = this.selectedPassageType.priceId;

      this.setJourneyByPassageType({
        passageType: this.selectedPassageType.passageType,
        priceId,
        originalPriceId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.passage-type-picker {
  margin-bottom: 1em;
}
</style>
