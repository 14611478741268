<template>
  <div class="traveller-picker">
    <st-fieldset :legend="$t('TravellerPicker_Label_NumberOfTravellers')" required id="numberOfTravellers" :validation="$v.numberOfTravellers" :validationErrors="validationErrors.travellerType" :alertErrorMessages="false">
      <template #default="{ ariaDescribedBy }">
      <div class="list-container">
        <div v-for="(item, index) in travellerTypes" :key="index">
          <div class="row">
            <div role="text" class="col col-4 col-sm-3 col-md-4 bold traveller-name">
              <st-icon role="presentation" :name="item.icon" class="traveller-type-icon" :alt="item.iconAltText" useSvg inline></st-icon>
              <span>{{ item.name }} {{ item.age }}</span>
            </div>
            <div class="col col-4 col-sm-6 col-md-4 text-center">
              <div class="count-div">
                <st-button :aria-label="getGrammar(item.name, false)" :aria-describedby="ariaDescribedBy" class="count-button" @click="decrease(item)" :disabled="!canDecrease(item.travellerType)" :tabindex="tabindex">
                  <st-icon role="presentation" name="minus" useSvg inline :alt="$t('TravellerPicker_Decrease_Button_Alt') + item.name"></st-icon>
                </st-button>
                <div role="text" aria-live="assertive" :aria-label="`Antal ${getPlural(item.name)} valda: ${travellersByTravellerType(item.travellerType).length}, pris ${priceByTravellerType(item.travellerType)} kronor`" class="count-text notranslate">
                  <span aria-hidden="true">{{ travellersByTravellerType(item.travellerType).length }}</span>
                </div>
                <st-button :aria-label="getGrammar(item.name, true)" :aria-describedby="ariaDescribedBy" class="count-button increase-count" @click="increase(item)" :disabled="!canIncrease(item.travellerType)" :tabindex="tabindex" @blur.native="$v.numberOfTravellers.$touch">
                  <st-icon role="presentation" name="plus" useSvg inline :alt="$t('TravellerPicker_Increase_Button_Alt') + item.name"></st-icon>
                </st-button>
              </div>
            </div>
            <div
              role="text"
              :aria-label="`Sammanlagd summa för ${travellersByTravellerType(item.travellerType).length} ${getPlural(item.name)}: ${priceByTravellerType(item.travellerType)} kronor`"
              class="col col-4 col-sm-3 col-md-4 text-right count-text notranslate"
              style="display: inline-block"
            >
              <span aria-hidden="true">{{ priceByTravellerType(item.travellerType) | fixedDecimals }} kr</span>
            </div>
          </div>
          <div v-if="index !== travellerTypes.length - 1" class="row-hr" />
        </div>
      </div>
      </template>
    </st-fieldset>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { GET_TRAVELLER_TYPES } from '../../../store/actions/base-actions';
import { ADD_TRAVELLER, REMOVE_TRAVELLER, GET_TRAVELLERS_BY_TRAVELLER_TYPE, GET_PRICE_BY_TRAVELLER_TYPE, GET_TRAVELLER_TOTAL } from '../../../store/actions/ticket-actions';
import globalMixins from '../../../mixins/globalMixin';
import travellerTypeFormValidationErrors from './traveller-picker-form-validation-errors';
import { requiredIf } from 'vuelidate/lib/validators';

export default {
  mixins: [globalMixins],
  props: {
    tabindex: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      validationErrors: travellerTypeFormValidationErrors
    };
  },
  computed: {
    ...mapGetters({
      travellerTypes: GET_TRAVELLER_TYPES,
      travellersByTravellerType: GET_TRAVELLERS_BY_TRAVELLER_TYPE,
      priceByTravellerType: GET_PRICE_BY_TRAVELLER_TYPE,
      numberOfTravellers: GET_TRAVELLER_TOTAL,
      wcagErrorHandling: 'wcagErrorHandling'
    })
  },
  validations: {
    numberOfTravellers: {
      requiredIf: requiredIf((val) => {
        return val.numberOfTravellers.length < 1;
      })
    }
  },
  methods: {
    ...mapMutations({
      increase: ADD_TRAVELLER,
      decrease: REMOVE_TRAVELLER
    }),
    canDecrease(travellerType) {
      let item;
      for (let i = 0; i < this.travellerTypes.length; i++) {
        item = this.travellerTypes[i];
        if (item.travellerType === travellerType) break;
      }

      if (!item) return;

      let count = this.travellersByTravellerType(travellerType).length;

      return count > item.min;
    },
    canIncrease(travellerType) {
      let item;
      for (let i = 0; i < this.travellerTypes.length; i++) {
        item = this.travellerTypes[i];
        if (item.travellerType === travellerType) break;
      }

      if (!item) return;

      let count = this.travellersByTravellerType(travellerType).length;

      return count < item.max;
    },
    getGrammar(name, isIncreasing) {
      let action = isIncreasing ? 'Lägg till ' : 'Ta bort ';

      if (name === 'Vuxen' || name === 'Student' || name.includes('Senior') || name.includes('Ungdom') || name.includes('Pensionär')) {
        return action + 'en ' + name + '. ';
      } else if (name === 'Barn 7-19 år' || 'Barn') {
        return action + 'ett barn. ';
      } else {
        return action + ' en resenär. ';
      }
    },
    getPlural(name) {
      if (name === 'Vuxen') return 'vuxna';
      else if (name === 'Student') return 'studenter';
      else if (name.includes('Barn')) return 'barn';
      else if (name.includes('Senior')) return 'seniorer';
      else return name;
    }
  },
  watch: {
    'wcagErrorHandling.shouldValidateAll': function (bool) {
      if (bool) {
        this.$v.$touch();
        if (this.$v.$error && !this.wcagErrorHandling.stopPointsHasError) {
          const $el = document.getElementsByClassName('increase-count')[0];
          if($el) $el.focus();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/focus.scss';
.traveller-picker {
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4d4845;

  .list-container {
    border: 0.0625em solid #807569;
    border-radius: 0.25em;
    padding: 0.25em 0em 0.25em 0.25em;

    .st-icon {
      height: 2em;
      width: 2.8125em;

      @media (max-width: 320px) {
        height: 1.6875em;
        width: 2.5em;
      }
    }

    .text-center {
      padding: 0em !important;
    }

    .st-icon.adult,
    .st-icon.child,
    .st-icon.student,
    .st-icon.senior {
      margin-left: -0.875em;
    }

    @media (max-width: 576px) {
      .traveller-type-icon {
        display: none;
      }
    }

    .st-button {
      width: 2.75em;
      height: 2em;
    }

    .row {
      margin: 0em;
      padding: 0.25em 1em 0.25em 0.75em;
      align-items: center;
    }

    .col {
      padding: 0em;
      color: #4d4845;

      &.bold {
        font-size: 1em;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;

        @media (max-width: 320px) {
          font-size: 0.8125em;
        }
      }

      &.traveller-name {
        padding-right: 0em !important;
      }
    }

    .age {
      display: inline-block;
      font-weight: normal;
    }

    .count-div {
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;

      .st-button.count-button {
        background: transparent;
        border: 0.0625em solid #807569;
        width: 2.75em;
        height: 2em;
        padding: 0em;
        border-radius: 0.25em;
        .st-icon {
          height: auto;
          width: 100%;
        }

        &:disabled {
          opacity: 0.5;
        }

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background-color: #faf9f7;
        }
      }
    }

    .count-text {
      min-width: 2em;
      font-size: 1em;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;

      @media (max-width: 320px) {
        font-size: 0.8125em;
      }
    }

    .row-hr {
      margin: 0.25em 1em 0.25em 1em;
      height: 0.0625em;
      opacity: 0.15;
      background-color: #6e6054;
    }
  }
}
</style>
