export default {
  getModel() {
    return {
      name: '',
      travellerType: '',
      availability: '',
      amountIncludingVat: '',
      count: '1',
      area: '',
      id: '',
      priceId: '',
      priceModelId: '',
      bannerColor: '',
      img: '',
      link: ''
    };
  }
};
