<template>
  <div class="campaign-tickets" v-if="hasCampaignOffers">
    <campaign-card></campaign-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { HAS_CAMPAIGN_OFFERS } from '../../store/actions/campaign-offers-actions';
import CampaignCard from './CampaignCard';
export default {
  name: 'campaign-tickets',
  components: {
    CampaignCard
  },
  computed: {
    ...mapGetters({
      hasCampaignOffers: HAS_CAMPAIGN_OFFERS
    })
  }
};
</script>
