var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-details-info"},[_c('div',{staticClass:"d-sm-none"},[_c('st-table-mobile',{attrs:{"items":_vm.transactionListModified,"fields":_vm.transactionMobileFields,"useToggle":""},scopedSlots:_vm._u([{key:"transaction-date-value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.created))])]}},{key:"amount-value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount))])]}},{key:"payment-status-value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))])]}}])})],1),_c('div',{staticClass:"d-none d-sm-block"},[_c('st-table',{attrs:{"items":_vm.transactionListModified,"fields":_vm.transactionFields},scopedSlots:_vm._u([{key:"transaction-date-value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.created))])]}},{key:"amount-value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount))])]}},{key:"payment-status-value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }