<template>
  <div class="addon-picker">
    <div class="list-container">
      <div v-for="(item, index) in avaliableAddons" :key="index">
        <div class="row">
          <div class="col col-1 bold text-left">
            <st-icon :name="getAddonIconByType(item.addonType, item.id)" :alt="getAddonAltText(item.addonType, item.id)" class="traveller-type-icon" useSvg role="presentation"></st-icon>
          </div>
          <div class="col col-12 col-md-3 addon-name bold">
            {{ item.name }}
            <span v-if="item.addonType === 2">{{ optionalCities.join(', ') }}</span>
          </div>
          <div class="col col-12 col-md-4 addon-count-wrapper text-center" v-if="!item.purchasePerTraveller">
            <div class="count-div">
              <st-button :aria-label="`Ta bort en ${item.name}`" id="addonBikeDecrease" class="count-button" @click="decrease(item)" :tabindex="tabindex">
                <st-icon name="minus" :alt="$t('AddonPicker_Remove_Addon')" useSvg inline role="presentation"></st-icon>
              </st-button>
              <div class="count-text notranslate" role="text" :class="addonById(item.id).count > 0 ? 'dark' : ''">
                <span aria-hidden="true">{{ addonById(item.id).count }}</span>
              </div>
              <st-button :aria-label="`Lägg till en ${item.name}`" id="addonBikeIncrease" class="count-button" @click="increase(item)" :tabindex="tabindex">
                <st-icon name="plus" :alt="$t('AddonPicker_Add_Addon')" useSvg inline role="presentation"></st-icon>
              </st-button>
            </div>
          </div>
          <div class="col col-12 col-md-4 text-center" v-else>
            <st-checkbox class="st-checkbox" :id="`${item.id}-checkbox`" :checked="addonIsSelected(item.id)" :tabindex="tabindex" :ariaLabel="$t('AddonPicker_Checkbox') + ' ' + item.name" @input="onToggle(item)">{{ $t('AddonPickerModal_Checkbox_Add_Addon') }}</st-checkbox>
          </div>
          <div role="text" class="col col-12 col-md-4 addon-price count-text notranslate" :class="addonById(item.id).count ? 'dark' : ''" style="display: inline-block" :aria-label="`Antal ${item.name} valda: ${addonById(item.id).count}, pris ${addonPriceById(item.id)} kronor`" aria-live="polite">
            <span aria-hidden="true">{{ addonPriceById(item.id) | fixedDecimals }} kr</span>
          </div>
        </div>
        <div v-if="index !== avaliableAddons.length - 1" class="row-hr" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { GET_AVAILABLE_ADDONS } from '../../../store/index';
import { ADD_ADDON, REMOVE_ADDON, TOGGLE_ADDON, GET_ADDON_BY_ID, GET_ADDON_PRICE_BY_ID, SET_OPTIONAL_CITIES, GET_SELECTED_ADDONS } from '../../../store/actions/ticket-actions';
import { GET_JOURNEY_POSSIBLE_JOURNEYS_OPTIONAL_CITIES } from '../../../store/actions/journey-actions';

import globalMixins from '../../../mixins/globalMixin.js';
export default {
  mixins: [globalMixins],
  props: {
    tabindex: {
      type: String,
      default: '0'
    }
  },
  computed: {
    ...mapGetters({
      avaliableAddons: GET_AVAILABLE_ADDONS,
      addonPriceById: GET_ADDON_PRICE_BY_ID,
      addonById: GET_ADDON_BY_ID,
      optionalCities: GET_JOURNEY_POSSIBLE_JOURNEYS_OPTIONAL_CITIES,
      selectedAddons: GET_SELECTED_ADDONS
    })
  },
  methods: {
    ...mapMutations({
      increase: ADD_ADDON,
      decrease: REMOVE_ADDON
    }),
    ...mapActions({
      setOptionalCities: SET_OPTIONAL_CITIES,
      toggle: TOGGLE_ADDON
    }),
    addonIsSelected(id) {
      return this.selectedAddons.some((addon) => addon.id === id);
    },
    onToggle(item) {
      var result = this.toggle(item).then((result) => {
        if (item.addonType === 2) {
          this.setOptionalCities(result ? this.optionalCities : []);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/focus.scss';
.addon-picker {
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4d4845;

  .list-container {
    border: solid 0.0625em #d6d3d0;
    border-radius: 0.5em;
    padding: 0.25em 0em;

    .st-icon {
      height: 2em;
      width: 2.8125em;

      @media (max-width: 320px) {
        width: 1.5em !important;
        height: 1.8125em !important;
      }
    }

    @media (max-width: 576px) {
      .traveller-type-icon {
        display: none;
      }
    }

    .st-button {
      width: 2.75em;
      height: 2em;
    }

    .st-checkbox {
      @media (max-width: 767px) {
        margin-top: 0.5em;
      }
    }
    .row {
      min-height: 3em;
      margin: 0em;
      padding: 0.25em 1em;
      align-items: center;
    }

    .col {
      padding: 0em;
      color: #4d4845;

      &.addon-name {
        text-align: center;
        @media (min-width: 768px) {
          text-align: left;
        }
      }

      &.addon-count-wrapper {
        @media (max-width: 767px) {
          margin: 0.313em 0em;
        }
      }

      &.addon-price {
        text-align: center;
        @media (min-width: 768px) {
          text-align: right;
        }
      }

      &.bold {
        font-size: 1em;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;

        @media (max-width: 320px) {
          font-size: 0.8125em;
        }
      }
    }

    .age {
      display: inline-block;
      font-weight: normal;
    }

    .count-div {
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;

      button.count-button {
        background: transparent;
        border: 0.0625em solid #807569;
        width: 2.75em;
        height: 2em;
        padding: 0em;
        border-radius: 0.25em;
        .st-icon {
          height: auto;
          width: 100%;
        }

        &:disabled {
          opacity: 0.5;
        }

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background-color: #faf9f7;
        }
      }
    }

    .count-text {
      min-width: 2em;
      opacity: 0.6;
      font-size: 1em;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;

      &.dark {
        opacity: 1;
      }
    }

    .row-hr {
      margin: 0.25em 1em 0.25em 1em;
      height: 0.0625em;
      opacity: 0.15;
      background-color: #6e6054;
    }
  }
}
</style>
