<template>
  <button :type="type" :class="classList" :disabled="disabled" :tabindex="tabindex" @click="onClick">
    <slot>default text</slot>
  </button>
</template>

<script>
export default {
  name: 'st-button',

  props: {
    type: {
      type: String,
      default: 'button',
      validator: function (type) {
        return ['submit', 'button', 'reset'].includes(type);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    primary: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classList: function () {
      return ['st-button', this.primary && 'primary', this.large && 'lg', this.medium && 'md', this.block && 'block'];
    }
  },

  methods: {
    onClick: function (e) {
      if (this.type === 'submit' || this.type === 'reset') return;

      this.$emit('click', e);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../scss/fonts.scss';
.st-button {
  background-color: #ffffff;
  transition: background-color 0.2s ease;
  border: 0.0625em solid #f7bb0d;
  border-radius: 0.25em !important;
  font-family: $source-sans-pro !important;

  padding: 0em 0.875em;
  height: 2.125em;

  font-size: 0.875em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #4d4845;

  &:hover:not(:disabled) {
    background-color: #fdf7e2;
  }

  &:focus {
    box-shadow: 0 0 0 0.125rem #514f4f;
    border-color: transparent;
    outline: none;
    cursor: pointer;
  }

  &.primary {
    color: #2b241d;
    background-color: #f6bb0a;
    border-color: #f6bb0a;

    &:hover:not(:disabled) {
      background-color: #f6a200;
      border-color: #f6a200;
    }
  }

  &.md {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    height: 3em;
    margin-top: 1.125em !important;
    padding: 0.75em 2em;
    border-width: 0.125em;

    @media (max-width: 767px) {
      padding: 0em 0.875em;
    }
  }

  &.lg {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    height: 3em;
    width: 9.6875em;
    margin-top: 2.25em;
    padding: 0.375em 1em;
    border-width: 0.125em;
  }

  &.block {
    width: 100% !important;
  }

  &:disabled {
    opacity: 0.5;
  }
}

@media (min-width: 768px) {
  .st-button {
    &.sm {
      font-size: 1em;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4d4845;
    }
    &.md,
    &.lg {
      font-size: 1.125em;
    }

    &.lg {
      padding: 0.75em 2em;
      height: 4em;
      width: 15em;
    }
  }
}
</style>
