class Offer {
  constructor(id, name, description, ticketOfferType, sortOrder, disable) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.ticketOfferType = ticketOfferType;
    this.sortOrder = sortOrder;
    this.disable = disable;
  }
}

export default Offer;
