import cardOrderState from '../states/card-order-state';
import { CLEAR_CARD_ORDER_MODEL_STATE } from '../actions/card-order-actions';

const state = {
  model: cardOrderState.getModel()
};

const mutations = {
  [CLEAR_CARD_ORDER_MODEL_STATE]: state => {
    state.model = cardOrderState.getModel();
  }
};

export default {
  state,
  mutations
};
