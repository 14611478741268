<template>
  <form class="body-form" @submit.prevent="submit">
    <st-form-group class="voucher-form" :label="$t('Voucher_Input_Label')" :validation="$v.model.voucher" :validationErrors="validationErrors.voucher" flex>
      <template v-slot:default="{ ariaDescribedBy }">
        <div class="clearfix">
          <st-input class="voucher-input" :aria-describedby="ariaDescribedBy" tabindex="0" v-model="model.voucher" :invalid="$v.model.voucher.$error" aria-label="Fyll i din värdekod" :blur="$v.model.voucher.$touch" :placeholder="$t('Voucher_Input_Placeholder')" :disabled="amountDue <= 0 || isLoading">> </st-input>
          <st-button medium type="submit" tabindex="0" :disabled="$v.$invalid || amountDue <= 0 || isLoading">
            <st-spinner :thin="true" v-if="isLoading" :loading-message="'Kontrollerar värdekod'" />
            <span v-if="!isLoading">{{ $t('Use_Voucher_Button') }}</span>
          </st-button>
        </div>
        <st-error :error="error" class="mt-2" v-if="!$v.$dirty && error" role="alert" />
      </template>
    </st-form-group>
  </form>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import VoucherValidation from './voucher-validations';
import VoucherValidationErrors from './voucher-validation-errors';
import { ADD_VOUCHER, GET_VOUCHER_BY_CODE, CLEAR_VOUCHERS } from '../../store/actions/voucher-actions';
import { GET_AMOUNT_DUE } from '../../store/actions/ticket-actions';
import Voucher from '../../models/voucher';

export default {
  name: 'voucher-form',

  deactivated() {
    this.onDeactivated();
  },

  beforeDestroy() {
    this.onDeactivated();
  },
  validations: {
    model: VoucherValidation
  },

  props: {
    id: {
      type: String
    }
  },

  data: function () {
    return {
      validationErrors: VoucherValidationErrors,
      isLoading: false,
      error: undefined,
      model: {
        voucher: ''
      },
      currentVoucher: null
    };
  },

  computed: {
    ...mapGetters({ amountDue: GET_AMOUNT_DUE })
  },

  methods: {
    ...mapActions({ getVoucher: GET_VOUCHER_BY_CODE }),
    ...mapMutations({ addVoucher: ADD_VOUCHER, clearVouchers: CLEAR_VOUCHERS }),

    onDeactivated() {
      this.clearForm();
      this.clearVouchers();
    },

    setCustomError: function (errorMessage) {
      if (errorMessage) {
        this.error = {
          response: {
            data: { message: errorMessage }
          }
        };
      } else {
        this.error = true;
      }

      this.$v.$reset();
    },

    clearForm: function () {
      this.currentVoucher = null;
      this.model.voucher = '';
      this.$v.$reset();
      this.error = undefined;
    },

    submit: async function () {
      let vm = this;
      vm.isLoading = true;
      vm.error = undefined;

      //eslint-disable-next-line no-undef
      if (!grecaptcha) {
        vm.setCustomError();
        vm.isLoading = false;
        return;
      }

      // grecaptcha returns a fake promise that is thenable but doesn't have any other promise features
      // eslint-disable-next-line no-undef
      grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'getvoucherbycode' }).then((token) => {
        let payload = {
          data: { voucher: vm.model.voucher },
          token: token
        };

        vm.getVoucher(payload)
          .then((result) => {
            if (!result || !result.voucherId || !result.voucherCode) {
              vm.setCustomError();
              return;
            }

            vm.currentVoucher = new Voucher(result.voucherId, result.voucherCode, result.amountIncVat, result.voucherType, result.redeemable, result.reasonNotRedeemable);

            if (vm.currentVoucher.isRedeemable) {
              vm.addVoucher(vm.currentVoucher);
              vm.clearForm();
              vm.isLoading = false;
              this.$nextTick(() => {
                document.getElementById('buy-ticket-confirm-submit').focus();
              });
            } else {
              vm.setCustomError(vm.currentVoucher.reasonNotRedeemable);
              vm.isLoading = false;
              this.$nextTick(() => {
                document.querySelector('.voucher-input').focus();
              });
            }
          })
          .catch((error) => {
            vm.error = error;
            this.$v.$reset();
            vm.isLoading = false;
            this.$nextTick(() => {
              document.querySelector('.voucher-input').focus();
            });
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.voucher-form {
  display: flex;
  flex-direction: column;
  width: calc(50% - 15px);
}

.body-form {
  display: flex;
  justify-content: flex-end;
}

.voucher-input {
  margin-top: 15px !important;
}

#buynsend .voucher-form.st-form-group input.voucher-input {
  flex: 4;
  margin-right: 0.625em;
}

/deep/ .voucher-form.st-form-group {
  margin: 0;
  @media (max-width: 576px) {
    width: 100%;
  }

  .clearfix {
    display: flex;
    align-items: end;
  }
}

/deep/ .st-form-group .st-button {
  flex: 3;
}

/deep/ .voucher-form.st-form-group label {
  height: 0em;
}

/deep/ .st-button .st-spinner .spinner.thin {
  width: 0.64em;
  height: 0.64em;
  margin-left: -0.28em;
  margin-top: -0.32em;
}
</style>
