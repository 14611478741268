<template>
  <div class="card-registration">
    <router-link to="/" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>

    <h1>{{ $t('CardRegistration_Header') }}</h1>
    <div class="row">
      <div class="col-md-7 col-12">
        <p>{{ $t('CardRegistration_Description') }}</p>
        <card-registration-form></card-registration-form>
      </div>
      <div class="d-none d-md-block col-md-5 card-info-wrapper">
        <card-info-image />
      </div>
    </div>
  </div>
</template>

<script>
import CardRegistrationForm from '../../../components/card-registration-form/CardRegistrationForm';
import CardInfoImage from '../../../components/CardInfoImage';
export default {
  components: {
    CardRegistrationForm,
    CardInfoImage
  }
};
</script>

<style lang="scss" scoped>
.card-registration {
  h1 {
    font-size: 2.25em !important;
    margin: 0.55555556em 0em 0.5em !important;
  }

  .card-info-wrapper {
    /deep/ .card-img,
    /deep/ .card-img-info {
      @media (min-width: 768px) and (max-width: 992px) {
        width: 80% !important;
      }
    }
  }
}
</style>
