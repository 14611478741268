export const focusTrap = {
  bind(el) {
    el.addEventListener('keydown', (event) => {
      const focusableElements = Array.from(
        el.querySelectorAll(
          'button:not([disabled]), [href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'
        )
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];
      if (event.keyCode === 9) {
        event.preventDefault();

        if (event.shiftKey) {
          if (document.activeElement === firstElement) {
            lastElement.focus();
          } else {
            const currentIndex = focusableElements.findIndex((element) => element === document.activeElement);
            const previousIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : focusableElements.length - 1;
            focusableElements[previousIndex].focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            firstElement.focus();
          } else {
            const currentIndex = focusableElements.findIndex((element) => element === document.activeElement);
            const nextIndex = currentIndex + 1 < focusableElements.length ? currentIndex + 1 : 0;
            focusableElements[nextIndex].focus();
          }
        }
      }
    });
  }
};
