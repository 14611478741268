<template>
  <div class="login">
    <div class="row">
      <div class="col-12 login-header">
        <span v-if="successfulSignup" v-html="$t('Login_SuccessfulSignup_Message')"></span>
        <h1>{{ $t('Login_Header') }}</h1>
        <p>{{ $t('Login_Description') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2">
        <login-form ref="loginForm"></login-form>

        <div class="login-bottom">
          <router-link class="link forgot-password-link" to="/konto/glomt-losenord" tabindex="0">{{ $t('Login_Link_RequestResetPassword') }}</router-link>
          <h2>{{ $t('Login_Links_Divider') }}</h2>
          <div>
            <st-button class="create-account-button single-button" medium tabindex="0" @click="toSignUpRoute">{{ $t('Login_Link_Signup') }}</st-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '../../../components/login-form/LoginForm';
import { mapGetters } from 'vuex';
import authService from '../../../services/authService';

export default {
  props: {
    successfulSignup: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LoginForm
  },
  mounted() {
    if (authService.isAuthenticated()) {
      this.$router.push('/');
    }
  },
  methods: {
    toSignUpRoute() {
      this.$router.push('/konto/skapa-konto');
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  .login-header {
    text-align: center;
  }
  .login-bottom {
    text-align: center;

    .forgot-password-link {
      margin-top: 1.5625em;
      margin-bottom: 3.125em;
      font-size: 0.875em;
      margin-top: 1.25em;
      color: #6e6054 !important;
    }

    .create-account-button {
      @media (min-width: 558px) {
        width: 17.25em;
      }

      margin-bottom: 0.9375em;
    }
  }
  h1 {
    font-size: 2.25em !important;
    margin: 0.55555556em 0em 0.5em !important;
  }

  h2 {
    font-size: 1.5em !important;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9375em;
    line-height: 1.25em;
    letter-spacing: 0.0167em;
  }
  a {
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    color: #4d4845;
    text-decoration: none;
    display: inline-block;
    margin-top: 0.25em;
    padding: 0.125em 0.4375em 0.3125em 0.4375em;
    border-radius: 0.25em 0.25em 0.25em 0.25em;

    &.link {
      &:after {
        content: ' ›';
      }
      &:hover {
        color: #cc4039 !important;
        text-decoration: underline !important;
      }
      &:focus {
        padding: 0.125em 0.4375em 0.3125em 0.4375em;
      }
    }
  }
}
</style>
