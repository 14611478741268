import i18n from '../../i18n/index';

export default {
  email: [
    {
      key: 'required',
      message: i18n.t('LoginForm_Email_Required_Validation'),
      id: 'emailRequired'
    },
    {
      key: 'email',
      message: i18n.t('LoginForm_Email_Email_Validation'),
      id: 'emailFormat'
    }
  ],
  password: [
    {
      key: 'required',
      message: i18n.t('LoginForm_Password_Required_Validation'),
      id: 'passwordRequired'
    }
  ]
};
