<template>
  <div class="credit-card-details-info">
    <div class="d-none d-md-block" style="float:left;">
      <img style="width: 10.3125rem; height: 6.8125rem;" :src="cardSandSrc" :alt="$t('Start_BuyCard_Alt_Text')" />
    </div>
    <div class="row">
      <div class="col-12">
        <span style="margin-right:8%;">
          <b>{{ $t('CardDetailsInfo_Label_CardNumber') }}</b>
        </span>

        <span>{{ card.cardNumber | cardNumberFormat }}</span>
      </div>
    </div>
    <div>
      <div class="col-12">
        <span>
          <b>Giltig t.o.m</b>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-3">
        <span>
          <b>{{ $t('CardDetailsInfo_Label_CardStatus') }}</b>
        </span>
      </div>
      <div class="col-6 col-md-9">
        <st-status :status="getStatus()"></st-status>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardSandSrc: `${process.env.VUE_APP_BASE_URL}assets/images/${process.env.VUE_APP_SYSTEM}/card-sand.svg`
    };
  }
};
</script>

<style lang="scss" scoped>
.credit-card-details-info {
  min-height: 9.375rem;
}
</style>
