<template>
  <div>
    <img class="card-img" :src="`${publicPath}assets/images/${system}/tomt-kort.svg`" :alt="$t('Start_BuyCard_Alt_Text')" aria-describedby="cardnumber-description" />
    <div class="card-img-info">
      <p id="cardnumber-description">{{ $t('CardInfoModal_CardNumber_Description') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      system: process.env.VUE_APP_SYSTEM
    };
  }
};
</script>

<style lang="scss" scoped>
.card-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15.1875em;

  @media (max-width: 767px) {
    padding-top: 1.125em;
  }
}

.card-img,
.card-img-info {
  @media (max-width: 575px) {
    width: 80% !important;
  }
}

.card-img-info {
  width: 15.25em;
  margin: auto;

  .arrow-img {
    margin-top: -0.875em;
    margin-left: 1.5em;
  }

  p {
    margin-top: -1.125em;
    margin-left: 4em;
    font-size: 0.9375em !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e6054;
    @media (max-width: 480px) {
      margin-left: 0em;
    }
  }
}
</style>
