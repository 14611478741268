import api from '../../api';

import { GET_ACCOUNT, SET_ACCOUNT, CLEAR_ACCOUNT } from '../actions/account-actions';

const state = {
  account: {},
  email: ''
};

const actions = {
  [GET_ACCOUNT]: ({ commit }, id) => {
    const url = `mitt-konto/get-account`;
    return api.get(url).then(({ data }) => {
      commit(SET_ACCOUNT, data);
    });
  }
};

const mutations = {
  [SET_ACCOUNT]: (state, payload) => {
    state.account = payload;
  },
  [CLEAR_ACCOUNT]: (state) => {
    state.account = {};
  },
  setEmail(state, payload) {
    state.email = payload;
  }
};

const getters = {
  [GET_ACCOUNT]: (state) => {
    return state.account;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
