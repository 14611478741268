import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';

import i18n from '../i18n/index';
import languages from '../i18n/languages/languages';

import journeyModule from './modules/journey-module';
import mapDetailsModule from './modules/map-details-module';
import ticketModule from './modules/ticket-module';
import ticketOffersModule from './modules/ticket-offers-module';
import travelAreaModule from './modules/travel-area-module';
import cardModule from './modules/card-module';
import tapnrideModule from './modules/tapnride-module';
import cardOrderModule from './modules/card-order-module';
import accountModule from './modules/account-module';
import voucherModule from './modules/voucher-module';
import campaignOffersModule from './modules/campaign-offers-module';
import passageTypeModule from './modules/passage-type-module';
import epiService from '../services/epiService';

import TravellerType from '../models/travellerType';
import Addon from '../models/addon';
import { GET_TICKET_OFFERS, GET_TICKET_OFFERS_OFFERS_NAME } from './actions/ticket-offers-actions';
import { GET_TRAVEL_AREAS } from './actions/travel-area-actions';
import { APP_BUY_N_SEND, APP_MITT_KONTO } from '../models/constants';
import {
  GET_TRAVELLER_TYPES,
  GET_COUNTRIES,
  SET_INIT_TRAVELLERTYPES_ST,
  SET_INIT_TRAVELLERTYPES_BT,
  SET_INIT_TRAVELLERTYPES_OT,
  FETCH_BUYANDSEND_URL,
  SET_BUYANDSEND_URL,
  GET_BUYANDSEND_URL
} from './actions/base-actions';

export const GET_AVAILABLE_ADDONS = 'GET_AVAILABLE_ADDONS';
export const LOAD_DEPENDENCIES = 'LOAD_DEPENDENCIES';
export const SET_DEPENDENCIES_LOADED = 'SET_DEPENDENCIES_LOADED';
export const IS_LOADED = 'IS_LOADED';
export const LANGAUGES_LOADED = 'LANGAUGES_LOADED';
export const GET_STOP_AREAS_FROM_SOK_RESA = 'GET_STOP_AREAS_FROM_SOK_RESA';

const buynSendStorage = window.sessionStorage.getItem('st-buy-n-send');
const stopAreasFromSokResa = buynSendStorage ? JSON.parse(buynSendStorage) : [];
window.sessionStorage.removeItem('st-buy-n-send');

export default new Vuex.Store({
  state: {
    wcagErrorHandling: {
      shouldValidateAll: false,
      stopPointsHasError: false,
      appBearerHasError: false,
      cardBearerHasError: false,
      emailHasError: false,
      acceptTermsHasError: false
    },
    stopAreasFromSokResa: stopAreasFromSokResa,
    buyAandSendUrl: null,
    isLoaded: false,
    languagesLoaded: false,
    travellerTypes: [
      new TravellerType('', i18n.t('Traveller_Type_Adult'), '', 1, 0, 1, 0, 0, 'adult'),
      new TravellerType('', i18n.t('Traveller_Type_Child'), '', 3, 0, 1, 0, 0, 'child'),
      new TravellerType('', i18n.t('Traveller_Type_Student'), '', 2, 0, 1, 0, 0, 'student')
    ],
    countries: [
      { id: 'AF', name: 'Afghanistan' },
      { id: 'AL', name: 'Albanien' },
      { id: 'DZ', name: 'Algeriet' },
      { id: 'VI', name: 'Amerikanska Jungfruöarna' },
      { id: 'AS', name: 'Amerikanska Samoa' },
      { id: 'AD', name: 'Andorra' },
      { id: 'AO', name: 'Angola' },
      { id: 'AI', name: 'Anguilla' },
      { id: 'AQ', name: 'Antarktis' },
      { id: 'AG', name: 'Antigua och Barbuda' },
      { id: 'AR', name: 'Argentina' },
      { id: 'AM', name: 'Armenien' },
      { id: 'AW', name: 'Aruba' },
      { id: 'AC', name: 'Ascension' },
      { id: 'AU', name: 'Australien' },
      { id: 'AZ', name: 'Azerbajdzjan' },
      { id: 'BS', name: 'Bahamas' },
      { id: 'BH', name: 'Bahrain' },
      { id: 'BD', name: 'Bangladesh' },
      { id: 'BB', name: 'Barbados' },
      { id: 'BE', name: 'Belgien' },
      { id: 'BZ', name: 'Belize' },
      { id: 'BJ', name: 'Benin' },
      { id: 'BM', name: 'Bermuda' },
      { id: 'BT', name: 'Bhutan' },
      { id: 'BO', name: 'Bolivia' },
      { id: 'BA', name: 'Bosnien och Hercegovina' },
      { id: 'BW', name: 'Botswana' },
      { id: 'BR', name: 'Brasilien' },
      { id: 'VG', name: 'Brittiska Jungfruöarna' },
      { id: 'IO', name: 'Brittiska territoriet i Indiska oceanen' },
      { id: 'BN', name: 'Brunei' },
      { id: 'BG', name: 'Bulgarien' },
      { id: 'BF', name: 'Burkina Faso' },
      { id: 'BI', name: 'Burundi' },
      { id: 'KY', name: 'Caymanöarna' },
      { id: 'CF', name: 'Centralafrikanska republiken' },
      { id: 'EA', name: 'Ceuta och Melilla' },
      { id: 'CL', name: 'Chile' },
      { id: 'CO', name: 'Colombia' },
      { id: 'CK', name: 'Cooköarna' },
      { id: 'CR', name: 'Costa Rica' },
      { id: 'CW', name: 'Curaçao' },
      { id: 'CY', name: 'Cypern' },
      { id: 'DK', name: 'Danmark' },
      { id: 'DG', name: 'Diego Garcia' },
      { id: 'DJ', name: 'Djibouti' },
      { id: 'DM', name: 'Dominica' },
      { id: 'DO', name: 'Dominikanska republiken' },
      { id: 'EC', name: 'Ecuador' },
      { id: 'EG', name: 'Egypten' },
      { id: 'GQ', name: 'Ekvatorialguinea' },
      { id: 'SV', name: 'El Salvador' },
      { id: 'CI', name: 'Elfenbenskusten' },
      { id: 'ER', name: 'Eritrea' },
      { id: 'EE', name: 'Estland' },
      { id: 'ET', name: 'Etiopien' },
      { id: 'FK', name: 'Falklandsöarna' },
      { id: 'FJ', name: 'Fiji' },
      { id: 'PH', name: 'Filippinerna' },
      { id: 'FI', name: 'Finland' },
      { id: 'FR', name: 'Frankrike' },
      { id: 'GF', name: 'Franska Guyana' },
      { id: 'PF', name: 'Franska Polynesien' },
      { id: 'TF', name: 'Franska sydterritorierna' },
      { id: 'FO', name: 'Färöarna' },
      { id: 'AE', name: 'Förenade Arabemiraten' },
      { id: 'GA', name: 'Gabon' },
      { id: 'GM', name: 'Gambia' },
      { id: 'GE', name: 'Georgien' },
      { id: 'GH', name: 'Ghana' },
      { id: 'GI', name: 'Gibraltar' },
      { id: 'GR', name: 'Grekland' },
      { id: 'GD', name: 'Grenada' },
      { id: 'GL', name: 'Grönland' },
      { id: 'GP', name: 'Guadeloupe' },
      { id: 'GU', name: 'Guam' },
      { id: 'GT', name: 'Guatemala' },
      { id: 'GG', name: 'Guernsey' },
      { id: 'GN', name: 'Guinea' },
      { id: 'GW', name: 'Guinea-Bissau' },
      { id: 'GY', name: 'Guyana' },
      { id: 'HT', name: 'Haiti' },
      { id: 'HN', name: 'Honduras' },
      { id: 'HK', name: 'Hongkong (S.A.R. Kina)' },
      { id: 'IN', name: 'Indien' },
      { id: 'ID', name: 'Indonesien' },
      { id: 'IQ', name: 'Irak' },
      { id: 'IR', name: 'Iran' },
      { id: 'IE', name: 'Irland' },
      { id: 'IS', name: 'Island' },
      { id: 'IM', name: 'Isle of Man' },
      { id: 'IL', name: 'Israel' },
      { id: 'IT', name: 'Italien' },
      { id: 'JM', name: 'Jamaica' },
      { id: 'JP', name: 'Japan' },
      { id: 'YE', name: 'Jemen' },
      { id: 'JE', name: 'Jersey' },
      { id: 'JO', name: 'Jordanien' },
      { id: 'CX', name: 'Julön' },
      { id: 'KH', name: 'Kambodja' },
      { id: 'CM', name: 'Kamerun' },
      { id: 'CA', name: 'Kanada' },
      { id: 'IC', name: 'Kanarieöarna' },
      { id: 'CV', name: 'Kap Verde' },
      { id: 'BQ', name: 'Karibiska Nederländerna' },
      { id: 'KZ', name: 'Kazakstan' },
      { id: 'KE', name: 'Kenya' },
      { id: 'CN', name: 'Kina' },
      { id: 'KG', name: 'Kirgizistan' },
      { id: 'KI', name: 'Kiribati' },
      { id: 'CC', name: 'Kokosöarna' },
      { id: 'KM', name: 'Komorerna' },
      { id: 'CG', name: 'Kongo-Brazzaville' },
      { id: 'CD', name: 'Kongo-Kinshasa' },
      { id: 'XK', name: 'Kosovo' },
      { id: 'HR', name: 'Kroatien' },
      { id: 'CU', name: 'Kuba' },
      { id: 'KW', name: 'Kuwait' },
      { id: 'LA', name: 'Laos' },
      { id: 'LS', name: 'Lesotho' },
      { id: 'LV', name: 'Lettland' },
      { id: 'LB', name: 'Libanon' },
      { id: 'LR', name: 'Liberia' },
      { id: 'LY', name: 'Libyen' },
      { id: 'LI', name: 'Liechtenstein' },
      { id: 'LT', name: 'Litauen' },
      { id: 'LU', name: 'Luxemburg' },
      { id: 'MO', name: 'Macao (S.A.R. Kina)' },
      { id: 'MG', name: 'Madagaskar' },
      { id: 'MK', name: 'Makedonien' },
      { id: 'MW', name: 'Malawi' },
      { id: 'MY', name: 'Malaysia' },
      { id: 'MV', name: 'Maldiverna' },
      { id: 'ML', name: 'Mali' },
      { id: 'MT', name: 'Malta' },
      { id: 'MA', name: 'Marocko' },
      { id: 'MH', name: 'Marshallöarna' },
      { id: 'MQ', name: 'Martinique' },
      { id: 'MR', name: 'Mauretanien' },
      { id: 'MU', name: 'Mauritius' },
      { id: 'YT', name: 'Mayotte' },
      { id: 'MX', name: 'Mexiko' },
      { id: 'FM', name: 'Mikronesien' },
      { id: 'MZ', name: 'Moçambique' },
      { id: 'MD', name: 'Moldavien' },
      { id: 'MC', name: 'Monaco' },
      { id: 'MN', name: 'Mongoliet' },
      { id: 'ME', name: 'Montenegro' },
      { id: 'MS', name: 'Montserrat' },
      { id: 'MM', name: 'Myanmar (Burma)' },
      { id: 'NA', name: 'Namibia' },
      { id: 'NR', name: 'Nauru' },
      { id: 'NL', name: 'Nederländerna' },
      { id: 'NP', name: 'Nepal' },
      { id: 'NI', name: 'Nicaragua' },
      { id: 'NE', name: 'Niger' },
      { id: 'NG', name: 'Nigeria' },
      { id: 'NU', name: 'Niue' },
      { id: 'KP', name: 'Nordkorea' },
      { id: 'MP', name: 'Nordmarianerna' },
      { id: 'NF', name: 'Norfolkön' },
      { id: 'NO', name: 'Norge' },
      { id: 'NC', name: 'Nya Kaledonien' },
      { id: 'NZ', name: 'Nya Zeeland' },
      { id: 'OM', name: 'Oman' },
      { id: 'PK', name: 'Pakistan' },
      { id: 'PW', name: 'Palau' },
      { id: 'PS', name: 'Palestinska territorierna' },
      { id: 'PA', name: 'Panama' },
      { id: 'PG', name: 'Papua Nya Guinea' },
      { id: 'PY', name: 'Paraguay' },
      { id: 'PE', name: 'Peru' },
      { id: 'PN', name: 'Pitcairnöarna' },
      { id: 'PL', name: 'Polen' },
      { id: 'PT', name: 'Portugal' },
      { id: 'PR', name: 'Puerto Rico' },
      { id: 'QA', name: 'Qatar' },
      { id: 'RE', name: 'Réunion' },
      { id: 'RO', name: 'Rumänien' },
      { id: 'RW', name: 'Rwanda' },
      { id: 'RU', name: 'Ryssland' },
      { id: 'BL', name: 'S:t Barthélemy' },
      { id: 'SH', name: 'S:t Helena' },
      { id: 'KN', name: 'S:t Kitts och Nevis' },
      { id: 'LC', name: 'S:t Lucia' },
      { id: 'MF', name: 'S:t Martin' },
      { id: 'PM', name: 'S:t Pierre och Miquelon' },
      { id: 'VC', name: 'S:t Vincent och Grenadinerna' },
      { id: 'SB', name: 'Salomonöarna' },
      { id: 'WS', name: 'Samoa' },
      { id: 'SM', name: 'San Marino' },
      { id: 'ST', name: 'São Tomé och Príncipe' },
      { id: 'SA', name: 'Saudiarabien' },
      { id: 'CH', name: 'Schweiz' },
      { id: 'SN', name: 'Senegal' },
      { id: 'RS', name: 'Serbien' },
      { id: 'SC', name: 'Seychellerna' },
      { id: 'SL', name: 'Sierra Leone' },
      { id: 'SG', name: 'Singapore' },
      { id: 'SX', name: 'Sint Maarten' },
      { id: 'SK', name: 'Slovakien' },
      { id: 'SI', name: 'Slovenien' },
      { id: 'SO', name: 'Somalia' },
      { id: 'ES', name: 'Spanien' },
      { id: 'LK', name: 'Sri Lanka' },
      { id: 'GB', name: 'Storbritannien' },
      { id: 'SD', name: 'Sudan' },
      { id: 'SR', name: 'Surinam' },
      { id: 'SJ', name: 'Svalbard och Jan Mayen' },
      { id: 'SE', name: 'Sverige' },
      { id: 'SZ', name: 'Swaziland' },
      { id: 'ZA', name: 'Sydafrika' },
      { id: 'GS', name: 'Sydgeorgien och Sydsandwichöarna' },
      { id: 'KR', name: 'Sydkorea' },
      { id: 'SS', name: 'Sydsudan' },
      { id: 'SY', name: 'Syrien' },
      { id: 'TJ', name: 'Tadzjikistan' },
      { id: 'TW', name: 'Taiwan' },
      { id: 'TZ', name: 'Tanzania' },
      { id: 'TD', name: 'Tchad' },
      { id: 'TH', name: 'Thailand' },
      { id: 'CZ', name: 'Tjeckien' },
      { id: 'TG', name: 'Togo' },
      { id: 'TK', name: 'Tokelau' },
      { id: 'TO', name: 'Tonga' },
      { id: 'TT', name: 'Trinidad och Tobago' },
      { id: 'TA', name: 'Tristan da Cunha' },
      { id: 'TN', name: 'Tunisien' },
      { id: 'TR', name: 'Turkiet' },
      { id: 'TM', name: 'Turkmenistan' },
      { id: 'TC', name: 'Turks- och Caicosöarna' },
      { id: 'TV', name: 'Tuvalu' },
      { id: 'DE', name: 'Tyskland' },
      { id: 'UG', name: 'Uganda' },
      { id: 'UA', name: 'Ukraina' },
      { id: 'HU', name: 'Ungern' },
      { id: 'UY', name: 'Uruguay' },
      { id: 'US', name: 'USA' },
      { id: 'UM', name: 'USA:s yttre öar' },
      { id: 'UZ', name: 'Uzbekistan' },
      { id: 'VU', name: 'Vanuatu' },
      { id: 'VA', name: 'Vatikanstaten' },
      { id: 'VE', name: 'Venezuela' },
      { id: 'VN', name: 'Vietnam' },
      { id: 'BY', name: 'Vitryssland' },
      { id: 'EH', name: 'Västsahara' },
      { id: 'WF', name: 'Wallis- och Futunaöarna' },
      { id: 'ZM', name: 'Zambia' },
      { id: 'ZW', name: 'Zimbabwe' },
      { id: 'AX', name: 'Åland' },
      { id: 'AT', name: 'Österrike' },
      { id: 'TL', name: 'Östtimor' }
    ]
  },
  actions: {
    [FETCH_BUYANDSEND_URL]: ({ commit }) =>
      new epiService().getBuyAndSendSpaUrlAsync().then((uri) => commit(SET_BUYANDSEND_URL, uri)),
    [LOAD_DEPENDENCIES]: ({ commit, dispatch }, app) => {
      let promises = [];

      if (app === APP_BUY_N_SEND) {
        promises.push(googleApiScript());
      }

      promises.push(recaptchaScript());
      let ticketOffersPromise = dispatch(GET_TICKET_OFFERS);
      promises.push(ticketOffersPromise);
      promises.push(dispatch(GET_TICKET_OFFERS_OFFERS_NAME));

      promises.push(
        new Promise((resolve) => {
          let url = process.env.VUE_APP_API_URL + 'language';
          return axios
            .get(url)
            .then((result) => {
              let texts = result.data;
              for (var textKey in texts) {
                let key = texts[textKey].name;
                for (var language in languages) {
                  if (texts[textKey].hasOwnProperty(language) && texts[textKey][language]) {
                    languages[language][key] = texts[textKey][language];
                  }
                }
              }
              resolve(result);
              commit(LANGAUGES_LOADED, true);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              if (process.env.VUE_APP_SYSTEM.valueOf() == 'st') {
                commit(SET_INIT_TRAVELLERTYPES_ST, ticketOffersPromise);
              } else if (process.env.VUE_APP_SYSTEM.valueOf() == 'bt') {
                commit(SET_INIT_TRAVELLERTYPES_BT, ticketOffersPromise);
              } else if (process.env.VUE_APP_SYSTEM.valueOf() == 'ot') {
                commit(SET_INIT_TRAVELLERTYPES_OT, ticketOffersPromise);
              }
            });
        })
      );

      Promise.all(promises).finally(() => {
        commit(SET_DEPENDENCIES_LOADED, true);
      });
    }
  },
  mutations: {
    setWcagErrors(state, payload) {
      state.wcagErrorHandling[payload.attr] = payload.value;
    },
    [SET_BUYANDSEND_URL]: (state, paylaod) => {
      state.buyAandSendUrl = paylaod;
    },
    [LANGAUGES_LOADED]: (state, payload) => (state.languagesLoaded = payload),
    [SET_DEPENDENCIES_LOADED]: (state) => {
      state.isLoaded = true;
    },
    [SET_INIT_TRAVELLERTYPES_ST]: async (state, payload) => {
      let payloadResolved = await payload;
      const travellerTypes = [
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Adult'),
          '',
          1,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Adult'), payload),
          0,
          0,
          'adult',
          '',
          '',
          i18n.t('Traveller_Type_Adult_Alt_Text')
        ),
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Child'),
          '',
          3,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Child'), payload),
          0,
          0,
          'child',
          '',
          '',
          i18n.t('Traveller_Type_Child_Alt_Text')
        ),
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Student'),
          '',
          2,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Student'), payload),
          0,
          0,
          'student',
          '',
          '',
          i18n.t('Traveller_Type_Student_Alt_Text')
        )
      ];
      if (process.env.VUE_APP_SENIOR_OFFER_ST.valueOf() == 'true') {
        travellerTypes.push(
          new TravellerType(
            '',
            i18n.t('Traveller_Type_Senior'),
            '',
            4,
            0,
            await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Senior'), payload),
            0,
            0,
            'senior',
            '70',
            '',
            i18n.t('Traveller_Type_Senior_Alt_Text')
          )
        );
      }
      state.travellerTypes = travellerTypes;
    },
    [SET_INIT_TRAVELLERTYPES_BT]: async (state, payload) => {
      state.travellerTypes = [
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Adult'),
          '',
          1,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Adult'), payload),
          0,
          0,
          'adult',
          '',
          '',
          i18n.t('Traveller_Type_Adult_Alt_Text')
        ),
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Child'),
          '',
          3,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Child'), payload),
          0,
          0,
          'child',
          '',
          '',
          i18n.t('Traveller_Type_Child_Alt_Text')
        ),
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Senior'),
          '',
          4,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Senior'), payload),
          0,
          0,
          'senior',
          '',
          '',
          i18n.t('Traveller_Type_Senior_Alt_Text')
        ),
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Student'),
          '',
          2,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Student'), payload),
          0,
          0,
          'student',
          '',
          '',
          i18n.t('Traveller_Type_Student_Alt_Text')
        )
      ];
    },
    [SET_INIT_TRAVELLERTYPES_OT]: async (state, payload) => {
      state.travellerTypes = [
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Adult'),
          '',
          1,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Adult'), payload),
          0,
          0,
          'adult',
          '',
          '',
          i18n.t('Traveller_Type_Adult_Alt_Text')
        ),
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Youth'),
          '',
          3,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Student'), payload),
          0,
          0,
          'child',
          '',
          '',
          i18n.t('Traveller_Type_Child_Alt_Text')
        ),
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Senior'),
          '',
          4,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Senior'), payload),
          0,
          0,
          'senior',
          '',
          '',
          i18n.t('Traveller_Type_Senior_Alt_Text')
        ),
        new TravellerType(
          '',
          i18n.t('Traveller_Type_Student'),
          '',
          2,
          0,
          await getTravellerTypeSortOrderFromOffersAync(i18n.t('Traveller_Type_Student'), payload),
          0,
          0,
          'student',
          '',
          '',
          i18n.t('Traveller_Type_Student_Alt_Text')
        )
      ];
    }
  },
  getters: {
    wcagErrorHandling: (state) => state.wcagErrorHandling,
    [GET_BUYANDSEND_URL]: (state) => state.buyAandSendUrl,
    [LANGAUGES_LOADED]: (state) => state.languagesLoaded,
    [IS_LOADED]: (state) => {
      return state.isLoaded;
    },
    [GET_AVAILABLE_ADDONS]: () => {
      let addons = [];

      let offerId = ticketModule.state.offerId;
      let priceModelId = ticketModule.state.priceModelId;
      let priceId = ticketModule.state.priceId;
      let journey = journeyModule.state.journey.possibleJourneys
        ? journeyModule.state.journey.possibleJourneys[0]
        : undefined;

      if (!offerId) return addons;
      if (!priceModelId) return addons;
      if (!priceId) return addons;

      let ticketOffers = ticketOffersModule.state.ticketOffers;

      let offer;
      for (let i = 0; i < ticketOffers.offers.length; i++) {
        if (ticketOffers.offers[i].id === offerId) {
          offer = ticketOffers.offers[i];
          break;
        }
      }

      if (!offer || !offer.priceModels[priceModelId] || !offer.priceModels[priceModelId].prices)
        return addons;
      let price;
      for (let i = 0; i < offer.priceModels[priceModelId].prices.length; i++) {
        price = offer.priceModels[priceModelId].prices[i];
        if (price.id === priceId) {
          break;
        }
      }

      if (!price) return addons;

      for (let i = 0; i < price.availableAddons.length; i++) {
        const availableAddon = price.availableAddons[i];
        if (availableAddon.availability.indexOf('NewPurchase') === -1) continue;

        for (let j = 0; j < ticketOffers.addons.length; j++) {
          const addon = ticketOffers.addons[j];

          // om det är ett cityAddon och det inte finns några cities kan vi inte läggga till den
          if (
            addon.addonType === 2 &&
            (!journey ||
              !journey.priceDetails.cityAddons ||
              !journey.priceDetails.cityAddons.optionalCities.length)
          )
            continue;

          if (addon.id === availableAddon.addonId) {
            for (let k = 0; k < addon.priceModels.length; k++) {
              const addonPriceModel = addon.priceModels[k];
              if (addonPriceModel.id.toLowerCase() == priceModelId.toLowerCase()) {
                addons.push(
                  new Addon(
                    addon.id,
                    addon.name.sv,
                    addon.addonType,
                    addon.purchasePerTraveller,
                    addon.sortOrder,
                    addonPriceModel.discountable
                  )
                );
              }
            }
          }
        }
      }

      return addons.sort((a1, a2) => a1.sortOrder - a2.sortOrder);
    },
    [GET_TRAVELLER_TYPES]: (state) => {
      let priceModelId = ticketModule.state.priceModelId;

      if (priceModelId == '') return state.travellerTypes;

      let offerId = ticketModule.state.offerId;
      let priceId = ticketModule.state.priceId;

      let ticketOffers = ticketOffersModule.state.ticketOffers;

      let offer;
      for (let i = 0; i < ticketOffers.offers.length; i++) {
        if (ticketOffers.offers[i].id === offerId) {
          offer = ticketOffers.offers[i];
          break;
        }
      }
      if (!offer || !offer.priceModels[priceModelId]) return state.travellerTypes;

      let usedTravellerTypes = [];
      let travellerTypes = [];

      for (let i = 0; i < offer.priceModels[priceModelId].availableTravellerTypes.length; i++) {
        const availableTravellerType = offer.priceModels[priceModelId].availableTravellerTypes[i];

        let travellerType = ticketOffers.travellerTypes.filter(
          (x) => x.id === availableTravellerType.travellerTypeId
        )[0];
        let travellerTypeByPriceId = ticketOffers.travellerTypes.filter(
          (x) =>
            x.id === availableTravellerType.travellerTypeId &&
            availableTravellerType.priceId === priceId
        )[0];

        if (travellerTypeByPriceId) {
          travellerType = travellerTypeByPriceId;
          if (usedTravellerTypes.indexOf(travellerType.travellerType) !== -1) {
            const indexOfTravellerType = usedTravellerTypes.indexOf(travellerType.travellerType);
            usedTravellerTypes.splice(indexOfTravellerType, 1);

            const indexOfTraveller = travellerTypes.indexOf(
              travellerTypes.filter(
                (x) => x.travellerType === travellerTypeByPriceId.travellerType
              )[0]
            );
            if (indexOfTraveller !== -1) travellerTypes.splice(indexOfTraveller, 1);
          }
        }

        if (usedTravellerTypes.indexOf(travellerType.travellerType) !== -1) continue;

        if (!travellerType) {
          throw Error('TravellerType undefined');
        }

        let icon = '';
        let iconAltText = '';
        let ageFrom = '';
        let ageTo = '';

        if (travellerType.travellerType === 1) {
          icon = 'adult';
          iconAltText = i18n.t('Traveller_Type_Adult_Alt_Text');
        } else if (travellerType.travellerType === 2) {
          icon = 'student';
          iconAltText = i18n.t('Traveller_Type_Student_Alt_Text');
        } else if (travellerType.travellerType === 3) {
          icon = 'child';
          iconAltText = i18n.t('Traveller_Type_Child_Alt_Text');
          ageFrom = '7';
          ageTo = priceModelId === 'oresund' ? '15' : '19';
        } else if (travellerType.travellerType === 4) {
          icon = 'senior';
          iconAltText = i18n.t('Traveller_Type_Senior_Alt_Text');
          if (process.env.VUE_APP_SYSTEM.valueOf() === 'st') ageFrom = '70';
        }

        travellerTypes.push(
          new TravellerType(
            travellerType.id,
            travellerType.name.sv,
            travellerType.description.sv,
            travellerType.travellerType,
            travellerType.discountPercentage,
            travellerType.sortOrder,
            availableTravellerType.max,
            availableTravellerType.min,
            icon,
            ageFrom,
            ageTo,
            iconAltText
          )
        );

        usedTravellerTypes.push(travellerType.travellerType);
      }

      return travellerTypes.sort((tt1, tt2) => tt1.sortOrder - tt2.sortOrder);
    },
    [GET_COUNTRIES]: (state) => {
      return state.countries;
    },
    [GET_STOP_AREAS_FROM_SOK_RESA]: (state) => {
      return state.stopAreasFromSokResa;
    }
  },
  modules: {
    journeyModule,
    mapDetailsModule,
    ticketModule,
    ticketOffersModule,
    travelAreaModule,
    cardModule,
    cardOrderModule,
    accountModule,
    tapnrideModule,
    voucher: voucherModule,
    campaignOffersModule,
    passageTypeModule
  }
});

//Helpers

const googleApiScript = () => {
  return new Promise((resolve) => {
    var script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.addEventListener('load', resolve('yes'));
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GMAPS_SITE_KEY}`;
    document.body.appendChild(script);
  });
};

const recaptchaScript = () => {
  return new Promise((resolve) => {
    var script = document.createElement('script');
    script.async = true;
    script.addEventListener('load', resolve('yes'));
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  });
};

const getTravellerTypeSortOrderFromOffersAync = async (name, offersPromise) => {
  if (!offersPromise || !name) return 1;
  let resolvedPromise = await offersPromise;
  if (!resolvedPromise.data || !resolvedPromise.data.travellerTypes) return 1;

  resolvedPromise.data.travellerTypes.find(
    (element) => element.name.sv == i18n.t('Traveller_Type_Student')
  )
    ? resolvedPromise.data.travellerTypes.find(
        (element) => element.name.sv == i18n.t('Traveller_Type_Student')
      ).sortOrder
    : 1;
};
