<template>
  <ul>
    <template v-for="(item, index) in travellerTypes">
      <li v-if="travellersByTravellerType(item.travellerType)" :key="index">{{ `${travellersByTravellerType(item.travellerType).count} ${item.name.toLowerCase()}` }}</li>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_TRAVELLER_TYPES } from '../store/actions/base-actions';
import { GET_TRAVELLERS_BY_TRAVELLER_TYPE } from '../store/actions/ticket-actions';
export default {
  props: {
    travellers: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      travellerTypes: GET_TRAVELLER_TYPES
    })
  },
  methods: {
    travellersByTravellerType(travellerType) {
      let tempTravellers = [];
      for (let i = 0; i < this.travellers.length; i++) {
        const traveller = this.travellers[i];
        if (traveller.travellerType == travellerType) {
          return traveller;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  margin-bottom: 0em;
  padding-left: 0em;
}
li {
  display: inline-block;
  min-width: 2.8125em;
  padding-right: 0.125em;
  color: #4d4845;

  font-size: 1em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4845;

  .st-icon {
    margin-top: -0.125em;
    text-align: left;
  }

  span {
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4d4845;
    text-align: right;
  }
}
</style>
