<template>
  <div class="start">
    <h1>{{ $t('Start_Header') }}</h1>
    <div class="boxes" :class="system">
      <div class="boxes__first">
        <div class="box">
          <router-link :to="firstLink.url" @focus.native="onFocus" @blur.native="onBlur" :tabindex="tabindex">
            <div class="box__wrapper" :class="system">
              <st-icon :name="firstLink.icon" :alt="firstLink.alt" useSvg role="presentation" />
              <div class="box__text" :class="system">
                <h2>{{ firstLink.header }}</h2>
                <p>{{ firstLink.description }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="boxes__container">
        <div class="box" v-for="(link, index) in links" :key="index">
          <router-link :to="link.url" @focus.native="onFocus" @blur.native="onBlur" :tabindex="tabindex">
            <div class="box__wrapper" :class="system">
              <st-icon :name="link.icon" :alt="link.alt" useSvg role="presentation" />
              <div class="box__text" :class="system">
                <h2>{{ link.header }}</h2>
                <p>{{ link.description }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as constant from '../../constants.js';
import globalMixins from '../../mixins/globalMixin';
export default {
  mixins: [globalMixins],
  data() {
    return {
      system: process.env.VUE_APP_SYSTEM,
      firstLink: {
        url: '/kop-biljett',
        header: this.$t('Start_BuyTicket_Header'),
        description: this.$t('Start_BuyTicket_Description'),
        alt: this.$t('Start_BuyTicket_Alt_Text'),
        icon: 'add-ticket',
        eventCategory: constant.EVENT_CATEGORY_BUY_TICKET,
        eventAction: constant.EVENT_ACTION_BUY_TICKET
      },
      links: [
        {
          url: '/kop-kort',
          header: this.$t('Start_BuyCard_Header'),
          description: this.$t('Start_BuyCard_Description'),
          alt: this.$t('Start_BuyCard_Alt_Text'),
          icon: 'add-card',
          eventCategory: constant.EVENT_CATEGORY_BUY_CARD,
          eventAction: constant.EVENT_ACTION_BUY_CARD
        },
        {
          url: '/hamta-kort',
          header: this.$t('Start_Card_Header'),
          description: this.$t('Start_Card_Description'),
          alt: this.$t('Start_Card_Alt_Text'),
          icon: 'check-ticket',
          eventCategory: constant.EVENT_CATEGORY_ACTIVATE_TICKET,
          eventAction: constant.EVENT_ACTION_ACTIVATE_TICKET
        }
      ],
      tabindex: '0'
    };
  },
  mounted() {
    if (process.env.VUE_APP_SYSTEM.valueOf() !== 'bt') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  methods: {
    onFocus(e) {
      e.target.parentElement.classList.add('box-focus');
    },
    onBlur(e) {
      e.target.parentElement.classList.remove('box-focus');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/colors.scss';
@import '../../assets/scss/focus.scss';

.start {
  h1 {
    margin: 0.55555556em 0em 0.5em !important;
    letter-spacing: -0.0119em !important;
    line-height: normal !important;
    font-size: 2.25em !important;
  }

  .boxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    &.st {
      max-width: 550px;
    }

    &.bt {
      max-width: 700px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 24px;
      margin-top: 24px;

      @media (min-width: 430px) {
        flex-direction: row;
      }
    }

    .box {
      flex: 1 1 0px;
      border-radius: 0.75em;
      box-shadow: 0em 0.375em 0.625em 0em rgba(0, 0, 0, 0.08);
      border: solid 0.125em $card-gul;
      padding: 16px;

      &__wrapper.st,
      &__wrapper.ot {
        @media (min-width: 430px) {
          display: flex;
        }
      }

      &__text.ot {
        margin-left: 16px;
      }

      &.box-focus {
        @include input-focus-border;
        border-width: 0.25em;
        border-color: #a9791c;

        a {
          &:focus {
            outline: 0;
          }
        }
      }

      .st-icon {
        height: 50px;
        width: 50px;
        margin-right: 16px;
        margin-bottom: 16px;
      }

      h2 {
        font-size: 1.25em !important;
        margin-bottom: 16px !important;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 0.9375em;
        line-height: 1.25em;
        letter-spacing: 0.0167em;
      }
    }
  }
}
</style>
