<template>
  <img :class="classList" @click="onClick" :src="imgUrl" :alt="alt" @mouseover="onHover" @mouseout="onHover" />
</template>

<script>
export default {
  name: 'st-icon',

  data() {
    return {
      isHovered: false
    };
  },

  props: {
    name: {
      type: String,
      required: true
    },

    isHoverable: {
      type: Boolean,
      default: false
    },

    inline: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'md',
      validator: function (size) {
        return ['xs', 'sm', 'md', 'lg', 'custom'].includes(size);
      }
    },
    useSvg: {
      type: Boolean,
      default: false
    },
    click: {
      type: Function
    },
    item: {
      type: Object
    },
    alt: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classList: function () {
      return ['st-icon', this.name, this.size, process.env.VUE_APP_SYSTEM, this.inline && 'inline', this.disabled && 'disabled'];
    },
    imgUrl: function () {
      if (this.isHovered) {
        return this.useSvg ? `${process.env.VUE_APP_BASE_URL}assets/icons/${process.env.VUE_APP_SYSTEM}/` + this.name + '_on.svg' : `${process.env.VUE_APP_BASE_URL}assets/icons/${process.env.VUE_APP_SYSTEM}/` + this.name + '_on.png';
      } else {
        return this.useSvg ? `${process.env.VUE_APP_BASE_URL}assets/icons/${process.env.VUE_APP_SYSTEM}/` + this.name + '.svg' : `${process.env.VUE_APP_BASE_URL}assets/icons/${process.env.VUE_APP_SYSTEM}/` + this.name + '.png';
      }
    }
  },

  methods: {
    onClick: function () {
      if (this.click && !this.disabled) this.click(this.item);
    },
    onHover: function () {
      if (this.isHoverable) this.isHovered = !this.isHovered;
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  background-repeat: no-repeat;
  background-position: center;

  &.st-icon {
    display: block;
    position: relative;

    &.xs {
      background-size: 0.75em 0.75em;
      width: 0.75em;
      height: 0.75em;
    }
    &.sm {
      background-size: 1.25em 1.25em;
      width: 1.25em;
      height: 1.25em;
    }

    &.md {
      background-size: 1.5em 1.5em;
      width: 1.5em;
      height: 1.5em;
    }

    &.lg {
      background-size: 2.25em 2.25em;
      width: 2.25em;
      height: 2.25em;
    }

    &.inline {
      display: inline-block;
    }

    &.ot {
      &.child {
        transform: scale(0.8);
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}
</style>
