<template>
  <div class="personal-data-display">
    <!-- <section class="birthdate">
      <h4>Födelsedata</h4>
      <p>{{ account.socialSecurityNumber }}</p>
    </section> -->
    <div class="address">
      <h2>Adress</h2>
      <p>{{ account.firstName }} {{ account.lastName }}</p>
      <p>{{ account.street }}</p>
      <p>{{ account.postalCode }} {{ account.city }}</p>
      <p>{{ account.country }}</p>
    </div>
    <div class="contact">
      <h2>Kontaktuppgifter</h2>
      <p>{{ account.mobile }}</p>
    </div>
    <st-button ref="editPersonalData" medium primary tabindex="0" @click="toEditPersonalDataRoute">Ändra uppgifter</st-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_ACCOUNT } from '../../../store/actions/account-actions';
export default {
  mounted() {
    this.$refs.editPersonalData.$el.focus();
  },
  methods: {
    toEditPersonalDataRoute() {
      this.$router.push('mina-personuppgifter/redigera');
    }
  },
  computed: {
    ...mapGetters({
      account: GET_ACCOUNT
    })
  }
};
</script>

<style lang="scss" scoped>
.personal-data-display {
  .address,
  .contact {
    margin-top: 1em;

    h2 {
      font-size: 1.5em !important;
      margin-bottom: 0.5em !important;
    }
    p {
      margin-bottom: 0.25em !important;
    }
  }
}
</style>
