<template>
  <div class="app-loading" v-if="!isLoaded">
    <st-spinner />
  </div>
  <div class="buy-ticket" v-else>
    <router-link to="/" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>
    <keep-alive>
      <campaign-tickets></campaign-tickets>
    </keep-alive>
    <h1 v-if="!isCampaignJourney">{{ $t('BuyTicket_Header') }}</h1>
    <h1 v-else tabindex="-1" class="campaign-ticket-header">{{ `${campaignOfferName}en` }}</h1>
    <keep-alive>
      <buy-ticket-form :is-repurchase-ticket-order="isRepurchaseTicketOrder"></buy-ticket-form>
    </keep-alive>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import BuyTicketForm from '../../components/buy-ticket-form/BuyTicketForm';
import CampaignTickets from '../../components/campaign-tickets/CampaignTickets';
import { CLEAR_TICKET_STATE, REPURCHASE_TICKET, SET_STOP_AREAS } from '../../store/actions/ticket-actions';
import { GET_STOP_AREAS_FROM_SOK_RESA } from '../../store';
import { CLEAR_JOURNEY_STATE } from '../../store/actions/journey-actions';
import { GET_TICKET_OFFER_LOADED, SET_SELECTED_OFFER_ID } from '../../store/actions/ticket-offers-actions';
import { IS_CAMPAIGN_JOURNEY_WITH_BANNER, GET_CAMPAIGN_TICKETOFFER_NAME } from '../../store/actions/campaign-offers-actions';
import globalMixin from '../../mixins/globalMixin';
export default {
  mixins: [globalMixin],
  components: {
    BuyTicketForm,
    CampaignTickets
  },
  props: {
    ticketId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isRepurchaseTicketOrder: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'buy-ticket-confirm') {
      this.clearJourneyState();
      this.clearTicketState();
    }
    next();
  },
  mounted() {
    this.scrollToTopWithOffset();

    if (this.isLoaded && this.ticketId) this.repurchase(this.ticketId);
    this.displayPrice();
  },
  computed: {
    ...mapGetters({
      isCampaignJourney: IS_CAMPAIGN_JOURNEY_WITH_BANNER,
      campaignOfferName: GET_CAMPAIGN_TICKETOFFER_NAME,
      isLoaded: GET_TICKET_OFFER_LOADED,
      stopAreasFromSokResa: GET_STOP_AREAS_FROM_SOK_RESA
    })
  },
  methods: {
    ...mapActions({
      clearTicketState: CLEAR_TICKET_STATE,
      repurchase: REPURCHASE_TICKET,
      setStopAreas: SET_STOP_AREAS
    }),
    ...mapMutations({
      clearJourneyState: CLEAR_JOURNEY_STATE,
      setSelectedOfferId: SET_SELECTED_OFFER_ID
    }),
    displayPrice() {
      if (this.isLoaded && this.stopAreasFromSokResa.length > 0) {
        this.setStopAreas(this.stopAreasFromSokResa);
      }
    }
  },
  watch: {
    isLoaded(bool) {
      if (bool && this.ticketId) {
        this.isRepurchaseTicketOrder = true;
        this.repurchase(this.ticketId);
      }
      this.displayPrice();
    }
  }
};
</script>

<style lang="scss" scoped>
.buy-ticket {
  h1 {
    font-size: 2.25em !important;
    &.campaign-ticket-header {
      @media (max-width: 576px) {
        font-size: 2em !important;
      }
    }
    margin: 0.55555556em 0em 0.5em !important;
  }
}
</style>
