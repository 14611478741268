class TravellerType {
  constructor(
    id,
    name,
    description,
    travellerType,
    discountPercentage,
    sortOrder,
    max,
    min,
    icon,
    ageFrom,
    ageTo,
    iconAltText
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.travellerType = travellerType;
    this.discountPercentage = discountPercentage;
    this.sortOrder = sortOrder;
    this.max = max;
    this.min = min;
    if (icon) {
      this.icon = icon;
    }
    if (ageFrom && ageTo) {
      this.age = `${ageFrom}-${ageTo} år`;
    } else if (ageFrom) {
      this.age = `från ${ageFrom} år`;
    } else {
      this.age = '';
    }
    if (icon && iconAltText) {
      this.iconAltText = iconAltText;
    }
  }
}

export default TravellerType;
