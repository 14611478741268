<template>
  <div :class="classList">
    <slot name="label">
      <label v-if="label" :for="id" tabindex="-1">{{ label }}</label>
    </slot>
    <slot name="default" :aria-described-by="getAriaDescribedByString">default slot</slot>
    <span :class="{ 'st-form-group__label__span--is-flex': flex }" v-for="error in validationErrors" :key="error.key" :role="alertErrorMessages ? 'alert' : null">
      <span :id="error.id" class="form-group-error" v-if="validation.$error && !validation[error.key]">
        <st-icon inline name="varning" v-show="validation.$error && !validation[error.key]" class="warning" alt=""></st-icon>
        {{ error.message }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'st-form-group',

  props: {
    id: {
      type: String
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object
    },
    validationErrors: {
      type: Array,
      default: () => {
        return [];
      }
    },
    flex: {
      type: Boolean,
      default: false
    },
    alertErrorMessages: {
      type: Boolean,
      default: true
    },
    ariaDescribedBy: {
      type: String,
      default: ''
    }
  },
  computed: {
    classList: function () {
      return ['st-form-group', this.required && 'required', this.hasError && 'invalid'];
    },
    hasError() {
      if (!this.validation) return false;

      return this.validation.$error;
    },
    getAriaDescribedByString() {
      let ariaString = this.ariaDescribedBy;
      if (Array.isArray(this.validationErrors)) {
        const validationAriaString = this.validationErrors.map((obj) => obj.id).join(' ');
        ariaString += ' ' + validationAriaString;
      }
      return ariaString.trim();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/colors.scss';
.st-form-group {
  margin-top: 1em;
  &__label {
    &__span {
      &--is-flex {
        display: flex;
      }
    }
  }

  &.required {
    label:after {
      content: ' - obligatoriskt';
      font-style: italic;
      font-weight: 400;
    }
  }

  label {
    height: 1.2375em;
    font-size: 0.875em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    display: inline-block;
    margin-bottom: 0.3125em;
    text-align: left;
  }

  .form-group-error {
    display: none;
    font-size: 0.875em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0.25em;
  }

  &.invalid .form-group-error {
    display: block;
    color: $invalid;
  }

  .warning {
    background-size: 0.875em 0.875em;
    display: inline-block;
    margin-right: 0.375em;
    &.st-icon.md {
      width: 0.875em;
      height: 0.875em;
    }
  }
}
</style>
