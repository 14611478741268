<template>
  <div id="zonepicker">
    <stop-area-picker v-if="!isCampaignJourney" aria-described-by="zonepicker-notification" />

    <!-- Note: Temporary notification that informs about removal of ferry tickets. Will be removed. -->
    <div id="zonepicker-notification" aria-live="polite">
      <div
        class="notification-box"
        v-if="isDenmarkJourney(priceId) && $t('ZonePicker_Ferry_Passage_Info')"
      >
        <div class="notification-icon">
          <st-icon name="info_on" useSvg inline></st-icon>
        </div>
        <p class="notification-message" v-html="$t('ZonePicker_Ferry_Passage_Info')"></p>
      </div>
    </div>

    <passage-type-picker v-if="passageTypes && passageTypes.length > 0" />

    <st-error v-if="journeyError.hasError" :error="getError" />

    <google-map aria-hidden="true"></google-map>
  </div>
</template>

<script>
import PassageTypePicker from './PassageTypePicker';
import StopAreaPicker from './StopAreaPicker';
import GoogleMap from './Map';

import {
  GET_JOURNEYS_ERROR,
  GET_JOURNEY_POSSIBLE_JOURNEYS
} from '../../../store/actions/journey-actions';
import { IS_CAMPAIGN_JOURNEY_WITH_BANNER } from '../../../store/actions/campaign-offers-actions';
import { GET_PASSAGE_TYPES } from '../../../store/actions/passage-type-actions';

import { mapGetters, mapState } from 'vuex';
import globalMixins from '../../../mixins/globalMixin';

export default {
  mixins: [globalMixins],
  props: {
    tabindex: {
      type: String,
      default: '0'
    }
  },
  components: {
    StopAreaPicker,
    PassageTypePicker,
    GoogleMap
  },
  computed: {
    ...mapGetters({
      journeyError: GET_JOURNEYS_ERROR,
      passageTypes: GET_PASSAGE_TYPES,
      possibleJourneys: GET_JOURNEY_POSSIBLE_JOURNEYS,
      isCampaignJourney: IS_CAMPAIGN_JOURNEY_WITH_BANNER
    }),
    ...mapState({
      priceId: (state) => state.ticketModule.priceId
    }),
    getError() {
      return {
        message: this.$t('ZonePicker_Journey_Error')
      };
    }
  }
};
</script>

<style lang="scss" scoped>
#zonepicker {
  .st-error {
    margin-top: 0em !important;
    margin-bottom: 0.625em !important;
  }

  .notification-box {
    background-color: #f7f6f2;
    border-radius: 0.25em;
    display: flex;
    padding: 1em 0;

    .notification-icon {
      margin: 0 1em;
    }

    .notification-message {
      margin-bottom: 0px;

      &::v-deep a {
        text-decoration: underline;
        &:hover {
          color: #cc4039 !important;
        }
      }
    }
  }
}
</style>
