<template>
  <div :class="classList">
    <fieldset>
      <slot name="legend">
        <legend v-if="legend" class="st-fieldset__legend">
          {{ legend }}
        </legend>
      </slot>
      <slot name="default" :ariaDescribedBy="getAriaDescribedByString">default slot</slot>
      <span
        :class="{ 'st-fieldset__label__span--is-flex': flex }"
        v-for="error in validationErrors"
        :key="error.key"
        :role="alertErrorMessages ? 'alert' : null"
      >
        <span
          :id="error.id"
          class="fieldset-error"
          v-if="validation.$error && !validation[error.key]"
        >
          <st-icon inline name="varning" v-show="validation.$error && !validation[error.key]" class="warning" alt=""></st-icon>
          {{ error.message }}
        </span>
      </span>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: 'st-fieldset',

  props: {
    id: {
      type: String
    },
    legend: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object
    },
    validationErrors: {
      type: Array,
      default: () => {
        return [];
      }
    },
    flex: {
      type: Boolean,
      default: false
    },
    alertErrorMessages: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classList: function () {
      return ['st-fieldset', this.required && 'required', this.hasError && 'invalid'];
    },
    hasError() {
      if (!this.validation) return false;

      return this.validation.$error;
    },
    getAriaDescribedByString() {
      let ariaString = '';
      if (Array.isArray(this.validationErrors)) {
        ariaString = this.validationErrors.map(obj => obj.id).join(" ");
      }
      return ariaString;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/colors.scss';
.st-fieldset {
  margin-top: 16px;

  &__label {
    &__span {
      &--is-flex {
        display: flex;
      }
    }
  }

  &.required {
    legend:after {
      content: ' - obligatoriskt';
      font-style: italic;
      font-weight: 400;
    }
  }

  &__legend, label {
    font-size: 0.875em !important;
    height: 17.3203px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    display: inline-block;
    margin-bottom: 8px;
    text-align: left;
  }

  .fieldset-error {
    display: none;
    font-size: 0.875em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    margin-top: 4px;
  }

  &.invalid .fieldset-error {
    display: block;
    color: $invalid;
  }

  .warning {
    background-size: 14px 14px;
    display: inline-block;
    margin-right: 6px;
    &.st-icon.md {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
