<template>
  <st-modal class="remove-card-modal" :ariaLabelledby="'remove-card-header'" :onClose="submit" :isLoading="isLoading">
    <div slot="header" class="st-modal-header-default">
      <h1 id="remove-card-header">{{ $t('RemoveCardModal_Header') }}</h1>
    </div>
    <div slot="body" class="st-modal-body-default">
      <p>{{ $t('RemoveCardModal_Description') }}</p>
    </div>
    <div slot="footer" class="st-modal-footer-default">
      <div class="st-modal-footer-content d-none d-sm-flex">
        <st-button @click="submit(0)" @keydown.tab.prevent.native="onCancelKeyDown" id="cancel" tabindex="1" medium block :disabled="isLoading" style="margin-right: 1em">{{ $t('RemoveCardModal_Button_No') }}</st-button>
        <st-button @click="submit(1)" @keydown.tab.prevent.native="onSubmitKeyDown" id="submit" tabindex="2" medium block primary :disabled="isLoading">{{ $t('RemoveCardModal_Button_Yes') }}</st-button>
      </div>
      <div class="d-sm-none">
        <div class="row">
          <div class="col-12">
            <st-button @click="submit(1)" primary medium block style="margin-bottom: 1em">{{ $t('RemoveCardModal_Button_Yes') }}</st-button>
          </div>
          <div class="col-12">
            <st-button @click="submit(0)" medium block>{{ $t('RemoveCardModal_Button_No') }}</st-button>
          </div>
        </div>
      </div>
      <st-error v-if="error" :error="error"></st-error>
    </div>
  </st-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { DELETE_CARD } from '../../../store/actions/card-actions';
import globalMixins from '../../../mixins/globalMixin';

export default {
  name: 'remove-card-modal',
  mixins: [globalMixins],
  props: {
    close: {
      type: Function
    },
    cardId: {
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      error: undefined
    };
  },
  mounted() {
    // Tabbning, focusera på första knappen i dialogen.
    document.getElementById('cancel').focus();
  },
  methods: {
    ...mapActions({
      removeCard: DELETE_CARD
    }),
    submit(value) {
      if (value) {
        this.isLoading = true;

        this.removeCard(this.cardId)
          .then((result) => {
            if (this.close()) this.close();
            this.$router.push('/');
            this.isLoading = false;
          })
          .catch((error) => {
            this.error = error;
            this.isLoading = false;
            this.$nextTick(() => {
              document.getElementById('cancel').focus();
            });
          });
      } else {
        if (this.close) this.close();
      }
    },
    onCancelKeyDown(e) {
      this.cancelKeyDown(e, 'cancel', 'submit');
    },
    onSubmitKeyDown(e) {
      this.submitKeyDown(e, 'cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.remove-card-modal {
  h1 {
    font-size: 1.9375em !important;
    word-break: break-word;
  }
  .st-modal-footer-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .st-modal-footer-default {
    .col-12 {
      @media (max-width: 375px) {
        padding: 0 !important;
      }
    }
  }
}
</style>
