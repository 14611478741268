<template>
  <div class="transaction-details-info-per-day">
    <div class="d-sm-none d-print-none">
      <st-table-mobile :items="ticketListModified" :fields="ticketMobileFields">
        <template slot="transaction-created" slot-scope="{ item }">
          <span>{{ item.created }}</span>
        </template>
        <template slot="zone-value" slot-scope="{ item }">
          <span>{{ item.description }}</span>
        </template>
        <template slot="payment-amount" slot-scope="{ item }">
          <span>{{ item.amount }}</span>
        </template>
      </st-table-mobile>
    </div>
    <div class="d-none d-sm-block d-print-none">
      <st-table
        :items="ticketListModified"
        :fields="ticketFields"
        doubleLines
        :columnPadding="'0px 24px'"
      >
        <template slot="transaction-created" slot-scope="{ item }">
          <span>{{ item.created }}</span>
        </template>
        <template slot="zone-value" slot-scope="{ item }">
          <span>{{ item.description }}</span>
        </template>
        <template slot="payment-amount" slot-scope="{ item }">
          <span>{{ item.amount }}</span>
        </template>
      </st-table>
    </div>
    <div id="printOnly">
      <TransactionReceipt v-if="hmac" ref="print-receipt-table"></TransactionReceipt>
    </div>
  </div>
</template>

<script>
import TransactionReceipt from './TransactionReceipt';
import { mapGetters } from 'vuex';
import { GET_TICKETS, GET_HMAC } from '../../store/actions/tapnride-actions';

import dayjs from 'dayjs';
import 'dayjs/locale/sv';
dayjs.locale('sv');

export default {
  props: {
    ticketList: {
      type: Array,
      required: true
    }
  },
  components: {
    TransactionReceipt
  },
  filters: {
    dateFilter: function (value) {
      return moment(value).format('D MMM, YYYY HH:mm');
    }
  },
  data() {
    return {
      ticketFields: [
        {
          label: this.$t('TapnRide_TravelHistory_TableColumn_DateTime'),
          key: 'created'
        },
        {
          label: this.$t('TapnRide_TravelHistory_TableColumn_Zone'),
          key: 'description'
        },
        {
          label: this.$t('TapnRide_TravelHistory_TableColumn_TicketId'),
          key: 'ticketId'
        },
        {
          label: this.$t('TapnRide_TravelHistory_TableColumn_Sum'),
          isArray: true,
          fields: [{ key: 'amountInclVat' }, { key: 'amountExclVat' }]
        }
      ],
      ticketMobileFields: [
        {
          key: 'created',
          label: this.$t('TapnRide_TravelHistory_TableColumn_DateTime')
        },
        {
          key: 'description',
          label: this.$t('TapnRide_TravelHistory_TableColumn_Zone')
        },
        {
          label: this.$t('TapnRide_TravelHistory_TableColumn_TicketId'),
          key: 'ticketId'
        },
        {
          label: this.$t('TapnRide_TravelHistory_TableColumn_Sum'),
          key: 'amountInclVatMobile'
        }
      ]
    };
  },
  methods: {
    dateFormat(d) {
      if (!d) return '-';
      return dayjs(String(d)).format('D MMM, YYYY kl. HH:mm');
    }
  },
  computed: {
    ...mapGetters({
      getTickets: GET_TICKETS,
      hmac: GET_HMAC
    }),
    ticketListModified() {
      if (!this.ticketList) return;

      let tickets = [];
      for (let i = 0; i < this.ticketList.length; i++) {
        let ticket = this.ticketList[i];

        if (!ticket) continue;

        tickets.push({
          created: this.dateFormat(ticket.created),
          description: ticket.description.sv,
          amountInclVat: parseFloat(ticket.amountInclVat).toFixed(2) + ` kr`, //`${ticket.amountInclVat} kr`,
          amountExclVat:
            `varav moms ` +
            parseFloat(ticket.amountInclVat - ticket.amountExclVat).toFixed(2) +
            ` kr`,
          amountInclVatMobile:
            `${ticket.amountInclVat}  (Varav moms ` +
            parseFloat(ticket.amountInclVat - ticket.amountExclVat).toFixed(2) +
            `) kr`,
          ticketId: ticket.ticketId
        });
      }
      return tickets;
    }
  }
};
</script>

<style lang="scss" scoped>
@media print {
  #printOnly {
    display: block !important;
  }
}

#printOnly {
  display: none;
}

.card-details-ticket-list {
  position: relative;

  h4 {
    margin-top: 31px;
    margin-bottom: 11px !important;
  }

  .card-details-list-overlay {
    position: absolute;
    top: 53px;
    left: 0;
    width: 100%;
    height: calc(100% - 53px);
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.st-table-item-mobile-expanded .item-footer {
  display: none !important;
}
</style>
