<template>
  <div class="get-card-form">
    <st-form-group required :validation="$v.model.cardNumber" :validationErrors="validationErrors.cardNumber">
      <template v-slot:label>
        <label for="cardNumberInput">{{ $t('CardNumber_Label') }}</label>
      </template>
      <template v-slot:default="{ ariaDescribedBy }">
        <card-number-input id="cardNumberInput" :ariaDescribedBy="ariaDescribedBy" @keyup.enter.native="onEnterSubmit" v-model="model.cardNumber" :blur="$v.model.cardNumber.$touch" :placeholder="$t('CardNumber_Placeholder')" tabindex="0"></card-number-input>
      </template>
    </st-form-group>

    <st-button primary block medium :disabled="$v.$invalid || isLoading" @click="submit" tabindex="0">{{ $t('GetCardForm_Button_GetTickets') }}</st-button>

    <st-error v-if="error" :error="error" />

    <card-info-modal v-if="showCardInfoModal" :close="onCardInfoModalClose" />
  </div>
</template>

<script>
import api from '../../api';

import CardNumberInput from '../CardNumberInput';
import CardInfoModal from '../CardInfoModal.vue';

import GetCardFormValidations from './get-card-form-validations';
import GetCardFormValidationErrors from './get-card-form-validation-errors';
import { mapMutations } from 'vuex';
import { SET_SELECTED_CARD } from '../../store/actions/card-actions';

export default {
  data() {
    return {
      model: {
        cardNumber: ''
      },
      validationErrors: GetCardFormValidationErrors,
      showCardInfoModal: false,
      error: undefined,
      isLoading: false
    };
  },
  validations: {
    model: GetCardFormValidations
  },
  components: {
    CardNumberInput,
    CardInfoModal
  },
  methods: {
    ...mapMutations({
      setSelectedCard: SET_SELECTED_CARD
    }),
    onCardInfoModalClose() {
      this.showCardInfoModal = false;
    },
    onEnterSubmit() {
      if (this.$v.$invalid || this.isLoading) return;
      this.submit();
    },
    submit() {
      this.isLoading = true;
      this.error = undefined;

      let vm = this;

      grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'getcardform' }).then(function (token) {
        const url = `cards/${vm.model.cardNumber}`;
        return api
          .post(url, {
            recaptchaToken: token
          })
          .then((result) => {
            if (result.status === 204) {
              vm.error = {
                message: vm.$t('Error_CardNotFound')
              };
              vm.isLoading = false;
              vm.$nextTick(() => {
                document.getElementById('cardNumberInput').focus();
              });
            } else {
              vm.setSelectedCard(result.data);
              vm.$router.push('/hamta-kort/detaljer');
              vm.isLoading = false;
            }
          })
          .catch((error) => {
            vm.error = error;
            vm.isLoading = false;
            vm.$nextTick(() => {
              document.getElementById('cardNumberInput').focus();
            });
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.get-card-form {
  .add-card-link {
    font-size: 0.875em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0000ff;
    text-decoration: underline;
    float: right;
  }
}
</style>
