<template>
  <div class="payment">
    <h1>Fel!</h1>
    <template v-if="data">
      <h2 v-if="data.code">{{ data.code }}</h2>
      <st-error :error="data" :alt="$t('Error_BuyTicket_Alt_Text')" />
    </template>

    <router-link to="/" tabindex="2" ref="paymentCancelBack">Tillbaka till startsidan</router-link>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { CLEAR_TICKET_STATE } from '../../../store/actions/ticket-actions';
import { CLEAR_CARD_ORDER_MODEL_STATE } from '../../../store/actions/card-order-actions';
import { CLEAR_JOURNEY_STATE } from '../../../store/actions/journey-actions';
import local from '../../../services/localStorageService';
import globalMixin from '../../../mixins/globalMixin';

export default {
  mixins: [globalMixin],
  props: ['data'],
  beforeRouteLeave(to, from, next) {
    if (window.payex) {
      payex.hostedView.paymentMenu().close();
    }

    this.clearTicketState();
    this.clearCardOrderModelState();
    this.clearJourneyState();
    local.clear();

    next();
  },
  mounted() {
    this.$refs.paymentCancelBack.$el.focus();
    this.scrollToTopWithOffset();
  },
  methods: {
    ...mapActions({
      clearTicketState: CLEAR_TICKET_STATE
    }),
    ...mapMutations({
      clearCardOrderModelState: CLEAR_CARD_ORDER_MODEL_STATE,
      clearJourneyState: CLEAR_JOURNEY_STATE
    })
  }
};
</script>

<style lang="scss" scoped>
.payment {
  h2 {
    font-size: 1.9375em !important;
  }
}
</style>
