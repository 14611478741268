<template>
  <div class="signup-form">
    <st-form-group id="mobile" :label="$t('SignupForm_Mobile_Label')" required :validation="$v.model.mobile" :validationErrors="validationErrors.mobile">
      <template #default="{ ariaDescribedBy }">
        <st-input id="mobile" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_Mobile_Placeholder')" required v-model="model.mobile" :invalid="$v.model.mobile.$error" autocomplete="tel" :blur="$v.model.mobile.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-form-group id="email" :label="$t('SignupForm_Email_Label')" required :validation="$v.model.email" :validationErrors="validationErrors.email">
      <template #default="{ ariaDescribedBy }">
        <st-input id="email" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_Email_Placeholder')" required v-model="model.email" :invalid="$v.model.email.$error" autocomplete="email" :blur="$v.model.email.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-form-group id="repeatEmail" :label="$t('SignupForm_RepeatEmail_Label')" required :validation="$v.model.repeatEmail" :validationErrors="validationErrors.repeatEmail">
      <template #default="{ ariaDescribedBy }">
        <st-input id="repeatEmail" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_RepeatEmail_Placeholder')" required v-model="model.repeatEmail" :invalid="$v.model.repeatEmail.$error" autocomplete="email" :blur="$v.model.repeatEmail.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-form-group id="password" :label="$t('SignupForm_Password_Label')" required :validation="$v.model.password" :validationErrors="validationErrors.password">
      <template #default="{ ariaDescribedBy }">
        <st-input id="password" :aria-describedby="ariaDescribedBy" type="password" :placeholder="$t('SignupForm_Password_Placeholder')" required v-model="model.password" :invalid="$v.model.password.$error" autocomplete="new-password" :blur="$v.model.password.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <div class="row">
      <div class="col-12 col-md-6">
        <st-form-group id="firstName" :label="$t('SignupForm_FirstName_Label')" required :validation="$v.model.firstName" :validationErrors="validationErrors.firstName">
          <template #default="{ ariaDescribedBy }">
            <st-input id="firstName" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_FirstName_Placeholder')" required v-model="model.firstName" :invalid="$v.model.firstName.$error" autocomplete="given-name" :blur="$v.model.firstName.$touch" tabindex="0"></st-input>
          </template>
        </st-form-group>
      </div>
      <div class="col-12 col-md-6">
        <st-form-group id="lastName" :label="$t('SignupForm_LastName_Label')" required :validation="$v.model.lastName" :validationErrors="validationErrors.lastName">
          <template #default="{ ariaDescribedBy }">
            <st-input id="lastName" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_LastName_Placeholder')" required v-model="model.lastName" :invalid="$v.model.lastName.$error" autocomplete="family-name" :blur="$v.model.lastName.$touch" tabindex="0"></st-input>
          </template>
        </st-form-group>
      </div>
    </div>

    <st-form-group id="careOf" :label="$t('SignupForm_CareOf_Label')" :validation="$v.model.careOf" :validationErrors="validationErrors.careOf">
      <template #default="{ ariaDescribedBy }">
        <st-input id="careOf" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_CareOf_Placeholder')" required v-model="model.careOf" :invalid="$v.model.careOf.$error" :blur="$v.model.careOf.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-form-group id="street" :label="$t('SignupForm_Street_Label')" required :validation="$v.model.street" :validationErrors="validationErrors.street">
      <template #default="{ ariaDescribedBy }">
        <st-input id="street" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_Street_Placeholder')" required v-model="model.street" :invalid="$v.model.street.$error" autocomplete="street-address" :blur="$v.model.street.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <div class="row">
      <div class="col-12 col-md-4">
        <st-form-group id="postalCode" :label="$t('SignupForm_PostalCode_Label')" required :validation="$v.model.postalCode" :validationErrors="validationErrors.postalCode">
          <template #default="{ ariaDescribedBy }">
            <st-input id="postalCode" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_PostalCode_Placeholder')" required v-model="model.postalCode" :invalid="$v.model.postalCode.$error" autocomplete="postal-code" :blur="$v.model.postalCode.$touch" tabindex="0"></st-input>
          </template>
        </st-form-group>
      </div>
      <div class="col-12 col-md-8">
        <st-form-group id="city" :label="$t('SignupForm_City_Label')" required :validation="$v.model.city" :validationErrors="validationErrors.city">
          <template #default="{ ariaDescribedBy }">
            <st-input id="city" :aria-describedby="ariaDescribedBy" :placeholder="$t('SignupForm_City_Placeholder')" required v-model="model.city" :invalid="$v.model.city.$error" autocomplete="address-level2" :blur="$v.model.city.$touch" tabindex="0"></st-input>
          </template>
        </st-form-group>
      </div>
    </div>

    <st-form-group id="country" :label="$t('SignupForm_Country_Label')" required :validation="$v.model.country" :validationErrors="validationErrors.country">
      <template #default="{ ariaDescribedBy }">
        <st-select id="country" :aria-describedby="ariaDescribedBy" :items="countries" :placeholder="$t('SignupForm_Country_Placeholder')" required v-model="model.country" :invalid="$v.model.country.$error" :blur="$v.model.country.$touch" tabindex="0" />
      </template>
    </st-form-group>

    <st-checkbox id="acceptTerms" v-model="model.acceptTerms" tabindex="0">
      <span v-html="$t('SignupForm_AcceptTerms_Checkbox')" />
    </st-checkbox>

    <st-checkbox id="acceptNewsletters" v-model="model.acceptNewsletters" tabindex="0">
      <span v-html="$t('SignupForm_AcceptNewsletters_Checkbox')" />
    </st-checkbox>

    <div class="edit-form-btn-wrapper">
      <st-button medium @click="cancel" class="cancel" :disabled="isLoading" tabindex="0">{{ $t('SignupForm_Button_Cancel') }}</st-button>
      <st-button medium primary @click="submit" class="submit" :disabled="$v.$invalid || isLoading" tabindex="0">{{ $t('SignupForm_Button_Submit') }}</st-button>
    </div>
    <st-error v-if="error" :error="error" />
  </div>
</template>

<script>
import api from '../../api';

import { GET_COUNTRIES } from '../../store/actions/base-actions';

import SignupFormValidations from './signup-form-validations';
import SignupFormValidationErrors from './signup-form-validation-errors';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      model: {
        firstName: '',
        lastName: '',
        email: '',
        repeatEmail: '',
        mobile: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        careOf: '',
        password: '',
        acceptTerms: false,
        acceptNewsletters: false
      },
      isLoading: false,
      error: undefined,
      validationErrors: SignupFormValidationErrors
    };
  },
  mounted() {
    document.querySelector('.st-checkbox a').setAttribute('tabindex', '0');
  },
  validations: {
    model: SignupFormValidations
  },
  computed: {
    ...mapGetters({
      countries: GET_COUNTRIES
    })
  },
  methods: {
    submit() {
      this.error = undefined;
      this.isLoading = true;
      const url = 'mitt-konto/create-account';
      let _this = this;
      api
        .post(url, this.model)
        .then((result) => {
          this.$router.push({
            name: 'login',
            params: {
              successfulSignup: true
            }
          });
        })
        .catch((error) => {
          if (error.response && error.response.status == 400) {
            error.message = _this.$t('Error_Created_Account_Failed');
          }
          this.error = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancel() {
      this.$router.push('/');
    }
  }
};
</script>
<style lang="scss" scoped>
.signup-form {
  .st-button {
    &:first-child {
      @media (min-width: 576px) {
        margin-right: 0.5em !important;
      }
    }
  }

  .edit-form-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .st-button {
      min-width: 11.25em;
      @media (max-width: 767px) {
        min-width: auto;
        flex: 1;
      }
      @media (max-width: 575px) {
        flex: 0 0 100%;
      }
    }
  }
}
</style>
