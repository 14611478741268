import i18n from '../../i18n/index';

export default {
  firstName: [
    {
      key: 'required',
      message: i18n.t('BuyCardForm_FirstName_Required_Validation'),
      id: 'firstNameRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('BuyCardForm_FirstName_MaxLength_Validation'),
      id: 'firstNameLength'
    }
  ],
  lastName: [
    {
      key: 'required',
      message: i18n.t('BuyCardForm_LastName_Required_Validation'),
      id: 'lastNameRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('BuyCardForm_LastName_MaxLength_Validation'),
      id: 'lastNameLength'
    }
  ],
  street: [
    {
      key: 'required',
      message: i18n.t('BuyCardForm_Address_Required_Validation'),
      id: 'streetRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('BuyCardForm_Address_MaxLength_Validation'),
      id: 'streetLength'
    }
  ],
  postalCode: [
    {
      key: 'required',
      message: i18n.t('BuyCardForm_PostalCode_Required_Validation'),
      id: 'postalCodeRequired'
    },
    {
      key: 'postalCodeFormat',
      message: i18n.t('BuyCardForm_PostalCode_PostalCodeFormat_Validation'),
      id: 'postalCodeFormat'
    }
  ],
  city: [
    {
      key: 'required',
      message: i18n.t('BuyCardForm_City_Required_Validation'),
      id: 'cityRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('BuyCardForm_City_MaxLength_Validation'),
      id: 'cityLength'
    }
  ],
  email: [
    {
      key: 'email',
      message: i18n.t('BuyCardForm_Email_Email_Validation'),
      id: 'emailFormat'
    },
    {
      key: 'required',
      message: i18n.t('BuyCardForm_Email_RequiredIf_Validation'),
      id: 'emailRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('BuyCardForm_Email_MaxLength_Validation'),
      id: 'emailLength'
    }
  ]
};
