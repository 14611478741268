import { required, email, sameAs, maxLength } from 'vuelidate/lib/validators';

function mobileFormat(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^(((0|((\+|00)46)|\+46)(7(0|2|3|6|9))(\d{7}))|((\+|00)(?!(46)))(\d{8,12}\b))/;
  return regex.test(value);
}

function passwordFormat(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^(?=.*\d)(?=.*[a-zåäöA-ZÅÄÖ])[\d\S]{8,}$/;
  return regex.test(value);
}

function postalCodeFormat(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^\d{5}$/;
  return regex.test(value);
}

export default {
  firstName: {
    required,
    maxLength: maxLength(50)
  },
  lastName: {
    required,
    maxLength: maxLength(50)
  },
  email: {
    required,
    email,
    maxLength: maxLength(100)
  },
  repeatEmail: {
    sameAsEmail: sameAs('email')
  },
  mobile: {
    required,
    mobileFormat
  },
  careOf: {
    maxLength: maxLength(50)
  },
  street: {
    required,
    maxLength: maxLength(50)
  },
  postalCode: {
    required,
    postalCodeFormat
  },
  city: {
    required,
    maxLength: maxLength(50)
  },
  country: {
    required,
    maxLength: maxLength(50)
  },
  password: {
    required,
    passwordFormat
  },
  acceptTerms: {
    sameAs: sameAs(() => true)
  }
};
