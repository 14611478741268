import i18n from '../../i18n/index';

export default {
  firstName: [
    {
      key: 'required',
      message: i18n.t('SignupForm_FirstName_Required_Validation'),
      id: 'firstNameRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_FirstName_MaxLength_Validation'),
      id: 'firstNameLength'
    }
  ],
  lastName: [
    {
      key: 'required',
      message: i18n.t('SignupForm_LastName_Required_Validation'),
      id: 'lastNameRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_LastName_MaxLength_Validation'),
      id: 'lastNameLength'
    }
  ],
  email: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Email_Required_Validation'),
      id: 'emailRequired'
    },
    {
      key: 'email',
      message: i18n.t('SignupForm_Email_Email_Validation'),
      id: 'emailFormat'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_Email_MaxLength_Validation'),
      id: 'emailLength'
    }
  ],
  repeatEmail: [
    {
      key: 'sameAsEmail',
      message: i18n.t('SignupForm_RepeatEmail_SameAs_Validation'),
      id: 'sameAsEmailValidation'
    }
  ],
  mobile: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Mobile_Required_Validation'),
      id: 'mobileRequired'
    },
    {
      key: 'mobileFormat',
      message: i18n.t('SignupForm_Mobile_Format_Validation'),
      id: 'mobileFormat'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_Mobile_MaxLength_Validation'),
      id: 'mobileLength'
    }
  ],
  careOf: [
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_CardOf_MaxLength_Validation'),
      id: 'careOfLength'
    }
  ],
  street: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Street_Required_Validation'),
      id: 'streetRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_Street_MaxLength_Validation'),
      id: 'streetLength'
    }
  ],
  postalCode: [
    {
      key: 'required',
      message: i18n.t('SignupForm_PostalCode_Required_Validation'),
      id: 'postalCodeRequired'
    },
    {
      key: 'postalCodeFormat',
      message: i18n.t('SignupForm_PostalCode_Format_Validation'),
      id: 'postalCodeFormat'
    }
  ],
  city: [
    {
      key: 'required',
      message: i18n.t('SignupForm_City_Required_Validation'),
      id: 'cityRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_City_MaxLength_Validation'),
      id: 'cityLength'
    }
  ],
  country: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Country_Required_Validation'),
      id: 'countryRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_Country_MaxLength_Validation'),
      id: 'countryLength'
    }
  ],
  password: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Password_Required_Validation'),
      id: 'passwordRequired'
    },
    {
      key: 'passwordFormat',
      message: i18n.t('SignupForm_Password_Format_Validation'),
      id: 'passwordFormat'
    }
  ]
};
