import { required, email, maxLength, sameAs } from 'vuelidate/lib/validators';

function passwordFormat(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^(?=.*\d)(?=.*[a-zåäöA-ZÅÄÖ])[\d\S]{8,}$/;
  return regex.test(value);
}

export default {
  currentPassword: {
    required
  },
  newPassword: {
    passwordFormat,
    required
  }
};
