var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.ticketOffersNamesLoaded)?_c('div',{staticClass:"loaderWrapper"},[_c('st-spinner',{attrs:{"loadingMessage":'Sidan laddar'}})],1):_c('div',{staticClass:"card-details-ticket-list"},[(_vm.isDisabled)?_c('div',{staticClass:"card-details-list-overlay"}):_vm._e(),_c('div',{staticClass:"d-sm-none"},[_c('h2',[_vm._v("Biljetter")]),_c('st-table-mobile',{attrs:{"items":_vm.ticketList,"fields":_vm.ticketMobileFields,"useToggle":""},scopedSlots:_vm._u([{key:"offerId-value",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.offerName(item.offerId)))]}},{key:"activatedStatus-value",fn:function(ref){
var item = ref.item;
return [(item.status !== 2)?_c('st-status',{attrs:{"status":_vm.getCardTicketStatus(item.status)}}):_c('st-button',{staticClass:"mobile-btn",attrs:{"small":""},on:{"click":function($event){return _vm.openActivateTicketModal(item)}}},[_vm._v(_vm._s(_vm.$t('CardDetailsTicketList_Button_ActivateTicket')))])]}},{key:"travellers-value",fn:function(ref){
var item = ref.item;
return [_c('traveller-type-list',{staticClass:"ticket-traveller-list",attrs:{"travellers":item.travellers}})]}},{key:"zone-value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.zone))]),(item.numberOfStopPoints > 0)?_c('span',{staticStyle:{"padding-left":"0.3125em","word-break":"break-word"}},[_vm._v("("+_vm._s(item.stopPoints)+")")]):_vm._e()]}},{key:"amount-value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("fixedDecimals")(item.amount))+" SEK")])]}},{key:"addons-value",fn:function(ref){
var item = ref.item;
return [(item.addons.length < 1)?_c('div',[_vm._v("-")]):_vm._l((item.addons),function(addon,index){return _c('div',{key:index},[_c('st-icon',{staticClass:"traveller-type-icon",staticStyle:{"margin-top":"-0.125em"},attrs:{"name":_vm.getAddonIconByType(addon.addonType, item.id),"alt":_vm.getAddonAltText(addon.addonType, item.id),"useSvg":"","inline":""}}),_vm._v("\n          "+_vm._s(addon.name)+"\n        ")],1)})]}},{key:"rePurchase-value",fn:function(ref){
var item = ref.item;
return [_c('re-purchase-ticket',{attrs:{"name":"rePurchase","ticketId":item.ticketId}})]}}])})],1),_c('div',{staticClass:"d-none d-sm-block"},[_c('st-table',{attrs:{"items":_vm.ticketList,"fields":_vm.ticketFields,"doubleLines":"","cellHeight":2.8125,"columnPadding":'0em 1.5em'},scopedSlots:_vm._u([{key:"offerId",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.offerName(item.offerId)))]}},{key:"activatedStatus",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-between"},[(item.status !== 2)?_c('st-status',{attrs:{"status":_vm.getCardTicketStatus(item.status)}}):_c('st-button',{attrs:{"disabled":_vm.isDisabled,"small":"","tabindex":_vm.tabindex},on:{"click":function($event){return _vm.openActivateTicketModal(item, index)}}},[_vm._v(_vm._s(_vm.$t('CardDetailsTicketList_Button_ActivateTicket')))])],1)]}},{key:"info",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('st-icon',{staticClass:"info-icon",class:!_vm.buyAndSendUrl ? 'align-right' : '',attrs:{"size":"md","isHoverable":"","inline":"","useSvg":"","name":"info","alt":_vm.$t('Information_Icon_Clickable_Alt_Text'),"disabled":_vm.isDisabled,"tabindex":_vm.tabindex},nativeOn:{"click":function($event){return _vm.openTicketInfoModal(item, index)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openTicketInfoModal(item, index)}}})]}},{key:"rePurchase",fn:function(ref){
var item = ref.item;
return [_c('re-purchase-ticket',{staticClass:"align-right",attrs:{"name":"rePurchase","ticketId":item.ticketId}})]}},{key:"activatedDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"doubleLine"},[_vm._v(_vm._s(_vm.ticketValidity(item)))])]}}])})],1),(_vm.showActivateTicketModal)?_c('activate-ticket-modal',{attrs:{"close":_vm.onCloseActivateTicketModal,"error":_vm.activateTicketError,"model":_vm.selectedTicket}}):_vm._e(),(_vm.showTicketInfoModal)?_c('ticket-info-modal',{attrs:{"close":_vm.onCloseTicketInfoModal,"model":_vm.selectedTicket}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }