<template>
  <div v-if="!ticketOffersNamesLoaded">
    <st-spinner :loadingMessage="'Sidan laddar'"/>
  </div>
  <st-modal v-else class="ticket-info-modal" :ariaLabelledby="'ticket-info-header'" :onClose="close">
    <div slot="header" class="st-modal-header-default">
      <h1 id="ticket-info-header">Biljett-id: {{ model.ticketId }}</h1>
    </div>
    <div slot="body" class="st-modal-body">
      <div class="row">
        <div class="col-4 bold">{{ $t('TicketInfo_Label_Created') }}</div>
        <div class="col-8 text-right text-sm-left">{{ model.createdDate }}</div>
      </div>
      <div class="row">
        <div class="col-4 bold">{{ $t('TicketInfo_Label_Travellers') }}</div>
        <div class="col-8 text-right text-sm-left">
          <traveller-type-list class="ticket-traveller-list" :travellers="model.travellers"></traveller-type-list>
        </div>
      </div>
      <div class="row">
        <div class="col-4 bold">{{ $t('TicketInfo_Label_Addons') }}</div>
        <div class="col-8 text-right text-sm-left">
          <div v-if="model.addons.length < 1">-</div>
          <div v-for="(item, index) in model.addons" :key="index">
            <st-icon :name="getAddonIconByType(item.addonType, item.id)" :alt="getAddonAltText(item.addonType, item.id)" class="traveller-type-icon" style="margin-top: -0.125em" useSvg inline></st-icon>
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 bold">{{ $t('TicketInfo_Label_Zone') }}</div>
        <div class="col-8 text-right text-sm-left">
          {{ model.zone }}
          <br />
          {{ model.stopPoints }}
          <div class="passageType" v-if="getPassageTypeByPriceId(model.priceId)">
            <st-icon :name="getPassageTypeByPriceId(model.priceId).icon" :alt="getPassageTypeByPriceId(model.priceId).alt" inline></st-icon>
            {{ $t(`Passage_Type_${getPassageTypeByPriceId(model.priceId).passageType}`) }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 bold">{{ $t('TicketInfo_Label_Offer') }}</div>
        <div class="col-8 text-right text-sm-left">{{ offerName(model.offerId) }}</div>
      </div>
      <div class="row">
        <div class="col-4 bold">{{ $t('TicketInfo_Label_Valid') }}</div>
        <div class="col-8 text-right text-sm-left">{{ model.activeTo }}</div>
      </div>
      <div class="row">
        <div class="col-4 bold">{{ $t('TicketInfo_Label_Price') }}</div>
        <div class="col-8 text-right text-sm-left">{{ model.amount | fixedDecimals }} SEK</div>
      </div>
    </div>
    <div slot="footer" class="st-modal-footer-default">
      <st-button ref="submitButton" @click="close" @keydown.tab.native.prevent medium block primary>{{ $t('TicketInfo_Button_Close') }}</st-button>
    </div>
  </st-modal>
</template>

<script>
import TravellerTypeList from '../TravellerTypeList';
import globalMixins from '../../mixins/globalMixin.js';
import { mapActions } from 'vuex';
import { SET_TICKET_TRAVELLERS_FOR_MODAL } from '../../store/actions/ticket-actions';
export default {
  mixins: [globalMixins],
  props: ['close', 'model'],
  components: {
    TravellerTypeList
  },
  mounted() {
    this.setTickettravellers({ priceModelId: this.model.priceModelId.toLowerCase(), priceId: this.model.priceId, offerId: this.model.offerId });
    this.$refs.submitButton.$el.focus();
  },
  methods: {
    ...mapActions({
      setTickettravellers: SET_TICKET_TRAVELLERS_FOR_MODAL
    })
  }
};
</script>

<style lang="scss" scoped>
.ticket-info-modal {
  .st-modal-header-default {
    border-bottom: 0.0625em solid rgba(77, 72, 69, 0.08);
    margin-bottom: 1.75em;

    h1 {
      text-align: left !important;
      font-size: 1.9375em !important;
    }
  }

  .st-modal-body {
    padding: 0em 2.25em !important;

    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4d4845;

    .bold {
      font-weight: bold;
    }

    .row {
      padding-bottom: 0.75em;
      .col-4 {
        padding: 0em;
      }
    }

    .ticket-traveller-list {
      margin: 0 !important;
    }
  }
}
</style>
