<template>
  <div class="passage-type-picker">
    <st-fieldset v-if="showPassageTypeSelector" legend="Välj överfart" required>
      <st-radio-button name="passageType" v-model="passageTypeModel" required="required" v-for="item of passageTypes" :key="item.priceId" :value="item" :id="item.passageType">
        {{ getPassageTypeByPriceId(item.priceId).alt }}
      </st-radio-button>
    </st-fieldset>
    <st-fieldset :legend="`Välj riktning ${!showPassageTypeSelector ? '(via Öresundsbron)' : ''}`" v-if="directionRequired" required>
      <st-radio-button name="passageDirection" v-model="directionModel" required="required" v-for="item of directions" :key="item.id" :value="item.id" :id="item.id">
        {{ item.name }}
      </st-radio-button>
    </st-fieldset>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { GET_PASSAGE_TYPES, GET_PASSAGE_DIRECTIONS, SET_SELECTED_PASSAGE_TYPE, SET_SELECTED_DIRECTION, GET_SELECTED_PASSAGE_TYPE, GET_SELECTED_DIRECTION } from '../../../store/actions/passage-type-actions';
import { SET_JOURNEY_BY_PASSAGE_TYPE } from '../../../store/actions/journey-actions';

import globalMixins from '../../../mixins/globalMixin';
export default {
  mixins: [globalMixins],
  data() {
    return {
      isMounted: false
    };
  },
  beforeMount() {
    this.setInitialValues();
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      passageTypes: GET_PASSAGE_TYPES,
      directions: GET_PASSAGE_DIRECTIONS,
      selectedPassageType: GET_SELECTED_PASSAGE_TYPE,
      selectedDirection: GET_SELECTED_DIRECTION
    }),
    ...mapState({
      offerId: (state) => state.ticketModule.offerId
    }),
    directionRequired() {
      return this.offerId.toLowerCase() === process.env.VUE_APP_OFFER_WITH_DIRECTION_REQUIRED.toLowerCase();
    },
    passageTypeModel: {
      get() {
        return this.selectedPassageType;
      },
      set(value) {
        this.setSelectedPassageType(value);
      }
    },
    directionModel: {
      get() {
        return this.selectedDirection;
      },
      set(value) {
        this.setSelectedDirection(value);
      }
    },
    showPassageTypeSelector() {
      return this.passageTypes && this.passageTypes.length > 1;
    }
  },
  methods: {
    ...mapActions({
      setJourneyByPassageType: SET_JOURNEY_BY_PASSAGE_TYPE
    }),
    ...mapMutations({
      setSelectedPassageType: SET_SELECTED_PASSAGE_TYPE,
      setSelectedDirection: SET_SELECTED_DIRECTION
    }),
    setInitialValues() {
      if (Object.keys(this.selectedPassageType).length === 0) {
        this.setSelectedPassageType(this.passageTypes[0]);
      }
      if (!this.selectedDirection) {
        this.setSelectedDirection(this.directions[0].id);
      }
    }
  },
  watch: {
    selectedPassageType: function () {
      if (!this.isMounted) return;

      let priceId = this.selectedPassageType.priceId;
      let originalPriceId = this.selectedPassageType.priceId;

      if (this.selectedDirection !== this.directions[0].id) {
        var priceParts = priceId.split('_');
        priceId = `${priceParts[2]}_${priceParts[1].split('').reverse().join('')}_${priceParts[0]}`;
      }

      this.setJourneyByPassageType({
        passageType: this.selectedPassageType.passageType,
        priceId,
        originalPriceId
      });
    },
    selectedDirection: function (val) {
      if (!this.isMounted) return;

      let priceId = this.selectedPassageType.priceId;
      let originalPriceId = this.selectedPassageType.priceId;

      if (val !== this.directions[0].id) {
        var priceParts = priceId.split('_');
        priceId = `${priceParts[2]}_${priceParts[1].split('').reverse().join('')}_${priceParts[0]}`;
      }

      this.setJourneyByPassageType({
        passageType: this.selectedPassageType.passageType,
        priceId,
        originalPriceId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.passage-type-picker {
  margin-bottom: 1em;
}
</style>
