import { UPDATE_MAP_DETAILS, GET_MAP_DETAILS_STATE, SET_MAP_DETAILS_STATE, CLEAR_MAP_DETAILS_STATE } from '../actions/map-details-actions';

const state = {
  polygons: [],
  circles: [],
  markers: [],
  controls: [],
  focus: [],
  zoom: 0,
  hasPossibleJourney: false
};

const actions = {
  [UPDATE_MAP_DETAILS]: ({ commit, rootState, getters }) => {
    if (!rootState.journeyModule.journey.possibleJourneys) {
      return;
    }

    const journey = rootState.journeyModule.journey.possibleJourneys[0];
    const priceModelId = rootState.ticketModule.priceModelId;
    const priceId = rootState.ticketModule.priceId;
    const travelAreaIds = rootState.ticketModule.travelAreaIds;
    const stopAreas = rootState.ticketModule.stopAreas;
    const cityAddonIds = rootState.ticketModule.cityAddonIds;
    const optionalCityAddonIds = rootState.ticketModule.optionalCityAddonIds;
    
    let cities = [];
    let circle = undefined;
    let areas = [];
    switch (priceModelId) {
      case 'city':
        var city = getters.GET_TRAVEL_AREA(priceModelId, priceId);
        if(city)
          cities.push(city);
        break;
        case 'personal':
          if (journey.circle) {
            // om circle finns är det en liten, mellan eller stor zon
            circle = {
              origo: [
              {
                latitude: journey.circle.circleOrigo.latitude,
                longitude: journey.circle.circleOrigo.longitude
              }
            ],
            diameter: journey.circle.diameter
          };

          // om det finns obligatoriska statszoner, för varje hämta stad
          if (journey.priceDetails.cityAddons && journey.priceDetails.cityAddons.mandatoryCities) {
            for (const mandatoryCity of journey.priceDetails.cityAddons.mandatoryCities) {
              var city = getters.GET_TRAVEL_AREA('city', mandatoryCity);
              cities.push(city);
            }
          }
          if (journey.priceDetails.cityAddons && journey.priceDetails.cityAddons.optionalCities) {
            for (let i = 0; i < optionalCityAddonIds.length; i++) {
              const cityId = optionalCityAddonIds[i];
              var city = getters.GET_TRAVEL_AREA('city', cityId);
              cities.push(city);
            }
          }
        } else {
          // om det inte finns en circle är det en area(hela skåne)
          areas.push(getters.GET_TRAVEL_AREA(priceModelId, priceId));
        }
        break;
        case 'oresund':
          // öresund är alltid en area
          areas.push(getters.GET_TRAVEL_AREA(priceModelId, priceId));
          break;
          case 'zones':
            for (let travelAreaId of travelAreaIds) {
              areas.push(getters.GET_TRAVEL_AREA(priceModelId, travelAreaId));
            }

            break;
          }

          let zoom = 0;
          let polygons = [];
          let circles = [];
          let focus = [];
          let markers = [];
          
          for (let i = 0; i < cities.length; i++) {
            let path = [];
            const city = cities[i];
            for (let j of city.polygons[0]) {
              path.push([j[1], j[0]]);
              let latlng = new google.maps.LatLng(Number.parseFloat(j[1]).toFixed(6), Number.parseFloat(j[0]).toFixed(6));
        focus.push(latlng);
      }
      polygons.push(path);
      zoom = 1;
    }
    
    if (circle) {
      circles.push(circle);
      let circleCoords = calcCircleCoords(circle.origo[0], circle.diameter);
      circleCoords.forEach(latlng => {
        focus.push(latlng);
      });
      
      zoom = 1;
    }

    if (areas) {
      for (let i = 0; i < areas.length; i++) {
        const area = areas[i];
        
        let path = [];
        for (let j of area.polygons[0]) {
          path.push([j[1], j[0]]);
          let latlng = new google.maps.LatLng(Number.parseFloat(j[1]).toFixed(6), Number.parseFloat(j[0]).toFixed(6));
          focus.push(latlng);
        }
        polygons.push(path);
      }
      zoom = 1;
    }

    for (let i = 0; i < stopAreas.length; i++) {
      markers.push({
        latitude: stopAreas[i].latitude,
        longitude: stopAreas[i].longitude,
        title: stopAreas[i].name
      });
    }
    commit(SET_MAP_DETAILS_STATE, {
      markers,
      polygons,
      circles,
      focus,
      zoom
    });
  }
};

const mutations = {
  [CLEAR_MAP_DETAILS_STATE]: state => {
    state.polygons = [];
    state.circles = [];
    state.markers = [];
    state.controls = [];
    state.focus = [];
    state.zoom = 0;
    state.hasPossibleJourney = false;
  },
  [SET_MAP_DETAILS_STATE]: (state, payload) => {
    if (payload.markers) {
      state.markers = payload.markers;
    }
    if (payload.polygons) {
      state.polygons = payload.polygons;
    }
    if (payload.zoom) {
      state.circles = payload.circles;
    }
    if (payload.focus) {
      state.focus = payload.focus;
    }
    if (payload.zoom) {
      state.zoom = payload.zoom;
    }
  }
};

const getters = {
  [GET_MAP_DETAILS_STATE]: state => {
    return state;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

let calcCircleCoords = function(origo, diameter) {
  /* eslint no-extend-native: ["error", { "exceptions": ["Number"] }] */
  Number.prototype.toRad = function() {
    return (this * Math.PI) / 180;
  };
  Number.prototype.toDeg = function() {
    return (this * 180) / Math.PI;
  };

  google.maps.LatLng.prototype.destinationPoint = function(brng, dist) {
    dist = dist / 6371;
    brng = brng.toRad();

    let lat1 = this.lat().toRad();
    let lon1 = this.lng().toRad();

    var lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) + Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));

    var lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(dist) * Math.cos(lat1), Math.cos(dist) - Math.sin(lat1) * Math.sin(lat2));

    if (isNaN(lat2) || isNaN(lon2)) return null;

    return new google.maps.LatLng(lat2.toDeg(), lon2.toDeg());
  };

  let center = new google.maps.LatLng(origo.latitude, origo.longitude);

  let radius = (diameter * 1000) / 2;
  let radiusInKm = radius / 1000;

  let circleCoords = [];
  circleCoords.push(center.destinationPoint(0, radiusInKm));
  circleCoords.push(center.destinationPoint(180, radiusInKm));

  return circleCoords;
};
