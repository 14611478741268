<template>
  <div v-if="!ticketOffersNamesLoaded" class="loaderWrapper">
    <st-spinner />
  </div>

  <div v-else class="buy-ticket-confirm" :aria-labelledby="'buy-ticket-confirm-header'">
    <router-link
      :to="{ name: 'buy-ticket', params: { isRoutingBack: true } }"
      class="top-link"
      tabindex="0"
      >{{ $t('TopNavigation_Back') }}</router-link
    >

    <h1 id="buy-ticket-confirm-header">{{ $t('BuyTicketConfirm_Header') }}</h1>
    <p class="description">{{ $t('BuyTicketConfirm_Description') }}</p>

    <div class="ticket-info">
      <table>
        <thead>
          <tr>
            <th>Zon</th>
            <th>Biljettyp</th>
            <th>Resande</th>
            <th>Tillägg</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div style="max-width: 15em">
                <span class="bold">{{
                  journeyName(
                    model.priceModelId,
                    model.priceId,
                    cityAddonIds.concat(optionalCityAddonIds)
                  )
                }}</span>
                <br />
                <span class="stop-places">{{
                  model.stopPoints.map((e) => e.name).join(', ')
                }}</span>
              </div>
            </td>
            <td>
              <span class="bold">{{ offerName(model.offerId) }}</span>
            </td>
            <td>
              <div v-for="(item, index) in model.travellers" :key="index">
                <st-icon
                  role="presentation"
                  useSvg
                  inline
                  size="md"
                  :name="travellerTypes.filter((tt) => tt.id == item.travellerTypeId)[0].icon"
                  :alt="travellerIconAltText(item.travellerType)"
                ></st-icon>
                <span class="bold"
                  >{{ item.count }}
                  {{ travellerTypes.filter((tt) => tt.id == item.travellerTypeId)[0].name }}</span
                >
              </div>
            </td>
            <td>
              <div v-for="(item, index) in addons" :key="index" style="max-width: 5.625em">
                <st-icon
                  :name="getAddonIconByType(item.addonType, item.id)"
                  :alt="getAddonAltText(item.addonType, item.id)"
                  class="traveller-type-icon"
                  style="margin-top: -0.125em"
                  useSvg
                  inline
                ></st-icon>
                <span class="bold">
                  {{ wordSplitWithLength(item.name, 0, 10) }}
                  <br />
                </span>
                <span class="small">{{ wordSplitWithLength(item.name, 10) }}</span>
              </div>
              <div v-if="addons.length === 0">
                <span>Inga tillägg</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mobile-ticket-info">
      <div class="row">
        <div class="col-12 mobile-zone-wrapper">
          <span class="mobile-zone">{{
            journeyName(
              model.priceModelId,
              model.priceId,
              cityAddonIds.concat(optionalCityAddonIds)
            )
          }}</span>
        </div>
        <div class="col-12">
          <span class="mobile-stop-points">{{model.stopPoints.map(e => e.name).join(', '),}}</span>
        </div>
        <div class="col-12">
          <span class="mobile-offer">{{ offerName(model.offerId) }}</span>
        </div>
        <div class="col-12">
          <traveller-type-list
            class="mobile-travellers d-flex flex-column"
            :travellers="model.travellers"
          ></traveller-type-list>
        </div>
        <div class="col-12">
          <div class="mobile-addons" v-for="(item, index) in addons" :key="index">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <st-item-counter
      :itemType="'biljetter'"
      :disabled="model.activateTicketOnPurchase"
      style="margin-top: 1.5em"
      label="Antal biljetter"
      v-model="numberOfTicketsModel"
      :price="price.amountIncludingVat"
      altDecreaseText="Minska antalet biljetter"
      altIncreaseText="Öka antalet biljetter"
    ></st-item-counter>

    <!-- Voucher checkbox -->
    <div class="clearfix">
      <div class="checkbox-container">
        <st-checkbox v-model="useVoucher" tabindex="0"
          >{{ $t('Confirm_Purchase_Voucher_Checkbox') }}
        </st-checkbox>
      </div>
    </div>
    <div>
      <voucher-form v-show="useVoucher" id="voucher-form" />
    </div>

    <buy-ticket-price-box></buy-ticket-price-box>

    <div ref="buttonfeild" class="row">
      <div class="col-6">
        <st-button
          medium
          block
          :disabled="isLoading"
          tabindex="0"
          class="cancel"
          @click="routeBack"
          >{{ $t('BuyTicketConfirm_Button_Back') }}</st-button
        >
      </div>
      <div class="col-6">
        <st-button
          id="buy-ticket-confirm-submit"
          class="submit"
          medium
          block
          primary
          @click="submit"
          :disabled="isLoading"
          tabindex="0"
          >{{
            this.model.activateTicketOnPurchase
              ? $t('BuyTicketConfirm_Button_Pay_And_Activate')
              : $t('BuyTicketConfirm_Button_Pay')
          }}</st-button
        >
      </div>
    </div>

    <st-error v-if="error" :error="error" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';

import api from '../../api';

import TravellerTypeList from '../../components/TravellerTypeList';
import BuyTicketPriceBox from '../../components/buy-ticket-form/BuyTicketPriceBox';
import VoucherForm from '../../components/buy-ticket-form/VoucherForm';

import {
  GET_PRICE,
  GET_NUMBER_OF_TICKETS,
  SET_NUMBER_OF_TICKETS,
  GET_AMOUNT_DUE,
  GET_VOUCHER_AMOUNT_USED,
  GET_TOTAL,
  GET_DISCOUNTS,
  GET_SELECTED_ADDONS
} from '../../store/actions/ticket-actions';

import globalMixin from '../../mixins/globalMixin.js';
import { GET_TRAVELLER_TYPES } from '../../store/actions/base-actions';
import { GET_VOUCHERS } from '../../store/actions/voucher-actions';

export default {
  mixins: [globalMixin],
  props: ['model'],
  components: {
    TravellerTypeList,
    BuyTicketPriceBox,
    VoucherForm
  },

  deactivated: function () {
    this.useVoucher = false;
  },

  data() {
    return {
      isLoading: false,
      error: undefined,
      numberOfTicketsModel: 1,
      useVoucher: false
    };
  },

  mounted() {
    this.numberOfTicketsModel = this.numberOfTickets;
    this.scrollToTopWithOffset();
  },

  watch: {
    numberOfTicketsModel: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.setNumberOfTickets(newVal);
      }
    }
  },

  methods: {
    formatCardNumber(value) {
      if (!value) return '';

      return value
        .substr(0, 12)
        .toString()
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
    },
    ...mapMutations({
      setNumberOfTickets: SET_NUMBER_OF_TICKETS
    }),
    submit() {
      this.isLoading = true;

      this.model.numberOfTickets = this.numberOfTickets;
      this.model.vouchers = this.vouchers;
      let vm = this;
      sessionStorage.setItem('st-bns-order-summary', JSON.stringify(this.orderSummary));

      grecaptcha
        .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'buyticketconfirm' })
        .then(function (token) {
          vm.model.recaptchaToken = token;
          const url = 'ticket-orders';
          api
            .post(url, vm.model)
            .then((result) => {
              vm.isLoading = false;
              // Skicka vidare användaren för att starta betalning
              vm.$router.push({
                //name: 'payment-checkin',
                name: 'payment-checkout',
                params: {
                  backUrl: '/kop-biljett',
                  model: {
                    orderId: result.data.id,
                    orderType: 'TICKET',
                    description: vm.$t('PayEx_Description_Ticket'),
                    authorizeCallback: 'CREATE_TICKET',
                    getTicketOrderInfo: 'GET_TICKET_ORDER_INFO',
                    zeroBalance: vm.amountDue <= 0
                  }
                }
              });
            })
            .catch((error) => {
              vm.error = error;
            })
            .finally(() => {
              vm.isLoading = false;
            });
        });
    },

    travellerIconAltText(travellerType) {
      let traveller = this.travellerTypes.find((item) => item.travellerType === travellerType);
      return traveller ? traveller.iconAltText : 'resenär';
    },

    wordSplitWithLength(value, start, length) {
      if (!value) return '';

      let values = [];
      let sumLength = 0;
      let startIndex = 0;

      const parts = value.split(' ');

      if (start > 0) {
        for (let i = 0; i < parts.length; i++) {
          const part = parts[i];
          sumLength += part.length;
          if (sumLength >= start) {
            startIndex = i;
            break;
          }
        }
      }

      for (let i = startIndex; i < parts.length; i++) {
        const part = parts[i];
        sumLength += part.length;
        if (length && sumLength >= length) break;

        values.push(part);
      }
      return values.join(' ');
    },
    routeBack() {
      this.$router.push({ name: 'buy-ticket', params: { isRoutingBack: true } });
    }
  },
  computed: {
    orderSummary() {
      const selectedOffer = this.allTicketOffers.find((a) => a.id === this.selectedOfferId);
      const travellersGrouped = this.travellersModified.reduce((p, c) => {
        const name = c.name;
        if (!p.hasOwnProperty(name)) {
          p[name] = 0;
        }
        p[name]++;
        return p;
      }, {});

      const totalTravellerCount = Object.values(travellersGrouped).reduce(
        (acc, val) => acc + val,
        0
      );

      const travellersString = Object.keys(travellersGrouped)
        .map((key) => {
          const name = key;
          const count = travellersGrouped[key];
          return `${count} ${name}`;
        })
        .join(', ');

      return {
        amountDue: this.amountDue,
        voucherAmountUsed: this.voucherAmountUsed,
        total: this.total,
        discount: this.discounts.length ? `${this.discounts[0].amount} kr` : '',
        stopAreas: this.stopAreas.map((s) => s.name).join(', '),
        ticketType: selectedOffer ? selectedOffer.name.sv : '',
        bearer:
          this.ticketModuleModel.bearerType === 'app'
            ? this.ticketModuleModel.phoneNumber
            : this.formatCardNumber(this.ticketModuleModel.cardNumber),
        email: this.ticketModuleModel.email,
        travellers: travellersString,
        addons: this.selectedAddons
          .map((a) => {
            const FIRST_CLASS_ADDON = a.addonType === 3;
            return FIRST_CLASS_ADDON ? `${totalTravellerCount} ${a.name}` : `${a.count} ${a.name}`;
          })
          .join(', '),
        zones: this.journeyName(
          this.model.priceModelId,
          this.model.priceId,
          this.cityAddonIds.concat(this.optionalCityAddonIds)
        ),
        numberOfTickets: this.numberOfTickets
      };
    },
    ...mapGetters({
      price: GET_PRICE,
      travellerTypes: GET_TRAVELLER_TYPES,
      vouchers: GET_VOUCHERS,
      numberOfTickets: GET_NUMBER_OF_TICKETS,
      amountDue: GET_AMOUNT_DUE,
      voucherAmountUsed: GET_VOUCHER_AMOUNT_USED,
      total: GET_TOTAL,
      discounts: GET_DISCOUNTS,
      selectedAddons: GET_SELECTED_ADDONS
    }),
    ...mapState({
      cityAddonIds: (state) => state.ticketModule.cityAddonIds,
      optionalCityAddonIds: (state) => state.ticketModule.optionalCityAddonIds,
      stopAreas: (state) => state.ticketModule.stopAreas,
      travellers: (state) => state.ticketModule.travellers,
      ticketOffersIssued: (state) => state.ticketOffersModule.ticketOffers.issued,
      ticketModuleModel: (state) => state.ticketModule.model,
      selectedOfferId: (state) => state.ticketOffersModule.selectedOfferId,
      allTicketOffers: (state) => state.ticketOffersModule.ticketOffers.offers,
      travellersModified: (state) => state.ticketModule.travellers.map((t) => t.travellerType)
    }),
    addons() {
      let addons = [];
      if (this.model.bikeAddon) {
        addons.push({
          addonType: 1,
          name: `${this.model.bikeAddon.count} ${this.model.bikeAddon.name}`
        });
      }

      if (this.model.firstClassAddon) {
        addons.push({ addonType: 3, name: `${this.model.firstClassAddon.name}` });
      }

      return addons;
    }
  }
};
</script>

<style lang="scss" scoped>
$font-color-headers: #4d4845;
$font-color-text: #6e6054;
.buy-ticket-confirm {
  h1 {
    margin: 0.55555556em 0em 0.5em !important;
  }
  p.description {
    margin: 1.25em 0em 1.875em;
    font-size: 1em !important;
  }

  .ticket-info {
    display: none;
    @media (min-width: 451px) {
      display: block;
    }
    table {
      width: 100%;
      @media (max-width: 576px) {
        table-layout: fixed;
      }

      thead {
        th {
          padding: 1.25em 1.125em 1.25em 0em;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: normal;
          color: #4d4845;
          @media (max-width: 576px) {
            font-size: 0.875em;
          }
        }
      }
      tbody {
        border-radius: 0.25em;
        background-color: #f7f6f2;
        vertical-align: top;
      }

      td {
        padding-top: 1.375em;
        font-size: 1em;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #4d4845;
        @media (max-width: 576px) {
          font-size: 0.875em;
        }

        span.bold {
          font-weight: bold;
        }
        span.stop-places,
        span.small {
          font-size: 0.875em;
        }
      }
      tr td:first-child,
      tr th:first-child {
        padding: 1.375 0em 1.25em 1.125em;
        border-top-left-radius: 0.375em;
        border-bottom-left-radius: 0.375em;
      }
      tr td:last-child,
      tr th:last-child {
        padding: 1.375em 1.125em 1.25em 0em;
        border-top-right-radius: 0.375em;
        border-bottom-right-radius: 0.375em;
      }
    }

    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;

    .ticket-confirm-header {
      font-family: 'Source sans pro' !important;
      margin-bottom: 0.9375em !important;
    }

    .journey {
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4d4845;
      margin-bottom: 0em;
    }

    .offer {
      line-height: 1.5;
      letter-spacing: normal;
      color: #4d4845;
      margin-bottom: 0em;
    }

    .ticket-traveller-list {
      display: inline;
      margin-left: 1.25em;
    }

    .price {
      line-height: 1.75;
      letter-spacing: normal;
      color: #6e6054;
    }
  }

  .mobile-ticket-info {
    display: none;
    background: #f7f6f2;
    border-radius: 0.25em;
    padding: 0.9375em;
    @media (max-width: 450px) {
      display: block;
    }
    .col-12 {
      margin-bottom: 0.25em;
    }
    .mobile-zone-wrapper {
      margin-bottom: -0.25em;
    }
    ul {
      margin-bottom: 0em !important;
    }
    .mobile-zone {
      text-align: start;
      font-size: 1em;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $font-color-headers;
    }
    .mobile-stop-points {
      font-size: 0.875em;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $font-color-text;
      margin-bottom: 0.375em;
    }
  }

  .checkbox-container {
    margin-top: 1em;
    margin-right: 0.3125em;
    float: right;
  }
}
</style>
