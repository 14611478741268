// actions
export const GET_JOURNEYS = 'GET_JOURNEYS';
export const GET_JOURNEYS_ERROR = 'GET_JOURNEYS_ERROR';
export const SET_JOURNEY_BY_PASSAGE_TYPE = 'SET_JOURNEY_BY_PASSAGE_TYPE';

// mutations
export const SET_JOURNEYS = 'SET_JOURNEYS';
export const SET_JOURNEY = 'SET_JOURNEY';
export const SET_JOURNEYS_ERROR = 'SET_JOURNEYS_ERROR';
export const CLEAR_JOURNEY_STATE = 'CLEAR_JOURNEY_STATE';

// getters
export const GET_JOURNEY_POSSIBLE_JOURNEYS = 'GET_JOURNEY_POSSIBLE_JOURNEYS';
export const GET_JOURNEY_POSSIBLE_JOURNEYS_OPTIONAL_CITIES = 'GET_JOURNEY_POSSIBLE_JOURNEYS_OPTIONAL_CITIES';
