<template>
  <div class="buy-card" aria-labelledby="buy-card-header">
    <router-link to="/" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>
    <h1 id="buy-card-header">{{ $t('BuyCard_Header') }}</h1>
    <p v-html="$t('BuyCard_Text')" />
    <buy-card-form></buy-card-form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import BuyCardForm from '../../components/buy-card-form/BuyCardForm';
import { CLEAR_CARD_ORDER_MODEL_STATE } from '../../store/actions/card-order-actions';
import globalMixin from '../../mixins/globalMixin';

export default {
  mixins: [globalMixin],
  components: {
    BuyCardForm
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'payment-checkout') {
      this.clearState();
    }

    next();
  },
  mounted() {
    this.scrollToTopWithOffset();
  },
  methods: {
    ...mapMutations({
      clearState: CLEAR_CARD_ORDER_MODEL_STATE
    })
  }
};
</script>

<style lang="scss" scoped>
.buy-card {
  h1 {
    margin: 0.5555555555555556em 0em 0.5em !important;
  }
  p {
    margin: 1.1764705882352942em 0em 2.1176470588235294em !important;
  }
}
</style>
