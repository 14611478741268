import i18n from '../../i18n/index';
export default {
  voucher: [
    {
      key: 'numeric',
      message: i18n.t('Voucher_Error_Numeric'),
      id: 'voucherNumeric'
    },
    {
      key: 'is8Or12Chars',
      message: i18n.t('Voucher_Error_8_or_12_Digits'),
      id: 'voucherChar'
    }
  ]
};
