import { required, maxLength } from 'vuelidate/lib/validators';

function numericLength(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  let regex = /^[0-9]{12}$/;
  return regex.test(value);
}

export default {
  cardNumber: {
    required,
    numericLength
  },
  name: {
    required,
    maxLength: maxLength(30)
  }
};
