import { required, maxLength, email, sameAs } from 'vuelidate/lib/validators';
import postalCodeFormat from '../../assets/validations/postal-code-format-validation';

export default {
  firstName: {
    required,
    maxLength: maxLength(50)
  },
  lastName: {
    required,
    maxLength: maxLength(50)
  },
  street: {
    required,
    maxLength: maxLength(255)
  },
  postalCode: {
    required,
    postalCodeFormat
  },
  city: {
    required,
    maxLength: maxLength(80)
  },
  email: {
    email,
    required,
    maxLength: maxLength(100)
  },
  acceptTerms: {
    sameAs: sameAs(() => true)
  }
};
