import axios from 'axios';
import cookieService from '../services/cookieService';
import router from '../router/mittkonto-router';
import store from '../store';

var axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

axiosInstance.interceptors.request.use(
  function(config) {
    let token = cookieService.getTokenFromCookie('StarJwtTokenCookieName');
    //if (!token) return config;

    if (token && token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    if (config.recaptchaAction) {
      return grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: config.recaptchaAction }).then(function(token) {
        config.headers['RecaptchaToken'] = token;
        return config;
      });
    } else {
      return config;
    }
  },
  function(err) {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    if (error.response) {
      if (error.response.status === 401) {
        cookieService.removeCookie('StarJwtTokenCookieName');
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
