// ?contactAction=Lead&crmguid=b932ce79-3b59-ea11-a811-000d3a385a1c&linkid=058b6d07-111b-4ef8-b838-18573a9a548b
<template>
  <div class="verify-email">
    <h1 v-if="state === 0">{{ $t('VerifyEmail_Header') }}</h1>
    <p v-if="state === 0">{{ $t('VerifyEmail_Body') }}</p>
    <st-button v-if="state === 0" medium primary @click="verifyEmail()" tabindex="0">{{ $t('VerifyEmail_Button') }}</st-button>

    <h1 v-if="state === 1">{{ $t('VerifyEmail_Header_OK') }}</h1>
    <p v-if="state === 1">{{ $t('VerifyEmail_Body_OK') }}</p>
    <st-button v-if="state === 1" medium primary @click="verifyEmail_OK()" tabindex="0">{{ $t('VerifyEmail_Button_OK') }}</st-button>

    <h1 v-if="state === 2">{{ $t('VerifyEmail_Header_BAD') }}</h1>
    <p v-if="state === 2">{{ $t('VerifyEmail_Body_BAD') }}</p>
    <st-button v-if="state === 2" medium primary @click="verifyEmail_BAD()" tabindex="0">{{ $t('VerifyEmail_Button_BAD') }}</st-button>
  </div>
</template>

<script>
import api from '../../../api';

export default {
  components: {},
  data() {
    return {
      model: {
        contactAction: '',
        crmguid: '',
        linkid: ''
      },
      state: 0
    };
  },
  created: function () {
    this.model.contactAction = this.$route.query.contactAction;
    this.model.crmguid = this.$route.query.crmguid;
    this.model.linkid = this.$route.query.linkid;
  },
  mounted() {
    document.getElementById('mittkonto').scrollIntoView();
  },
  methods: {
    verifyEmail: function () {
      let validateUserRequest = {
        crmguid: this.model.crmguid,
        linkid: this.model.linkid,
        contactAction: this.model.contactAction
      };

      api
        .post('mitt-konto/verify-account', validateUserRequest)
        .then((result) => {
          var success = result.data;
          if (success) {
            this.state = 1;
          } else {
            this.state = 2;
          }
        })
        .catch((error) => {
          console.log(error);
          this.state = 2;
        });
    },
    verifyEmail_OK: function () {
      this.$router.push('/konto');
    },
    verifyEmail_BAD: function () {
      this.verifyEmail();
    }
  }
};
</script>

<style lang="scss" scoped>
.verify-email {
  color: black;
  h1 {
    font-size: 2.25em !important;
  }
}
</style>