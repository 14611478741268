export default {
  data() {
    return {
      pointer: 0
    };
  },
  watch: {
    filteredItems() {
      this.pointerAdjust();
    }
  },
  methods: {
    isItemHighlighted(index) {
      return index === this.pointer;
    },
    addPointerElement() {
      if (this.filteredItems.length > 0) {
        this.addItem(this.filteredItems[this.pointer]);
      }
      this.pointerReset();
    },

    pointerForward() {
      if (this.pointer < this.filteredItems.length - 1) {
        this.pointer++;
      }
    },
    pointerBackward() {
      if (this.pointer > 0) {
        this.pointer--;
      }
    },
    pointerReset() {
      this.pointer = 0;
    },
    pointerAdjust() {
      if (this.pointer >= this.filteredItems.length - 1) {
        this.pointer = this.filteredItems.length ? this.filteredItems.length - 1 : 0;
      }
    },
    pointerSet(index) {
      this.pointer = index;
    }
  }
};
