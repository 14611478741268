<template>
  <div class="st-table-item-mobile-expanded full-width">
    <div class="item-body" @click="onRowClick(itemIndex, item.id)">
      <div v-for="(field, i) in fields" class="row" v-bind:key="i" v-show="!field.isToggle || isToggled">
        <slot :name="field.key" :item="item">
          <div class="col-5 bold">{{ field.label }}</div>
          <div class="col-7 text-right text">
            <slot :name="field.key + '-value'" :item="item">{{ formattedText(item, field) }}</slot>
          </div>
        </slot>
      </div>
    </div>
    <div class="item-footer" v-if="useToggle">
      <a @click="toggle(itemIndex)">
        <st-icon size="md" useSvg inline :name="isToggled ? 'up' : 'down'" :alt="isToggled ? $t('CardDetailsTicketList_Arrow_Up') : $t('CardDetailsTicketList_Arrow_Down')" />
        {{ isToggled ? 'Visa mindre' : 'Visa mer' }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'st-table-item-mobile-expanded',
  props: {
    item: { type: Object },
    itemIndex: { type: Number },
    fields: { type: Array },
    onToggle: { type: Function },
    formattedText: {
      type: Function
    },
    useToggle: {
      type: Boolean,
      default: true
    },
    rowClick: {
      type: Function
    }
  },
  data() {
    return {
      isToggled: false
    };
  },
  methods: {
    onRowClick(index, id) {
      if (this.useToggle) return;

      if (this.rowClick && id) {
        this.rowClick(id);
      }
    },
    toggle: function (itemId) {
      this.isToggled = !this.isToggled;
      if (this.onToggle) this.onToggle(itemId);
    }
  }
};
</script>

<style lang="scss" scoped>
.st-table-item-mobile-expanded:nth-of-type(odd) {
  background-color: #f9f8f5;
}

.st-table-item-mobile-expanded {
  background-color: #fff;
  margin: 0em;

  &.full-width {
    width: 100%;
  }

  .item-body {
    margin-left: 0;
    margin-right: 0;
    padding: 0.4375em 1em 2em;
    word-break: break-all;
    .row {
      padding: 0em;
      padding-top: 0.5625em;
    }
  }

  .item-footer {
    text-align: center;
    padding-bottom: 1.5625em;

    a {
      font-size: 1em;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4d4845;
    }
  }

  .bold {
    font-size: 1em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4d4845;
  }

  .text {
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4d4845;
    word-break: break-all;
  }
}
</style>
