<template>
  <div class="st-temp-autocomplete-input">
    <div
      :class="
        hasFocus
          ? 'st-temp-autocomplete-input-container st-temp-autocomplete-input-container--has-focus'
          : 'st-temp-autocomplete-input-container '
      "
    >
      <div
        :class="
          hasFocus
            ? 'st-temp-autocomplete-input-container__input st-temp-autocomplete-input-container__input--has-focus'
            : 'st-temp-autocomplete-input-container__input'
        "
      >
        <st-input
          :placeholder="placeholder"
          :id="id + 'Input'"
          @input="onInput"
          @keydownUp="onArrowKey('up')"
          @keydownDown="onArrowKey('down')"
          @keydownEnter="onEnter"
          :value="value"
          :focus="onFocus"
          :blur="onBlur"
          :tabindex="'0'"
          :required="required"
          :invalid="invalid"
          :keyup-esc="onEsc"
          :aria-describedby="ariaDescribedBy"
          aria-autocomplete="list"
          :aria-activedescendant="activedescendant"
          role="combobox"
          :aria-expanded="suggestionsVisible ? 'true' : 'false'"
          :aria-controls="id + 'AutocompleteListbox'"
        />
      </div>
    </div>

    <ul
      class="st-temp-autocomplete-input__suggestions"
      :id="id + 'AutocompleteListbox'"
      role="listbox"
      :aria-labelledby="id"
      v-show="suggestionsVisible"
      @mousedown.prevent
    >
      <li
        role="presentation"
        v-if="showListHeadline"
        class="st-temp-autocomplete-input__suggestions__head"
      >
        Hållplatser närmast angiven adress
      </li>
      <li
        v-for="(item, index) in autocompleteSuggestions"
        @click="click(item)"
        :key="item.id2"
        role="option"
        :id="id + 'Option-' + index"
        :aria-selected="index === focusIndex"
        :class="
          index === focusIndex
            ? 'st-temp-autocomplete-input__suggestions__item st-temp-autocomplete-input__suggestions__item--focused'
            : 'st-temp-autocomplete-input__suggestions__item'
        "
      >
        <div class="st-temp-autocomplete-input__suggestions__item__area">
          {{ item.name }}<span v-if="item.city">, {{ item.city }}</span>
          <div class="st-temp-autocomplete-input__suggestions__item__area__type">
            {{
              item.type === 'STOP_AREA' ? 'Hållplats' : item.type === 'POI' ? 'Sevärdhet' : 'Adress'
            }}
          </div>
        </div>
        <div v-if="item.distance > 0">{{ item.distance }} m.</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { debounce } from 'lodash';
export default {
  name: 'AutocompleteInput',
  data() {
    return {
      focusIndex: null,
      value: '',
      showSuggestions: false,
      selectedItem: {},
      selectionByClick: false,
      selectionByEnter: false,
      hasFocus: false,
      isAutoselect: this.autoSelect,
      hasSearchStorage: false
    };
  },
  computed: {
    focusedItem() {
      return this.autocompleteSuggestions[this.focusIndex];
    },
    activedescendant() {
      return this.focusIndex !== null ? this.id + 'Option-' + this.focusIndex : null;
    },
    suggestionsVisible() {
      return this.showSuggestions && this.autocompleteSuggestions.length > 0;
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    autocompleteSuggestions: {
      default: () => []
    },
    overrideValue: {
      type: String,
      default: ''
    },
    autoSelect: {
      type: Boolean,
      default: false
    },
    showListHeadline: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    ariaDescribedBy: {
      type: String
    }
  },
  watch: {
    overrideValue: function (newVal) {
      this.hasSearchStorage = true;
      this.value = newVal;
      this.$nextTick(() => (this.showSuggestions = false));
    },
    suggestionsVisible: function (bool) {
      this.$emit('suggestionsVisible', bool);
    }
  },
  methods: {
    onInput(val) {
      if (!this.selectionByClick && !this.selectionByEnter && !this.hasSearchStorage) {
        this.shouldDebounce(val);
      } else {
        this.hasSearchStorage = false;
        this.handleInput(val);
      }
    },
    shouldDebounce: debounce(function (val) {
      this.handleInput(val);
    }, 200),
    onFocus() {
      this.showSuggestions = true;
      this.selectionByClick = false;
      this.selectionByEnter = false;
      this.focusIndex = null;
      this.hasFocus = true;
    },
    handleInput(val) {
      this.focusIndex = null;
      this.showSuggestions = true;
      this.value = val;
      this.$emit('input', val);
    },
    onArrowKey(direction) {
      if (direction === 'up') {
        if (this.focusIndex > 0) this.focusIndex--;
        else this.focusIndex = this.autocompleteSuggestions.length - 1;
      } else if (direction === 'down') {
        if (this.focusIndex >= this.autocompleteSuggestions.length - 1) this.focusIndex = 0;
        else if (this.focusIndex === null) this.focusIndex = 0;
        else this.focusIndex++;
      }
    },
    onBlur() {
      this.showSuggestions = false;
      if (!this.selectionByClick && !this.selectionByEnter) this.setItem();
      this.hasFocus = false;
      this.focusIndex = null;
      this.$emit('blur');
    },
    onEnter() {
      this.selectionByEnter = true;
      this.isAutoselect = true;
      this.setItem();
      this.value = '';
    },
    setItem() {
      if (this.isAutoselect) {
        if (this.focusedItem) {
          this.selectedItem = this.focusedItem;
          this.select();
        }
      }
    },
    onEsc() {
      this.value = '';
      this.showSuggestions = false;
      this.$emit('esc');
    },
    click(item) {
      this.selectedItem = item;
      this.selectionByClick = true;
      this.select();
      this.value = '';
    },
    select() {
      this.value = this.selectedItem.name;
      this.$emit('select', this.selectedItem);
    },
    onKeydownDelete() {
      this.$emit('keydownDelete');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables.scss';
@import '../../scss/colors.scss';
@import '../../scss/functions.scss';
.st-temp-autocomplete-input {
  width: 100%;
  position: relative;
  &__suggestions {
    list-style: none;
    position: absolute;
    background-color: $white;
    width: inherit;
    z-index: 3;
    margin: 0;
    padding: 0;
    border: $border-width--thin solid $dark-grey;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-top: none;
    &__head,
    &__item {
      padding: $md;
      font-weight: $semi-bold;
      color: $black;
      &:not(:last-child) {
        border-bottom: $border-width--thin solid $light-grey;
      }
    }
    &__item {
      color: $dark-grey;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &--focused,
      &:hover {
        background-color: $dark-grey;
        color: #fff;
        cursor: pointer;
      }
      &__area {
        &__type {
          font-size: rem-calc(14);
          font-weight: $regular;
        }
      }
    }
  }
}
</style>
