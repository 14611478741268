import api from '../../api';
import campaignOrderState from '../states/campaign-order-state';
import * as constants from '../../models/constants';

import {
  SET_CAMPAIGN_JOURNEY_OFFER,
  GET_CAMPAIGN_JOURNEY,
  GET_CAMPAIGN_TICKET_OFFERS,
  IS_CAMPAIGN_JOURNEY_WITH_BANNER,
  GET_CAMPAIGN_TICKET_OFFERS_MODEL,
  SET_CAMPAIGN_TICKET_OFFERS,
  HAS_CAMPAIGN_OFFERS,
  GET_CAMPAIGN_CARD,
  SET_CAMPAIGN_TICKET_OFFERS_MODEL,
  SET_CAMPAIGN_TICKETOFFER_NAME,
  GET_CAMPAIGN_TICKETOFFER_NAME,
  IS_TRAVELLERS_FIXED
} from '../actions/campaign-offers-actions';
import { SET_JOURNEYS, SET_JOURNEY } from '../actions/journey-actions';
import { SET_SELECTED_OFFER_ID } from '../actions/ticket-offers-actions';

const state = {
  campaignTicketOffers: [],
  campaignTicketOffersModel: [],
  campaignTicketOfferName: []
};

const actions = {
  [SET_CAMPAIGN_JOURNEY_OFFER]: ({ commit, dispatch, state, getters }, id) => {
    if (!id) return;

    commit(SET_SELECTED_OFFER_ID, id);
    commit(SET_CAMPAIGN_TICKETOFFER_NAME, id);

    let campaignJourney = [];
    let journeyData = getters.GET_CAMPAIGN_JOURNEY(id);

    if (journeyData && Object.keys(journeyData).length !== 0) {
      campaignJourney.push(journeyData);
      commit(SET_JOURNEYS, campaignJourney);
      dispatch(SET_JOURNEY, id);
    }
  },
  [GET_CAMPAIGN_CARD]: ({ commit, state }) => {
    let offerIds = state.campaignTicketOffers.map(offer => offer.id);

    let data = {
      offerIds: offerIds
    };

    const url = 'campaign-offer/validate';
    return new Promise(resolve => {
      return api
        .post(url, data)
        .then(result => {
          commit(SET_CAMPAIGN_TICKET_OFFERS_MODEL, result.data);
          resolve(result);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          resolve();
        });
    });
  }
};

const mutations = {
  [SET_CAMPAIGN_TICKET_OFFERS]: (state, payload) => {
    if (payload && payload.offers) {
      let campaignOffer = payload.offers.filter(offer => offer.categoryType === 2);

      if (campaignOffer.length > 0) {
        state.campaignTicketOffers = campaignOffer;
      }
    }
  },
  [SET_CAMPAIGN_TICKET_OFFERS_MODEL]: (state, payload) => {
    for (let i = 0; i < payload.length; i++) {
      let campaignModelItem = campaignOrderState.getModel();

      if (Object.keys(payload).length !== 0) {
        campaignModelItem.img = payload[i].img;
        campaignModelItem.link = payload[i].readMorelink;
        campaignModelItem.color = payload[i].color;
        campaignModelItem.textColor = payload[i].textColor;
        campaignModelItem.id = payload[i].id;
      }

      let isOfferExisting = state.campaignTicketOffersModel.filter(model => model.id === payload[i].id)[0];
      if (!isOfferExisting) {
        state.campaignTicketOffersModel.push(campaignModelItem);
      }
    }
  },
  [SET_CAMPAIGN_TICKETOFFER_NAME]: (state, id) => {
    if (!id) return;
    let campaignModelItem = state.campaignTicketOffersModel.filter(model => model.id === id)[0];
    if (campaignModelItem) {
      state.campaignTicketOfferName = campaignModelItem.name;
    }
  }
};

const getters = {
  [IS_CAMPAIGN_JOURNEY_WITH_BANNER]: (state, getters, rootState) => {
    let campaignOffer = state.campaignTicketOffers.filter(offer => offer.id === rootState.ticketModule.offerId)[0];
    if (campaignOffer) {
      let hasCampaignOfferBanner = state.campaignTicketOffersModel.filter(offer => offer.id === campaignOffer.id)[0];
      if (hasCampaignOfferBanner) {
        return true;
      }
      return false;
    }
    return false;
  },
  [HAS_CAMPAIGN_OFFERS]: (state, getters, rootstate) => {
    let availableCampaignOffer = rootstate.ticketOffersModule.ticketOffers.offers.filter(offer => offer.categoryType === 2)[0];
    return !availableCampaignOffer ? false : availableCampaignOffer && state.campaignTicketOffersModel.length < 1 ? false : true;
  },
  [GET_CAMPAIGN_TICKET_OFFERS_MODEL]: state => {
    for (let i = 0; i < state.campaignTicketOffers.length; i++) {
      let campaignTicketOffer = state.campaignTicketOffers[i];

      for (let j = 0; j < state.campaignTicketOffersModel.length; j++) {
        let campaignModelItem = state.campaignTicketOffersModel[j];

        if (campaignTicketOffer.id === campaignModelItem.id) {
          if (Object.keys(campaignTicketOffer.name).length !== 0) {
            campaignModelItem.name = campaignTicketOffer.name.sv;
          }

          if (Object.keys(campaignTicketOffer.priceModels).length !== 0) {
            let campaignPriceModel;

            for (var key in campaignTicketOffer.priceModels) {
              if (campaignTicketOffer.priceModels[key]) {
                campaignPriceModel = campaignTicketOffer.priceModels[key];
                break;
              }
            }

            if (Object.keys(campaignPriceModel).length !== 0) {
              campaignModelItem.priceModelId = campaignPriceModel.id;

              if (campaignPriceModel.prices) {
                campaignPriceModel.prices.map(val => {
                  if (val.amountIncludingVat) {
                    campaignModelItem.amountIncludingVat = val.amountIncludingVat;
                  }

                  if (val.id) {
                    campaignModelItem.priceId = val.id;
                  }

                  if (val.name.sv) {
                    campaignModelItem.area = val.name.sv;
                  }
                });
              }
            }
          }
        }
      }
    }
    return state.campaignTicketOffersModel;
  },
  [GET_CAMPAIGN_JOURNEY]: state => id => {
    for (let i = 0; i < state.campaignTicketOffersModel.length; i++) {
      let campaignModelItem = state.campaignTicketOffersModel[i];
      if (campaignModelItem.id === id) {
        let possibleJourneys = [];
        let offerId = '';

        possibleJourneys.push({
          circle: null,
          priceDetails: {
            cityAddons: null,
            price: campaignModelItem.amountIncludingVat ? campaignModelItem.amountIncludingVat : null,
            priceId: campaignModelItem.priceId ? campaignModelItem.priceId : '',
            priceModelId: campaignModelItem.priceModelId ? campaignModelItem.priceModelId.toLowerCase() : ''
          },
          properties: {
            denmarkArea: null,
            direction: null,
            passageType: null,
            scaniaArea: null,
            shortName: null,
            shortNameDirection: null
          },
          travelAreaIds: process.env.VUE_APP_SYSTEM.valueOf() == 'bt' ? Array.of(constants.PRICE_ID_COUNTYBT) : campaignModelItem.priceId ? Array.of(campaignModelItem.priceId) : []
        });

        offerId = campaignModelItem.id ? campaignModelItem.id : '';

        return {
          possibleJourneys,
          offerId
        };
      }
    }
  },
  [GET_CAMPAIGN_TICKET_OFFERS]: state => {
    return state.campaignTicketOffers;
  },
  [GET_CAMPAIGN_TICKETOFFER_NAME]: state => {
    return state.campaignTicketOfferName;
  },
  [IS_TRAVELLERS_FIXED]: (state, getters, rootState) => {
    if (!getters.IS_CAMPAIGN_JOURNEY_WITH_BANNER) return false;
    let travellers = rootState.ticketModule.travellers;
    for (let i = 0; i < travellers.length; i++) {
      let traveller = travellers[i];
      if (Object.keys(traveller).length !== 0) {
        if (traveller.travellerType.min === traveller.travellerType.max) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
