<template>
  <form @submit.prevent.stop="submit" class="login-data-form-email">
    <p>{{ $t('LoginDataForm_Change_LoginData_Text') }}</p>

    <st-form-group id="email" :label="$t('Edit_MK_Epost_Label')" required :validation="$v.model.newEmail" :validationErrors="validationErrors.newEmail">
      <template #default="{ ariaDescribedBy }">
        <st-input ref="editEmail" id="email" :aria-describedby="ariaDescribedBy" required v-model="model.newEmail" :invalid="$v.model.newEmail.$error" autocomplete="email" :blur="$v.model.newEmail.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>
    <st-form-group id="emailRepeat" :label="$t('Edit_MK_Epost_Verify_Label')" required :validation="$v.model.newEmailRepeat" :validationErrors="validationErrors.newEmailRepeat">
      <template #default="{ ariaDescribedBy }">
        <st-input id="emailRepeat" :aria-describedby="ariaDescribedBy" required v-model="model.newEmailRepeat" :invalid="$v.model.newEmailRepeat.$error" autocomplete="email" :blur="$v.model.newEmailRepeat.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>
    <div class="edit-form-btn-wrapper">
      <st-button medium class="cancel" :disabled="isLoading" @click="cancel" tabindex="0">Avbryt</st-button>
      <st-button medium primary class="submit" :disabled="$v.$invalid || isLoading" type="submit" tabindex="0">Spara</st-button>
    </div>
    <st-error v-if="error" :error="error" />
  </form>
</template>

<script>
import api from '../../api';
import { mapGetters } from 'vuex';
import { GET_ACCOUNT } from '../../store/actions/account-actions';

import LoginDataFormValidations from './login-data-form-email-validations';
import LoginDataFormValidationErrors from './login-data-form-email-validation-errors';

export default {
  data() {
    return {
      model: {
        newEmail: '',
        newEmailRepeat: '',
        currentPassword: '',
        newPassword: ''
      },
      isLoading: false,
      error: undefined,
      validationErrors: LoginDataFormValidationErrors
    };
  },
  validations: {
    model: LoginDataFormValidations
  },
  computed: {
    ...mapGetters({
      account: GET_ACCOUNT
    })
  },
  mounted() {
    this.$refs.editEmail.$el.focus();
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    submit() {
      let data = { ...this.model, userId: this.account.userId, currentEmail: this.account.email };

      this.isLoading = true;
      this.error = undefined;

      let url = 'mitt-konto/update-password';
      api
        .put(url, data)
        .then((result) => {
          document.cookie = 'StarJwtTokenCookieName=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
          this.$router.go();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            error.message = this.$t('Error_Change_Password_Unauthorized');
          }
          this.error = error;
          this.isLoading = false;
          this.$nextTick(() => {
            this.$refs.editEmail.$el.focus();
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-form-btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .st-button {
    min-width: 11.25em;
    @media (max-width: 767px) {
      min-width: auto;
      flex: 1;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
    }
  }
}
</style>
