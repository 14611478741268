import api from '../api';
import paymentCallbackService from '../services/paymentCallbackService';

export default {
  initiateConsumerSession(email) {
    return api.post('payments/consumer', { email }).then((result) => {
      return result;
    });
  },
  createPaymentOrder(orderType, orderId, description) {
    const url = window.location.href.substring(0, window.location.href.indexOf('#') + 2);
    return api
      .post('payments/payment-order', { orderType, orderId, description, url, userAgent: navigator.userAgent, language: navigator.language })
      .then((result) => {
        let data = result.data;

        return {
          paymentOrderId: data.result.paymentOrderId,
          data: data
        };
      });
  },
  capturePaymentOrder(orderType, orderId, paymentOrderId, description, callback, instrument) {
    const paymentCaptureUrl = `payments/payment-order/capture`;

    let paymentCaptureRequest = {
      paymentOrderId,
      orderType,
      orderId,
      description,
      instrument
    };

    return api.post(paymentCaptureUrl, paymentCaptureRequest).then((paymentCaptureResponse) => {
      // returnera response från payex service

      // om vi skickar med callback, kör denna innan vi returnerar response från payex service
      if (callback) {
        return paymentCallbackService[callback](orderId, paymentCaptureResponse.data).then((callbackResponse) => {
          return callbackResponse;
        });
      } else {
        return paymentCaptureResponse;
      }
    });
  }
};
