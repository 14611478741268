<template>
  <st-modal class="addon-picker-modal" :onClose="submit" large tabindex="-1">
    <div slot="header" class="st-modal-header">
      <h1 tabindex="-1" id="addon-picker-header">{{ $t('AddonPickerModal_Header') }}</h1>
    </div>
    <div slot="body" class="st-modal-body-default">
      <addon-picker ref="addonPicker" :tabindex="tabindex"></addon-picker>
    </div>
    <div slot="footer" class="st-modal-footer-default">
      <div class="st-modal-footer-content">
        <st-button @click="submit(false)" :tabindex="tabindex" medium block>{{ $t('AddonPickerModal_Button_Cancel') }}</st-button>
        <st-button @click="submit(true)" :tabindex="tabindex" :disabled="!hasModelChanged" medium block primary>{{ $t('AddonPickerModal_Button_Ok') }}</st-button>
      </div>
    </div>
  </st-modal>
</template>

<script>
import AddonPicker from './AddonPicker';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { GET_SELECTED_ADDONS, SET_SELECTED_ADDONS, RESET_TICKET_ADDONS } from '../../../store/actions/ticket-actions';
import { GET_AVAILABLE_ADDONS } from '../../../store/index';
import globalFunctionsService from '../../../services/globalFunctionsService';
import globalMixins from '../../../mixins/globalMixin';

export default {
  mixins: [globalMixins],

  props: {
    close: {
      type: Function
    },
    tabindex: {
      type: String,
      default: '0'
    }
  },
  components: {
    AddonPicker
  },
  mounted() {
    this.originalModel = globalFunctionsService.deepClone(this.selectedAddons);
    document.getElementById('addon-picker-header').focus();
  },
  data() {
    return {
      originalModel: [],
      setToggleFocus: false
    };
  },
  computed: {
    ...mapGetters({
      selectedAddons: GET_SELECTED_ADDONS,
      avaliableAddons: GET_AVAILABLE_ADDONS
    }),
    hasModelChanged() {
      return JSON.stringify(this.originalModel) !== JSON.stringify(this.selectedAddons);
    }
  },
  methods: {
    ...mapMutations({
      setAddons: SET_SELECTED_ADDONS
    }),
    ...mapActions({
      resetTicketAddons: RESET_TICKET_ADDONS
    }),
    submit(value) {
      if (!value) {
        this.resetTicketAddons(this.originalModel);
      }

      if (this.close) this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.addon-picker-modal {
  h1 {
    font-size: 1.9375em !important;
  }
  .st-modal-header {
    padding: 2em 2.25em;
  }

  .st-modal-body-default {
    @media (max-width: 767px) {
      padding: 0em 1em !important;
    }
  }

  .st-modal-footer-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    & > * {
      &:first-child {
        margin-right: 1em !important;
        @media (max-width: 767px) {
          margin-right: 0 !important;
        }
      }
      &:last-child {
        @media (max-width: 767px) {
          margin-top: 15px !important;
        }
      }
    }
  }
}
</style>
