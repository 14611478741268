<template>
  <div class="card-registration-form">
    <form @submit.prevent.stop="submit">
      <st-form-group required :validation="$v.model.cardNumber" :validationErrors="validationErrors.cardNumber">
        <template v-slot:label>
          <label for="cardNumberInput">{{ $t('CardNumber_Label') }}</label>
          <st-icon ref="cardInfoIcon" class="card-info" :alt="$t('Information_Icon_Clickable_Alt_Text')" isHoverable name="info" size="sm" useSvg inline @click.native="showCardInfoModal = true" @keypress.enter.prevent.native="showCardInfoModal = true" tabindex="0" />
        </template>
        <template v-slot:default="{ ariaDescribedBy }">
          <card-number-input id="cardNumberInput" :ariaDescribedBy="ariaDescribedBy" v-model="model.cardNumber" @keypress.enter.prevent.native="onKeyEnterSubmit" :blur="$v.model.cardNumber.$touch" :placeholder="$t('CardNumber_Placeholder')" tabindex="0"></card-number-input>
        </template>
      </st-form-group>

      <st-form-group id="card-name" :label="$t('CardRegistrationForm_Name_Label')" required :validation="$v.model.name" :validationErrors="validationErrors.name">
        <template #default="{ ariaDescribedBy }">
          <st-input id="card-name" :aria-describedby="ariaDescribedBy"  :placeholder="$t('CardRegistrationForm_Name_PlaceHolder')" @keypress.enter.prevent.native="onKeyEnterSubmit" required v-model="model.name" :invalid="$v.model.name.$error" :blur="$v.model.name.$touch" tabindex="0"></st-input>
        </template>
      </st-form-group>

      <st-button medium block primary type="submit" :disabled="$v.$invalid || isLoading" tabindex="0">{{ $t('CardRegistrationForm_Button_Submit') }}</st-button>

      <st-error v-if="error" :error="error" :alt="$t('Error_BuyTicket_Alt_Text')" />
    </form>

    <card-info-modal v-if="showCardInfoModal" :close="onCardInfoModalClose" />
  </div>
</template>

<script>
import CardRegistrationFormValidations from './card-registration-form-validations';
import CardRegistrationFormValidationErrors from './card-registration-form-validation-errors';

import CardNumberInput from '../CardNumberInput';
//import CardInfoModal from '../CardInfoModal.vue';

import { ADD_CARD } from '../../store/actions/card-actions';
import { mapActions } from 'vuex';

export default {
  components: {
    CardNumberInput,
    CardInfoModal: () => import('../CardInfoModal.vue')
  },
  data() {
    return {
      model: {
        cardNumber: '',
        name: ''
      },
      validationErrors: CardRegistrationFormValidationErrors,
      isLoading: false,
      showCardInfoModal: false,
      error: undefined
    };
  },
  validations: {
    model: CardRegistrationFormValidations
  },
  mounted() {
    document.getElementById('cardNumberInput').focus();
  },
  methods: {
    ...mapActions({
      addCard: ADD_CARD
    }),
    onKeyEnterSubmit() {
      if (this.$v.$invalid || this.isLoading) return;
      this.submit();
    },
    submit() {
      this.isLoading = true;
      this.error = undefined;

      let vm = this;
      grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'cardregistrationform' }).then(function (token) {
        vm.model.recaptchaToken = token;
        vm.addCard(vm.model)
          .then((result) => {
            if (result.status === 204) {
              vm.error = {
                message: vm.$t('Error_CardNotFound')
              };
              vm.isLoading = false;
              vm.$nextTick(() => {
                document.getElementById('cardNumberInput').focus();
              });
            } else {
              vm.$router.push('/');
              vm.isLoading = false;
            }
          })
          .catch((error) => {
            vm.error = error;
            vm.isLoading = false;
            vm.$nextTick(() => {
              document.getElementById('cardNumberInput').focus();
            });
          });
      });
    },
    onCardInfoModalClose() {
      this.showCardInfoModal = false;
      this.$refs.cardInfoIcon.$el.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.st-icon.card-info {
  background-size: 1.25em 1.25em;
  width: 1.25em !important;
  height: 1.25em !important;

  padding-left: 0.1875em;
  margin-bottom: 0.25em;
}
</style>
