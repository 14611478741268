<template>
  <div id="BaseMap" tabindex="-1" :class="customClass" :title="$t('GoogleMap_Iframe_Title')">
    <div v-if="mapLoader" class="mapLoader"><st-spinner /></div>
    <div class="map" :id="id" tabindex="-1"></div>
  </div>
</template>

<script>
import { GET_TRAVEL_AREAS } from '../../../store/actions/travel-area-actions';
import { GET_MAP_DETAILS_STATE, UPDATE_MAP_DETAILS } from '../../../store/actions/map-details-actions';
import { GET_STOP_AREAS_FROM_SOK_RESA } from '../../../store';
/* global google */
import GMaps from 'gmaps';
import { mapGetters, mapActions } from 'vuex';

export default {
  beforeMount() {
    this.getCords().then(() => {
      this.detailsLoaded = true;
      this.update();
    });
  },
  props: {
    showBiggerMap: {
      type: Function
    },
    controls: {
      type: Array
    },
    id: { default: 'map' },
    isFullScreen: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      map: undefined,
      startCoordinates: JSON.parse(process.env.VUE_APP_START_COORDINATES),
      mapLoader: false,
      detailsLoaded: false,
      mapLoadedError: false,
      hej: document.querySelector('.gm-style')
    };
  },
  mounted: function () {
    if (!this.stopAreasFromSokResa.length) this.initMap(this.startCoordinates.lat, this.startCoordinates.lng, 7);
  },
  computed: {
    ...mapGetters({
      details: GET_MAP_DETAILS_STATE,
      stopAreasFromSokResa: GET_STOP_AREAS_FROM_SOK_RESA
    })
  },
  methods: {
    ...mapActions({
      getCords: GET_TRAVEL_AREAS,
      update: UPDATE_MAP_DETAILS
    }),
    initMap: function (lat, lng, zoom, reTry = 0) {
      // Sometimes it tries render map before google api script is loaded
      if (reTry <= 3 && (!window.google || !google.maps)) {
        window.setTimeout(() => {
          reTry++;
          this.initMap(lat, lng, zoom, reTry);
        }, 1000);
        return;
      }

      let _ = this;
      this.map = new GMaps({
        el: '#' + _.id,
        lat: lat,
        lng: lng,
        zoom: zoom,
        tilesloaded: () => {
          var controlImages = document.querySelectorAll('#map img');
          var controlLinks = document.querySelectorAll('#map a');
          let hej = document.querySelector('.gm-style').children[0].setAttribute('tabindex', '-1');

          if (controlImages.length > 0) {
            controlImages.forEach((img) => {
              img.alt = 'Google maps ikoner';
              img.setAttribute('tabindex', '-1');
            });
          }
          if (controlLinks.length > 0) {
            controlLinks.forEach((link) => {
              link.setAttribute('tabindex', '-1');
            });
          }
        },

        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        scaleControl: false,
        zoomControl: false
      });

      if (this.controls && this.controls.length > 0) {
        for (let c = 0; c < this.controls.length; c++) {
          this.map.addControl({
            position: this.controls[c].position,
            content: this.controls[c].content,
            style: this.controls[c].style,
            events: this.controls[c].events
          });
        }
      }
    },

    redrawMap: function (object) {
      if (!this.detailsLoaded) {
        this.mapLoader = true;
        return;
      } else {
        this.mapLoader = false;
      }
      // Detta ska inte hända, men händer
      if (!this.map) {
        this.initMap(this.startCoordinates.lat, this.startCoordinates.lng, 7);
      }

      this.map.removePolygons();
      this.map.removeMarkers();
      if (object.polygons && object.polygons.length > 0) {
        this.drawPolygons(object.polygons);
      }

      if (object.circles && object.circles.length > 0) {
        for (let c = 0; c < object.circles.length; c++) {
          this.drawCircles(object.circles[c]);
        }
      }

      if (object.markers && object.markers.length > 0) {
        for (let m = 0; m < object.markers.length; m++) {
          this.drawMarkers(m, object.markers[m]);
        }
      }

      if (object.focus && object.focus.length > 0) {
        this.adjustFocus(object.focus);
        if (!this.isFullScreen) {
          this.adjustZoom(object.zoom);
        }
      }

      if (!object.hasPossibleJourney) {
        this.map.panTo(new google.maps.LatLng(this.startCoordinates.lat, this.startCoordinates.lng));
        this.map.setZoom(7);
      }
    },

    drawPolygons: function (path) {
      this.map.drawPolygon({
        paths: path,
        strokeColor: '#4d4845',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#ffcc00',
        fillOpacity: 0.3
      });
    },

    drawCircles: function (circle) {
      this.map.drawCircle({
        lat: circle.origo[0].latitude,
        lng: circle.origo[0].longitude,
        radius: (circle.diameter * 1000) / 2,
        fillColor: '#ffcc00',
        fillOpacity: 0.3,
        strokeWeight: 1,
        strokeColor: '#4d4845',
        strokeOpacity: 0.3
      });
    },

    drawMarkers: function (index, marker) {
      let number = index + 1;
      this.map.addMarker({
        lat: marker.latitude,
        lng: marker.longitude,
        title: marker.title,
        icon: {
          url: `${process.env.VUE_APP_BASE_URL}assets/icons/${process.env.VUE_APP_SYSTEM}/zone-pin.svg`,
          labelOrigin: new google.maps.Point(15, 11),
          scaledSize: new google.maps.Size(30, 30)
        },
        label: {
          text: number.toString(),
          color: '#f9f8f5',
          fontFamily: 'Source Sans Pro, sans-serif'
        }
      });
    },

    adjustFocus: function (mapBounds) {
      let map = this.map;
      setTimeout(function () {
        map.fitLatLngBounds(mapBounds);
      }, 200);
    },

    adjustZoom: function (zoomLevels) {
      let map = this.map;
      setTimeout(function () {
        let zoom = map.getZoom() + zoomLevels;
        map.setZoom(zoom);
      }, 200);
    }
  },
  watch: {
    details: {
      handler: function (object) {
        this.redrawMap(object);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
#BaseMap {
  position: relative;
  max-width: 100%;
  height: 15em;
}

.large-map {
  height: 37.875em !important;
}

.map {
  max-width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.3125em;
}

.mapLoader {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(232, 232, 232, 0.5);
  z-index: 999;
}
</style>
