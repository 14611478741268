<template>
  <div v-if="!ticketOffersNamesLoaded" class="loaderWrapper">
    <st-spinner :loadingMessage="'Sidan laddar'" />
  </div>
  <div v-else class="card-details-ticket-list">
    <div class="card-details-list-overlay" v-if="isDisabled"></div>
    <div class="d-sm-none">
      <h2>Biljetter</h2>

      <st-table-mobile :items="ticketList" :fields="ticketMobileFields" useToggle>
        <template slot="offerId-value" slot-scope="{ item }">{{ offerName(item.offerId) }}</template>
        <template slot="activatedStatus-value" slot-scope="{ item }">
          <st-status v-if="item.status !== 2" :status="getCardTicketStatus(item.status)" />
          <st-button class="mobile-btn" small v-else @click="openActivateTicketModal(item)">{{ $t('CardDetailsTicketList_Button_ActivateTicket') }}</st-button>
        </template>
        <template slot="travellers-value" slot-scope="{ item }">
          <traveller-type-list class="ticket-traveller-list" :travellers="item.travellers"></traveller-type-list>
        </template>
        <template slot="zone-value" slot-scope="{ item }">
          <span>{{ item.zone }}</span>
          <span style="padding-left: 0.3125em; word-break: break-word" v-if="item.numberOfStopPoints > 0">({{ item.stopPoints }})</span>
        </template>
        <template slot="amount-value" slot-scope="{ item }">
          <span>{{ item.amount | fixedDecimals }} SEK</span>
        </template>
        <template slot="addons-value" slot-scope="{ item }">
          <div v-if="item.addons.length < 1">-</div>
          <div v-else v-for="(addon, index) in item.addons" :key="index">
            <st-icon :name="getAddonIconByType(addon.addonType, item.id)" :alt="getAddonAltText(addon.addonType, item.id)" class="traveller-type-icon" style="margin-top: -0.125em" useSvg inline></st-icon>
            {{ addon.name }}
          </div>
        </template>
        <template slot="rePurchase-value" slot-scope="{ item }">
          <re-purchase-ticket name="rePurchase" :ticketId="item.ticketId" class="" />
        </template>
      </st-table-mobile>
    </div>
    <div class="d-none d-sm-block">
      <st-table :items="ticketList" :fields="ticketFields" doubleLines :cellHeight="2.8125" :columnPadding="'0em 1.5em'">
        <template slot="offerId" slot-scope="{ item }">{{ offerName(item.offerId) }}</template>
        <template slot="activatedStatus" slot-scope="{ item, index }">
          <div class="d-flex justify-content-between">
            <st-status v-if="item.status !== 2" :status="getCardTicketStatus(item.status)" />
            <st-button v-else :disabled="isDisabled" small @click="openActivateTicketModal(item, index)" :tabindex="tabindex">{{ $t('CardDetailsTicketList_Button_ActivateTicket') }}</st-button>
          </div>
        </template>
        <template slot="info" slot-scope="{ item, index }">
          <st-icon class="info-icon" :class="!buyAndSendUrl ? 'align-right' : ''" size="md" isHoverable inline useSvg name="info" @click.native="openTicketInfoModal(item, index)" @keypress.enter.native="openTicketInfoModal(item, index)" :alt="$t('Information_Icon_Clickable_Alt_Text')" :disabled="isDisabled" :tabindex="tabindex" />
        </template>
        <template slot="rePurchase" slot-scope="{ item }">
          <re-purchase-ticket name="rePurchase" :ticketId="item.ticketId" class="align-right" />
        </template>
        <template slot="activatedDate" slot-scope="{ item }">
          <span class="doubleLine">{{ ticketValidity(item) }}</span>
        </template>
      </st-table>
    </div>
    <activate-ticket-modal v-if="showActivateTicketModal" :close="onCloseActivateTicketModal" :error="activateTicketError" :model="selectedTicket" />
    <ticket-info-modal v-if="showTicketInfoModal" :close="onCloseTicketInfoModal" :model="selectedTicket" />
  </div>
</template>

<script>
import api from '../../api';
import { mapGetters, mapActions, mapState } from 'vuex';
import { ACTIVATE_CARD_TICKET } from '../../store/actions/card-actions';

import mobileDetection from '../../services/mobileDetectionService';
import dayjs from 'dayjs';
import TravellerTypeList from '../TravellerTypeList';
import RePurchaseTicket from '../RePurchaseTicket';
import ActivateTicketModal from '../ActivateTicketModal.vue';
import TicketInfoModal from './TicketInfoModal.vue';

import * as constant from '../../constants.js';

import 'dayjs/locale/sv';
import * as dayjsPluginUTC from 'dayjs/plugin/utc';
dayjs.extend(dayjsPluginUTC);
dayjs.locale('sv');
import { GET_BUYANDSEND_URL } from '../../store/actions/base-actions';
import globalMixins from '../../mixins/globalMixin.js';
export default {
  mixins: [globalMixins],
  components: {
    TravellerTypeList,
    ActivateTicketModal,
    TicketInfoModal,
    RePurchaseTicket
  },
  props: ['id'],
  data() {
    return {
      ticketInfoIconIndex: 0,
      selectedTicket: {},
      showActivateTicketModal: false,
      showTicketInfoModal: false,
      activateTicketError: undefined,
      ticketFields: [
        { label: this.$t('TicketList_Header_Zone'), isArray: true, fields: [{ key: 'zone' }, { key: 'stopPoints' }] },
        { label: this.$t('TicketList_Header_Offer'), key: 'offerId' },
        { label: this.$t('TicketList_Header_Status'), isArray: true, fields: [{ key: 'activatedStatus' }, { key: 'activatedDate' }] },
        { key: 'info' },
        { key: 'rePurchase' }
      ],
      ticketMobileFields: [
        {
          key: 'ticketId',
          label: this.$t('TicketList_Header_TicketId')
        },
        {
          key: 'zone',
          label: this.$t('TicketList_Header_Zone')
        },
        {
          key: 'offerId',
          label: this.$t('TicketList_Header_Offer')
        },
        {
          key: 'activatedStatus',
          label: this.$t('TicketList_Header_Status')
        },
        {
          isToggle: true,
          key: 'createdDate',
          label: this.$t('TicketInfo_Label_Created')
        },
        {
          isToggle: true,
          key: 'travellers',
          label: this.$t('TicketInfo_Label_Travellers')
        },
        {
          isToggle: true,
          key: 'addons',
          label: this.$t('TicketInfo_Label_Addons')
        },
        {
          isToggle: true,
          key: 'activeTo',
          label: this.$t('TicketInfo_Label_Valid')
        },
        {
          isToggle: true,
          key: 'amount',
          label: this.$t('TicketInfo_Label_Price')
        },
        { key: 'rePurchase' }
      ],
      tabindex: '0'
    };
  },
  computed: {
    ...mapState({
      card: (state) => state.cardModule.selectedCard
    }),
    ...mapGetters({
      buyAndSendUrl: GET_BUYANDSEND_URL
    }),
    useMobileTable() {
      return mobileDetection.isMobile();
    },
    ticketList() {
      if (!this.card) return;

      let tickets = [];
      for (let i = 0; i < this.card.tickets.length; i++) {
        const ticket = this.card.tickets[i];

        let addons = [];
        if (ticket.bikeAddons.length) {
          for (let j = 0; j < ticket.bikeAddons.length; j++) {
            const bikeAddon = ticket.bikeAddons[j];
            addons.push({ addonType: 1, name: `${bikeAddon.count} ${bikeAddon.name}` });
          }
        }

        if (ticket.firstClassAddons.length) {
          for (let j = 0; j < ticket.firstClassAddons.length; j++) {
            const firstClassAddon = ticket.firstClassAddons[j];
            addons.push({ addonType: 3, name: `${firstClassAddon.name}` });
          }
        }

        var stopPoints = '';
        var numberOfStopPoints = 0;
        if (ticket.stopPoints !== null) {
          stopPoints = ticket.stopPoints.map((e) => e.name).join(', ');
          numberOfStopPoints = ticket.stopPoints.length;
        }

        tickets.push({
          createdDate: this.dateTimeFormat(ticket.created),
          zone: this.journeyName(ticket.priceModelId, ticket.priceId, ticket.cityAddons, ticket.offerId),
          stopPoints: stopPoints,
          numberOfStopPoints: numberOfStopPoints,
          status: ticket.isSeasonTicket ? 7 : ticket.status,
          blocked: ticket.blocked,
          ticketId: ticket.ticketId,
          activeFromDateShort: this.dateShortFormat(ticket.activated),
          activeFromDate: this.dateFormat(ticket.activated),
          activeTo: this.dateTimeFormat(this.zeroHourCorrection(ticket.activeTo)),
          activeToDateShort: this.dateShortFormat(this.zeroHourCorrection(ticket.activeTo)),
          activeToDate: this.dateFormat(this.zeroHourCorrection(ticket.activeTo)),
          activationIntervalTo: this.dateTimeFormat(ticket.activationIntervalTo),
          addons: addons,
          travellers: ticket.travellers,
          amount: ticket.amount,
          offerId: ticket.offerId,
          priceId: ticket.priceId,
          priceModelId: ticket.priceModelId,
          ticketType: ticket.ticketType
        });
      }

      return tickets;
    },
    isDisabled() {
      if (!this.card.isActive) {
        this.tabindex = '-1';
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions({
      activateTicket: ACTIVATE_CARD_TICKET
    }),
    openActivateTicketModal(item, index) {
      this.selectedTicket = item;
      this.ticketInfoIconIndex = index;
      this.activateTicketError = undefined;

      this.showActivateTicketModal = true;
    },
    onCloseActivateTicketModal(result) {
      if (result) {
        let data = {
          cardNumber: this.card.cardNumber,
          ticketId: this.selectedTicket.ticketId
        };

        this.activateTicket(data)
          .then((result) => {
            this.showActivateTicketModal = false;
            this.$ga.event(constant.EVENT_CATEGORY_ACTIVATE_TICKET, constant.EVENT_ACTION_CONFIRM_ACTIVATE_TICKET);
            this.setInfoIconFocus();
          })
          .catch((error) => {
            this.activateTicketError = error;
            this.setInfoIconFocus();
          });
      } else {
        this.showActivateTicketModal = false;
        this.setInfoIconFocus();
      }
    },
    openTicketInfoModal(item, index) {
      if (this.isDisabled) return;
      this.selectedTicket = item;
      this.ticketInfoIconIndex = index;
      this.showTicketInfoModal = true;
    },
    onCloseTicketInfoModal(result) {
      this.showTicketInfoModal = false;
      this.setInfoIconFocus();
    },
    setInfoIconFocus() {
      let infoIconElements = [...document.querySelectorAll('.info-icon')];
      let findInfoIcon = infoIconElements.find((icon, index) => index === this.ticketInfoIconIndex);
      this.$nextTick(() => {
        findInfoIcon.focus();
      });
    },
    dateTimeFormat(d) {
      if (!d) return '-';
      return `${dayjs(d).format('D MMM, YYYY')} kl. ${dayjs(d).format('HH:mm')}`;
    },
    dateShortFormat(d) {
      if (!d) return '-';
      return `${dayjs(d).format('D MMM')}`;
    },
    dateFormat(d) {
      if (!d) return '-';
      return `${dayjs(d).format('D MMM, YYYY')}`;
    },
    zeroHourCorrection(d) {
      if (!d) return d;
      var dd = dayjs(d);
      if (dd.hour() === 0 && dd.minute() === 0) {
        return dd.subtract(1, 'minutes').toDate();
      }
      return d;
    },
    ticketValidity(item) {
      switch (item.status) {
        case 2: // oanvänd
          return this.$t('Ticket_Status_Validity_Unused') + ` ${item.activationIntervalTo}`;
          break;
        case 1: // aktiv
          return this.$t('Ticket_Status_Validity_Active') + ` ${item.activeTo}`;
          break;
        case 3: // Förbrukad
          return this.$t('Ticket_Status_Validity_Consumed') + ` ${item.activeTo}`;
          break;
        case 4: // Utgången
          return this.$t('Ticket_Status_Validity_Expired');
          break;
        case 5:
          return this.$t('Ticket_Status_Validity_Blocked');
          break;
        case 6:
          return this.$t('Ticket_Status_Validity_Active') + ` ${item.activeToDate}`;
        case 7:
          if (item.ticketType === 'TravelPassHours') {
            return this.$t('Ticket_Status_Validity_Active') + ` ${item.activeTo}`;
          } else if (item.ticketType === 'TravelPassDays') {
            // 1-second rule can be moved here from backend.
            return this.$t('Ticket_Status_Validity_Active') + ` ${item.activeToDateShort}`;
          }
          return this.$t('Ticket_Status_Validity_Active') + ` ${item.activeToDateShort}`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-details-ticket-list {
  position: relative;

  h4 {
    margin-top: 1.9375em;
    margin-bottom: 0.6875em !important;
  }

  .mobile-btn {
    @media (max-width: 400px) {
      padding: 0 0.375em !important;
    }
  }

  .card-details-list-overlay {
    position: absolute;
    top: 3.3125em;
    left: 0;
    width: 100%;
    height: calc(100% - 3.3125em);
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .activation-link {
    margin-left: 0.8125em;
    font-size: 0.875em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.0275em;
    color: #4d4845;
    text-decoration: underline;
  }

  .st-button {
    border: solid 0.125em #f6bb0a;
    margin: 0em;
    &:hover {
      border-color: #f6bb0a !important;
    }
  }

  .info-icon {
    // position: absolute;
    // right: 1.5625em;
    margin: auto 0px;
    margin-top: 0.625em;

    &:hover {
      cursor: pointer;
    }
  }

  .align-right {
    position: absolute;
    right: 1.5625em;
  }
}
</style>
