<template>
  <div class="reset-password-form">
    <p v-if="showPinInput">{{ $t('ResetPassword_SMS_Information') }}</p>

    <st-form-group v-if="showPinInput" id="token" :label="$t('SetPasswordForm_Token_Label')" required :validation="$v.model.token" :validationErrors="validationErrors.token">
      <template #default="{ ariaDescribedBy }">
        <st-input id="token" :aria-describedby="ariaDescribedBy" :placeholder="$t('SetPasswordForm_Token_Placeholder')" required v-model="model.token" :invalid="$v.model.token.$error" :blur="$v.model.token.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-form-group id="password" :label="$t('SetPasswordForm_Password_Label')" required :validation="$v.model.password" :validationErrors="validationErrors.password">
      <template #default="{ ariaDescribedBy }">
        <st-input ref="newPassword" :aria-describedby="ariaDescribedBy" type="password" id="password" :placeholder="$t('SetPasswordForm_Password_Placeholder')" required v-model="model.password" :invalid="$v.model.password.$error" :blur="$v.model.password.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-form-group id="repeatPassword" :label="$t('SetPasswordForm_RepeatPassword_Label')" required :validation="$v.model.repeatPassword" :validationErrors="validationErrors.repeatPassword">
      <template #default="{ ariaDescribedBy }">
        <st-input type="password" :aria-describedby="ariaDescribedBy" id="repeatPassword" :placeholder="$t('SetPasswordForm_RepeatPassword_Placeholder')" required v-model="model.repeatPassword" :invalid="$v.model.repeatPassword.$error" :blur="$v.model.repeatPassword.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-button medium block primary @click="submit" :disabled="$v.$invalid || isLoading" tabindex="0">{{ $t('SetPasswordForm_Button_Submit') }}</st-button>

    <st-error v-if="error" :error="error" />
  </div>
</template>

<script>
import api from '../../api';
import ResetPasswordFormValidations from './reset-password-form-validations';
import ResetPasswordFormValidationErrors from './reset-password-form-validation-errors';
export default {
  props: {
    email: {
      type: String
    },
    showPinInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
        repeatPassword: '',
        token: '',
        tokenRequired: this.showPinInput
      },
      isLoading: false,
      error: undefined,
      validationErrors: ResetPasswordFormValidationErrors
    };
  },
  validations: {
    model: ResetPasswordFormValidations
  },
  created() {
    if (this.$route.query.rt) {
      this.model.token = this.$route.query.rt;
    }
  },
  mounted() {
    this.$refs.newPassword.$el.focus();
  },
  methods: {
    submit() {
      this.error = undefined;
      this.isLoading = true;

      this.model.email = this.email;

      const url = 'mitt-konto/reset-password';
      api
        .post(url, this.model)
        .then((result) => {
          this.$router.push('/konto');
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error;
          this.isLoading = false;
          this.$nextTick(() => {
            this.$refs.newPassword.$el.focus();
          });
        });
    }
  }
};
</script>
