<template>
  <div class="mitt-konto-start">
    <h1 v-if="isStEnvironment" :class="'welcome-img ' + system">{{ $t('MittKonto_Header_Welcome') }}</h1>
    <h2 v-if="isLoading">Laddar ...</h2>
    <router-view v-show="!isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CARDS } from '../../store/actions/card-actions';
export default {
  data() {
    return {
      system: process.env.VUE_APP_SYSTEM,
      isLoading: true
    };
  },
  created() {
    if (this.cards.length == 0) {
      this.getCards()
        .then((result) => {
          if (result.length > 0) {
            this.$router.push('card-list');
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
      this.$router.push('card-list');
    }
  },
  computed: {
    ...mapGetters({
      cards: GET_CARDS
    }),
    isStEnvironment() {
      if (process.env.VUE_APP_SYSTEM.valueOf() === 'st') {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions({
      getCards: GET_CARDS
    })
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Skanetrafiken-Bali';
  src: url($VUE_APP_BASE_URL + 'assets/fonts/Skanetrafiken-Bali.eot');
  src: url($VUE_APP_BASE_URL + 'assets/fonts/Skanetrafiken-Bali.eot?#iefix') format('embedded-opentype'), url($VUE_APP_BASE_URL + 'assets/fonts/Skanetrafiken-Bali.ttf') format('truetype');
}

.mitt-konto-start {
  h2 {
    font-size: 1.9375em !important;
  }
  .welcome-img {
    &.st {
      font-family: 'Skanetrafiken-Bali' !important;
      font-size: 5.875em !important;
      color: #e72385 !important;
      font-weight: normal !important;
      line-height: normal !important;
      margin-bottom: 0.213em !important;
      @media (max-width: 576px) {
        font-size: 5.25em !important;
        word-break: break-all;
      }
    }
  }
}
</style>
