<template>
  <div class="successful-payment">
    <!-- <st-spinner :isLoading="isLoading" />-->
    <h1 v-if="isLoading">{{ $t('PayEx_Loading') }}</h1>
    <div v-show="!isLoading">
      <h1>{{ $t('SuccessfulPayment_Header') }}</h1>
      <div class="successful-payment-header">
        <svg role="img" :aria-label="$t('SuccessfulPayment_Check_Icon_Title')" xmlns="http://www.w3.org/2000/svg" width="63" height="50" viewBox="0 0 23 20">
          <title>{{ $t('SuccessfulPayment_Check_Icon_Title') }}</title>
          <desc>{{ $t('SuccessfulPayment_Check_Icon_Description') }}</desc>
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" opacity=".5" />
            <path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M3 12.988L8.008 18 20 6" />
          </g>
        </svg>
      </div>
      <st-order-summary v-if="orderTypeValue" />
      <p v-html="$t(descriptionKey)" />
      <p v-show="referenceId" v-html="$t('SuccessfulPayment_ReferenceId', { referenceId })" />

      <st-button ref="paymentSucessApprove" primary medium style="min-width: 11.25em" tabindex="0" @click="toStartRoute">{{ $t('SuccessfulPayment_Button') }}</st-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { CLEAR_TICKET_STATE } from '../../../store/actions/ticket-actions';
import { CLEAR_CARD_ORDER_MODEL_STATE } from '../../../store/actions/card-order-actions';

import payExService from '../../../services/payExService';
import { CLEAR_JOURNEY_STATE } from '../../../store/actions/journey-actions';
import local from '../../../services/localStorageService';
import globalMixin from '../../../mixins/globalMixin';
import StOrderSummary from '@/components/OrderSummary';

export default {
  mixins: [globalMixin],
  props: ['model', 'orderType', 'orderId', 'paymentOrderId', 'descriptionKey', 'orderTypeValue'],
  components: {
    StOrderSummary
  },
  data() {
    return {
      referenceId: undefined,
      isLoading: false,
    };
  },
  mounted() {
    this.$refs.paymentSucessApprove.$el.focus();
    this.scrollToTopWithOffset();
  },
  beforeRouteLeave(to, from, next) {
    if (window.payex) {
      payex.hostedView.paymentMenu().close();
    }

    this.clearTicketState();
    this.clearCardOrderModelState();
    this.clearJourneyState();

    next();
  },
  created() {
    local.clear('st-bearer'); // param is for excluding what to be cleared.

    if (!this.model) {
      this.$router.push(this.$parent.$data.url);
      return;
    }

    if (this.model.captureCallback) {
      this.isLoading = true;
      payExService
        .capturePaymentOrder(this.model.orderType, this.model.orderId, this.model.paymentOrderId, this.model.description, this.model.captureCallback, this.model.instrument)
        .then((result) => {
          this.referenceId = result.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.$router.push({
            name: 'payment-cancel',
            params: {
              data: error
            }
          });
        });
    }
  },
  methods: {
    ...mapActions({
      clearTicketState: CLEAR_TICKET_STATE
    }),
    ...mapMutations({
      clearCardOrderModelState: CLEAR_CARD_ORDER_MODEL_STATE,
      clearJourneyState: CLEAR_JOURNEY_STATE
    }),
    toStartRoute() {
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss" scoped>
.successful-payment {
  h1 {
    font-size: 1.9375em !important;
  }
  text-align: center;
}
.successful-payment-header {
  width: 6.5em;
  height: 6.5em;
  background-color: #4cba4d;
  border-radius: 50%;
  margin: auto;
  padding: 1.3125em;
  margin-top: 2.5em;
  margin-bottom: 1.9375em;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
}
</style>
