<template>
  <st-modal role="dialog" aria-modal="true" :ariaLabelledby="'activate-header'" class="activate-ticket-modal" :onClose="close" :isLoading="isLoading">
    <div slot="header" class="st-modal-header-default">
      <h1 id="activate-header">{{ $t('ActivateTicketModal_Header') }}</h1>
    </div>
    <div slot="body" class="st-modal-body-default">
      <p id="ticket-description">{{ ticketDescription }}</p>
    </div>
    <div ref="footer" slot="footer" class="st-modal-footer-default">
      <div class="st-modal-footer-content d-none d-sm-flex">
        <st-button @click="closeModal(0)" @keydown.tab.prevent.native="onCancelKeyDown" id="cancel" tabindex="0" medium block :disabled="isLoading" style="margin-right: 1em">{{ $t('ActivateTicketModal_Button_No') }}</st-button>
        <st-button @click="closeModal(1)" @keydown.tab.prevent.native="onSubmitKeyDown" id="submit" tabindex="0" medium block primary :disabled="isLoading">{{ $t('ActivateTicketModal_Button_Yes') }}</st-button>
      </div>
      <div class="d-sm-none">
        <div class="row">
          <div class="col-12">
            <st-button @click="closeModal(1)" medium block primary tabindex="0" :disabled="isLoading" style="margin-bottom: 1em">{{ $t('ActivateTicketModal_Button_Yes') }}</st-button>
          </div>
          <div class="col-12">
            <st-button :aria-label="ticketDescription" @click="closeModal(0)" medium block tabindex="0" :disabled="isLoading">{{ $t('ActivateTicketModal_Button_No') }}</st-button>
          </div>
        </div>
      </div>
      <st-error v-if="error" :error="error" />
    </div>
  </st-modal>
</template>

<script>
import { GET_TICKET_OFFERS_OFFERS } from '../store/actions/ticket-offers-actions';
import { mapGetters } from 'vuex';
import globalMixins from '../mixins/globalMixin';

export default {
  props: ['close', 'model', 'error'],
  mixins: [globalMixins],
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      offers: GET_TICKET_OFFERS_OFFERS
    }),
    ticketDescription() {
      if (!this.model.offerId) return '';

      for (let i = 0; i < this.offers.length; i++) {
        const offer = this.offers[i];
        if (offer.id == this.model.offerId) return offer.description;
      }

      return '';
    }
  },
  methods: {
    closeModal(result) {
      this.isLoading = true;
      this.close(result);
    },
    onCancelKeyDown(e) {
      this.cancelKeyDown(e, 'cancel', 'submit');
    },
    onSubmitKeyDown(e) {
      this.submitKeyDown(e, 'cancel');
    }
  },
  watch: {
    error(val) {
      this.isLoading = false;
    }
  },
  mounted() {
    // Tabbning, focusera på första knappen i dialogen.
    this.$refs.footer.getElementsByTagName('button')[0].focus();
  }
};
</script>

<style lang="scss" scoped>
.activate-ticket-modal {
  h1 {
    font-size: 1.9375em !important;
    word-break: break-word;
  }
  .st-modal-footer-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
}
</style>
