<template>
  <div class="payment" aria-labelledby="payment-checkout-header">
    <h1 id="payment-checkout-header" v-show="!isLoading">{{ $t('BuyTicketConfirm_Header') }}</h1>
    <st-spinner v-if="isLoading" />
    <div v-show="!isLoading" id="checkout" tabindex="0"></div>
  </div>
</template>

<script>
import api from '../../../api';
import payExService from '../../../services/payExService';
import scriptService from '../../../services/scriptService';
import local from '../../../services/localStorageService';
import paymentCallbackService from '../../../services/paymentCallbackService';
import { mapState } from 'vuex';
import globalMixin from '../../../mixins/globalMixin';

export default {
  mixins: [globalMixin],
  props: ['model', 'type'],
  mounted() {
    // Starta betalning
    this.initiatePaymentOrder();
    this.scrollToTopWithOffset();
  },
  destroyed() {
    scriptService.removeScript(this.payExSrc);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'payment-success' && window.payex) {
      payex.hostedView.paymentMenu().close();
      local.clear('st-bearer'); // param is for excluding what to be cleared.
    }

    next();
  },
  data() {
    return {
      payExSrc: '',
      isLoading: false,
      dataModel: {},
      counter: 0
    };
  },
  computed: {
    ...mapState({
      bearerType: (state) => state.ticketModule.model.bearerType
    })
  },
  methods: {
    getHref(operations, rel) {
      let href = '';
      for (let i = 0; i < operations.length; i++) {
        const operation = operations[i];
        if (operation.rel === rel) {
          href = operation.href;
          break;
        }
      }

      return href;
    },

    completePayment() {
      let vm = this;
      var instrument;

      // skapa en script tag och lägg den i "head"
      let script = scriptService.createAndAddScript(this.payExSrc);

      script.onload = function () {
        vm.isLoading = false;
        // skript har laddats. Initiera en payex hostedView av typen paymentMenu
        payex.hostedView
          .paymentMenu({
            container: 'checkout',
            culture: 'sv-SE',
            onPaid: function (paymentCompletedEvent) {
              vm.isLoading = true;
              // Detta event triggas när betalningen är klar och lyckats.

              // skapa authorize
              let paymentAuthorizeRequest = {
                payeeReference: vm.payeeReference,
                acquisitionId: vm.acquisitionId,
                paymentOrderId: vm.dataModel.paymentOrderId,
                instrument: vm.dataModel.instrument
              };

              const paymentAuthorizeUrl = `payments/payment-order/authorize`;
              return api
                .post(paymentAuthorizeUrl, paymentAuthorizeRequest)
                .then((paymentAuthorizeResponse) => {
                  paymentAuthorizeResponse.data.callBackWillCarryOutOrder && vm.dataModel.orderType === "TICKET"
                    ? vm.checkIfOrderIsCompleted()
                    : vm.completeOrder(paymentAuthorizeResponse.data);
                })
                .catch((error) => {
                  vm.cancel(error);
                });
            },
            onInstrumentSelected: function (paymentMenuInstrumentSelectedEvent) {
              vm.dataModel.instrument = paymentMenuInstrumentSelectedEvent.instrument;
            },
            onTermsOfServiceRequested: function (paymentToSEvent) {
              // Öppna villkor i ett nytt fönster
              var win = window.open(paymentToSEvent.openUrl, '_blank');
              win.focus();
            },
            onError: function (error) {
              //Triggas bara vid fel i faktura. Vi gör inget utan låter payex visar Försök igen ruta
            }
          })
          .open();
      };
    },

    checkIfOrderIsCompleted() {
      let getTicketOrderInfo = paymentCallbackService[this.dataModel.getTicketOrderInfo];

      return getTicketOrderInfo(this.dataModel.orderId)
        .then(() => {
          this.succeedOrder();
        })
        .catch((error) => {
          if (this.counter < 20 && error.response.status === 404) {
            this.counter++;
            setTimeout(() => this.checkIfOrderIsCompleted(), 1000);
          } else {
            this.succeedOrder();
            return;
          }
        });
    },

    completeOrder(requestData) {
      let authorizeCallback = paymentCallbackService[this.dataModel.authorizeCallback];

      if (authorizeCallback) {
        return authorizeCallback(this.dataModel.orderId, requestData)
          .then(() => {
            this.succeedOrder();
            return;
          })
          .catch((error) => {
            this.cancel(error);
            return;
          });
      } else {
        this.succeedOrder();
        return;
      }
    },

    succeedOrder() {
      const bearer = window.localStorage.getItem('st-bearer');
      let route = {
        name: 'payment-success',
        params: {
          model: this.dataModel,
          descriptionKey:
            this.dataModel.orderType == 'CARD'
              ? 'SuccessfulPayment_Cards_Description'
              : bearer === 'app'
              ? 'SuccessfulPayment_Tickets_App_Description'
              : 'SuccessfulPayment_Tickets_Description',
          orderTypeValue: this.dataModel.orderType === 'CARD' ? false : true
        }
      };
      this.isLoading = false;
      this.$router.push(route);
    },

    weGotHereFromARedirect() {
      return !this.model || !this.model.orderType;
    },

    leaveIfPayexGaveUsADifferentOrderIdThanWeHaveStored() {
      if (!this.$route.params.orderId || this.$route.params.orderId != this.dataModel.orderId) {
        this.$router.push(this.$parent.$data.url);
        return true;
      }
      return false;
    },

    initiatePaymentOrder() {
      this.isLoading = true;

      try {
        if (this.weGotHereFromARedirect()) {
          this.dataModel = {
            orderId: local.get('orderId'),
            orderType: local.get('orderType'),
            description: local.get('description'),
            paymentOrderId: local.get('paymentOrderId'),
            authorizeCallback: local.get('authorizeCallback'),
            getTicketOrderInfo: local.get('getTicketOrderInfo'),
            captureCallback: local.get('captureCallback')
          };

          if (!this.dataModel.orderId) throw new Error(this.$t('Error_PaymentCheckOut_Redirect'));

          this.payeeReference = local.get('payeeReference');
          this.acquisitionId = local.get('acquisitionId');

          if (this.leaveIfPayexGaveUsADifferentOrderIdThanWeHaveStored()) return;

          var request = { PaymentOrderId: this.dataModel.paymentOrderId };
          api
            .post(`payments/payex-script`, request)
            .then((result) => {
              this.payExSrc = result.data;
              this.completePayment();
            })
            .catch((error) => {
              this.cancel(error);
            });
        } else {
          if (!this.model.orderType || !this.model.orderId) throw new Error();

          this.dataModel = this.model;

          if (this.dataModel.zeroBalance) {
            this.completeOrder();
            return;
          }

          // Anropa PayExService för att initiera betalning och få länk till betalningssida
          // Skicka med användarprofilen som kom när användaren loggade in
          payExService
            .createPaymentOrder(this.model.orderType, this.model.orderId, this.model.description)
            .then((result) => {
              if (result.data.zeroBalance) {
                this.completeOrder();
                return;
              }

              this.dataModel.paymentOrderId = result.paymentOrderId;
              this.payeeReference = result.data.payeeReference;
              this.acquisitionId = result.data.acquisitionId;
              this.payExSrc = this.getHref(
                [result.data.result.paymentMenuOperation],
                'view-checkout'
              );
              window.localStorage.setItem('st-bearer', this.bearerType);

              local.clear('st-bearer'); // param is for excluding what to be cleared.

              local.set('orderId', this.dataModel.orderId);
              local.set('orderType', this.dataModel.orderType);
              local.set('description', this.dataModel.description);
              local.set('paymentOrderId', this.dataModel.paymentOrderId);
              local.set('payeeReference', result.data.payeeReference);
              local.set('acquisitionId', result.data.acquisitionId);
              local.set('authorizeCallback', this.dataModel.authorizeCallback);
              local.set('getTicketOrderInfo', this.dataModel.getTicketOrderInfo);
              local.set('captureCallback', this.dataModel.captureCallback);

              this.completePayment();
            })
            .catch((error) => {
              this.cancel(error);
            });
        }
      } catch (error) {
        this.cancel(error);
      }
    },

    cancel(error) {
      local.clear();
      this.isLoading = false;

      if (!error.message) error.message = this.$t('Error_PaymentCheckOut_Redirect');
      this.$router.push({
        name: 'payment-cancel',
        params: {
          data: error
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.payment {
  h1 {
    @media (max-width: 576px) {
      font-size: 1.875em !important;
    }
    margin: 20px 0px 40px 0px !important;
    text-align: center;
  }
}
</style>
