<template>
  <div class="st-card-list">
    <h1 id="cardListHeader">{{ $t('CardList_Header') }}</h1>

    <registered-card-list :click="rowClicked" descriptionId="cardListHeader"></registered-card-list>

    <st-button block medium class="register-card-button activate-button" tabindex="0" @click="toCardRegistrationRoute">
      <st-icon name="add-card-add" style="width: 2.188em; height: 1.563em; margin-right: 0.75em" :alt="$t('Start_BuyCard_Alt_Text')" inline useSvg />
      {{ $t('Register_Card_Button') }}
    </st-button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { GET_CARD, SET_SELECTED_CARD } from '../../../store/actions/card-actions';

import RegisteredCardList from '../../../components/registered-card-list/RegisteredCardList';
export default {
  components: {
    RegisteredCardList
  },
  mounted() {
    document.getElementById('mittkonto').scrollIntoView();
  },
  computed: {
    ...mapGetters({
      getCard: GET_CARD
    })
  },
  methods: {
    ...mapMutations({
      setSelectedCard: SET_SELECTED_CARD
    }),
    rowClicked(id) {
      let data = this.getCard(id);
      this.setSelectedCard(data);
      this.$router.push('/cards/details');
    },
    toCardRegistrationRoute() {
      this.$router.push('/cards/card-registration');
    }
  }
};
</script>

<style lang="scss" scoped>
.st-card-list {
  h1 {
    font-size: 1.9375em !important;
  }
  .register-card-button.st-button {
    height: 4.5em;
    border: 0.125em dashed #f6bb0a;

    &:hover {
      border-color: #f6bb0a;
    }
  }
}
</style>
