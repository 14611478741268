<template>
  <st-modal class="revoke-card-modal" :ariaLabelledby="'revoke-card-header'" :onClose="closeModal" :isLoading="isLoading">
    <div slot="header" class="st-modal-header-default">
      <h1 id="revoke-card-header">{{ $t('RevokeCardModal_Header') }}</h1>
    </div>
    <div slot="body" class="st-modal-body-default">
      <p>{{ $t('RevokeCardModal_Body') }}</p>
    </div>
    <div slot="footer" class="st-modal-footer-default">
      <div class="st-modal-footer-content d-none d-sm-flex">
        <st-button @click="closeModal(0)" @keydown.tab.prevent.native="onCancelKeyDown" id="cancel" tabindex="1" medium block :disabled="isLoading" style="margin-right: 1em">{{ $t('RevokeCardModal_Button_Cancel') }}</st-button>
        <st-button @click="closeModal(1)" @keydown.tab.prevent.native="onSubmitKeyDown" id="submit" tabindex="2" medium block primary :disabled="isLoading">{{ $t('RevokeCardModal_Button_Ok') }}</st-button>
      </div>
      <div class="d-sm-none">
        <div class="row">
          <div class="col-12">
            <st-button @click="closeModal(1)" primary medium block style="margin-bottom: 1em">{{ $t('RevokeCardModal_Button_Ok') }}</st-button>
          </div>
          <div class="col-12">
            <st-button @click="closeModal(0)" medium block>{{ $t('RevokeCardModal_Button_Cancel') }}</st-button>
          </div>
        </div>
      </div>
    </div>
  </st-modal>
</template>

<script>
import globalMixins from '../../../mixins/globalMixin';

export default {
  mixins: [globalMixins],
  props: ['close', 'isLoading'],
  mounted() {
    // Tabbning, focusera på första knappen i dialogen.
    document.getElementById('cancel').focus();
  },
  methods: {
    closeModal(result) {
      this.close(result);
    },
    onCancelKeyDown(e) {
      this.cancelKeyDown(e, 'cancel', 'submit');
    },
    onSubmitKeyDown(e) {
      this.submitKeyDown(e, 'cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.revoke-card-modal {
  h1 {
    font-size: 1.9375em !important;
  }
  .st-modal-footer-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
}
</style>
