import Price from '../models/price';

let instance = null;
class TicketOffersService {
  constructor() {
    if (!instance) {
      instance = this;

      this.ticketOffers = {};
    }

    return instance;
  }

  setTicketOffers = function(ticketOffers) {
    this.ticketOffers = ticketOffers;
  };

  getTicketOffers = function() {
    return this.ticketOffers;
  };

  getOffer = function(offerId) {
    if (!offerId) return;

    if (!this.ticketOffers.offers) return;

    let item;
    for (let i = 0; i < this.ticketOffers.offers.length; i++) {
      if (this.ticketOffers.offers[i].id.toLowerCase() === offerId.toLowerCase()) {
        item = this.ticketOffers.offers[i];
        break;
      }
    }

    return item;
  };

  getPrice = function(offerId, priceModel, priceId) {
    let offer = this.getOffer(offerId);
    if (!offer || !offer.priceModels[priceModel] || !offer.priceModels[priceModel].prices) return new Price(0, 0, 0);

    let item;
    let prices = offer.priceModels[priceModel].prices;
    for (let i = 0; i < prices.length; i++) {
      if (prices[i].id.toLowerCase() === priceId.toLowerCase()) {
        item = prices[i];
        break;
      }
    }

    return item;
  };

  getCityAddon = function(offerId, priceModel, priceId, cityAddonIds) {
    if (!cityAddonIds.length) return;

    let price = this.getPrice(offerId, priceModel, priceId);

    for (let index = 0; index < price.availableAddons.length; index++) {
      let addon;

      if (price.availableAddons[index].availability.indexOf('NewPurchase') === -1) continue;

      for (let i = 0; i < this.ticketOffers.addons.length; i++) {
        let a = this.ticketOffers.addons[i];
        if (a.id === price.availableAddons[index].addonId && a.addonType === 2) {
          addon = a;
          break;
        }
      }

      if (addon) return addon;
    }
  };

  getAddonPrice = function(offerId, priceModel, priceId, cityAddonIds) {
    if (!cityAddonIds.length) return;

    var addon = this.getCityAddon(offerId, priceModel, priceId, cityAddonIds);
    if (!addon) return;

    let addonPrice;
    for (let i = 0; i < addon.priceModels.length; i++) {
      if (addon.priceModels[i].id.toLowerCase() === priceModel.toLowerCase()) {
        if (addonPrice) break;
        let priceModel = addon.priceModels[i];
        for (let j = 0; j < priceModel.prices.length; j++) {
          if (priceModel.prices[j].id.toLowerCase() === priceId.toLowerCase()) {
            addonPrice = priceModel.prices[j];
            addonPrice.discountable = priceModel.discountable;
            break;
          }
        }
      }
    }
    return addonPrice;
  };

  getAddonPrice2 = function(addonId, priceModelId, priceId) {
    let addon;
    for (let i = 0; i < this.ticketOffers.addons.length; i++) {
      let a = this.ticketOffers.addons[i];
      if (a.id === addonId) {
        addon = a;
        break;
      }
    }

    for (let index = 0; index < addon.priceModels.length; index++) {
      const priceModel = addon.priceModels[index];
      if (priceModel.id.toLowerCase() === priceModelId.toLowerCase()) {
        for (let j = 0; j < priceModel.prices.length; j++) {
          const addonPrice = priceModel.prices[j];
          if (addonPrice.id.toLowerCase() == priceId.toLowerCase()) {
            addonPrice.discountable = priceModel.discountable;
            return addonPrice;
          }
        }
      }
    }
  };
}

export default TicketOffersService;
