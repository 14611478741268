<template>
  <div class="request-reset-password">
    <router-link to="/konto" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>
    <h1>{{ $t('RequestResetPassword_Header') }}</h1>
    <p>{{ $t('RequestResetPassword_Description') }}</p>
    <request-reset-password-form></request-reset-password-form>
  </div>
</template>

<script>
import RequestResetPasswordForm from '../../../components/request-reset-password-form/RequestResetPasswordForm';
export default {
  components: {
    RequestResetPasswordForm
  }
};
</script>

<style lang="scss" scoped>
.request-reset-password {
  h1 {
    font-size: 2.25em !important;
    margin: 0.55555556em 0em 0.5em !important;
  }
}
</style>
