<template >
  <a id="" v-if="buyNSendUri" :href="rePurchaseTicketUrl" class="rePurchaseButton" tabindex="0" >Köp igen</a>
</template>


<script>
import {GET_BUYANDSEND_URL} from '../store/actions/base-actions'
import {mapGetters} from 'vuex'
export default {
  props: {
    ticketId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      buyNSendUrl: null
      }
  },
  computed: {
    ...mapGetters({
      buyNSendUri: GET_BUYANDSEND_URL
    }),
    rePurchaseTicketUrl: function() {
      return `${this.buyNSendUri}#/kop-biljett/${this.ticketId}`
    },
  }
};
</script>

<style scoped>
.rePurchaseButton {
  margin-top: 0.4rem;
  font-weight: bold;
  position: inherit !important;
}
.rePurchaseButton:hover {
    text-decoration: underline  !important;
}
</style>
