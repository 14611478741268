export default {
  methods: {
    propertyFilter: function(filters, items) {
      for (var property in filters) {
        if (filters.hasOwnProperty(property)) {
          let filter = filters[property];
          items = items.filter(item => {
            if (filter.test) {
              var value = filter.property ? item[filter.property] : item[property];
              return filter.test(value, filter.value);
            } else {
              return true;
            }
          });
        }
      }

      return items;
    },

    searchFilter: function(items, fields, filterKey) {
      items = items.filter(function(item) {
        return fields.some(function(field) {
          let filter = function(input, key) {
            if (!key) key = input.key;
            // Get value and transform if customFilter
            let value = input.subObj && item[input.subObj] ? item[input.subObj][key] : item[key];
            if (input.customFilter) {
              return input.customFilter(value, filterKey);
            }
            return (
              String(value)
                .toLowerCase()
                .indexOf(filterKey) > -1
            );
          };

          if (field.isArray) {
            return field.fields.some(function(f) {
              if (f.keys) {
                return f.keys.some(k => {
                  return filter(f, k);
                });
              } else {
                return filter(f);
              }
            });
          } else if (field.filterFields) {
            return field.filterFields.some(function(f) {
              if (f.keys) {
                return f.keys.some(k => {
                  return filter(f, k);
                });
              } else {
                return filter(f);
              }
            });
          } else {
            if (field.keys) {
              return field.keys.some(k => {
                return filter(field, k);
              });
            } else {
              return filter(field);
            }
          }
        });
      });

      return items;
    },

    sortItems: function(fields, items, sortKey, order) {
      let field = fields.filter(f => {
        if (f.fields) {
          return f.fields[0].key === sortKey;
        } else {
          return f.key === sortKey;
        }
      })[0];

      items = items.slice().sort(function(a, b) {
        if (field.customSort) {
          a = field.customSort(a);
          b = field.customSort(b);
        } else {
          a = a[sortKey];
          b = b[sortKey];
        }
        return (a === b ? 0 : a > b ? 1 : -1) * order;
      });

      return items;
    },

    formatText: function(item, field) {
      let text = '';
      if (field.subObj && !item[field.subObj]) return text;

      if (field.keys && field.customFormat) {
        let texts = [];
        for (let i = 0; i < field.keys.length; i++) {
          const element = field.keys[i];
          text = field.subObj ? item[field.subObj][element] : item[element];
          texts.push(text);
        }
        text = field.customFormat(texts);
      } else {
        text = field.subObj ? item[field.subObj][field.key] : item[field.key];

        if (field.customFormat) {
          text = field.customFormat(text);
        }
      }

      if (field.truncate && text.length > field.truncate + 3) {
        text = text.substring(0, 17) + '...';
      }

      return text;
    }
  }
};
