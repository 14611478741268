<template>
  <div id="tapnride" class="tap-n-ride">
    <div class="app-loading" v-if="!isLoaded">
      <st-spinner :loadingMessage="'Sidan laddar'"/>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { IS_LOADED, LOAD_DEPENDENCIES } from './store';
import { APP_TAP_N_RIDE } from './models/constants';
import { mapGetters } from 'vuex';
export default {
  created() {
    this.$store.dispatch(LOAD_DEPENDENCIES, APP_TAP_N_RIDE);
  },
  computed: {
    ...mapGetters({
      isLoaded: IS_LOADED
    })
  }
};
</script>

<style lang="scss" scoped>
#tapnride /deep/ {
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins';

  @import '~bootstrap/scss/reboot';
  @import '~bootstrap/scss/grid';
  @import '~bootstrap/scss/utilities';
  @import '~bootstrap/scss/modal';

  @import './assets/scss/colors.scss';
  @import './assets/scss/fonts.scss';

  font-family: $source-sans-pro;
  overflow-x: hidden;

  .price-box {
    border-radius: 4px;
    background-color: #f7f6f2;
    margin-top: 24px;
    padding: 22px 18px 20px 18px;

    span {
      &.label {
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #6e6054;
        vertical-align: bottom;
      }
      &.amount {
        font-size: 32px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.75;
        letter-spacing: normal;
        text-align: right;
        color: #4d4845;
      }
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: $bitter;
    margin-bottom: 0px;
    color: $grey-dark;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;

    letter-spacing: normal;
    line-height: 1.44;
  }

  h1 {
    font-size: 49px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 31px;
  }

  h4 {
    font-size: 24px;
  }

  p {
    color: $grey-brown1;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6e6054;
  }

  a {
    color: #4d4846 !important;

    &:hover {
      text-decoration: none;
    }

    &.top-link {
      font-size: 13px;
      font-weight: 600;

      &:before {
        content: '‹';
        margin-right: 5px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
