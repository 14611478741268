// actions
export const SET_STOP_AREAS = 'SET_STOP_AREAS';
export const SET_PRICE_DATA = 'SET_PRICE_DATA';
export const SET_OPTIONAL_CITIES = 'SET_OPTIONAL_CITIES';
export const CLEAR_TICKET_STATE = 'CLEAR_TICKET_STATE';
export const RESET_TICKET_ADDONS = 'RESET_TICKET_ADDONS';
export const SET_TICKET_TRAVELLERS_FOR_MODAL = 'SET_TICKET_TRAVELLERS_FOR_MODAL'
export const REBUY_TICKET = 'REBUY_TICKET'
export const REPURCHASE_TICKET = 'REPURCHASE_TICKET'

// mutations
export const SYNC_ADD_ONS = 'SYNC_ADD_ONS'
export const ADD_TRAVELLER = 'ADD_TRAVELLER';
export const REMOVE_TRAVELLER = 'REMOVE_TRAVELLER';
export const SET_OFFER_ID = 'SET_OFFER_ID';
export const SET_PRICE_MODEL_ID_AND_PRICE_ID = 'SET_PRICE_MODEL_ID_AND_PRICE_ID';
export const SET_CIRCLE_ORIGO = 'SET_CIRCLE_ORIGO';
export const SET_ZONES = 'SET_ZONES';
export const SET_MODEL = 'SET_MODEL';
export const CLEAR_TICKET_PRICE_DETAILS = 'CLEAR_TICKET_PRICE_DETAILS';
export const ADD_ADDON = 'ADD_ADDON';
export const REMOVE_ADDON = 'REMOVE_ADDON';
export const TOGGLE_ADDON = 'TOGGLE_ADDON';
export const SET_SELECTED_ADDONS = 'SET_SELECTED_ADDONS';
export const SET_NUMBER_OF_TICKETS = 'SET_NUMBER_OF_TICKETS';
export const SET_OFFER_DESCRIPTION = 'SET_OFFER_DESCRIPTION';

// getters
export const GET_TRAVELLERS_BY_TRAVELLER_TYPE = 'GET_TRAVELLERS_BY_TRAVELLER_TYPE';
export const GET_PRICE_BY_TRAVELLER_TYPE = 'GET_PRICE_BY_TRAVELLER_TYPE';
export const GET_DISCOUNTS = 'GET_DISCOUNTS';
export const GET_PRICE = 'GET_PRICE';
export const GET_CITY_ADDON = 'GET_CITY_ADDON';
export const GET_ADDON_BY_ID = 'GET_ADDON_BY_ID';
export const GET_ADDON_PRICE_BY_ID = 'GET_ADDON_PRICE_BY_ID';
export const GET_SELECTED_ADDONS = 'GET_SELECTED_ADDONS';
export const GET_OFFER_ID = 'GET_OFFER_ID';
export const GET_TOTAL = 'GET_TOTAL';
export const GET_AMOUNT_DUE = 'GET_AMOUNT_DUE';
export const GET_VOUCHER_AMOUNT_USED = 'GET_VOUCHER_AMOUNT_USED';
export const GET_NUMBER_OF_TICKETS = 'GET_NUMBER_OF_TICKETS';
export const GET_TRAVELLER_TOTAL = 'GET_TRAVELLER_TOTAL';
