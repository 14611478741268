<template>
  <div class="addon-selected-list">
    <div class="list-container">
      <div class="row">
        <div class="col col-8">
          <span class="bold addon-label" sty>{{ $t('AddonSelectedList_Label_Addons') }}</span>
          <span>{{ selectedAddons }}</span>
        </div>
        <div class="col col-4 text-right">
          <st-button id="addon-select" @click="openModal" :disabled="avaliableAddons.length === 0" :tabindex="tabindex">{{ $t('AddonSelectedList_Button_SelectAddon') }}</st-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { GET_AVAILABLE_ADDONS } from '../../../store';

import globalMixins from '../../../mixins/globalMixin.js';

export default {
  mixins: [globalMixins],
  props: {
    tabindex: {
      type: String,
      default: '0'
    },
    openModal: {
      type: Function
    }
  },
  computed: {
    ...mapGetters({
      avaliableAddons: GET_AVAILABLE_ADDONS
    }),
    ...mapState({
      addons: (state) => state.ticketModule.addons
    }),
    selectedAddons() {
      let addonNames = [];
      let addons = this.addons.sort((a1, a2) => a1.sortOrder - a2.sortOrder);

      for (let i = 0; i < addons.length; i++) {
        const addon = addons[i];
        let addonByName = this.getAddonTranslatedByType(addon.addonType, addon.id);
        let name = !addon.purchasePerTraveller ? `${addon.count} ${addonByName}` : addonByName;
        addonNames.push(name);
      }
      return addonNames.join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
.list-container {
  border: 0.0625em solid #807569;
  border-radius: 0.25em;
  padding: 0.25em 0em;

  .st-icon {
    height: 2em;
    width: 2.8125em;
  }

  .st-button {
    &#addon-select {
      height: 100%;
      min-height: 2em;
    }
  }

  @media (max-width: 576px) {
    .traveller-type-icon {
      display: none;
    }

    .col-4 {
      padding-left: 0em !important;
      @media (max-width: 576px) {
        padding-right: 0.813em !important;
      }
    }
  }

  @media (max-width: 490px) {
    .st-button {
      font-size: 0.75em !important;
    }
  }

  .row {
    margin: 0em;
    padding: 0.25em 1em 0.25em 0.75em;
    align-items: center;
  }
  .bold {
    font-size: 1em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
  }

  .addon-label {
    padding-left: 0.25em;
    padding-right: 0.625em;
  }
}
</style>
