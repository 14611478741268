<template>
  <div class="card-details-per-day">
    <router-link to="/visa-transaktioner" class="top-link">{{ $t('TopNavigation_Back') }}</router-link>
    <!-- <div class="row">
      <div class="col-12 col-md-4 col-lg-3">
        <div class="d-none d-md-block" style="float:left;">
          <img style="width: 10.3125rem;height: 6.8125rem;" :src="`/assets/images/card-sand.svg`" alt />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5">
        <label for="card-number">Kortnummer:</label>
        <span class="card-number">**** **** **** 1111</span>
        <br />
        <label for="card-expiration-date">Giltig t.o.m:</label>
        <span class="card-expiration-date">12/21</span>
        <br />
        <div class="col-md-1">
          <st-button class="receipt-btn" @click="openReceipt()">Kvitto</st-button>
        </div>
      </div>
    </div>-->

    <div class="row">
      <div class="col-12">
        <st-button onClick="window.print()" class="d-none d-lg-block" :disabled="!ticketHistoryData || ticketHistoryData.length === 0">{{ $t('TapnRide_Payment_receipt') }}</st-button>
        <div class="row" id="navbar">
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 text-center">
            <router-link id="navigation-transactions" to="/visa-transaktioner">{{ $t('TapnRide_Link_TransactionHistory') }}</router-link>
          </div>
          <div class="col-6 col-sm-3 col-md-3 col-lg-2 text-center">
            <router-link class="navigation" id="navigation-history" to="/visa-transaktioner-per-dag">{{ $t('TapnRide_Link_TravelHistory') }}</router-link>
          </div>
        </div>
        <div v-if="!ticketHistoryData || ticketHistoryData.length === 0">
          <p>{{ $t('TapnRide_TravelHistory_NoData') }}</p>
        </div>
        <div v-else>
          <transaction-details-info-per-day v-if="ticketHistoryData" :ticketList="ticketHistoryData"></transaction-details-info-per-day>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionDetailsInfoPerDay from '../../components/transaction-details-info/TransactionDetailsInfoPerDay';
import { mapActions, mapGetters } from 'vuex';
import { GET_TICKETS, GET_HMAC } from '../../store/actions/tapnride-actions';

export default {
  props: {
    data: {
      type: Object
    },
    transactionId: {
      type: String
    }
  },
  components: {
    TransactionDetailsInfoPerDay
  },
  mounted() {
    if (!this.hmac) {
      this.$router.push('/');
    }
  },
  data() {
    return {
      ticketHistoryData: undefined
    };
  },
  computed: {
    ...mapGetters({
      hmac: GET_HMAC
    })
  },
  watch: {
    hmac: {
      immediate: true,
      handler(val, oldVal) {
        if (!val) return;

        this.retrieveTickets();
      }
    }
  },
  methods: {
    ...mapActions({
      getTickets: GET_TICKETS
    }),
    openReceipt() {
      this.$router.push('/visa-kvitto');
    },
    retrieveTickets() {
      let _this = this;

      if (!this.hmac) {
        return;
      }

      let data = {
        TransactionId: this.hmac.transactionId
      };

      this.getTickets(data).then((data) => {
        _this.ticketHistoryData = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.receipt-btn {
  margin-top: 3px !important;
}

#navbar {
  border-bottom: 1px solid #e4e3e3;
  margin-bottom: 10px;
}

#navigation-transactions {
  font-size: 20px;
}

#navigation-history {
  border-bottom: 1px solid #f6bb0a;
}

.navigation {
  font-size: 20px;
  font-weight: 600;
}

li {
  float: left;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

a {
  display: block;
  padding: 8px;
  background-color: #dddddd;
}

.card-number {
  padding-top: 10px;
}

.card-expiration-date {
  margin-left: 11px;
}

.card-status {
  margin-left: 16px;
}
</style>
