import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import TransactionDetails from '@/views/TapnRide/TransactionDetails.vue';
import TransactionDetailsPerDay from '@/views/TapnRide/TransactionDetailsPerDay.vue';
import Start from '@/views/TapnRide/Start.vue';

let routes = [
  {
    path: '/',
    component: Start
  },
  {
    name: 'transaction-details',
    path: '/visa-transaktioner',
    component: TransactionDetails
  },
  {
    name: 'transaction-details-per-day',
    path: '/visa-transaktioner-per-dag',
    component: TransactionDetailsPerDay
  }
];

export default new Router({
  routes
});
