<template>
  <div class="credit-card-lookup">
    <div class="row">
      <router-link to="/" class="top-link">{{ $t('TopNavigation_Back') }}</router-link>
      <iframe class="col-12" style="border: none; overflow: hidden" id="ecomm" width="100%" height="340px"></iframe>
    </div>
  </div>
</template>

<script>
import scriptService from '../../services/scriptService';
import { mapActions } from 'vuex';
import { GET_HMAC } from '../../store/actions/tapnride-actions';

//let frame = {};
//let ridangoDomain = '';
//let hmacData = {};
export default {
  mounted() {
    this.loadIframeContent();
    //this.$router.push('/visa-transaktioner');
  },
  computed: {
    sendIframeData() {
      this.frame = document.getElementById('ecomm');
      this.frame.src = this.ridangoDomain + '/index.html#/pan-token-generation';
      window.addEventListener(eventListenerName, this.reactOnMessage);
    }
  },
  data() {
    return {
      hmacData: {},
      ridangoDomain: '',
      frame: undefined,
      ridangoData: undefined
    };
  },
  methods: {
    ...mapActions({
      getHmac: GET_HMAC
    }),
    loadIframeContent() {
      let eventListenerName = 'message';
      const use3ds = process.env.VUE_APP_TAPNRIDE_3DS_ENABLED.valueOf() === 'true';

      this.ridangoDomain = process.env.VUE_APP_RIDANGO_URL;
      let _this = this;
      this.getHmac().then((result) => {
        _this.hmacData = result;

        _this.ridangoData = {
          lang: 'sv',
          hostApp: 'emv',
          hmacFields: ['companyId', 'datetime', 'hmacFields'],
          transactionId: this.hmacData.transactionId,
          companyId: this.hmacData.companyId,
          datetime: this.hmacData.timeStamp,
          hmac: this.hmacData.hmac,
          useNewCardTokenForm: use3ds
        };

        _this.frame = document.getElementById('ecomm');
        _this.frame.src = _this.ridangoDomain + '/index.html#/pan-token-generation';

        window.addEventListener(eventListenerName, _this.reactOnMessage);
      });
    },
    reactOnMessage(event) {
      let _this = this;
      if (event.origin !== this.ridangoDomain) {
        return;
      }
      if (event.data.ready) {
        this.sendPanTokenGenerationData();
      } else if (event.data.responseCodeName && event.data.responseCodeName === 'APPROVED') {
        window.removeEventListener('message', _this.reactOnMessage);
        this.$router.push('/visa-transaktioner');
      } else {
        let errorMsg = 'ERROR' + ' responseCodeName = ' + event.data.responseCodeName + ' specificCodeName = ' + event.data.specificCodeName + ' statusCode = ' + event.data.statusCode;
        console.log(errorMsg);
      }
    },

    sendPanTokenGenerationData() {
      if (!this.frame || !this.frame.contentWindow) {
        return;
      }

      let receiver = this.frame.contentWindow;
      receiver.postMessage(this.ridangoData, this.ridangoDomain);
    }
  }
};
</script>

<style lang="scss" scoped>
#ecomm {
  margin-top: 20px;
}

.top-link,
.st-button.primary {
  margin-left: 20px !important;
}
</style>
