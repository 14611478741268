import i18n from '../../../i18n/index';
export default {
  searchStopQuery: [
    {
      key: 'required',
      message: i18n.t('StopAreaPicker_Query_Required_Validation'),
      id: 'searchStopQueryRequired'
    }
  ]
};
