import api from '../../api';

import { GET_TRAVEL_AREAS, SET_TRAVEL_AREAS, GET_CITY_BY_ID, GET_TRAVEL_AREA } from '../actions/travel-area-actions';

const state = {
  travelAreas: {}
};

const actions = {
  [GET_TRAVEL_AREAS]: ({ commit }) => {
    const url = 'geography/travel-areas';
    return new Promise(resolve => {
      return api
        .get(url)
        .then(result => {
          commit(SET_TRAVEL_AREAS, result.data);
          resolve(result);
        })
        .finally(() => {
          resolve();
        });
    });
  }
};

const mutations = {
  [SET_TRAVEL_AREAS]: (state, payload) => {
    state.travelAreas = payload;
  }
};

const getters = {
  [GET_CITY_BY_ID]: state => id => {
    let travelArea;
    for (let i = 0; i < state.travelAreas.areas.length; i++) {
      const item = state.travelAreas.areas[i];
      if (item.groupId === 'city' && item.id.toLowerCase() === id.toLowerCase()) {
        travelArea = item;
        break;
      }
    }

    return travelArea;
  },
  [GET_TRAVEL_AREA]: state => (priceModelId, priceId) => {
    let travelArea;
    if(state.travelAreas) {
      for (let i = 0; i < state.travelAreas.areas.length; i++) {
        const item = state.travelAreas.areas[i];
        if (item.groupId.toLowerCase() === priceModelId.toLowerCase() && item.id.toLowerCase() === priceId.toLowerCase()) {
          travelArea = item;
          break;
        }
      }
    }

    return travelArea;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
