// actions
export const GET_TICKET_OFFERS = 'GET_TICKET_OFFERS';

// mutations
export const SET_TICKET_OFFERS = 'SET_TICKET_OFFERS';
export const SET_TICKET_OFFERS_NAMES = 'SET_TICKET_OFFERS_NAMES';
export const SET_TICKET_OFFER_DISABLED = 'SET_TICKET_OFFER_DISABLED';
export const SET_SELECTED_OFFER_ID = 'SET_SELECTED_OFFER_ID';
export const SET_TICKET_OFFER_LOADED = 'SET_TICKET_OFFER_LOADE';
export const SET_TICKET_OFFERS_OFFERS_NAME_LOADED = 'SET_TICKET_OFFERS_OFFERS_NAME_LOADED';

// getters
export const HAS_VALID_TICKET_OFFERS = 'HAS_VALID_TICKET_OFFERS';
export const GET_TICKET_OFFERS_OFFERS = 'GET_TICKET_OFFERS_OFFERS';
export const GET_TICKET_OFFERS_OFFERS_NAME = 'GET_TICKET_OFFERS_OFFERS_NAME';
export const GET_SELECTED_OFFER_ID = 'GET_SELECTED_OFFER_ID';
export const GET_TICKET_OFFER_DESCRIPTION = 'GET_TICKET_OFFER_DESCRIPTION';
export const GET_TICKET_OFFER_LOADED = 'GET_TICKET_OFFER_LOADED';
export const GET_TICKET_OFFERS_OFFERS_NAME_LOADED = 'GET_TICKET_OFFERS_OFFERS_NAME_LOADED';
