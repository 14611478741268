<template>
  <div class="login-data-display">
    <div class="email">
      <h2>E-postadress</h2>
      <p>{{ account.email }}</p>
    </div>
    <div>
      <st-button medium primary tabindex="1" @click="toEditEmailRoute">Ändra e-postadress</st-button>
    </div>
    <div>
      <st-button medium primary tabindex="2" @click="toEditPasswordRoute">Ändra lösenord</st-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_ACCOUNT } from '../../../store/actions/account-actions';
export default {
  mounted() {
    document.getElementById('mittkonto').scrollIntoView();
  },
  methods: {
    toEditEmailRoute() {
      this.$router.push({ name: 'login-data-edit-email' });
    },
    toEditPasswordRoute() {
      this.$router.push({ name: 'login-data-edit-password' });
    }
  },
  computed: {
    ...mapGetters({
      account: GET_ACCOUNT
    })
  }
};
</script>

<style lang="scss" scoped>
.login-data-display {
  .email {
    margin-top: 1em;
    word-break: break-all;
    h2 {
      margin-bottom: 0.5em !important;
    }
    p {
      margin-bottom: 0.25em !important;
    }
  }
}
</style>
