import i18n from '../../i18n/index';

export default {
  newEmail: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Email_Required_Validation'),
      id: 'emailRequired'
    },
    {
      key: 'email',
      message: i18n.t('SignupForm_Email_Email_Validation'),
      id: 'emailFormat'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_Email_MaxLength_Validation'),
      id: 'emailLength'
    }
  ],

  newEmailRepeat: [
    {
      key: 'sameAsEmail',
      message: i18n.t('SignupForm_RepeatEmail_SameAs_Validation'),
      id: 'sameAsEmailFormat'
    }
  ]
};
