import i18n from '../../i18n/index';
import journeyModule from './journey-module';
import {
  GET_PASSAGE_TYPES,
  GET_SELECTED_PASSAGE_TYPE,
  SET_SELECTED_PASSAGE_TYPE
} from '../actions/passage-type-actions';

const state = {
  selectedPassageType: {},
  passageTypes: [
    {
      passageType: 'oresundBridge'
    },
    {
      passageType: 'hhFerry'
    }
  ]
};

const getters = {
  [GET_PASSAGE_TYPES]: () => {
    if (!journeyModule || !journeyModule.state.journey.possibleJourneys) return;

    let journey = journeyModule.state.journey;
    let passageTypes = [];

    for (let i = 0; i < journey.possibleJourneys.length; i++) {
      const possibleJourney = journey.possibleJourneys[i];

      let existingPassageType = passageTypes.filter((x) => {
        return x.passageType == possibleJourney.properties.passageType;
      })[0];

      if (possibleJourney.properties.passageType && !existingPassageType) {
        passageTypes.push({
          passageType: possibleJourney.properties.passageType,
          priceId: possibleJourney.priceDetails.priceId
        });
      }
    }

    return passageTypes;
  },
  [GET_SELECTED_PASSAGE_TYPE]: (state) => {
    return state.selectedPassageType;
  }
};

const mutations = {
  [SET_SELECTED_PASSAGE_TYPE]: (state, selectedPassageType) => {
    state.selectedPassageType = selectedPassageType;
  }
};

export default {
  state,
  mutations,
  getters
};
