<template>
  <div class="cardNumberInputWrapper focusOut" @focusout="focus" @keydown="arrowNav">
    <st-input inputmode="numeric" :classes="inputClasses" :value="localValue | format" @input="input" :placeholder="placeholder" :tabindex="tabindex" :id="id" ref="inputfield" :aria-describedby="ariaDescribedBy"></st-input>
    <st-icon name="remove" v-if="localValue.length > 0" class="clear-button" @click.native="clearInput" @keyup.native.enter="clearInput" alt="Ta bort text" useSvg inline size="sm" tabindex="0" role="button" />
    <div class="registerdCardsLookUp focusOut" v-show="showAutocomplete" ref="suggestions">
      <div class="cardSuggestion" v-for="cards in autocompletedCards" :key="cards.cardNumber" tabindex="0" @click="selectSuggestion(cards)" v-on:keyup.enter="selectSuggestion(cards)" role="button">
        <div class="col col-12 col-md-6">
          {{ cards.name }}
        </div>
        <div class="col col-12 col-md-6 card-number"><st-icon name="card" alt="Kort ikon" useSvg inline /> {{ cards.cardNumber.replace(/(.{4})/g, '$1 ') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_CARDS } from '../store/actions/card-actions';
import { mapGetters } from 'vuex';
import cookieService from '../services/cookieService';
export default {
  data() {
    return {
      localValue: this.value,
      inputClasses: [],
      showAutocomplete: false,
      autocompletedCards: []
    };
  },
  props: {
    value: {
      default: null
    },
    blur: {
      type: Function
    },
    placeholder: {
      type: String
    },
    tabindex: {
      type: String
    },
    id: {
      type: String,
      default: 'cardNumberInput'
    },
    ariaDescribedBy: {
      type: String,
    }
  },
  computed: {
    ...mapGetters({
      cards: GET_CARDS
    })
  },
  methods: {
    clearInput: function (e) {
      this.input('');
      this.$refs.inputfield.$el.focus();
    },
    arrowNav: function (e) {
      switch (e.keyCode) {
        case 38:
          e.preventDefault();
          if (e.target.previousElementSibling) e.target.previousElementSibling.focus();
          else this.$refs.inputfield.$el.focus();
          break;
        case 40:
          e.preventDefault();
          if (e.target.id === this.id) this.$refs.suggestions.firstChild.focus();
          else if (e.target.nextElementSibling) e.target.nextElementSibling.focus();
          break;
      }
    },
    focus: function (e) {
      if (!e.relatedTarget || !e.relatedTarget.parentElement.classList.contains('focusOut')) {
        this.blur(e);
        this.toggleAutoComplete(false);
      }
    },
    selectSuggestion: function (item) {
      this.$refs.inputfield.$el.focus();
      this.localValue = item.cardNumber;
      this.toggleAutoComplete(false);
    },
    input: function (value) {
      if (this.localValue === value.replace(/\s/g, '')) return;
      this.localValue = value;
      this.autoComplete(value);
    },
    autoComplete: function (value) {
      if (!cookieService.getTokenFromCookie('StarJwtTokenCookieName')) return;
      let tmp = [];
      let formatedValue = value.toUpperCase().replace(/\s/g, '');
      this.cards.forEach((card) => {
        if (!card.isActive) return;
        let numberMatch = card.cardNumber.toUpperCase().replace(/\s/g, '').indexOf(formatedValue) < 0;

        let nameMatch = card.name.toUpperCase().replace(/\s/g, '').indexOf(formatedValue) < 0;

        if (!numberMatch || !nameMatch) tmp.push(card);
      });
      this.autocompletedCards = tmp.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      if (tmp.length > 0) this.toggleAutoComplete(value.length >= 2);
    },
    toggleAutoComplete: function (bool) {
      bool ? ((this.showAutocomplete = true), (this.inputClasses = ['bearerInput'])) : ((this.showAutocomplete = false), (this.inputClasses = []));
    }
  },
  filters: {
    format: function (value) {
      if (!value) return '';

      value = value
        .toString()
        // .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();

      return value;
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localValue = newVal;
      }
    },
    localValue: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        newVal = newVal.replace(/\s/g, '');
        this.$emit('input', newVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/colors.scss';

$sugestionHeight: 3em;
.cardNumberInputWrapper {
  position: relative;
}
.registerdCardsLookUp {
  border: 0.0625em solid $varm-grå-alt;
  border-radius: 0em 0em 0.25em 0.25em !important;
  position: absolute;
  z-index: 999;
  width: 100%;
  background-color: white;
  max-height: $sugestionHeight * 4;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  ul {
    padding: 0;
  }

  .cardSuggestion {
    display: flex;
    min-height: $sugestionHeight;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      margin-bottom: 0.313em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    div {
      padding: 0 2em 0 1em !important;
      margin: auto 0;

      &.card-number {
        @media (min-width: 768px) {
          text-align: end;
        }
      }
    }

    img {
      margin-right: 0.5em;
      display: none !important;
      @media (min-width: 370px) {
        display: inline-block !important;
      }
    }
  }
  .cardSuggestion:hover {
    cursor: pointer;
    background-color: #f7f6f2;
  }
  // .cardSuggestion:nth-child(even) {
  //   background-color: #f7f6f2;
  // }
}

.bearerInput {
  border-bottom: 1px solid #d6d3d0 !important;
  border-radius: 0.25em 0.25em 0em 0em !important;
}

.hide {
  display: none;
}

.clear-button {
  position: absolute !important;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;

  &:hover {
    cursor: pointer;
  }
}
</style>
