<template>
  <div class="card-details-info" v-if="card">
    <div class="d-none d-sm-block">
      <div style="float: left">
        <img style="width: 10.313em; height: 6.813em" :src="cardSandSrc" :alt="$t('Start_BuyCard_Alt_Text')" />
      </div>
      <div class="row">
        <div class="col-6 col-md-3">
          <span class="card-details-label strong">
            {{ $t('CardDetailsInfo_Label_CardNumber') }}
          </span>
        </div>
        <div class="col-6 col-md-9">
          <span>{{ card.cardNumber | cardNumberFormat }}</span>
        </div>
      </div>
      <div class="row" v-if="!card.isActive">
        <div class="col-6 col-md-3">
          <span class="strong">
            {{ $t('CardDetailsInfo_Label_CardStatus') }}
          </span>
        </div>
        <div class="col-6 col-md-9">
          <st-status :status="getCardStatus(card)"></st-status>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <st-button ref="revokeButton" v-if="showRevokeButton" medium @click="openRevokeModal" :disabled="isLoading" tabindex="0">{{ $t('CardDetailsInfo_Button_Revoke') }}</st-button>
          <st-button ref="moveButton" v-if="showMoveButton" medium @click="openMoveTicketsModal" :disabled="isLoading" tabindex="0">{{ $t('CardDetailsInfo_Button_MoveTickets') }}</st-button>
          <st-button ref="removeButton" v-if="showRevokeButton" medium class="remove-button-desktop" @click="openRemoveModal" :disabled="isLoading" tabindex="0">{{ $t('CardDetailsInfo_Button_Remove') }}</st-button>
        </div>
      </div>
    </div>

    <div class="d-sm-none">
      <div class="mobile-card-section">
        <div style="float: left">
          <img style="width: 5.625em; height: 3.75em" :src="cardSandSrc" :alt="$t('Start_BuyCard_Alt_Text')" />
        </div>
        <div class="row">
          <div class="col-12">
            <span class="card-details-label"> {{ $t('CardDetailsInfo_Label_CardNumber') }} </span><br />

            <span>{{ card.cardNumber | cardNumberFormat }}</span>
          </div>
        </div>
        <div class="row" v-if="!card.isActive">
          <div class="col-12">
            <span class="strong">
              {{ $t('CardDetailsInfo_Label_CardStatus') }}
            </span>
          </div>
          <div class="col-12">
            <st-status :status="getCardStatus(card)"></st-status>
          </div>
        </div>
      </div>
      <div class="mobile-buttons-section">
        <st-button class="col-12 revoke-button-mobile" v-if="showRevokeButton" medium @click="openRevokeModal" :disabled="isLoading">{{ $t('CardDetailsInfo_Button_Revoke') }}</st-button>
        <st-button class="col-12 move-button-mobile" v-if="showMoveButton" medium @click="openMoveTicketsModal" :disabled="isLoading">{{ $t('CardDetailsInfo_Button_MoveTickets') }}</st-button>
        <st-button class="col-12 remove-button-mobile" v-if="showRevokeButton" medium @click="openRemoveModal" :disabled="isLoading">{{ $t('CardDetailsInfo_Button_Remove') }}</st-button>
      </div>
    </div>

    <remove-card-modal v-if="showModal.removeCard" :close="closeRemoveModal" :cardId="card.id"></remove-card-modal>
    <revoke-card-modal v-if="showModal.revokeCard" :close="onRevokeModalClose" :isLoading="isLoading" />
    <move-tickets-modal v-if="showModal.moveTickets" :close="onMoveTicketsModalClose" :cardId="card.id" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { REVOKE_CARD } from '../../store/actions/card-actions';

import mobileDetection from '../../services/mobileDetectionService';

import RemoveCardModal from './modals/RemoveCardModal';
import RevokeCardModal from './modals/RevokeCardModal';
import MoveTicketsModal from './modals/MoveTicketsModal';

import globalMixins from '../../mixins/globalMixin';

export default {
  mixins: [globalMixins],
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RemoveCardModal,
    RevokeCardModal,
    MoveTicketsModal
  },
  data() {
    return {
      cardSandSrc: `${process.env.VUE_APP_BASE_URL}assets/images/${process.env.VUE_APP_SYSTEM}/card-sand.svg`,
      isLoading: false,
      showModal: {
        removeCard: false,
        moveTickets: false,
        revokeCard: false
      }
    };
  },
  methods: {
    ...mapActions({
      revokeCard: REVOKE_CARD
    }),
    openRemoveModal() {
      this.showModal.removeCard = true;
    },
    closeRemoveModal() {
      this.showModal.removeCard = false;
      this.$refs.removeButton.$el.focus();
    },
    openRevokeModal() {
      this.showModal.revokeCard = true;
    },
    onRevokeModalClose(result) {
      if (result) {
        this.isLoading = true;
        this.revokeCard(this.card.id).then(() => {
          this.showModal.revokeCard = false;
          this.isLoading = false;
          this.$nextTick(() => {
            this.$refs.moveButton.$el.focus();
          });
        });
      } else {
        this.showModal.revokeCard = false;
        this.$refs.revokeButton.$el.focus();
      }
    },
    openMoveTicketsModal() {
      this.showModal.moveTickets = true;
    },
    onMoveTicketsModalClose() {
      this.showModal.moveTickets = false;
      this.$refs.moveButton.$el.focus();
    }
  },
  computed: {
    ...mapState({
      card: (state) => state.cardModule.selectedCard
    }),
    showRevokeButton() {
      return this.isAuthenticated && this.card.isActive;
    },
    showMoveButton() {
      return this.isAuthenticated && !this.card.isActive;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-details-info {
  min-height: 9.375em;

  .card-details-label {
    font-weight: bold;
    word-break: break-word;
  }

  span {
    &.strong {
      font-weight: bold;
    }
  }

  .st-button {
    margin-right: 0.5em !important;
    border: solid 0.125em #f6bb0a;
    &:hover {
      border-color: #f6bb0a !important;
    }
    &.remove-button-desktop {
      margin-right: 0;
      margin-bottom: 1.25em;
    }
  }
  @media (max-width: 638px) {
    .st-button {
      &.revoke-button-mobile {
        border: solid 0.125em #f6bb0a;
      }
      &.move-button-mobile {
        margin-bottom: 1.25em;
        border: solid 0.125em #f6bb0a;
      }
      &.remove-button-mobile {
        margin-bottom: 1.25em;
        border: solid 0.125em #f6bb0a;
      }
    }
  }
}

.cardDetailsButton {
  width: 11.375em;
  border: solid 0.125em #f6bb0a;
}

@media (max-width: 576px) {
  .card-details-info {
    min-height: 5em;
  }
}
</style>
