<template>
  <div class="st-table">
    <table :class="cards ? 'st-card-table' : ''">
      <thead>
        <div class="table-header-wrapper" v-for="item in fields" :key="item.key">
          <th @click="sortBy(item)" :class="[item.sortable ? 'clickable' : '']" v-if="item.label">
            {{ item.label }}
            <span v-if="item.sortable" class="arrow" :class="toggleSort(item) ? 'asc' : 'dsc'"></span>
          </th>
          <td v-else></td>
        </div>
      </thead>
      <tbody>
        <template v-for="(item, i) in filteredItems">
          <tr :key="i" :class="[doubleLines ? 'double' : '', useToggle ? 'toggle-row' : '', cards ? 'card-row' : '']" @click="toggle(i, item.id)" @keyup.enter.prevent="toggle(i, item.id)" v-if="!openedRows.includes(i)" :tabindex="tabTableRow(i)">
            <td v-for="field in fields" :key="field.key" :style="tableColumn">
              <template v-if="isObject(field)">
                <div :style="tableCell">
                  <slot :name="field.key" :item="item" :index="i">{{ formattedText(item, field) }}</slot>
                </div>
              </template>
              <template v-else-if="isArray(field)">
                <div :style="tableCell">
                  <slot :name="field.fields[0].key" :item="item" :index="i">{{ formattedText(item, field.fields[0]) }}</slot>
                  <span class="doubleLine" :class="field.useBigDoubleLines ? 'big-text' : ''">
                    <slot :name="field.fields[1].key" :item="item" :index="i">{{ formattedText(item, field.fields[1]) }}</slot>
                  </span>
                </div>
              </template>
            </td>
          </tr>
          <tr :key="i" v-if="useToggle && openedRows.includes(i)">
            <td :colspan="fields.length" :style="openedRows.includes(i) ? 'padding:0;' : ''">
              <slot name="details" :toggle="toggle" :item="item" :index="i">Default slot</slot>
            </td>
          </tr>
          <tr v-if="cards && i < filteredItems.length - 1" :key="'separator-' + i" class="st-table-row-separator" />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import tableMixin from './tableMixin.js';
import globalMixins from '../../../mixins/globalMixin';

export default {
  mixins: [tableMixin, globalMixins],
  props: {
    fields: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    descriptionId: {
      type: String,
      default: ''
    },
    filterKey: String,
    filters: Object,
    doubleLines: Boolean,
    useBigDoubleLines: {
      type: Boolean,
      default: false
    },
    cards: Boolean,
    useToggle: {
      type: Boolean,
      default: false
    },
    rowClick: {
      type: Function
    },
    isExportable: {
      type: Boolean,
      default: false
    },
    exportData: {
      type: Object
    },
    cellHeight: {
      type: Number
    },
    columnPadding: {
      type: String,
      default: '1.25em 1.5em'
    },
    tabindex: {
      type: String,
      default: '0'
    }
  },

  data() {
    var sortOrders = {};
    this.fields.forEach(function (field) {
      if (field.fields) {
        sortOrders[field.fields[0].key] = 1;
      } else {
        sortOrders[field.key] = 1;
      }
    });
    return {
      sortKey: '',
      sortOrders: sortOrders,
      openedRows: []
    };
  },
  mounted() {
    if (this.descriptionId) {
      let tableElement = document.querySelector('table');
      tableElement.setAttribute('aria-describedby', this.descriptionId);
    }
  },
  computed: {
    filteredItems: function () {
      var sortKey = this.sortKey;
      var filterKey = this.filterKey && this.filterKey.toLowerCase();
      var filters = this.filters;
      var order = this.sortOrders[sortKey] || 1;
      var items = this.items;
      var fields = this.fields;

      // filters är ett objekt där vi filtrerar på varje property
      // varje filter ska ha en "test" metod som som returnerar true/false om den ska synas eller inte
      if (filters) {
        items = this.propertyFilter(filters, items);
      }

      // filterKey kollar på jämför mot värdet på alla fält som skickats in
      if (filterKey) {
        items = this.searchFilter(items, fields, filterKey);
      }

      if (sortKey) {
        items = this.sortItems(fields, items, sortKey, order);
      }

      return items;
    },
    tableCell() {
      if (!this.cellHeight) return;
      let cellStyle = {
        minHeight: this.cellHeight + 'em'
      };

      return cellStyle;
    },

    tableColumn() {
      if (!this.cellHeight) return;
      let columnStyle = {
        padding: this.columnPadding,
        verticalAlign: 'middle'
      };

      return columnStyle;
    }
  },
  methods: {
    isObject(field) {
      return !field.isArray;
    },
    isArray(field) {
      return field.isArray;
    },
    sortBy: function (item) {
      if (!item.sortable) return;

      // plocka ut hash på alla rader som är öppna
      let openRowIds = [];
      this.openedRows.forEach((index) => {
        let hash = this.hash(JSON.stringify(this.filteredItems[index]));
        openRowIds.push(hash);
      });
      // nollställ öppna rader
      this.openedRows = [];

      if (item.fields) {
        let key = item.fields[0].key;
        this.sortKey = key;
        this.sortOrders[key] = this.sortOrders[key] * -1;
      } else {
        this.sortKey = item.key;
        this.sortOrders[item.key] = this.sortOrders[item.key] * -1;
      }

      // öppna alla rader igen med hjälp av hash
      for (let i = 0; i < this.filteredItems.length; i++) {
        const tempItem = this.filteredItems[i];
        for (let j = 0; j < openRowIds.length; j++) {
          const openHash = openRowIds[j];
          if (openHash === this.hash(JSON.stringify(tempItem))) {
            let itemIndex = this.filteredItems.indexOf(tempItem);
            this.openedRows.push(itemIndex);
          }
        }
      }
    },
    toggleSort: function (item) {
      if (item.fields) {
        return this.sortOrders[item.fields[0].key] > 0;
      } else {
        return this.sortOrders[item.key] > 0;
      }
    },
    formattedText(item, field) {
      return this.formatText(item, field);
    },

    toggle(itemIndex, id) {
      if (!this.useToggle) return;

      if (this.rowClick && id) {
        this.rowClick(id);
        return;
      }

      const index = this.openedRows.indexOf(itemIndex);

      if (index > -1) {
        this.openedRows.splice(index, 1);
      } else {
        this.openedRows.push(itemIndex);
      }
    },

    exportTable: function () {
      let data = {
        items: this.filteredItems,
        fields: this.exportData.exportFields
      };
      exportService.exportToExcel(data, this.exportData.fileName, this.exportData.sheetName, this.exportData.title, this.exportData.author);
    },
    hash(s) {
      return s.split('').reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    },
    tabTableRow(index) {
      if (!this.useToggle) return '-1';
      return '0';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/focus.scss';

.st-table {
  table {
    width: 100%;
    max-width: 100%;

    &.st-card-table {
      border-collapse: separate !important;
      border-spacing: 0;
    }
  }

  tbody {
    .st-table-row-separator {
      height: 0.5em;
    }
  }

  .table-header-wrapper {
    display: table-cell;
    td,
    th {
      background-color: #ffffff;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 0.9375em 1.5em 0.375em 1.5em;
      min-width: 7.5em;
      font-size: 1em;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4d4845;
      &.clickable {
        cursor: pointer;
      }
    }
  }

  tr {
    // background-color: rgba(255, 255, 255, 0.8);
    background-color: #f9f8f5;
    height: 4em;
    border-radius: 0.375em;
    &.toggle-row {
      &:focus {
        @include link-focus-outline;
        outline-offset: -0.125em;
      }
    }

    &.double {
      vertical-align: top;
      height: 5.125em;

      span.doubleLine {
        font-size: 0.875em;
        color: #6e6054;

        &.big-text {
          color: #4d4845;
          font-size: 1em;
        }
      }
    }

    &.card-row {
      height: 4.5em;
      background-color: rgba(247, 246, 242, 0.65);
    }

    span {
      display: block;
      font-size: 1em;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #4d4845;
    }
  }

  tr:nth-of-type(even) {
    background-color: #ffffff;
  }

  td {
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4d4845;
  }

  td {
    min-width: 7.5em;
    padding: 1.25em 1.5em;
  }

  tr td:first-child {
    border-top-left-radius: 0.375em;
    border-bottom-left-radius: 0.375em;
  }
  tr td:last-child {
    border-top-right-radius: 0.375em;
    border-bottom-right-radius: 0.375em;
  }

  th.active {
    color: #fff;
  }

  th.active .arrow {
    opacity: 1;
  }

  .st-card-table {
    td {
      border-top: solid 0.0625em rgba(214, 211, 208, 0.65);
      border-bottom: solid 0.0625em rgba(214, 211, 208, 0.65);
      vertical-align: middle;
      padding: 0.375em 1.5em;
    }

    tr td:first-child {
      border-left: solid 0.0625em rgba(214, 211, 208, 0.65);
    }

    tr td:last-child {
      border-right: solid 0.0625em rgba(214, 211, 208, 0.65);
    }
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 0.3125em;
    opacity: 0.66;
    border-radius: 0.25em;
  }

  .arrow.asc {
    border-left: 0.375em solid transparent;
    border-right: 0.375em solid transparent;
    border-bottom: 0.375em solid #4d4845;
  }

  .arrow.dsc {
    border-left: 0.375em solid transparent;
    border-right: 0.375em solid transparent;
    border-top: 0.375em solid #4d4845;
  }

  .toggle-row {
    cursor: pointer;
  }
}
</style>
