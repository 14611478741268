import i18n from '../../i18n/index';

export default {
  firstName: [
    {
      key: 'required',
      message: i18n.t('SignupForm_FirstName_Required_Validation'),
      id: 'firstNameRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_FirstName_MaxLength_Validation'),
      id: 'firstNameLength'
    }
  ],
  lastName: [
    {
      key: 'required',
      message: i18n.t('SignupForm_LastName_Required_Validation'),
      id: 'lastNameRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_LastName_MaxLength_Validation'),
      id: 'lastNameLength'
    }
  ],
  mobile: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Mobile_Required_Validation'),
      id: 'mobileRequired'
    },
    {
      key: 'mobileFormat',
      message: i18n.t('SignupForm_Mobile_Format_Validation'),
      id: 'mobileFormat'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_Mobile_MaxLength_Validation'),
      id: 'mobileLength'
    }
  ],
  careOf: [
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_CardOf_MaxLength_Validation'),
      id: 'careOfLength'
    }
  ],
  street: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Street_Required_Validation'),
      id: 'streetrequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_Street_MaxLength_Validation'),
      id: 'streetlength'
    }
  ],
  postalCode: [
    {
      key: 'required',
      message: i18n.t('SignupForm_PostalCode_Required_Validation'),
      id: 'postalCodeRequired'
    },
    {
      key: 'postalCodeFormat',
      message: i18n.t('SignupForm_PostalCode_Format_Validation'),
      id: 'postalCodeFormat'
    }
  ],
  city: [
    {
      key: 'required',
      message: i18n.t('SignupForm_City_Required_Validation'),
      id: 'cityRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_City_MaxLength_Validation'),
      id: 'citylength'
    }
  ],
  country: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Country_Required_Validation'),
      id: 'countryRequired'
    },
    {
      key: 'maxLength',
      message: i18n.t('SignupForm_Country_MaxLength_Validation'),
      id: 'countryLength'
    }
  ]
};
