<template>
  <select :class="classList" :value="value" :tabindex="tabindex" @click.stop.prevent @input="onInput" @change="onChange" :style="styles">
    <option v-if="hasEmpty" value></option>
    <option v-for="item in items" v-bind:key="item.id" :value="item.id" v-if="!item.disable">{{ item.name }}</option>
    <slot></slot>
  </select>
</template>

<script>
export default {
  name: 'st-select',

  data: function () {
    return {
      localValue: this.value
    };
  },

  props: {
    value: {
      default: null
    },
    items: {
      type: Array
    },
    invalid: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    hasEmpty: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classList: function () {
      return ['st-select', this.invalid && 'invalid'];
    },
    styles() {
      return { background: 'url(' + `${process.env.VUE_APP_BASE_URL}assets/icons/${process.env.VUE_APP_SYSTEM}/` + 'ic-dropdown.svg) #fcfcfa  99%  no-repeat' };
    }
  },

  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localValue = newVal;
      }
    },
    localValue: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal);
      }
    }
  },

  methods: {
    onInput: function (evt) {
      this.localValue = evt.target.value;
    },
    onChange: function (evt) {
      this.localValue = evt.target.value;
      this.$emit('change', this.localValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';
.st-select {
  height: 3em;
  border-radius: 0.25em;
  border: 0.0625em solid $varm-grå-alt;

  font-size: 1em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;

  color: $grey-dark;
  font-family: $source-sans-pro;
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.8125em;

  &:focus {
    background-color: #fff;
    border-color: $nästan-svart;
    outline: none;
  }

  &.invalid {
    border-color: $invalid;
    border-width: 0.125em;
    color: $invalid;
  }

  &:disabled {
    background-color: $off-white;
  }

  &::placeholder {
    opacity: 0.6;
  }

  .hidden {
    display: none;
  }
  option {
    color: $varm-grå-alt;
  }

  // Removes default arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }

  /* target Internet Explorer 9 to undo the custom arrow */
  @media screen and (min-width: 0\0) {
    background: none\9;
    padding: 0.3125em\9;
  }
}
</style>
