import * as constants from '../models/constants.js';

export default {
  validate: function (mobilePhoneNumberString) {
    return this.processNumber(mobilePhoneNumberString);
  },

  processNumber: function (mobilePhoneNumberString) {
    let number = mobilePhoneNumberString;
    if (this.isSwedish(number)) {
      let regex =
        /^\s*([0-9]{10}|[0-9]{3}[\-\s]{1}[0-9]{3}[\s]{1}[0-9]{2}[\s]{1}[0-9]{2}|[0-9]{4}[\-\s]{1}[0-9]{2}[\s]{1}[0-9]{2}[\s]{1}[0-9]{2}|[0-9]{3}[\-\s]{1}[0-9]{7}|[0-9]{4}[\-\s]{1}[0-9]{6})$/;
      if (regex.test(number)) return true;
    } else if (this.isScandinavian(number)) {
      if (number.startsWith('+')) {
        let regex = /^([\+]{1}[0-9]{10,14})$/;

        if (number.startsWith('+46')) {
          regex =
            /^([\+]{1}[0-9]{2}[7]{1}[0]{1}[0-9]{7}|[\+]{1}[0-9]{2}[7]{1}[2]{1}[0-9]{7}|[\+]{1}[0-9]{2}[7]{1}[3]{1}[0-9]{7}|[\+]{1}[0-9]{2}[7]{1}[6]{1}[0-9]{7}|[\+]{1}[0-9]{2}[7]{1}[9]{1}[0-9]{7})$/;
        }

        if (regex.test(number)) return true;
      } else if (number.startsWith('00')) {
        let regex = /^([0]{2}[0-9]{10,14})$/;

        if (number.startsWith('0046')) {
          regex =
            /^([0]{2}[0-9]{2}[7]{1}[0]{1}[0-9]{7}|[0]{2}[0-9]{2}[7]{1}[2]{1}[0-9]{7}|[0]{2}[0-9]{2}[7]{1}[3]{1}[0-9]{7}|[0]{2}[0-9]{2}[7]{1}[6]{1}[0-9]{7}|[0]{2}[0-9]{2}[7]{1}[9]{1}[0-9]{7})$/;
        }

        if (regex.test(number)) return true;
      }
    } else if (number.startsWith('+')) {
      let tempNumber = number.substring(1);

      if (this.startsWithCountryCode(tempNumber)) {
        let regex = /^([\+]{1}[0-9]{1,3}[0-9]{4,15})$/;
        if (regex.test(number)) return true;
      }
    } else if (number.startsWith('00')) {
      let tempNumber = number.substring(2);

      if (this.startsWithCountryCode(tempNumber)) {
        let regex = /^([0]{2}[0-9]{1,3}[0-9]{4,15})$/;
        if (regex.test(number)) return true;
      }
    }
    return false;
  },

  startsWithCountryCode: function (phoneNumberString) {
    var oneNbr = phoneNumberString.substring(0, 1);
    var twoNbrs = phoneNumberString.substring(0, 2);
    var threeNbrs = phoneNumberString.substring(0, 3);
    let codes = constants.COUNTRY_CODES;
    return codes.includes(oneNbr) || codes.includes(twoNbrs) || codes.includes(threeNbrs);
  },

  isSwedish: function (phoneNumber) {
    let prefixCodes = ['070', '072', '073', '076', '079'];

    for (let i = 0; i < prefixCodes.length; i++) {
      if (phoneNumber.startsWith(prefixCodes[i])) {
        return true;
      }
    }
    return false;
  },

  isScandinavian: function (phoneNumber) {
    let prefixCodes = ['+45', '+46', '+47', '0045', '0046', '0047'];

    for (let i = 0; i < prefixCodes.length; i++) {
      if (phoneNumber.startsWith(prefixCodes[i])) {
        return true;
      }
    }
    return false;
  }
};
