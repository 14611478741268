<template>
  <div class="signup">
    <router-link to="/konto" class="top-link" tabindex="0">{{ $t('TopNavigation_Back') }}</router-link>
    <h1>{{ $t('Signup_Header') }}</h1>
    <signup-form></signup-form>
  </div>
</template>

<script>
import SignupForm from '../../../components/signup-form/SignupForm.vue';
export default {
  components: {
    SignupForm
  },
  mounted() {
    document.getElementById('mittkonto').scrollIntoView();
  }
};
</script>

<style lang="scss" scoped>
.signup {
  h1 {
    font-size: 2.25em !important;
    margin: 0.55555556em 0em 0.5em !important;
  }
}
</style>
