<template>
  <div class="buy-card-form">
    <form @submit.prevent.stop="submit">
      <st-item-counter :itemType="'kort'" :label="$t('BuyCardForm_NumberOfCards_Label')" v-model="model.numberOfCards" :price="pricePerCard" :altDecreaseText="$t('BuyCardForm_NumberOfCards_Decrease_Button_Alt')" :altIncreaseText="$t('BuyCardForm_NumberOfCards_Increase_Button_Alt')"></st-item-counter>
      <div class="row">
        <div class="col col-12 col-md-6">
          <st-form-group id="firstName" :label="$t('BuyCardForm_FirstName_Label')" required :validation="$v.model.firstName" :validationErrors="validationErrors.firstName">
            <template #default="{ ariaDescribedBy }">
              <st-input id="firstName" :aria-describedby="ariaDescribedBy" :placeholder="$t('BuyCardForm_FirstName_PlaceHolder')" required v-model="model.firstName" :invalid="$v.model.firstName.$error" autocomplete="given-name" :blur="$v.model.firstName.$touch" tabindex="0"></st-input>
            </template>
          </st-form-group>
        </div>
        <div class="col col-12 col-md-6">
          <st-form-group id="lastName" :label="$t('BuyCardForm_LastName_Label')" required :validation="$v.model.lastName" :validationErrors="validationErrors.lastName">
            <template #default="{ ariaDescribedBy }">
              <st-input id="lastName" :aria-describedby="ariaDescribedBy" :placeholder="$t('BuyCardForm_LastName_PlaceHolder')" required v-model="model.lastName" :invalid="$v.model.lastName.$error" autocomplete="family-name" :blur="$v.model.lastName.$touch" tabindex="0"></st-input>
            </template>
          </st-form-group>
        </div>
      </div>

      <st-form-group id="address" :label="$t('BuyCardForm_Address_Label')" required :validation="$v.model.street" :validationErrors="validationErrors.street">
        <template #default="{ ariaDescribedBy }">
          <st-input id="address" :aria-describedby="ariaDescribedBy" :placeholder="$t('BuyCardForm_Address_Label')" required v-model="model.street" :invalid="$v.model.street.$error" autocomplete="street-address" :blur="$v.model.street.$touch" tabindex="0"></st-input>
        </template>
      </st-form-group>

      <div class="row">
        <div class="col col-12 col-md-6">
          <st-form-group id="postalCode" :label="$t('BuyCardForm_PostalCode_Label')" required :validation="$v.model.postalCode" :validationErrors="validationErrors.postalCode">
            <template #default="{ ariaDescribedBy }">
              <st-input id="postalCode" :aria-describedby="ariaDescribedBy" :placeholder="$t('BuyCardForm_PostalCode_Placeholder')" required v-model="model.postalCode" :invalid="$v.model.postalCode.$error" autocomplete="postal-code" :blur="$v.model.postalCode.$touch" tabindex="0"></st-input>
            </template>
          </st-form-group>
        </div>
        <div class="col col-12 col-md-6">
          <st-form-group id="city" :label="$t('BuyCardForm_City_Label')" required :validation="$v.model.city" :validationErrors="validationErrors.city">
            <template #default="{ ariaDescribedBy }">
              <st-input id="city" :aria-describedby="ariaDescribedBy" :placeholder="$t('BuyCardForm_City_Placeholder')" required v-model="model.city" :invalid="$v.model.city.$error" autocomplete="address-level2" :blur="$v.model.city.$touch" tabindex="0"></st-input>
            </template>
          </st-form-group>
        </div>
      </div>

      <st-form-group id="email" :label="$t('BuyCardForm_Email_Label')" required :validation="$v.model.email" :validationErrors="validationErrors.email">
        <template #default="{ ariaDescribedBy }">
          <st-input id="email" :aria-describedby="ariaDescribedBy" :placeholder="$t('BuyCardForm_Email_Placeholder')" required v-model="model.email" :invalid="$v.model.email.$error" autocomplete="email" :blur="$v.model.email.$touch" tabindex="0"></st-input>
        </template>
      </st-form-group>

      <div class="price-box">
        <div class="row">
          <div class="col-6 text-left">
            <span class="large">{{ $t('BuyCardForm_ToPay_Label') }}</span>
          </div>
          <div class="col-6 text-right">
            <span class="large notranslate">{{ (pricePerCard * model.numberOfCards) | fixedDecimals }} kr</span>
          </div>
        </div>
      </div>
      <st-checkbox id="acceptTerms" v-model="model.acceptTerms" tabindex="0" required>
        <span v-html="$t('BuyCardForm_AcceptTerms_Checkbox')" />
      </st-checkbox>
      <div class="text-right">
        <st-button type="submit" primary medium style="min-width: 11.25em" :disabled="$v.$invalid || isLoading" tabindex="0">{{ $t('BuyCardForm_Submit_Button') }}</st-button>
      </div>
    </form>

    <st-error v-if="error" :error="error" />
  </div>
</template>

<script>
import api from '../../api';

import { mapState } from 'vuex';
import { SET_CARD_ORDER_MODEL } from '../../store/actions/card-order-actions';

import BuyCardFormValidations from './buy-card-form-validations';
import BuyCardFormValidationErrors from './buy-card-form-validation-errors';

export default {
  data() {
    return {
      validationErrors: BuyCardFormValidationErrors,
      pricePerCard: process.env.VUE_APP_SYSTEM.valueOf().toString() === 'bt' ? 40 : 30,
      isLoading: false,
      error: undefined
    };
  },
  validations: {
    model: BuyCardFormValidations
  },
  computed: {
    ...mapState({
      model: (state) => state.cardOrderModule.model
    })
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isLoading = true;
      this.error = undefined;

      // Skapa "Card Order"
      let vm = this;
      grecaptcha
        .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'buycardform' })
        .then(function (token) {
          vm.model.recaptchaToken = token;

          const url = 'card-orders';
          return api
            .post(url, vm.model)
            .then((result) => {
              vm.isLoading = false;
              // Skicka vidare användaren för att starta betalning
              vm.$router.push({
                name: 'payment-checkout',
                params: {
                  backUrl: '/kop-kort',
                  model: {
                    orderId: result.data.id,
                    orderType: 'CARD',
                    description: vm.$t('PayEx_Description_Card'),
                    email: vm.model.email,
                    authorizeCallback: 'SET_PAYMENT_ON_CARD',
                    captureCallback: 'SET_CARD_ORDER'
                  }
                }
              });
            })
            .catch((error) => {
              vm.error = error;
            })
            .finally(() => {
              vm.isLoading = false;
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>
