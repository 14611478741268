<template>
  <div class="buy-ticket-form">
    <zone-picker ref="zonepicker" tabindex="0"></zone-picker>
    <span
      v-show="journeyName(priceModelId, priceId, cityAddonIds.concat(optionalCityAddonIds)) !== ''"
      class="zone"
      >{{ journeyName(priceModelId, priceId, cityAddonIds.concat(optionalCityAddonIds)) }}</span
    >
    <span v-show="stopAreas.length" class="stopPoint">({{ stopPointName }})</span>

    <div class="journey-notification-box" v-if="isDenmarkJourney(priceId)">
      <div class="journey-notification-container">
        <div class="notification-icon">
          <st-icon name="info_on" :alt="$t('Information_Icon_Alt_Text')" useSvg inline></st-icon>
        </div>
        <div class="notification-message">
          <p class="journey-notification-message">{{ $t('Denmark_InspectionReciept_info') }}</p>
        </div>
      </div>
    </div>

    <st-form-group
      id="offer"
      :label="$t('BuyTicketForm_Offer_Label')"
      required
      :alertErrorMessages="false"
    >
      <st-select
        id="offer"
        :items="offers"
        v-model="selectedOfferId"
        :hasEmpty="false"
        tabindex="0"
        required
      />
    </st-form-group>

    <ticket-info-box v-if="ticketOfferDescription"></ticket-info-box>

    <traveller-picker v-if="!isCampaignTravellersFixed" tabindex="0"></traveller-picker>

    <addon-selected-list
      ref="addonSelectedList"
      class="addonSelectedList"
      v-if="!isCampaignJourney"
      :openModal="onAddonPicketModalShow"
      tabindex="0"
    ></addon-selected-list>

    <st-fieldset :legend="$t('BuyTicketForm_Bearer_Label')" required :alertErrorMessages="false">
      <div class="bearer-radio">
        <st-radio-button
          id="app"
          name="ticketBearer"
          :value="'app'"
          v-model="model.bearerType"
          tabindex="0"
          >{{ $t('BuyTicketForm_Bearer_App') }}</st-radio-button
        >
        <st-radio-button
          id="card"
          name="ticketBearer"
          :value="'card'"
          v-model="model.bearerType"
          tabindex="0"
          >{{ $t('BuyTicketForm_Bearer_Card') }}</st-radio-button
        >
      </div>
    </st-fieldset>

    <st-form-group
      required
      :validation="$v.model.cardNumber"
      :validationErrors="validationErrors.cardNumber"
      v-if="this.model.bearerType === 'card'"
      :alertErrorMessages="false"
    >
      <template #label>
        <div class="card-number-wrapper">
          <label for="card-number">{{ $t('CardNumber_Label') }}</label>
          <st-icon
            role="button"
            ref="cardInfoIcon"
            @click.native="showCardInfoModal = true"
            @keypress.enter.prevent.native="showCardInfoModal = true"
            isHoverable
            :alt="$t('Information_Icon_Clickable_Alt_Text')"
            class="card-info"
            name="info"
            useSvg
            inline
            tabindex="0"
          ></st-icon>
          <router-link id="add-card-link" to="/kop-kort" tabindex="0">{{
            $t('CardNumber_Link_No_Card')
          }}</router-link>
        </div>
      </template>
      <template #default="{ ariaDescribedBy }">
        <card-number-input
          v-model="model.cardNumber"
          :ariaDescribedBy="ariaDescribedBy"
          :value="model.cardNumber"
          :blur="$v.model.cardNumber.$touch"
          id="card-number"
          :placeholder="$t('CardNumber_Placeholder')"
          tabindex="0"
        ></card-number-input>
      </template>
    </st-form-group>

    <st-form-group
      id="phone"
      :label="$t('BuyTicketForm_PhoneNumber_Label')"
      required
      :alertErrorMessages="false"
      :validation="$v.model.phoneNumber"
      :validationErrors="validationErrors.phoneNumber"
      v-else
    >
      <template #label>
        <div class="phone-number-wrapper">
          <label for="phone">{{ $t('BuyTicketForm_PhoneNumber_Label') }}</label>
          <st-icon
            role="button"
            ref="phoneNumberInfoIcon"
            @click.native="showPhoneNumberInfoModal = true"
            @keypress.enter.prevent.native="showPhoneNumberInfoModal = true"
            isHoverable
            :alt="$t('Information_Icon_Clickable_Alt_Text')"
            class="card-info"
            name="info"
            useSvg
            inline
            tabindex="0"
          ></st-icon>
        </div>
      </template>
      <template #default="{ ariaDescribedBy }">
        <st-input
          id="phone"
          :aria-describedby="ariaDescribedBy"
          :placeholder="$t('PhoneNumber_Placeholder')"
          required
          v-model="model.phoneNumber"
          :invalid="$v.model.phoneNumber.$error"
          :blur="$v.model.phoneNumber.$touch"
          autocomplete="tel"
          tabindex="0"
        ></st-input>
      </template>
    </st-form-group>

    <st-form-group
      id="email"
      :label="$t('BuyTicketForm_Email_Label')"
      required
      :alertErrorMessages="false"
      :validation="$v.model.email"
      :validationErrors="validationErrors.email"
    >
      <template #default="{ ariaDescribedBy }">
        <st-input
          id="email"
          :aria-describedby="ariaDescribedBy"
          :placeholder="$t('BuyTicketForm_Email_Placeholder')"
          required
          v-model="model.email"
          :invalid="$v.model.email.$error"
          autocomplete="email"
          :blur="$v.model.email.$touch"
          tabindex="0"
        ></st-input>
      </template>
    </st-form-group>

    <buy-ticket-price-box></buy-ticket-price-box>
    <st-form-group
      id="acceptTerms"
      required
      :validation="$v.model.acceptTerms"
      :alertErrorMessages="false"
      :validation-errors="validationErrors.acceptTerms"
    >
      <template #default="{ ariaDescribedBy }">
        <st-checkbox
          id="acceptTerms"
          :ariaDescribedBy="ariaDescribedBy"
          v-model="model.acceptTerms"
          tabindex="0"
          required
        >
          <span v-html="$t('BuyTicketForm_AcceptTerms_Checkbox')" />
        </st-checkbox>
      </template>
    </st-form-group>

    <div class="text-right">
      <st-button
        ref="submitButton"
        primary
        medium
        style="min-width: 11.25em"
        :disabled="isLoading"
        :aria-busy="isLoading"
        @click="submit"
        tabindex="0"
        >{{ $t('BuyTicketForm_Submit_Button') }}</st-button
      >
    </div>

    <st-error role="alert" aria-live="assertive" v-if="error" :error="error" class="error" />

    <card-info-modal v-if="showCardInfoModal" :close="onCardInfoModalClose" />
    <phone-number-info-modal v-if="showPhoneNumberInfoModal" :close="onPhoneNumberInfoModalClose" />

    <activate-ticket-modal
      v-if="activateTicketModal.show"
      :close="onActivateTicketModalClose"
      :model="activateTicketModal.ticket"
    ></activate-ticket-modal>
    <activate-ticket-on-purchase-required-modal
      v-if="activateTicketOnPurchaseRequiredModal.show"
      :model="activateTicketOnPurchaseRequiredModal.ticket"
      :close="onActivateTicketOnPurchaseRequiredModalClose"
    />
    <addon-picker-modal v-if="addonPickerModal.show" :close="onAddonPicketModalClose" />
  </div>
</template>

<script>
import api from '../../api';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import {
  GET_TICKET_OFFERS_OFFERS,
  SET_TICKET_OFFER_DISABLED,
  GET_SELECTED_OFFER_ID,
  SET_SELECTED_OFFER_ID,
  GET_TICKET_OFFER_DESCRIPTION
} from '../../store/actions/ticket-offers-actions';
import {
  SET_OFFER_ID,
  GET_DISCOUNTS,
  GET_PRICE,
  SET_MODEL,
  GET_CITY_ADDON,
  GET_TRAVELLER_TOTAL
} from '../../store/actions/ticket-actions';
import { GET_JOURNEY_POSSIBLE_JOURNEYS } from '../../store/actions/journey-actions';
import {
  IS_CAMPAIGN_JOURNEY_WITH_BANNER,
  GET_CAMPAIGN_TICKET_OFFERS,
  SET_CAMPAIGN_JOURNEY_OFFER,
  IS_TRAVELLERS_FIXED
} from '../../store/actions/campaign-offers-actions';

import ZonePicker from './zone-picker/ZonePicker';
import TravellerPicker from './traveller-picker/TravellerPicker';
import BuyTicketPriceBox from './BuyTicketPriceBox';
import CardNumberInput from '../CardNumberInput';
import AddonSelectedList from './addon-picker/AddonSelectedList';
import TicketInfoBox from '../buy-ticket-form/TicketInfoBox';

import CardInfoModal from '../CardInfoModal.vue';
import PhoneNumberInfoModal from '../PhoneNumberInfoModal.vue';

import ActivateTicketModal from '../ActivateTicketModal.vue';
import ActivateTicketOnPurchaseRequiredModal from './ActivateTicketOnPurchaseRequiredModal.vue';
import AddonPickerModal from './addon-picker/AddonPickerModal.vue';

import BuyTicketFormValidations from './buy-ticket-form-validations';
import BuyTicketFormValidationErrors from './buy-ticket-form-validation-errors';

import globalMixins from '../../mixins/globalMixin.js';

export default {
  mixins: [globalMixins],
  name: 'buy-ticket-form',
  components: {
    ZonePicker,
    TravellerPicker,
    BuyTicketPriceBox,
    CardNumberInput,
    CardInfoModal,
    ActivateTicketModal,
    ActivateTicketOnPurchaseRequiredModal,
    AddonSelectedList,
    AddonPickerModal,
    TicketInfoBox,
    PhoneNumberInfoModal
  },
  props: {
    isRepurchaseTicketOrder: Boolean
  },
  data() {
    return {
      validationErrors: BuyTicketFormValidationErrors,
      showCardInfoModal: false,
      showPhoneNumberInfoModal: false,
      activateTicketOnPurchaseRequiredModal: {
        show: false,
        ticket: {}
      },
      activateTicketModal: {
        show: false,
        ticket: {}
      },
      addonPickerModal: {
        show: false
      },
      isLoading: false,
      error: undefined
    };
  },
  mounted() {
    // Clearing order summary in case there was any when user restarts flow.
    sessionStorage.removeItem('st-bns-order-summary');
    this.$store.commit(SET_TICKET_OFFER_DISABLED, {
      id: process.env.VUE_APP_TIRTHY_DAY_METRO_OFFER.toLowerCase(),
      disable: true
    });
    this.setAcceptTermsLinkAttributes();

    const user = sessionStorage.getItem('st-user')
      ? JSON.parse(sessionStorage.getItem('st-user'))
      : null;
    if (user && (user.email || user.mobile)) {
      this.model.email = user.email;
      this.model.phoneNumber = user.mobile;
    }
    const bearerFromPreviousPurchases = localStorage.getItem('st-bearer');

    const isRoutingBack = this.$route.params.isRoutingBack;

    if (bearerFromPreviousPurchases && !this.isRepurchaseTicketOrder && !isRoutingBack) {
      this.model.bearerType = bearerFromPreviousPurchases;
    }
  },
  validations: {
    model: BuyTicketFormValidations
  },
  methods: {
    ...mapActions({
      setOfferId: SET_OFFER_ID,
      setCampaignJourneyOffer: SET_CAMPAIGN_JOURNEY_OFFER
    }),
    ...mapMutations({
      setModel: SET_MODEL,
      setWcagErrors: 'setWcagErrors'
    }),
    onPhoneNumberInfoModalClose() {
      this.showPhoneNumberInfoModal = false;
      this.$refs.phoneNumberInfoIcon.$el.focus();
    },
    onCardInfoModalClose() {
      this.showCardInfoModal = false;
      this.$refs.cardInfoIcon.$el.focus();
    },
    onActivateTicketModalClose(result) {
      this.activateTicketModal.show = false;
      if (result === undefined) return;

      this.model.activateTicketOnPurchase = !!+result;
      this.goToBuyTicketConfirm();
    },
    onActivateTicketOnPurchaseRequiredModalClose(result) {
      this.activateTicketOnPurchaseRequiredModal.show = false;
      if (result) {
        this.model.activateTicketOnPurchase = true;
        this.goToBuyTicketConfirm();
      } else {
        this.$refs.submitButton.$el.focus();
      }
    },
    onAddonPicketModalShow() {
      this.addonPickerModal.show = true;
    },
    onAddonPicketModalClose() {
      this.addonPickerModal.show = false;
      this.$refs.addonSelectedList.$el.getElementsByTagName('button')[0].focus();
    },
    submit() {
      this.setWcagErrors({ attr: 'shouldValidateAll', value: false });
      this.$nextTick(() => {
        this.$v.$touch();
        this.setWcagErrors({ attr: 'shouldValidateAll', value: true });
      });
      
      if (this.$v.$invalid) {
        return;
      };

      let formModel = this.getRequest();
      if(!formModel.priceId || !formModel.priceModelId || formModel.travellers.length < 1) return;

      this.isLoading = true;

      if (this.isCampaignJourney) {
        this.model.activateTicketOnPurchase = true;
      } else {
        this.model.activateTicketOnPurchase = false;
      }

      this.error = undefined;
      let vm = this;




      grecaptcha
        .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'buyticketform' })
        .then(function (token) {
          let data = vm.getRequest();
          data.recaptchaToken = token;

          let url = 'ticket-orders/validate';
          api
            .post(url, data)
            .then((result) => {
              if (result.status === 204) {
                vm.error = {
                  message: vm.$t('Error_CardNotFound')
                };
              } else {
                vm.setModel(vm.model);
                // If bearer is app the ticket should never be activated on purchase = go directly to buy ticket confirm
                if (vm.isCampaignJourney || vm.model.bearerType === 'app') {
                  vm.goToBuyTicketConfirm();
                } else {
                  vm.activateTicketModal.ticket = vm.getRequest();
                  vm.activateTicketModal.show = vm;
                }
              }
            })
            .catch((error) => {
              if (error.response.data.code === 4001) {
                vm.activateTicketOnPurchaseRequiredModal.ticket = error.response.data.ticket;
                vm.activateTicketOnPurchaseRequiredModal.show = true;
              } else if (
                error.response.data.code === 4003 &&
                error.response.data.message === 'Error_PhoneNumber_Or_Device_Blocked'
              ) {
                vm.error = {
                  message: vm.$t('Error_Blocked_Nbr')
                };
              } else {
                vm.error = error;
              }
            })
            .finally(() => {
              vm.isLoading = false;
            });
        });
    },
    goToBuyTicketConfirm() {
      this.$router.push({
        name: 'buy-ticket-confirm',
        params: {
          model: this.getRequest()
        }
      });
    },
    getRequest() {
      let stopPoints = [];
      for (let i = 0; i < this.stopAreas.length; i++) {
        const stopArea = this.stopAreas[i];
        stopPoints.push({
          index: i,
          name: stopArea.name,
          coordinate: {
            latitude: stopArea.latitude,
            longitude: stopArea.longitude
          }
        });
      }

      let travellers = [];
      let userTravellerTypeIds = [];
      for (let i = 0; i < this.travellers.length; i++) {
        const traveller = this.travellers[i];
        const travellerTypeId = traveller.travellerType.id;

        if (userTravellerTypeIds.indexOf(travellerTypeId) !== -1) continue;

        let count = 0;
        for (let j = 0; j < this.travellers.length; j++) {
          const t = this.travellers[j];
          if (t.travellerType.id === travellerTypeId) {
            count += 1;
          }
        }

        userTravellerTypeIds.push(travellerTypeId);

        travellers.push({
          travellerType: traveller.travellerType.travellerType,
          travellerTypeId: travellerTypeId,
          count: count
        });
      }
      let cityAddon;
      if (this.cityAddon) {
        cityAddon = this.cityAddon;
        cityAddon.cityIds = this.cityAddonIds.length
          ? this.cityAddonIds
          : this.optionalCityAddonIds;
        cityAddon.count = 0;
        for (let i = 0; i < travellers.length; i++) {
          cityAddon.count += travellers[i].count;
        }
      }

      let bikeAddon;
      let addonOfType1 = this.addons.filter((x) => x.addonType === 1)[0];
      if (addonOfType1) {
        bikeAddon = {
          id: addonOfType1.id,
          addonType: addonOfType1.addonType,
          name: addonOfType1.name,
          count: addonOfType1.purchasePerTraveller ? this.travellers.length : addonOfType1.count
        };
      }

      let firstClassAddon;
      let addonOfType3 = this.addons.filter((x) => x.addonType === 3)[0];
      if (addonOfType3) {
        firstClassAddon = {
          id: addonOfType3.id,
          addonType: addonOfType3.addonType,
          name: addonOfType3.name,
          count: addonOfType3.purchasePerTraveller ? this.travellers.length : addonOfType3.count
        };
      }
      return {
        activateTicketOnPurchase: this.model.activateTicketOnPurchase,
        bearer: this.model.bearerType === 'card' ? this.model.cardNumber : this.model.phoneNumber,
        bearerType: this.model.bearerType,
        email: this.model.email,
        offerId: this.offerId,
        priceModelId: this.priceModelId,
        priceId: this.priceId,
        travelAreaIds: this.travelAreaIds,
        travellers: travellers,
        stopPoints: stopPoints,
        ticketOffersIssued: this.ticketOffersIssued,
        circle: this.circle,
        zones: this.zones && this.zones.length > 0 ? this.zones : null,
        ticketType: '',
        cityAddon,
        firstClassAddon,
        bikeAddon,
        amount: {
          amountIncludingVat: this.price.amountIncludingVatAfterDiscount,
          vat: this.price.vatAfterDiscount
        }
      };
    },
    setAcceptTermsLinkAttributes() {
      let acceptTermsLink = document.getElementById('buy-ticket-accept-terms');
      acceptTermsLink.setAttribute('tabindex', '0');
    }
  },
  computed: {
    ...mapGetters({
      offers: GET_TICKET_OFFERS_OFFERS,
      price: GET_PRICE,
      cityAddon: GET_CITY_ADDON,
      getPossibleJourneys: GET_JOURNEY_POSSIBLE_JOURNEYS,
      getCampaignTicketOffers: GET_CAMPAIGN_TICKET_OFFERS,
      ticketOfferDescription: GET_TICKET_OFFER_DESCRIPTION,
      isCampaignJourney: IS_CAMPAIGN_JOURNEY_WITH_BANNER,
      isCampaignTravellersFixed: IS_TRAVELLERS_FIXED,
      wcagErrorHandling: 'wcagErrorHandling'
    }),
    ...mapState({
      offerId: (state) => state.ticketModule.offerId,
      priceModelId: (state) => state.ticketModule.priceModelId,
      priceId: (state) => state.ticketModule.priceId,
      travelAreaIds: (state) => state.ticketModule.travelAreaIds,
      stopAreas: (state) => state.ticketModule.stopAreas,
      travellers: (state) => state.ticketModule.travellers,
      circle: (state) => state.ticketModule.circle,
      zones: (state) => state.ticketModule.zones,
      cityAddonIds: (state) => state.ticketModule.cityAddonIds,
      optionalCityAddonIds: (state) => state.ticketModule.optionalCityAddonIds,
      addons: (state) => state.ticketModule.addons,
      ticketOffersIssued: (state) => state.ticketOffersModule.ticketOffers.issued,
      model: (state) => state.ticketModule.model,
      campaignTicketOffersModel: (state) => state.campaignOffersModule.campaignTicketOffersModel
    }),
    hasValidTicket() {
      if (this.travellers.length < 1) return false;

      if (!this.offerId || !this.priceModelId || !this.priceId) return false;

      if (this.stopAreas.length < 1 && this.getPossibleJourneys.length < 1) return false;

      return true;
    },
    stopPointName() {
      return this.stopAreas.map((e) => e.name).join(', ');
    },
    selectedOfferId: {
      get() {
        return this.$store.getters.GET_SELECTED_OFFER_ID;
      },
      set(value) {
        this.$store.commit(SET_SELECTED_OFFER_ID, value);
      }
    }
  },
  watch: {
    selectedOfferId: function (val, oldVal) {
      if (!val) return;

      let isCampaignOffer = this.getCampaignTicketOffers.filter((offer) => offer.id === val)[0];
      if (isCampaignOffer) {
        let hasCampaignOfferBanner = this.campaignTicketOffersModel.filter(
          (offer) => offer.id === val
        )[0];
        if (hasCampaignOfferBanner) {
          this.setCampaignJourneyOffer(val).catch((err) => console.error(err));
        }
      }
      this.setOfferId(val).catch((err) => console.error(err));
    },
    priceId: function (val, oldVal) {
      if (this.priceId && this.isDenmarkJourney(this.priceId)) {
        this.$store.commit(SET_TICKET_OFFER_DISABLED, {
          id: process.env.VUE_APP_TIRTHY_DAY_METRO_OFFER.toLowerCase(),
          disable: false
        });
      } else {
        this.$store.commit(SET_TICKET_OFFER_DISABLED, {
          id: process.env.VUE_APP_TIRTHY_DAY_METRO_OFFER.toLowerCase(),
          disable: true
        });
      }
    },
    'wcagErrorHandling.shouldValidateAll': function (bool) {
      if (bool) {
        this.$v.$touch();
        if (this.$v.$error) {
          let $el;
          let errorAttr;
          let elementId;

          if (this.$v.model.phoneNumber.$error) {
            errorAttr = 'appBearerHasError';
            elementId = 'phone';
          } else if (this.$v.model.cardNumber.$error) {
            errorAttr = 'cardBearerHasError';
            elementId = 'card-number';
          } else if (this.$v.model.email.$error) {
            errorAttr = 'emailHasError';
            elementId = 'email';
          } else if (this.$v.model.acceptTerms.$error) {
            errorAttr = 'acceptTermsHasError';
            elementId = 'acceptTerms';
          }

          this.setWcagErrors({ attr: errorAttr, value: true });
          $el = document.getElementById(elementId);
          if ($el) $el.focus();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.buy-ticket-form {
  margin-bottom: 2.5em;

  .zone,
  .stopPoint {
    height: 1.2375em;
    font-size: 1em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    display: inline-block;
    margin-top: 0.3125em;
    text-align: left;
  }

  .stopPoint {
    padding-left: 0.25em;
    font-size: 0.875em;
  }

  .st-icon.card-info {
    background-size: 1.25em 1.25em;
    width: 1.25em !important;
    height: 1.25em !important;

    padding-left: 0.1875em;
    margin-bottom: 0.25em;

    &:hover {
      cursor: pointer;
    }
  }
  #add-card-link {
    font-size: 0.875em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    &:hover {
      color: #cc4039 !important;
      text-decoration: underline;
    }
  }

  .addonSelectedList {
    margin-top: 1.25em;
  }
  .error {
    clear: both;
  }

  .bearer-radio {
    margin-top: 4px;
  }

  .card-number-wrapper,
  .phone-number-wrapper {
    @media (min-width: 430px) {
      display: flex;
    }
    a {
      float: none;
      display: block;
      margin-bottom: 0.75em;
      margin-left: 0.188em;

      @media (min-width: 430px) {
        margin-left: auto;
      }
    }
  }

  .journey-notification-box {
    display: inline-block;
    background-color: #f7f6f2;
    margin-top: 1em;

    .journey-notification-container {
      padding: 1em 0;

      .notification-icon {
        float: left;
        width: 2.5em;
        margin-left: 1em;
      }

      .notification-message {
        width: calc(100% - 3.5em);
        float: right;
        padding-right: 3em;
      }
    }
  }
}
</style>
