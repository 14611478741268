import Vue from 'vue';
import Router from 'vue-router';
import authService from '../services/authService';

import store from '../store/index';

import Start from '@/views/MittKonto/Start.vue';
import CardInformation from '@/views/MittKonto/Start/CardInformation.vue';
import CardList from '@/views/MittKonto/Start/CardList.vue';

import Card from '@/views/MittKonto/Card.vue';
import CardRegistration from '@/views/MittKonto/Card/CardRegistration.vue';
import CardDetails from '@/views/MittKonto/Card/CardDetails.vue';

import Account from '@/views/MittKonto/Account.vue';
import Login from '@/views/MittKonto/Account/Login.vue';
import Signup from '@/views/MittKonto/Account/Signup.vue';
import RequestResetPassword from '@/views/MittKonto/Account/RequestResetPassword.vue';
import ResetPassword from '@/views/MittKonto/Account/ResetPassword.vue';
import PersonalData from '@/views/MittKonto/Account/PersonalData.vue';
import PersonalDataDisplay from '@/views/MittKonto/Account/PersonalDataDisplay.vue';
import PersonalDataEdit from '@/views/MittKonto/Account/PersonalDataEdit.vue';
import LoginData from '@/views/MittKonto/Account/LoginData.vue';
import LoginDataDisplay from '@/views/MittKonto/Account/LoginDataDisplay.vue';
import LoginDataEditEmail from '@/views/MittKonto/Account/LoginDataEditEmail.vue';
import LoginDataEditPassword from '@/views/MittKonto/Account/LoginDataEditPassword.vue';
import VerifyEmail from '@/views/MittKonto/Account/VerifyEmail.vue';

Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
  if (to.path === '/' && from.path === '/konto/verify-email') {
    return;
  }
  if (authService.isAuthenticated()) {
    next();
    return;
  }
  next('/konto');
};

let routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/konto',
    component: Account,
    beforeEnter: function(to, from, next) {
      if (process.env.VUE_APP_HAS_ACCOUNT_FEATURE.valueOf() == 'true') {
        next();
        return;
      }
    },
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
        props: true,
        beforeEnter: function(to, from, next) {
          if (process.env.VUE_APP_HAS_LOGIN_FEATURE.valueOf() == 'true') {
            next();
            return;
          }
        }
      },
      {
        path: 'glomt-losenord',
        component: RequestResetPassword,
        beforeEnter: function(to, from, next) {
          if (process.env.VUE_APP_HAS_REQUEST_RESET_PASSWORD_FEATURE.valueOf() == 'true') {
            next();
            return;
          }
        }
      },
      {
        path: 'aterstall-losenord',
        name: 'reset-password',
        component: ResetPassword,
        props: true,
        beforeEnter: function(to, from, next) {
          if (process.env.VUE_APP_HAS_RESET_PASSWORD_FEATURE.valueOf() == 'true') {
            next();
            return;
          }
        }
      },
      {
        path: 'skapa-konto',
        component: Signup,
        beforeEnter: function(to, from, next) {
          if (process.env.VUE_APP_HAS_SIGNUP_FEATURE.valueOf() == 'true') {
            next();
            return;
          }
        }
      },
      {
        path: 'mina-personuppgifter',
        component: PersonalData,
        beforeEnter: ifAuthenticated,
        children: [
          {
            path: '',
            component: PersonalDataDisplay
          },
          {
            path: 'redigera',
            component: PersonalDataEdit
          }
        ]
      },
      {
        path: 'mina-inloggningsuppgifter',
        component: LoginData,
        beforeEnter: ifAuthenticated,
        children: [
          {
            path: '',
            component: LoginDataDisplay
          },
          {
            name: 'login-data-edit-email',
            path: 'redigera-epostadress',
            component: LoginDataEditEmail
          },
          {
            name: 'login-data-edit-password',
            path: 'redigera-losenord',
            component: LoginDataEditPassword
          }
        ]
      },
      {
        path: 'verify-email',
        component: VerifyEmail
      }
    ]
  },
  {
    path: '/',
    component: Start,
    children: [
      {
        path: '',
        component: CardInformation,
        beforeEnter: ifAuthenticated
      },
      {
        path: 'card-list',
        component: CardList,
        beforeEnter: ifAuthenticated
      }
    ]
  },
  {
    path: '/cards',
    component: Card,
    children: [
      {
        path: 'card-registration',
        component: CardRegistration,
        beforeEnter: ifAuthenticated
      },
      {
        name: 'card-details',
        path: 'details',
        component: CardDetails,
        beforeEnter: ifAuthenticated
      }
    ]
  }
];

export default new Router({
  routes
});
