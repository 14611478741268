import cookieService from './cookieService'

export default {
  isAuthenticated: () => {
    if (cookieService.getTokenFromCookie('StarJwtTokenCookieName')) {
      return true;
    }
    return false;
  },
  getToken: () => {
    return cookieService.getTokenFromCookie('StarJwtTokenCookieName');
  }
}