import globalFunctionsService from '../services/globalFunctionsService';

class PriceSum {
  constructor(prices) {
    this.prices = prices;
  }

  get amountIncludingVat() {
    let sumAmount = 0;
    for (let i = 0; i < this.prices.length; i++) {
      const price = this.prices[i];
      sumAmount += price.amountIncludingVat;
    }
    return globalFunctionsService.roundTo(sumAmount, 2);
  }

  get amountIncludingVatAfterDiscount() {
    let sumAmount = 0;
    for (let i = 0; i < this.prices.length; i++) {
      const price = this.prices[i];
      sumAmount += price.amountIncludingVatAfterDiscount;
    }
    return globalFunctionsService.roundTo(sumAmount, 2);
  }

  get vatAfterDiscount() {
    let sumVat = 0;
    for (let i = 0; i < this.prices.length; i++) {
      const price = this.prices[i];
      sumVat += price.vatAfterDiscount;
    }
    return globalFunctionsService.roundTo(sumVat, 2);
  }
}

export default PriceSum;
