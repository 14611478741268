<template>
  <div class="personal-data-edit">
    <personal-data-form></personal-data-form>
  </div>
</template>

<script>
import PersonalDataForm from '../../../components/personal-data-form/PersonalDataForm.vue';
export default {
  components: {
    PersonalDataForm
  }
};
</script>