<template>
  <div class="st-spinner">
    <div class="spinner" :aria-label="loadingMessage ? null : $t('Spinner_Alt_text')" :class="thin ? 'thin' : 'thick'"></div>
    <div class="message" aria-live="assertive" v-if="loadingMessage"></div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    loadingMessage: {
      type: String,
      default: ''
    },
    thin: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.loadingMessage) {
        document.querySelector('.st-spinner .message').innerHTML = this.loadingMessage;
      }
    }, 100);
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/colors.scss';

.st-spinner {
  height: 100%;
  width: 100%;
  position: relative;

  .spinner {
    &.thick {
      border-top: 0.16em solid #e5e5e5;
      border-right: 0.16em solid #e5e5e5;
      border-bottom: 0.16em solid #e5e5e5;
      border-left: 0.16em solid #ccc;

      width: 1.52em;
      height: 1.52em;
      margin-left: -0.76em;
      margin-top: -0.76em;
    }

    &.thin {
      border: 0.04em solid $vit;
      border-top: 0.04em solid rgba(77, 72, 69, 0.24);
      border-right: 0.04em solid rgba(77, 72, 69, 0.24);

      width: 1.28em;
      height: 1.28em;
      margin-left: -0.64em;
      margin-top: -0.64em;
    }

    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3.125em;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .message {
    position: relative;
    top: 65%;
    text-align: center;
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #6e6054;
    opacity: 0;
  }
}
</style>
