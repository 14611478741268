<template>
  <div class="st-order-summary">
    <h2>Kvitto översikt</h2>
    <ul class="st-order-summary__list">
      <li class="st-order-summary__list__item">
        <span class="st-order-summary__list__item__label">Zon:</span>
        <span>{{ orderSummary.zones }} ({{ orderSummary.stopAreas }})</span>
      </li>
      <li class="st-order-summary__list__item">
        <span class="st-order-summary__list__item__label">Biljettyp:</span>
        <span>{{ orderSummary.ticketType }}</span>
      </li>
      <li class="st-order-summary__list__item">
        <span class="st-order-summary__list__item__label">Resande:</span>
        <span>{{ orderSummary.travellers }}</span>
      </li>
      <li class="st-order-summary__list__item">
        <span class="st-order-summary__list__item__label">Antal biljetter:</span>
        <span>{{ orderSummary.numberOfTickets }} st</span>
      </li>
      <li class="st-order-summary__list__item" v-if="orderSummary.addons">
        <span class="st-order-summary__list__item__label">Tillägg:</span>
        <span>{{ orderSummary.addons }}</span>
      </li>
      <li class="st-order-summary__list__item" v-if="orderSummary.discount">
        <span class="st-order-summary__list__item__label">Tillsammansrabatt:</span>
        <span>{{ orderSummary.discount }}</span>
      </li>
      <li class="st-order-summary__list__item" v-if="orderSummary.amountDue !== orderSummary.total">
        <span class="st-order-summary__list__item__label">Totalt:</span>
        <span>{{ orderSummary.total }} kr</span>
      </li>
      <li class="st-order-summary__list__item" v-if="orderSummary.voucherAmountUsed">
        <span class="st-order-summary__list__item__label">Värdekod:</span>
        <span>{{ orderSummary.voucherAmountUsed }} kr</span>
      </li>
      <li class="st-order-summary__list__item">
        <span class="st-order-summary__list__item__label">Summa betalt:</span>
        <span>{{ orderSummary.amountDue }} kr</span>
      </li>
      <li class="st-order-summary__list__item">
        <span class="st-order-summary__list__item__label">Biljett skickas till:</span>
        <span>{{ orderSummary.bearer }}</span>
      </li>
      <li class="st-order-summary__list__item">
        <span class="st-order-summary__list__item__label">Bekräftelse skickas till:</span>
        <span>{{ orderSummary.email }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'OrderSummary',
  data() {
    return {
      orderSummary: {}
    };
  },
  mounted() {
    this.orderSummary = JSON.parse(sessionStorage.getItem('st-bns-order-summary'));
  }
};
</script>

<style lang="scss" scoped>
.st-order-summary {
  max-width: 795px;
  margin: 0 auto;
  text-align: left;
  &__list {
    list-style: none;
    padding: 0;
    &__item {
      margin: 8px 0;
      &__label {
        font-weight: bold;
        margin-right: 4px;
      }
    }
  }
}
</style>
