import api from '../../api';

import {
  GET_VOUCHER_BY_CODE,
  ADD_VOUCHER,
  GET_VOUCHERS,
  GET_VOUCHER_TOTAL,
  CLEAR_VOUCHERS
} from '../actions/voucher-actions';

const state = {
  vouchers: []
};

const actions = {
  [GET_VOUCHER_BY_CODE]: (store, payload) => {
    const url = `vouchers/code`;
    return api({
      method: 'post',
      data: {
        Code: payload.data.voucher
      },
      url: url,
      headers: { 'RecaptchaToken': payload.token }
    }).then(result => {
      return result.data;
    });
  }
};

const mutations = {
  [ADD_VOUCHER]: (state, payload) => {
    if (state.vouchers.find(voucherEl => voucherEl.voucherId === payload.voucherId)) {
      return;
    }

    let voucher = payload;
    state.vouchers.push(voucher);
  },

  [CLEAR_VOUCHERS]: (state) => {
    state.vouchers = [];
  }
}

const getters = {
  [GET_VOUCHERS]: state => {
    return state.vouchers;
  },

  [GET_VOUCHER_TOTAL]: state => {
    let total = 0;
    for (let i = 0; i < state.vouchers.length; i++) {
      total += state.vouchers[i].amountIncVat;
    }

    return total;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};