//actions
export const SET_CAMPAIGN_JOURNEY_OFFER = 'SET_CAMPAIGN_JOURNEY_OFFER';
export const GET_CAMPAIGN_CARD = 'GET_CAMPAIGN_CARD';

//mutations
export const SET_CAMPAIGN_TICKET_OFFERS = 'SET_CAMPAIGN_TICKET_OFFERS';
export const SET_CAMPAIGN_TICKET_OFFERS_MODEL = 'SET_CAMPAIGN_TICKET_OFFERS_MODEL';
export const SET_CAMPAIGN_TICKETOFFER_NAME = 'SET_CAMPAIGN_TICKETOFFER_NAME';

//getters
export const GET_CAMPAIGN_JOURNEY = 'GET_CAMPAIGN_JOURNEY';
export const GET_CAMPAIGN_TICKET_OFFERS = 'GET_CAMPAIGN_TICKET_OFFERS';
export const IS_CAMPAIGN_JOURNEY_WITH_BANNER = 'IS_CAMPAIGN_JOURNEY_WITH_BANNER';
export const GET_CAMPAIGN_TICKET_OFFERS_MODEL = 'GET_CAMPAIGN_TICKET_OFFERS_MODEL';
export const HAS_CAMPAIGN_OFFERS = 'HAS_CAMPAIGN_OFFERS';
export const GET_CAMPAIGN_TICKETOFFER_NAME = 'GET_CAMPAIGN_TICKETOFFER_NAME';
export const IS_TRAVELLERS_FIXED = 'IS_TRAVELLERS_FIXED';
