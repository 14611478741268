<template>
  <div class="st-status">
    <span class="dot" :class="status.color" :aria-label="ariaLabelStatus"></span>
    <span class="text-column">{{ status.text }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'st-status',
  props: {
    status: {
      type: Object,
      required: true
    },
    showWarning: {
      type: Boolean,
      default: false
    },
    alignRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      card: (state) => state.cardModule.selectedCard
    }),
    ariaLabelStatus() {
      if (Object.keys(this.status).length === 0) return 'En cirkel';

      if (!this.card.isActive) return this.$t('Ticket_Status_Dot_Blocked');

      if (this.status.color.indexOf('red') > -1) {
        return this.$t('Ticket_Status_Dot_Consumed');
      }
      return this.$t('Ticket_Status_Dot_Active');
    }
  }
};
</script>

<style lang="scss" scoped>
.st-status {
  .dot {
    height: 0.5625em;
    width: 0.5625em;
    margin-right: 0.25em;
    border-radius: 50%;
    display: inline-block;
    background-color: transparent;
    border: 0.0625em solid #6e6054;

    &.red {
      border: none;
      background-color: #e14949;
    }

    &.yellow {
      border: none;
      background-color: #f6bb0a;
    }
    &.green {
      border: none;
      background-color: #4cba4d;
    }
  }

  .text-column {
    display: inline;
  }
}
</style>
