<template>
  <div class="request-reset-password-form">
    <st-form-group id="emailAdress" :label="$t('ResetPasswordForm_Email_Label')" required :validation="$v.model.email" :validationErrors="validationErrors.email">
      <template #default="{ ariaDescribedBy }">
        <st-input ref="resetPasswordEmail" id="emailAdress" :aria-describedby="ariaDescribedBy" :placeholder="$t('ResetPasswordForm_Email_Placeholder')" required v-model="model.email" :invalid="$v.model.email.$error" autocomplete="email" :blur="$v.model.email.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>

    <st-form-group :label="$t('ResetPasswordForm_ResetPasswordMedia_Label')" required>
      <template #default="{ ariaDescribedBy }">
        <div style="dispalay: block">
          <st-radio-button id="email" :aria-describedby="ariaDescribedBy" :value="2" v-model="model.resetPasswordMedia" tabindex="0">{{ $t('ResetPasswordForm_ResetPasswordMedia_Email') }}</st-radio-button>
          <st-radio-button id="sms" :aria-describedby="ariaDescribedBy" :value="1" v-model="model.resetPasswordMedia" tabindex="0">{{ $t('ResetPasswordForm_ResetPasswordMedia_SMS') }}</st-radio-button>
        </div>
      </template>
    </st-form-group>

    <st-button medium block primary @click="submit" :disabled="$v.$invalid || isLoading" tabindex="0">{{ $t('ResetPasswordForm_Button_Submit') }}</st-button>

    <p v-if="showInformation" style="padding-top: 1.125em">{{ $t('RequestResetPassword_Email_Information') }}</p>

    <st-error v-if="error" :error="error" />
  </div>
</template>
<script>
import api from '../../api/index';

import RequestResetPasswordFormValidations from './request-reset-password-form-validations';
import RequestResetPasswordFormValidationErrors from './request-reset-password-form-validation-errors';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      model: {
        email: '',
        resetPasswordMedia: 2
      },
      isLoading: false,
      showInformation: false,
      error: undefined,
      validationErrors: RequestResetPasswordFormValidationErrors
    };
  },
  mounted() {
    this.$refs.resetPasswordEmail.$el.focus();
    this.model.email = this.storedEmail;
  },
  validations: {
    model: RequestResetPasswordFormValidations
  },
  computed: {
    ...mapState({
      storedEmail: (state) => state.accountModule.email
    })
  },
  methods: {
    submit() {
      this.error = undefined;
      this.isLoading = true;
      const url = 'mitt-konto/request-reset-password';
      api
        .post(url, this.model)
        .then(({ data }) => {
          if (this.model.resetPasswordMedia === 1) {
            this.$router.push({
              name: 'reset-password',
              params: {
                email: this.model.email,
                showPinInput: true
              }
            });
          } else {
            this.showInformation = true;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error;
          this.isLoading = false;
          this.$nextTick(() => {
            this.$refs.resetPasswordEmail.$el.focus();
          });
        });
    }
  }
};
</script>
