import api from '../../api';
import { GET_TRANSACTION, GET_TICKETS, GET_HMAC, SET_HMAC, PAY_DEBT, GET_RECEIPT, SET_RECEIPT } from '../actions/tapnride-actions';
const state = {
  hmac: undefined,
  receiptData: undefined
};

let actions = {
  [GET_TRANSACTION]: ({ commit }, data) => {
    const url = 'tapnride/transactions';

    return api.post(url, data, { recaptchaAction: url }).then(result => {
      console.log('DONE', result);
      return result.data;
    });
  },
  [GET_TICKETS]: ({ commit }, data) => {
    const url = 'tapnride/tickets';

    return api.post(url, data, { recaptchaAction: url }).then(result => {
      console.log('DONE', result);
      return result.data;
    });
  },
  [GET_RECEIPT]: ({ commit }, data) => {
    const url = 'tapnride/reciept';

    return api.post(url, data, { recaptchaAction: url }).then(result => {
      commit(SET_RECEIPT, result.data);
      return result.data;
    });
  },
  [GET_HMAC]: ({ commit }) => {
    const url = 'tapnride/hmac';

    return api.get(url).then(result => {
      let hmacData = result.data;
      commit(SET_HMAC, hmacData);
      return hmacData;
    });
  },
  [PAY_DEBT]: ({ commit }, data) => {
    const url = 'tapnride/pay';

    return api.post(url, data, { recaptchaAction: url }).then(result => {
      return result.data;
    });
  }
};

const mutations = {
  [SET_HMAC]: (state, payload) => {
    state.hmac = payload;
  },
  [SET_RECEIPT]: (state, payload) => {
    state.receiptData = payload;
  }
};

const getters = {
  [GET_HMAC]: state => {
    return state.hmac;
  },
  [GET_RECEIPT]: state => {
    return state.receiptData;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
