<template>
  <span class="st-toggle">
    <input type="checkbox" class="checkbox-toggle" :aria-label="ariaLabel" :value="value" :checked="state" @change="onChange" @keydown.enter.prevent="onChange" :tabindex="tabindex" />
    <span class="slider round"></span>
  </span>
</template>

<script>
import globalMixins from '../../../mixins/globalMixin';
export default {
  mixins: [globalMixins],
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    value: {
      default: null
    },
    modelValue: {
      default: undefined
    },
    checked: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '1'
    },
    ariaLabel: {
      type: String,
      default: 'Toggle knapp'
    }
  },
  computed: {
    state: function () {
      if (this.modelValue === undefined) {
        return this.checked;
      }

      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1;
      }

      return !!this.modelValue;
    }
  },
  methods: {
    onChange: function () {
      this.toggle();
    },
    toggle: function () {
      let value;

      if (Array.isArray(this.modelValue)) {
        value = this.modelValue.slice(0);

        if (this.state) {
          value.splice(value.indexOf(this.value), 1);
        } else {
          value.push(this.value);
        }
      } else {
        value = !this.state;
      }
      this.$emit('input', value);
      this.$emit('togglechanged');
    }
  },
  mounted: function () {
    if (this.checked && !this.state) {
      this.toggle();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/focus.scss';
.st-toggle {
  position: relative;
  display: inline-block;
  width: 2.625em;
  height: 1.5em;

  input {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }

  input:checked + .slider {
    background-color: #00726F;
  }

  input:checked:hover + .slider {
    background-color: #00504E;
  }

  input:hover + .slider {
    background-color: #3E3B39;
  }

  input:focus + .slider {
    @include link-focus-outline;
    outline-offset: 0.12em;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(1.125em);
    -ms-transform: translateX(1.125em);
    transform: translateX(1.125em);
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #706C6A;

    &:before {
      position: absolute;
      content: '';
      height: 1.125em;
      width: 1.125em;
      left: 0.1875em;
      bottom: 0.1875em;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 1.0625em;

      &:before {
        border-radius: 50%;
      }
    }
  }
}
</style>
