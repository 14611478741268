<template>
  <div class="card-information">
    <h1>{{ $t('CardInformation_Header') }}</h1>
    <p>{{ $t('CardInformation_Description') }}</p>

    <st-button ref="linkCardRegistration" block medium class="register-card-button activate-button" tabindex="0" @click="toCardRegistrationRoute">
      <st-icon name="add-card-add" style="width: 2.188em; height: 1.563em; margin-right: 0.75em" :alt="$t('Start_BuyTicket_Header')" inline useSvg />
      {{ $t('Register_Card_Button') }}
    </st-button>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$refs.linkCardRegistration.$el.focus();
  },
  methods: {
    toCardRegistrationRoute() {
      this.$router.push({ path: 'cards/card-registration' });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2.25em !important;
}
.register-card-button.st-button {
  height: 4.5em !important;
  border: 0.0625em dashed #f6bb0a;

  &:hover {
    border-color: #f6bb0a !important;
  }
}
</style>
