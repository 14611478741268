import i18n from '../../i18n/index';

export default {
  currentPassword: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Password_Required_Validation'),
      id: 'currentPasswordRequired'
    }
  ],
  newPassword: [
    {
      key: 'required',
      message: i18n.t('SignupForm_Password_Required_Validation'),
      id: 'newPasswordRequired'
    },
    {
      key: 'passwordFormat',
      message: i18n.t('SignupForm_Password_Format_Validation'),
      id: 'newPasswordFormat'
    }
  ]
};
