import i18n from '../../i18n/index';

export default {
  token: [
    {
      key: 'tokenFormat',
      message: i18n.t('SetPasswordForm_Token_Format_Validation'),
      id: 'tokenFormat'
    },
    {
      key: 'requiredIf',
      message: i18n.t('SetPasswordForm_Token_Required_Validation'),
      id: 'tokenRequired'
    }
  ],
  password: [
    {
      key: 'required',
      message: i18n.t('SetPasswordForm_Password_Required_Validation'),
      id: 'passwordRequired'
    },
    {
      key: 'passwordFormat',
      message: i18n.t('SetPasswordForm_Password_Format_Validation'),
      id: 'passwordFormat'
    }
  ],
  repeatPassword: [
    {
      key: 'sameAsPassword',
      message: i18n.t('SetPasswordForm_RepeatPassword_SameAs_Validation'),
      id: 'sameAsPasswordValidation'
    }
  ]
};
