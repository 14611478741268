// Event categories for Google Analytic
export const EVENT_CATEGORY_BUY_CARD = 'Buy card';
export const EVENT_CATEGORY_BUY_TICKET = 'Buy ticket';
export const EVENT_CATEGORY_ACTIVATE_TICKET = 'Activate ticket';

//Event actions for category Buy card
export const EVENT_ACTION_BUY_CARD = 'Buy card';
//Event actions for category Buy ticket
export const EVENT_ACTION_BUY_TICKET = 'Buy ticket';
//Event actions for category Activate ticket
export const EVENT_ACTION_ACTIVATE_TICKET = 'Activate ticket';
export const EVENT_ACTION_CONFIRM_ACTIVATE_TICKET = 'Confirm activate ticket';