import globalFunctionsService from '../services/globalFunctionsService';

class Price {
  constructor(amountIncludingVat, vatPercentage, amountIncludingVatAfterDiscount, round = true) {
    this.amountIncludingVat = amountIncludingVat;
    this.vatPercentage = vatPercentage || 0;
    this.amountIncludingVatAfterDiscount = amountIncludingVatAfterDiscount || amountIncludingVat;
    this.round = round;
  }

  get vat() {
    if (this.round) {
      return globalFunctionsService.roundTo((this.amountIncludingVat * (this.vatPercentage / 100)) / (this.vatPercentage / 100 + 1), 2);
    } else {
      return (this.amountIncludingVat * (this.vatPercentage / 100)) / (this.vatPercentage / 100 + 1);
    }
  }

  get vatAfterDiscount() {
    if (this.round) {
      return globalFunctionsService.roundTo((this.amountIncludingVatAfterDiscount * (this.vatPercentage / 100)) / (this.vatPercentage / 100 + 1), 2);
    } else {
      return (this.amountIncludingVatAfterDiscount * (this.vatPercentage / 100)) / (this.vatPercentage / 100 + 1);
    }
  }
}

export default Price;
