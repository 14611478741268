<template>
  <st-modal class="card-info-modal" :ariaLabelledby="'card-info-header'" :ariaDescribedby="'ci-modal-body-default'" :onClose="close">
    <div slot="header" class="st-modal-header-default">
      <h1 id="card-info-header">{{ $t('CardInfoModal_Header') }}</h1>
    </div>
    <div slot="body" id="ci-modal-body-default" class="st-modal-body-default">
      <card-info-image />
      <p v-html="$t('CardInfoModal_Description')" class="card-info-description"></p>
    </div>
    <div slot="footer" class="st-modal-footer-default">
      <st-button @click="close" @keydown.tab.prevent.native="onCancelKeyDown" medium block :tabindex="tabindex" id="cancel">{{ $t('CardInfoModel_Button_Close') }}</st-button>
    </div>
  </st-modal>
</template>

<script>
import CardInfoImage from './CardInfoImage';
import globalMixins from '../mixins/globalMixin';
export default {
  mixins: [globalMixins],
  props: {
    close: {
      type: Function
    },
    tabindex: {
      type: String,
      default: '0'
    }
  },
  components: {
    CardInfoImage
  },
  mounted() {
    this.setLinkAttributes();
    let cardModalLink = document.getElementById('card-info-modal-link');
    cardModalLink.focus();

    cardModalLink.addEventListener('keydown', this.onTabShiftBack);
  },
  beforeDestroy() {
    let cardModalLink = document.getElementById('card-info-modal-link');
    cardModalLink.removeEventListener('keydown', this.onTabShiftBack);
  },
  methods: {
    onCancelKeyDown(e) {
      this.cancelKeyDown(e, 'card-info-modal-link');
    },
    setLinkAttributes() {
      // Vi vill inte hårdkoda tabindex i lokalise på a-tag så därför sätter vi tabindex i komponenten
      let cardInfoModalLink = document.getElementById('card-info-modal-link');
      cardInfoModalLink.setAttribute('tabindex', this.tabindex);
    },
    onTabShiftBack(e) {
      this.shiftTabBack(e, 'cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.card-info-modal {
  h1 {
    font-size: 1.9375em !important;
  }
  .st-modal-header-default {
    padding: 2.25em 1.5em 1.5em !important;
    @media (min-width: 768px) {
      padding: 2.25em 2.625em !important;
    }
  }

  .st-modal-body-default {
    .card-info-description {
      margin-top: 1.875em !important;

      /deep/ a {
        font-weight: bolder;
      }
    }
  }
}
</style>
