import i18n from '../../i18n/index';

export default {
  cardNumber: [
    {
      key: 'required',
      message: i18n.t('CardNumber_Required_Validation'),
      id: 'cardNumberRequired'
    },
    {
      key: 'numericLength',
      message: i18n.t('CardNumber_Format_Validation'),
      id: 'cardNumberLength'
    }
  ]
};
