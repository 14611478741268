<template>
  <div class="campaign-cards-item">
    <div
      class="card-body"
      :style="{ backgroundColor: item.color }"
      v-for="(item, id) in campaignModel"
      :key="id"
    >
      <div class="card-body-left col-sm-5 align-self-center">
        <div class="campaign-ticket-title" :style="{ color: item.textColor }">{{ item.name }}</div>
        <div class="campaign-ticket-area-and-price">
          <p :style="{ color: item.textColor }">
            {{ `${item.area} ${item.amountIncludingVat} kr` }}
          </p>
        </div>
      </div>
      <div class="card-body-right col-sm-7 align-self-center">
        <div class="campaign-card-info">
          <p :style="{ color: item.textColor }">
            {{
              $t('Campaign_Ticket_Offer_Info_Text', {
                campaignName: `${item.name.toLowerCase()}en`
              })
            }}
          </p>
        </div>
        <div class="summer-button-wrapper">
          <st-button primary medium @click="setJourney(item.id)" tabindex="0">
            {{
              $t('Campaign_Ticket_Offer_Submit', { campaignName: `${item.name.toLowerCase()}en` })
            }}
          </st-button>
          <st-button medium @click="goToSrc(item.link)" tabindex="0">
            {{ $t('Campaign_Ticket_Offer_Read_More') }}
          </st-button>
        </div>
      </div>
    </div>

    <div
      class="card-body-mobile d-sm-none"
      :style="`background-color: ${item.color}`"
      v-for="(item, i) in campaignModel"
      :key="'A' + i"
    >
      <div class="campaign-ticket-title" :style="{ color: `${item.textColor} !important` }">
        {{ item.name }}
      </div>
      <div class="card-mobile-header">
        <div class="card-body-mobile-offer">
          <p :style="{ color: item.textColor }">
            {{ `${item.area} ${item.amountIncludingVat} kr` }}
          </p>
        </div>
      </div>
      <div class="card-body-mobile-info">
        <p :style="{ color: item.textColor }">
          {{
            $t('Campaign_Ticket_Offer_Info_Text', { campaignName: `${item.name.toLowerCase()}en` })
          }}
        </p>
      </div>
      <div class="card-body-mobile-footer row">
        <div class="col-9">
          <st-button primary medium @click="setJourney(item.id)" tabindex="0">
            {{
              $t('Campaign_Ticket_Offer_Submit', { campaignName: `${item.name.toLowerCase()}en` })
            }}
          </st-button>
        </div>
        <div class="col-3">
          <a
            @click="goToSrc(item.link)"
            :style="{ color: `${item.textColor} !important` }"
            tabindex="0"
            >{{ $t('Campaign_Ticket_Offer_Read_More_Mobile') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  GET_CAMPAIGN_TICKET_OFFERS,
  GET_CAMPAIGN_TICKET_OFFERS_MODEL,
  SET_CAMPAIGN_JOURNEY_OFFER
} from '../../store/actions/campaign-offers-actions';
export default {
  name: 'campaign-card',
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      system: process.env.VUE_APP_SYSTEM
    };
  },
  computed: {
    ...mapGetters({
      getCampaignTicketOffers: GET_CAMPAIGN_TICKET_OFFERS,
      campaignModel: GET_CAMPAIGN_TICKET_OFFERS_MODEL
    })
  },
  methods: {
    ...mapActions({
      setCampaignJourney: SET_CAMPAIGN_JOURNEY_OFFER
    }),
    setJourney(id) {
      if (!this.getCampaignTicketOffers || Object.keys(this.getCampaignTicketOffers).length === 0) {
        throw new Error('No existing Campaign Offer. Campaign Offer is null or undefined.');
      }
      this.setCampaignJourney(id).catch((err) => console.error(err));

      setTimeout(() => {
        document.getElementsByClassName('campaign-ticket-header')[0].focus();
      }, 300);
    },
    goToSrc(link) {
      if (!link) return;
      window.open(link, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/fonts.scss';
.campaign-cards-item {
  .campaign-ticket-title {
    font-size: 43px;
    font-family: $bitter;
    font-weight: 600;
    margin-bottom: 8px;
    word-break: break-all;
  }

  img {
    position: relative;
    top: 0.3125em;
    width: 100%;
    height: auto;
  }
  .card-body {
    padding: 0.625em;
    border-radius: 0.5em;
    display: none;
    margin-top: 1.25em;
    @media (min-width: 576px) {
      display: flex;
    }

    .card-body-left {
      @media (max-width: 576px) {
        padding-top: 0.625em !important;
      }
      .campaign-ticket-area-and-price {
        p {
          font-weight: bold !important;
        }
      }
    }
    .card-body-right {
      .campaign-card-info {
        p {
          margin-bottom: 0em !important;
        }
      }
      .summer-button-wrapper {
        display: flex;
        justify-content: space-between;
        .st-button {
          width: 49%;
          padding: 0.75em 0em !important;
          margin-top: 0.9375em !important;
          @media (min-width: 576px) and (max-width: 620px) {
            font-size: 0.8125em !important;
          }

          @media (min-width: 620px) and (max-width: 768px) {
            font-size: 0.875em !important;
          }
        }
      }
    }
  }

  .card-body-mobile {
    padding: 0.625em;
    border-radius: 0.5em;
    .card-body-mobile-offer {
      @media (max-width: 320px) {
        padding-left: 0em !important;
      }
      p {
        font-weight: bold !important;
      }
    }

    .card-body-mobile-info {
      margin: 0.9375em 0em;
    }

    .card-body-mobile-footer {
      .st-button {
        width: 100%;
        padding: 0.75em 0em 0.875em !important;
      }
      .col-3 {
        padding-top: 1.875em;
        padding-left: 0.625em !important;
        @media (max-width: 320px) {
          padding-left: 0em !important;
        }
        a {
          text-decoration: underline !important;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
