<template>
  <input
    :id="id"
    :type="type"
    :value="value"
    :placeholder="placeholder"
    :class="classList"
    :disabled="disabled"
    :tabindex="tabindex"
    :aria-required="required"
    :aria-invalid="invalid"
    :autocomplete="autocomplete"
    :aria-activedescendant="activedescendant"
    :aria-controls="ariaControls"
    @input="onInput"
    @blur="onBlur"
    @change="onChange"
    @focus="onFocus"
    @click="onClick"
    @keydown.down.prevent="onKeydownDown"
    @keydown.up.prevent="onKeydownUp"
    @keyup.esc="onKeyupEsc"
    @keydown.enter="onKeydownEnter"
  />
</template>

<script>
export default {
  name: 'st-input',

  data: function () {
    return {
      localValue: this.value
    };
  },

  props: {
    id: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text',
      validator: function (type) {
        return ['text', 'number', 'date', 'tel', 'password'].includes(type);
      }
    },
    placeholder: {
      type: String
    },
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    invalid: {
      type: Boolean,
      default: false
    },
    blur: {
      type: Function
    },
    focus: {
      type: Function
    },
    click: {
      type: Function
    },
    input: {
      type: Function
    },
    keydownDown: {
      type: Function
    },
    keydownUp: {
      type: Function
    },
    keyupEsc: {
      type: Function
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    classes: {
      type: Array,
      default: () => []
    },
    activedescendant: {
      type: String
    },
    ariaControls: {
      type: String
    }
  },

  computed: {
    classList: function () {
      return [...this.classes, 'st-input'];
    }
  },

  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localValue = newVal;
      }
    },
    localValue: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal);
      }
    }
  },

  methods: {
    onInput: function (evt) {
      this.localValue = evt.target.value;
      if (this.input) this.input();
    },
    onChange: function (evt) {
      this.localValue = evt.target.value;
      this.$emit('change', this.localValue);
    },
    onBlur: function () {
      if (this.blur) this.blur();
    },
    onFocus: function () {
      if (this.focus) this.focus();
    },
    onClick: function () {
      if (this.click) this.click();
    },
    onKeydownDown: function () {
      if (this.keydownDown) this.keydownDown();
      this.$emit('keydownDown');
    },
    onKeydownUp: function () {
      if (this.keydownUp) this.keydownUp();
      this.$emit('keydownUp');
    },
    onKeyupEsc: function () {
      if (this.keyupEsc) this.keyupEsc();
    },
    onKeydownEnter: function () {
      this.$emit('keydownEnter');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/colors.scss';
@import '../../scss/fonts.scss';
.st-input {
  height: 3em;

  border: 0.0625em solid $varm-grå-alt;
  border-radius: 0.25em;
  color: $grey-dark;
  font-family: $source-sans-pro;
  width: 100%;
  box-sizing: border-box;
  padding-left: 1em;
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 0em !important;

  &:focus {
    background-color: #fff;
    border-color: $nästan-svart;
    outline: none;
  }

  &.invalid {
    border-color: $invalid;
    border-width: 0.125em;
    color: $invalid;
  }

  &:disabled {
    background-color: $off-white;
  }

  &::placeholder {
    opacity: 0.6;
  }
}
</style>
