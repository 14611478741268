import api from '../../api';

import TicketOffersService from '../../services/ticketOffersService';
let ticketOffersService = new TicketOffersService();

import Offer from '../../models/offer';

import { GET_TICKET_OFFERS_OFFERS_NAME_LOADED,GET_TICKET_OFFER_LOADED, SET_TICKET_OFFERS_OFFERS_NAME_LOADED, SET_TICKET_OFFER_LOADED, GET_TICKET_OFFERS, GET_TICKET_OFFERS_OFFERS, GET_TICKET_OFFERS_OFFERS_NAME, SET_TICKET_OFFERS, SET_TICKET_OFFERS_NAMES, HAS_VALID_TICKET_OFFERS, SET_TICKET_OFFER_DISABLED, SET_SELECTED_OFFER_ID, GET_SELECTED_OFFER_ID, GET_TICKET_OFFER_DESCRIPTION } from '../actions/ticket-offers-actions';
import { SET_CAMPAIGN_TICKET_OFFERS, GET_CAMPAIGN_CARD } from '../actions/campaign-offers-actions';

const state = {
  selectedOfferId: '' + process.env.VUE_APP_START_OFFER,
  ticketOffers: {},
  ticketOffersNames: {},
  ticketOfferDescription: '',
  ticketOffersLoaded: false,
  ticketOffersNameLoaded: false,
};

const actions = {
  [GET_TICKET_OFFERS]: ({ commit, dispatch }) => {
    const url = 'orchestrator/ticket-offers';
    return new Promise(resolve => {
      api
        .get(url)
        .then(result => {
          commit(SET_TICKET_OFFERS, result.data);
          commit(SET_CAMPAIGN_TICKET_OFFERS, result.data);
          commit(SET_TICKET_OFFER_LOADED, true);
          dispatch(GET_CAMPAIGN_CARD);
          resolve(result);
        })
        .finally(() => {
          resolve();
        });
    });
  },
  [GET_TICKET_OFFERS_OFFERS_NAME]: ({ commit }) => {
    const url = 'orchestrator/ticket-offers-names';
    return new Promise(resolve => {
      return api
        .get(url)
        .then(result => {
          commit(SET_TICKET_OFFERS_NAMES, result.data);
          commit(SET_TICKET_OFFERS_OFFERS_NAME_LOADED, true);
          resolve(result);
        })
        .finally(() => {
          resolve();
        });
    });
  }
};

const mutations = {
[SET_TICKET_OFFER_LOADED]: (state, bool) => {
  state.ticketOffersLoaded = bool
},
[SET_TICKET_OFFERS_OFFERS_NAME_LOADED]: (state, bool) => {
  state.ticketOffersNameLoaded = bool
},
  [SET_TICKET_OFFERS]: (state, payload) => {
    state.ticketOffers = payload;
    ticketOffersService.setTicketOffers(payload);
  },
  [SET_TICKET_OFFERS_NAMES]: (state, payload) => {
    state.ticketOffersNames = payload;
  },
  [SET_TICKET_OFFER_DISABLED]: (state, payload) => {
    let xOffer = state.ticketOffers.offers.find(o => o.id === payload.id);
    if (xOffer) {
      let indexOf = state.ticketOffers.offers.indexOf(xOffer);
      xOffer.disable = payload.disable;
      state.ticketOffers.offers.splice(indexOf, 1, xOffer);
    }
  },
  [SET_SELECTED_OFFER_ID]: (state, payload) => {
    state.selectedOfferId = payload;
  }
};

const getters = {
  [GET_TICKET_OFFERS_OFFERS_NAME_LOADED]: state => state.ticketOffersNameLoaded,
  [GET_TICKET_OFFER_LOADED]: state => state.ticketOffersLoaded,
  [GET_SELECTED_OFFER_ID]: state => {
    return state.selectedOfferId;
  },
  [HAS_VALID_TICKET_OFFERS]: state => {
    if (!state.ticketOffers) return false;

    return new Date(state.ticketOffers.validUntil) > new Date();
  },
  [GET_TICKET_OFFERS]: state => {
    return state.ticketOffers;
  },
  [GET_TICKET_OFFERS_OFFERS]: state => {
    if (!state.ticketOffers || !state.ticketOffers.offers) {
      return;
    }

    let offers = [];
    for (let i = 0; i < state.ticketOffers.offers.length; i++) {
      let offer = state.ticketOffers.offers[i];
      offers.push(new Offer(offer.id, offer.name.sv, offer.description.sv, offer.ticketOfferType, offer.sortOrder, offer.disable));
    }
    return offers;
  },
  [GET_TICKET_OFFERS_OFFERS_NAME]: state => offerId => {
    let offerName = '';
    for (let i = 0; i < state.ticketOffersNames.length; i++) {
      let offer = state.ticketOffersNames[i];
      if (offer.id === offerId) {
        offerName = offer.name.sv;
        break;
      }
    }

    return offerName;
  },
  [GET_TICKET_OFFER_DESCRIPTION]: (state, getters, rootState) => {
    let ticketOffers = getters.GET_TICKET_OFFERS_OFFERS;
    let ticketOffer = ticketOffers.filter(offer => offer.id === rootState.ticketModule.offerId)[0];
    if (ticketOffer) {
      state.ticketOfferDescription = ticketOffer.description;
    }
    return state.ticketOfferDescription;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
