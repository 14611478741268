<template>
  <div v-if="!ticketOffersNamesLoaded">
    <st-spinner />
  </div>
  <div v-else class="registered-card-list">
    <div class="d-none d-sm-block">
      <st-table :items="cardList" :fields="cardFields" doubleLines cards useToggle :rowClick="click" :descriptionId="descriptionId" columnPadding="0em 0em" :cellHeight="0.1">
        <template slot="type">
          <img style="margin: -0.5em; height: auto" :src="cardSandSrc" :alt="$t('Start_BuyCard_Alt_Text')" />
        </template>
        <template slot="tickets" slot-scope="{ item }">
          <ul>
            <li v-for="(ticketGroup, index) in ticketGroups(item)" :key="index">
              <span>{{ ticketGroup.count }} x {{ offerName(ticketGroup.offerId) }}</span>
            </li>
          </ul>
        </template>
      </st-table>
    </div>
    <div class="d-sm-none">
      <card-list-mobile :items="cardList" :click="click"></card-list-mobile>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_CARDS } from '../../store/actions/card-actions';

import globalMixins from '../../mixins/globalMixin';

import CardListMobile from './CardListMobile';

export default {
  mixins: [globalMixins],
  props: {
    click: Function,
    descriptionId: {
      type: String,
      default: ''
    }
  },
  components: {
    CardListMobile
  },
  data() {
    return {
      cardSandSrc: `${process.env.VUE_APP_BASE_URL}assets/images/${process.env.VUE_APP_SYSTEM}/card-sand.svg`,
      cardFields: [
        {
          label: this.$t('CardList_Table_Type'),
          key: 'type'
        },
        {
          label: this.$t('CardList_Table_Name'),
          key: 'name'
        },
        {
          label: this.$t('CardList_Table_CardNumber'),
          key: 'cardNumber',
          customFormat: function (value) {
            return value
              .substr(0, 12)
              .toString()
              .replace(/[^\dA-Z]/g, '')
              .replace(/(.{4})/g, '$1 ')
              .trim();
          }
        },
        {
          label: this.$t('CardList_Table_Content'),
          key: 'tickets'
        }
        // {
        //   label: this.$t('CardList_Table_Status'),
        //   key: 'status'
        // }
      ]
    };
  },
  computed: {
    ...mapGetters({
      cards: GET_CARDS
    }),
    cardList() {
      if (!this.cards) return;

      let cards = [];
      for (let i = 0; i < this.cards.length; i++) {
        const card = this.cards[i];
        cards.push(card);
      }

      return cards;
    }
  },
  methods: {
    ticketGroups(item) {
      let groups = [];
      for (let i = 0; i < item.tickets.length; i++) {
        const ticket = item.tickets[i];
        if (ticket.status > 2) continue;

        let group = groups.filter((x) => {
          return x.offerId === ticket.offerId;
        })[0];
        if (!group) {
          groups.push({ count: 1, offerId: ticket.offerId });
        } else {
          group.count++;
        }
      }
      return groups;
    }
  }
};
</script>

<style lang="scss" scoped>
.registered-card-list {
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.0313em;
  color: #4d4845;
  .st-table /deep/ {
    .st-card-table {
      th,
      td {
        min-width: 4em;
      }

      .table-header-wrapper {
        th {
          padding: 0.9375em 0em 0.375em 0em;
        }
      }

      tbody {
        tr {
          td {
            &:first-child {
              min-width: 4.5em;
              padding-left: 1.5em !important;
            }
          }
        }
      }
    }
  }

  ul {
    padding-left: 0em;
    margin: 0em !important;
    li {
      list-style-type: none;
    }
  }
}
</style>
