<template>
  <form @submit.prevent.stop="submit" class="login-data-form-password">
    <p>{{ $t('LoginDataForm_Change_Password_LoginData_Text') }}</p>
    <st-form-group id="currentPassword" :label="$t('Edit_MK_Current_Password_Label')" required :validation="$v.model.currentPassword" :validationErrors="validationErrors.currentPassword">
      <template #default="{ ariaDescribedBy }">
        <st-input ref="editPassword" type="password" :aria-describedby="ariaDescribedBy" id="currentPassword" required v-model="model.currentPassword" :invalid="$v.model.currentPassword.$error" :blur="$v.model.currentPassword.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>
    <st-form-group id="newPassword" :label="$t('Edit_MK_New_Password_Label')" required :validation="$v.model.newPassword" :validationErrors="validationErrors.newPassword">
      <template #default="{ ariaDescribedBy }">
        <st-input type="password" id="newPassword" :aria-describedby="ariaDescribedBy" required v-model="model.newPassword" :invalid="$v.model.newPassword.$error" :blur="$v.model.newPassword.$touch" tabindex="0"></st-input>
      </template>
    </st-form-group>
    <div class="edit-form-btn-wrapper">
      <st-button medium class="cancel" :disabled="isLoading" @click="cancel" tabindex="0">Avbryt</st-button>
      <st-button medium primary class="submit" :disabled="$v.$invalid || isLoading" type="submit" tabindex="0">Spara</st-button>
    </div>
    <st-error v-if="error" :error="error" />
  </form>
</template>

<script>
import api from '../../api';
import { mapGetters } from 'vuex';
import { GET_ACCOUNT } from '../../store/actions/account-actions';

import LoginDataFormValidations from './login-data-form-password-validations';
import LoginDataFormValidationErrors from './login-data-form-password-validation-errors';

export default {
  data() {
    return {
      model: {
        email: '',
        emailRepeat: '',
        currentPassword: '',
        newPassword: ''
      },
      isLoading: false,
      error: undefined,
      validationErrors: LoginDataFormValidationErrors
    };
  },
  validations: {
    model: LoginDataFormValidations
  },
  computed: {
    ...mapGetters({
      account: GET_ACCOUNT
    })
  },
  mounted() {
    this.$refs.editPassword.$el.focus();
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    submit() {
      let data = { ...this.model };
      data.userId = this.account.userId;

      this.isLoading = true;
      this.error = undefined;

      if (!this.model.newPassword || this.model.newPassword.trim().length < 8) {
        this.error = { message: 'Nytt lösenord måste innehålla minst 8 tecken.' };
        this.isLoading = false;
        return;
      }

      let url = 'mitt-konto/update-password';
      api
        .put(url, data)
        .then((result) => {
          document.cookie = 'StarJwtTokenCookieName=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
          this.$router.go();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            error.message = this.$t('Error_Change_Password_Unauthorized');
          }
          this.error = error;
          this.isLoading = false;
          this.$nextTick(() => {
            this.$refs.editPassword.$el.focus();
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-form-btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .st-button {
    min-width: 11.25em;
    @media (max-width: 767px) {
      min-width: auto;
      flex: 1;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
    }
  }
}
</style>
