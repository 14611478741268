<template>
  <span :class="classList">
    <input type="checkbox" :id="id" :name="name" :value="value" :aria-label="ariaLabel" :aria-required="ariaRequired" :required="required" :disabled="disabled" @change="onChange" :checked="state" :aria-checked="state" :tabindex="tabindex" :aria-describedby="ariaDescribedBy" />
    <label :for="id">
      <slot name="input-box">
        <span class="input-box">
          <svg role="img" :aria-hidden="ariaHidden" class="input-box-tick" viewBox="0 3 16 16">
            <path fill="none" d="M13 3.75L6.125 10.625L3 7.5" stroke="white" stroke-width="2.5" />
          </svg>
        </span>
      </slot>
      <div :class="{ 'checkbox-label--required': required }">
        <slot>default text</slot>
      </div>
    </label>
  </span>
</template>

<script>
export default {
  name: 'st-checkbox',
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return 'st-checkbox-id-' + this._uid;
      }
    },
    name: {
      type: String,
      default: null
    },
    value: {
      default: null
    },
    ariaHidden: {
      type: Boolean,
      default: false
    },
    modelValue: {
      default: undefined
    },
    checked: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    ariaRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    ariaDescribedBy: {
      type: String
    },
    ariaLabel: {
      type: String
    },
    model: {}
  },

  computed: {
    classList: function () {
      return ['st-checkbox'];
    },
    state: function () {
      if (this.modelValue === undefined) {
        return this.checked;
      }

      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1;
      }

      return !!this.modelValue;
    }
  },

  methods: {
    onChange: function () {
      this.toggle();
    },

    toggle: function () {
      let value;

      if (Array.isArray(this.modelValue)) {
        value = this.modelValue.slice(0);

        if (this.state) {
          value.splice(value.indexOf(this.value), 1);
        } else {
          value.push(this.value);
        }
      } else {
        value = !this.state;
      }

      this.$emit('input', value);
    }
  },

  watch: {
    checked: function (newValue) {
      if (newValue !== this.state) {
        this.toggle();
      }
    }
  },

  mounted: function () {
    if (this.checked && !this.state) {
      this.toggle();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/colors.scss';
.st-checkbox {
  margin-left: 0.313em;
  display: block;
  position: relative;

  input[type='checkbox'] ~ label:before {
    content: '';
  }

  > input {
    opacity: 0;
    position: absolute;
    top: 0.375em;
    left: 0.25em;

    + label > .input-box {
      box-sizing: content-box;
      display: inline-block;
      border-radius: 0.3em;
      border: solid 0.13em #5c5957;
      margin: 0;
      padding: 0;
      width: 1.5em;
      min-width: 1.5em;
      height: 1.5em;
      background: $vit;
      overflow: hidden;
      vertical-align: -15%;
      margin-right: 0.75em;
      user-select: none;

      &:hover {
        border: solid 0.13em #008c8c;
      }

      > .input-box-tick {
        width: 100%;
        height: 100%;

        > path {
          opacity: 0;
          stroke-width: 0.125em;
          stroke-dashoffset: 18;
          stroke-dasharray: 18;
        }
      }
    }

    &:checked + label > .input-box {
      background-color: #00726f;
      box-shadow: none;
      &:hover {
        border: solid 0.13em #5c5957;
        background-color: #008c8c;
      }
    }

    &:focus + label > .input-box {
      opacity: 1;
      box-shadow: 0 0 0 0.125em $vit, 0 0 0 0.25em #5c5957;
      border: solid 0.13em #5c5957;
      outline: 0;
    }

    &:checked + label > .input-box > .input-box-tick > path {
      opacity: 1;
      stroke-dashoffset: 0;
      stroke: $vit;
    }

    &:disabled + label {
      opacity: 0.7;
    }
  }

  label {
    display: inline-flex !important;
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $grey-dark;
  }

  .checkbox-label--required::after {
    content: ' - obligatoriskt';
    font-style: italic;
    font-weight: 400;
  }
}
</style>
