class Voucher {
  constructor(voucherId, code, amountIncVat, voucherType, isRedeemable, reasonNotRedeemable) {
    this.voucherId = voucherId;
    this.voucherCode = code;
    this.amountIncVat = amountIncVat;
    this.voucherType = voucherType;
    this.isRedeemable = isRedeemable;
    this.reasonNotRedeemable = reasonNotRedeemable;
  }
}

export default Voucher;
