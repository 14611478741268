import {
  ADD_TRAVELLER,
  REMOVE_TRAVELLER,
  SET_STOP_AREAS,
  SET_PRICE_MODEL_ID_AND_PRICE_ID,
  SET_PRICE_DATA,
  SET_CIRCLE_ORIGO,
  SET_MODEL,
  SET_OFFER_ID,
  SET_OPTIONAL_CITIES,
  GET_TRAVELLERS_BY_TRAVELLER_TYPE,
  GET_PRICE_BY_TRAVELLER_TYPE,
  GET_DISCOUNTS,
  GET_PRICE,
  GET_TOTAL,
  GET_AMOUNT_DUE,
  GET_VOUCHER_AMOUNT_USED,
  GET_CITY_ADDON,
  GET_OFFER_ID,
  GET_NUMBER_OF_TICKETS,
  CLEAR_TICKET_STATE,
  CLEAR_TICKET_PRICE_DETAILS,
  ADD_ADDON,
  REMOVE_ADDON,
  TOGGLE_ADDON,
  GET_ADDON_BY_ID,
  GET_ADDON_PRICE_BY_ID,
  GET_SELECTED_ADDONS,
  SET_SELECTED_ADDONS,
  SET_ZONES,
  SET_NUMBER_OF_TICKETS,
  GET_TRAVELLER_TOTAL,
  SYNC_ADD_ONS,
  RESET_TICKET_ADDONS,
  SET_TICKET_TRAVELLERS_FOR_MODAL,
  REPURCHASE_TICKET
} from '../actions/ticket-actions';
import axios from 'axios';
import PriceService from '../../services/priceService';
let priceService = new PriceService();
import Traveller from '../../models/traveller';
import { GET_JOURNEYS, SET_JOURNEY, CLEAR_JOURNEY_STATE, GET_JOURNEY_POSSIBLE_JOURNEYS_OPTIONAL_CITIES } from '../actions/journey-actions';
import { UPDATE_MAP_DETAILS, CLEAR_MAP_DETAILS_STATE } from '../actions/map-details-actions';
import { SET_SELECTED_OFFER_ID } from '../actions/ticket-offers-actions';
import Price from '../../models/price';
import PriceSum from '../../models/price-sum';
import { GET_VOUCHER_TOTAL } from '../../store/actions/voucher-actions';
import { GET_AVAILABLE_ADDONS } from '../index';

const state = {
  travellers: [],
  stopAreas: [],
  offerId: process.env.VUE_APP_START_OFFER,
  priceId: '',
  priceModelId: '',
  travelAreaIds: [],
  cityAddonIds: [],
  optionalCityAddonIds: [],
  zones: [],
  circle: {},
  circleOrigo: {},
  addons: [],
  model: {
    cardNumber: '',
    phoneNumber: '',
    bearerType: 'app',
    email: '',
    activateTicketOnPurchase: false,
    acceptTerms: false,
    numberOfTickets: 1
  }
};

const actions = {
  [SET_TICKET_TRAVELLERS_FOR_MODAL]: ({ commit, getters }, payload) => {
    commit(SET_PRICE_MODEL_ID_AND_PRICE_ID, {
      payload: {
        priceDetails: {
          priceModelId: payload.priceModelId,
          priceId: payload.priceId
        }
      },
      getters
    });

    commit(SET_OFFER_ID, { payload: payload.offerId, getters });
  },
  [REPURCHASE_TICKET]: async ({ dispatch, commit, getters, state }, ticketId) => {
    let ticketData = await axios.get(process.env.VUE_APP_API_URL + `ticket-orders/ticketinfo/${ticketId}`);
    await dispatch(SET_STOP_AREAS, ticketData.data.stopPoints);
    await commit(SET_SELECTED_OFFER_ID, ticketData.data.offerId);

    for (let key in ticketData.data.travelerTypeId) {
      let traveler = getters.GET_TRAVELLER_TYPES.find((y) => y.id === key);

      for (let i = 0; i < ticketData.data.travelerTypeId[key]; i++) {
        commit(ADD_TRAVELLER, traveler);
      }
    }

    getters.GET_AVAILABLE_ADDONS.forEach((x) => {
      let addOn = Object.keys(ticketData.data.ticketAddonsId).find((y) => y === x.id);
      if (addOn) {
        for (let i = 0; i < ticketData.data.ticketAddonsId[addOn]; i++) {
          commit(ADD_ADDON, x);
        }
      }
    });

    commit(SET_MODEL, {
      cardNumber: ticketData.data.cardNumber,
      bearerType: 'card',
      email: ticketData.data.email,
      activateTicketOnPurchase: false,
      acceptTerms: false,
      numberOfTickets: 1
    });
  },
  [RESET_TICKET_ADDONS]: ({ dispatch, commit, getters }, payload) => {
    commit(SET_SELECTED_ADDONS, payload);
    dispatch(SET_OPTIONAL_CITIES, payload.find((x) => x.addonType === 2) ? getters.GET_JOURNEY_POSSIBLE_JOURNEYS_OPTIONAL_CITIES : []);
  },
  [SET_STOP_AREAS]: async ({ dispatch, commit, getters }, payload) => {
    if (payload.length > 0) {
      commit(SET_STOP_AREAS, payload);
      await dispatch(GET_JOURNEYS, payload);
      commit(SYNC_ADD_ONS, { getters, dispatch });
    } else {
      commit(SET_STOP_AREAS, payload);
      commit(CLEAR_JOURNEY_STATE);
      commit(CLEAR_MAP_DETAILS_STATE);
      commit(CLEAR_TICKET_PRICE_DETAILS);
    }
  },
  [SET_OFFER_ID]: ({ dispatch, commit, getters }, payload) => {
    commit(SET_OFFER_ID, { payload, getters });
    dispatch(SET_JOURNEY, payload);
  },
  [SET_PRICE_DATA]: ({ dispatch, commit, state, getters }, payload) => {
    const isCampaignOffer = getters.IS_CAMPAIGN_JOURNEY_WITH_BANNER;
    commit(SET_PRICE_MODEL_ID_AND_PRICE_ID, { payload, getters });

    if (isCampaignOffer) {
      commit(SET_STOP_AREAS, []);
    }

    commit(SET_ZONES, payload);

    commit(SET_CIRCLE_ORIGO, payload.circle);

    dispatch(UPDATE_MAP_DETAILS); // if map state is loaded

    const travellerTypes = getters.GET_TRAVELLER_TYPES;
    const travellers = [...state.travellers];

    for (let i = 0; i < travellers.length; i++) {
      const traveller = travellers[i];
      let found = false;

      for (let j = 0; j < travellerTypes.length; j++) {
        const travellerType = travellerTypes[j];

        if (!travellerType.id || traveller.travellerType.id === travellerType.id) {
          found = true;
          break;
        }
      }

      if (!found || isCampaignOffer) {
        commit(REMOVE_TRAVELLER, traveller.travellerType);
      }
    }
    if (isCampaignOffer) {
      for (let i = 0; i < travellerTypes.length; i++) {
        let campaignTravellerType = travellerTypes[i];
        if (campaignTravellerType.min === campaignTravellerType.max) {
          for (let j = 0; j < campaignTravellerType.min; j++) {
            commit(ADD_TRAVELLER, campaignTravellerType);
          }
        }
      }
    }
  },
  [TOGGLE_ADDON]: ({ commit, state }, payload) => {
    const addonCount = state.addons.length;
    commit(TOGGLE_ADDON, payload);

    return state.addons.length > addonCount;
  },
  [SET_OPTIONAL_CITIES]: ({ dispatch, commit }, payload) => {
    commit(SET_OPTIONAL_CITIES, payload);
    dispatch(UPDATE_MAP_DETAILS);
  },
  [CLEAR_TICKET_STATE]: ({ commit }) => {
    commit(CLEAR_TICKET_STATE);
    commit(CLEAR_MAP_DETAILS_STATE);
    commit(SET_SELECTED_OFFER_ID, '' + process.env.VUE_APP_START_OFFER);
  }
};

const mutations = {
  [SYNC_ADD_ONS]: (state, { getters, dispatch }) => {
    state.addons.forEach((addon, index) => {
      let addOnValid = getters.GET_AVAILABLE_ADDONS.find((x) => x.id === addon.id);
      if (!addOnValid) {
        state.addons.splice(index, 1);
        if (addon.addonType === 2) dispatch(SET_OPTIONAL_CITIES, []);
      }
    });
  },
  [SET_MODEL]: (state, payload) => {
    state.model = payload;
  },
  [SET_OFFER_ID]: (state, { payload, getters }) => {
    if (state.offerId !== payload) {
      state.offerId = payload;
      // Ta bort alla tillägg om offerId ändras
      const availableAddons = getters.GET_AVAILABLE_ADDONS;
      const ids = availableAddons.map((addon) => addon.id);
      state.addons = state.addons.filter((addon) => ids.includes(addon.id));
      state.optionalCityAddonIds = [];
    }
  },
  [ADD_TRAVELLER]: (state, payload) => {
    let traveller = new Traveller(payload);
    state.travellers.push(traveller);
  },
  [REMOVE_TRAVELLER]: (state, payload) => {
    let traveller = new Traveller(payload);
    for (let i = 0; i < state.travellers.length; i++) {
      const existingTraveller = state.travellers[i];
      if (traveller.travellerType.travellerType === existingTraveller.travellerType.travellerType) {
        state.travellers.splice(i, 1);
        break;
      }
    }
  },
  [SET_STOP_AREAS]: (state, payload) => {
    state.stopAreas = payload;
  },
  [SET_PRICE_MODEL_ID_AND_PRICE_ID]: (state, { payload, getters }) => {
    let idIsUpdated = false;
    if (state.priceModelId !== payload.priceDetails.priceModelId) {
      state.priceModelId = payload.priceDetails.priceModelId;
      idIsUpdated = true;
    }

    if (state.priceId !== payload.priceDetails.priceId) {
      state.priceId = payload.priceDetails.priceId;
      idIsUpdated = true;
    }

    if (idIsUpdated) {
      // Ta bort alla tillägg om priceModelId eller priceId ändras
      const availableAddons = getters.GET_AVAILABLE_ADDONS;
      const ids = availableAddons.map((addon) => addon.id);
      state.addons = state.addons.filter((addon) => ids.includes(addon.id));
      state.optionalCityAddonIds = [];
    }

    state.travelAreaIds = payload.travelAreaIds;

    if (payload.priceDetails.cityAddons && payload.priceDetails.cityAddons.mandatoryCities.length) {
      state.cityAddonIds = payload.priceDetails.cityAddons.mandatoryCities;
    } else {
      state.cityAddonIds = [];
    }
  },
  [SET_OPTIONAL_CITIES]: (state, payload) => {
    if (payload.length) {
      state.optionalCityAddonIds = payload;
    } else {
      state.optionalCityAddonIds = [];
    }
  },
  [SET_CIRCLE_ORIGO]: (state, payload) => {
    state.circle = payload;
  },
  [SET_ZONES]: (state, payload) => {
    if (payload && payload.priceDetails && payload.priceDetails.priceModelId && payload.priceDetails.priceModelId === 'zones') {
      if (payload.travelAreaIds && payload.travelAreaIds.length > 0) {
        let zones = [];
        for (let i = 0; i < payload.travelAreaIds.length; i++) {
          let item = payload.travelAreaIds[i];
          zones.push({
            id: item,
            name: item
          });
        }

        state.zones = [];
        state.zones = zones;
      }
    }
  },
  [ADD_ADDON]: (state, payload) => {
    for (let i = 0; i < state.addons.length; i++) {
      const addon = state.addons[i];
      if (addon.id == payload.id) {
        addon.count += 1;
        return;
      }
    }

    let addon = payload;
    addon.count = 1;
    state.addons.push(addon);
  },
  [REMOVE_ADDON]: (state, payload) => {
    let removeIndex = -1;
    for (let i = 0; i < state.addons.length; i++) {
      const addon = state.addons[i];
      if (addon.id == payload.id) {
        addon.count -= 1;

        if (addon.count === 0) removeIndex = i;
      }
    }

    if (removeIndex !== -1) {
      state.addons.splice(removeIndex, 1);
    }
  },
  [TOGGLE_ADDON]: (state, payload) => {
    let removeIndex = -1;
    for (let i = 0; i < state.addons.length; i++) {
      const addon = state.addons[i];
      if (addon.id == payload.id) {
        addon.count -= 1;

        if (addon.count === 0) removeIndex = i;
      }
    }

    if (removeIndex !== -1) {
      state.addons.splice(removeIndex, 1);
    } else {
      let addon = payload;
      addon.count = 1;
      state.addons.push(addon);
    }
  },
  [CLEAR_TICKET_STATE]: (state) => {
    state.travellers = [];
    state.stopAreas = [];
    state.offerId = process.env.VUE_APP_START_OFFER;
    state.priceId = '';
    state.priceModelId = '';
    state.cityAddonIds = [];
    state.circle = {};
    state.zones = [];
    state.addons = [];
    state.model = {
      cardNumber: '',
      phoneNumber: '',
      bearerType: 'app',
      email: '',
      activateTicketOnPurchase: false,
      acceptTerms: false,
      numberOfTickets: 1
    };
  },

  [CLEAR_TICKET_PRICE_DETAILS]: (state) => {
    state.priceId = '';
    state.priceModelId = '';
  },

  [SET_SELECTED_ADDONS]: (state, payload) => {
    state.addons = payload;
  },

  [SET_NUMBER_OF_TICKETS]: (state, payload) => {
    state.model.numberOfTickets = payload;
  }
};

const getters = {
  [GET_TRAVELLERS_BY_TRAVELLER_TYPE]: (state) => (travellerType) => {
    let tempTravellers = [];
    for (let i = 0; i < state.travellers.length; i++) {
      const traveller = state.travellers[i];
      if (traveller.travellerType.travellerType === travellerType) {
        tempTravellers.push(traveller);
      }
    }

    return tempTravellers;
  },
  [GET_PRICE_BY_TRAVELLER_TYPE]: (state) => (travellerType) => {
    let sum = 0;
    let hasPrice = state.offerId && state.priceModelId && state.priceId;

    if (hasPrice) {
      for (let i = 0; i < state.travellers.length; i++) {
        const traveller = state.travellers[i];
        if (traveller.travellerType.travellerType === travellerType) {
          let price = priceService.calculatePrice(traveller.travellerType, state.offerId, state.priceModelId, state.priceId, state.cityAddonIds);
          sum += price.amountIncludingVat;
        }
      }
    }

    return sum;
  },
  [GET_ADDON_PRICE_BY_ID]: (state) => (id) => {
    let sum = 0;
    for (let i = 0; i < state.addons.length; i++) {
      const addon = state.addons[i];
      if (addon.id === id) {
        let addonPrice = priceService.getAddonPrice(id, state.priceModelId, state.priceId);
        if (addon.purchasePerTraveller) {
          if (addon.discountable) {
            for (let j = 0; j < state.travellers.length; j++) {
              const traveller = state.travellers[j];
              sum += (addonPrice.amountIncludingVat * (100 - traveller.travellerType.discountPercentage)) / 100;
            }
          } else {
            sum += addonPrice.amountIncludingVat * state.travellers.length;
          }
        } else {
          sum += addonPrice.amountIncludingVat * addon.count;
        }
      }
    }

    return sum;
  },
  [GET_ADDON_BY_ID]: (state) => (id) => {
    for (let i = 0; i < state.addons.length; i++) {
      if (state.addons[i].id === id) return state.addons[i];
    }

    return { count: 0, id };
  },

  [GET_DISCOUNTS]: (state, getters) => {
    let discounts = priceService.calculateDiscount(state.travellers, state.offerId, state.priceModelId, state.priceId, state.cityAddonIds, state.addons);
    for (let i = 0; i < discounts.length; i++) {
      discounts[i].amount = discounts[i].amount * getters.GET_NUMBER_OF_TICKETS;
    }

    return discounts;
  },

  [GET_PRICE]: (state) => {
    let hasPrice = state.offerId && state.priceModelId && state.priceId;
    let discounts = priceService.calculateDiscount(state.travellers, state.offerId, state.priceModelId, state.priceId, state.cityAddonIds, state.addons);

    let sumAmountIncludingVat = 0;
    let vatPercentage = 0;
    let sumDiscount = 0;
    let prices = [];
    if (hasPrice) {
      for (let i = 0; i < state.travellers.length; i++) {
        let price = priceService.calculatePrice(state.travellers[i].travellerType, state.offerId, state.priceModelId, state.priceId, state.cityAddonIds);
        vatPercentage = price.vatPercentage;
        sumAmountIncludingVat += price.amountIncludingVat;
      }

      for (let i = 0; i < discounts.length; i++) {
        const discount = discounts[i];
        sumDiscount += discount.amount;
      }
    }

    prices.push(new Price(sumAmountIncludingVat, vatPercentage, sumAmountIncludingVat - sumDiscount, false));

    for (let i = 0; i < state.addons.length; i++) {
      const addon = state.addons[i];
      let addonPrice = priceService.getAddonPrice(addon.id, state.priceModelId, state.priceId);
      vatPercentage = addonPrice.vatPercentage;
      let amount = 0;
      if (addon.purchasePerTraveller) {
        if (addon.discountable) {
          for (let j = 0; j < state.travellers.length; j++) {
            const traveller = state.travellers[j];
            amount += (addonPrice.amountIncludingVat * (100 - traveller.travellerType.discountPercentage)) / 100;
          }
        } else {
          amount += addonPrice.amountIncludingVat * state.travellers.length;
        }
      } else {
        amount += addonPrice.amountIncludingVat * addon.count;
      }

      prices.push(new Price(amount, vatPercentage, amount, false));
    }

    return new PriceSum(prices);
  },

  [GET_TOTAL]: (state, getters) => {
    let unitPrice = getters.GET_PRICE;
    let quantity = getters.GET_NUMBER_OF_TICKETS;
    return unitPrice.amountIncludingVatAfterDiscount * quantity;
  },

  [GET_AMOUNT_DUE]: (state, getters) => {
    return getters.GET_TOTAL - getters.GET_VOUCHER_AMOUNT_USED;
  },

  [GET_VOUCHER_AMOUNT_USED]: (state, getters) => {
    let total = getters.GET_TOTAL;
    let vouchersTotal = getters.GET_VOUCHER_TOTAL;
    let amount;
    if (vouchersTotal <= total) {
      amount = vouchersTotal;
    } else {
      amount = total;
    }
    return amount;
  },

  [GET_CITY_ADDON]: (state) => {
    let cities = state.cityAddonIds.length ? state.cityAddonIds : state.optionalCityAddonIds;
    return priceService.getCityAddon(state.offerId, state.priceModelId, state.priceId, cities);
  },

  [GET_SELECTED_ADDONS]: (state) => {
    return state.addons;
  },

  [GET_OFFER_ID]: (state) => {
    return state.offerId;
  },

  [GET_NUMBER_OF_TICKETS]: (state) => {
    return state.model.numberOfTickets;
  },
  [GET_TRAVELLER_TOTAL]: (state) => {
    return state.travellers;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
