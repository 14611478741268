export default {
  getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2)
      return parts
        .pop()
        .split(';')
        .shift();
  },

  getTokenFromCookie(name) {
    let cookie = this.getCookie(name);
    if (!cookie) return;

    let token = cookie.substr(cookie.indexOf('=') + 1);
    if (!token) return;

    return token.substr(0, token.indexOf('&'));
  },

  removeCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};
