<template>
  <div class="st-multiSelect">
    <div class="st-multiselect-input-group" :class="displayDropdown ? 'st-multiselect-input-active' : ''">
      <st-input
        :autocomplete="autocomplete"
        :id="id"
        :required="required"
        role="combobox"
        aria-autocomplete="list"
        aria-owns="multiselect-dropdown"
        :aria-label="ariaLabelFunc()"
        aria-live="polite"
        ref="multiSelectField"
        v-model="query"
        :placeholder="'Exempel: ' + placeholder"
        :blur="deactivate"
        :focus="activate"
        :tabindex="tabindex"
        @input="updateSearch"
        :keydown-down="pointerForward"
        :keydown-up="pointerBackward"
        @keyup.enter.native="submit"
        :keyup-esc="clearInput"
        :aria-describedby="ariaDescribedBy"
      ></st-input>
      <div style="height: 0em" v-if="filterItem">
        <div class="st-multiselect-filterItem">
          <div class="st-multiselect-filterItem-detail">
            <slot name="custom-filter-item" v-bind:filterItem="filterItem">
              <div>{{ itemDisplayProp ? filterItem[itemDisplayProp] : filterItem }}</div>
            </slot>
            <st-icon class="clear-button" @click.native="clearFilterItem" name="remove" useSvg size="sm" inline></st-icon>
          </div>
        </div>
      </div>
      <div class="st-multiselect-input-group-prepend">
        <transition name="loading-icon">
          <div v-show="loading" class="spinner" :aria-label="$t('Spinner_Alt_text')" />
        </transition>
      </div>
    </div>
    <transition name="st-multiselect-dropdown-transition">
      <div v-if="displayDropdown" :aria-expanded="displayDropdown" class="st-multiselect-dropdown-box" @mousedown.prevent>
        <ul class="st-multiselect-dropdown" id="multiselect-dropdown" :aria-label="'Hållplatslista med ' + filteredItems.length + ' alternativ'">
          <li v-if="filterItem" class="st-multiselect-dropdown-list">
            <slot name="custom-filter-text" class="st-multiselect-dropdown-slot">default text</slot>
          </li>
          <li
            role="option"
            style="padding-left: 0em"
            v-show="filteredItems && filteredItems.length > 0"
            v-for="(item, index) in filteredItems"
            v-bind:key="index"
            @click.stop="addItem(item)"
            @mouseenter.self="pointerSet(index)"
            class="st-multiselect-dropdown-listitem"
            :class="isItemHighlighted(index) ? 'st_dropdown_item_highlight' : ''"
          >
            <slot name="custom-dropdown-item" v-bind:dropdownItem="item" :isHighlighted="isItemHighlighted(index)">
              <div>{{ itemDisplayProp ? item[itemDisplayProp] : item }}</div>
            </slot>
          </li>
          <li v-show="showNoResults" aria-live="assertive" class="st-multiselect-dropdown-listitem">
            <span v-show="showNoResultsMessage">
              <slot name="no-results">Inga föremål hittades. Försök att ändra din sökning.</slot>
            </span>
          </li>
        </ul>
      </div>
    </transition>
    <slot name="error"></slot>
    <ul class="st-multiselect-selected-items-list">
      <li class="st-multiselect-listItem" v-for="(item, index) in selectedItems" v-bind:key="item[itemDisplayProp]" :aria-label="item[itemDisplayProp]">
        <slot name="custom-selected-item" v-bind:dropdownItem="item">
          <div class="chip">
            <span class="chip-stack" aria-hidden="true">
              <st-icon role="presentation" name="zone-pin" :alt="$t('MultiSelect_Pin_Alt_text')" useSvg size="md" inline></st-icon>
              <span class="chip-stack-marker-text">{{ index + 1 }}</span>
            </span>
            <span role="text" :aria-label="`Hållplats ${index + 1}: ${item[itemDisplayProp]}`" class="chipText">
              <span aria-hidden="true">{{ item[itemDisplayProp] }}</span>
            </span>
            <span class="chip-stack">
              <st-icon class="removeBtn" role="button" :alt="$t('MultiSelect_Remove_Pin_Alt_text') + item[itemDisplayProp]" @click.native="removeItem(item)" @keyup.enter.native="removeItem(item)" name="remove" useSvg size="sm" inline tabindex="0"></st-icon>
            </span>
          </div>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
import pointerMixin from './pointerMixin.js';
import globalMixin from '../../../mixins/globalMixin';

export default {
  name: 'st-multiselect',
  mixins: [pointerMixin, globalMixin],
  props: {
    id: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: function () {
        return [];
      }
    },
    selectedItems: {
      type: Array,
      require: true,
      default: function () {
        return [];
      }
    },
    itemDisplayProp: {
      type: String
    },
    clearOnLeave: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    minSearchLength: {
      type: Number
    },
    optionsLimit: {
      type: Number,
      default: 10
    },
    input: {
      type: Function
    },
    excludeSelectedItems: {
      type: Boolean,
      default: true
    },
    tabindex: {
      type: String,
      default: '0'
    },
    onSubmit: {
      type: Function
    },
    onClearStopInput: {
      type: Function
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    ariaDescribedBy: {
      type: String
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  data: function () {
    return {
      active: false,
      query: '',
      canAdd: false,
      filterItem: undefined,
      showNoResultsMessage: false,
    };
  },
  methods: {
    activate: function () {
      this.active = true;
    },
    deactivate: function () {
      this.active = false;
      if (this.clearOnLeave) {
        this.clearInput();
      }
    },
    clearInput: function () {
      this.query = '';
      if (this.onClearStopInput) {
        this.onClearStopInput();
      }
    },
    updateSearch: function (value) {
      if (value !== '') this.canAdd = true;

      this.$emit('input', value);
    },
    clearFilterItem: function () {
      this.filterItem = undefined;
    },
    addFilterItem: function (item) {
      this.filterItem = item;
    },
    addItem: function (item) {
      if (this.canAdd) {
        this.canAdd = false;
        this.$emit('add', item);
        this.clearInput();
        this.clearFilterItem();
      }
    },
    removeItem: function (item) {
      this.$emit('remove', item);

      this.setFocus();
    },
    ariaLabelFunc() {
      if (this.filteredItems.length > 0) {
        return 'Välj en hållplats från listan nedan';
      } else {
        return '';
      }
    },
    submit: function (e) {
      if (this.onSubmit) {
        this.onSubmit(this.filteredItems[this.pointer]);
      } else {
        this.addPointerElement();
      }

      if (e) e.preventDefault();
    },
    handleClickOutside: function (evt) {
      if (!this.$el.contains(evt.target)) {
        this.clearFilterItem();
      }
    },
    setFocus: function () {
      this.$nextTick(() => this.$refs.multiSelectField.$el.focus());
    },
    triggerNoResultsMessageDelay() {
      setTimeout(() => {
        this.showNoResultsMessage = true;
      }, 700);
    },
  },
  computed: {
    filteredItems: function () {
      if (this.selectedItems.length > 0 && this.excludeSelectedItems) {
        let property = this.itemDisplayProp;
        let selectedItemsValues = [];

        for (let i = 0; i < this.selectedItems.length; i++) {
          selectedItemsValues.push(this.selectedItems[i][property]);
        }

        let filteredList = [];
        for (let i = 0; i < this.items.length; i++) {
          if (selectedItemsValues.indexOf(this.items[i][property]) === -1) {
            filteredList.push(this.items[i]);
          }
        }
        let result = filteredList.slice(0, this.optionsLimit);

        return result;
      } else {
        return this.items.slice(0, this.optionsLimit);
      }
    },
    displayDropdown: function () {
      if (this.searchIsActive && (this.showNoResults || this.containsResults)) {
        return true;
      }
      return false;
    },
    searchIsActive: function () {
      if (this.active && this.query.length >= this.minSearchLength) {
        return true;
      }
      return false;
    },
    showNoResults: function () {
      this.showNoResultsMessage = false;
      const showMessage = this.filteredItems.length < 1 && !this.loading;
      if (showMessage) {
        this.triggerNoResultsMessageDelay();
        return showMessage;
      }
      return showMessage;
    },
    containsResults: function () {
      if (this.filteredItems.length > 0) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/colors.scss';

.st-multiSelect {
  position: relative;
  padding-bottom: 1em;

  ul {
    margin-bottom: 0em !important;
    padding-bottom: 0em !important;
  }

  .st-multiselect-input-group {
    margin-bottom: 0em;

    input[type='text'] {
      margin-bottom: 0em;
    }
  }

  .st-multiselect-input-active {
    .st-input:focus {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .st-multiselect-input-group {

    .loading-icon-enter-active,
    .loading-icon-leave-active {
      transition: opacity 0.4s ease-in-out;
      opacity: 1;
    }

    .loading-icon-enter,
    .loading-icon-leave-active {
      opacity: 0;
    }

    .spinner {
      position: absolute;
      right: 0em;
      top: 0.125em;
      width: 3em;
      height: 2.1875em;
      background: transparent;
      display: block;
    }

    .spinner:before,
    .spinner:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -0.5em 0 0 -0.5em;
      width: 1em;
      height: 1em;
      border-radius: 100%;
      border-color: rgba(77, 72, 69, 0.24) transparent transparent;
      border-style: solid;
      border-width: 0.125em;
      box-shadow: 0 0 0 0.0625em transparent;
    }

    .spinner:before {
      animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
      animation-iteration-count: infinite;
    }

    .spinner:after {
      animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
      animation-iteration-count: infinite;
    }

    @keyframes spinning {
      from {
        transform: rotate(0);
      }

      to {
        transform: rotate(2turn);
      }
    }
  }
}

.st-multiselect-selected-items-list {
  list-style-type: none;
  margin: 0em;
  padding: 0.3125em;
  padding-top: 0em;
  padding-left: 0em;
  overflow: hidden;

  .st-multiselect-listItem {
    float: left;
    padding-right: 0.3125em;
    padding-top: 0.3125em;
    z-index: 10;

    .chip {
      min-height: 2em;
      padding: 0.25em 0.5em;
      border-radius: 1em;
      background-color: #f2efe9;

      .chip-stack {
        .chip-stack-marker-text {
          color: #f9f8f5;
          font-size: 0.625em;
          margin-top: 0.4em;
          margin-left: -1.45em;
          position: absolute;
        }
      }

      .chipText {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 0.875em;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #4d4845;
      }

      .removeBtn {
        cursor: pointer;
        margin-left: 0.25em;

        &:focus {
          padding: 0;
        }
      }
    }
  }
}

.st-multiselect-dropdown-box {
  position: absolute;
  z-index: 1001;
  background: #fff;
  width: 100%;
  border: solid 0.0625em $varm-grå-alt;
  border-top: none;
  border-bottom-left-radius: 0.3125em;
  border-bottom-right-radius: 0.3125em;
  -webkit-overflow-scrolling: touch;

  .st-multiselect-dropdown {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 100%;
    vertical-align: top;

    .st-multiselect-dropdown-listitem {
      display: block;
      color: #514f4f;
      font-size: 1em;
      min-height: 3.125em;
      line-height: 3.125em;
      padding-left: 0.5em;
      white-space: nowrap;
      cursor: pointer;
    }

    .st_dropdown_item_highlight {
      color: #fff;
      background-color: #514f4f;
      outline: none;
    }

    .st_dropdown_item_highlight:after {
      content: attr(data-select);
      background-color: rgb(241, 241, 241);
    }
  }
}

.st-multiselect-dropdown-transition-enter-active {
  transition: opacity 0.2s, transform 0.2s ease-out;
}

.st-multiselect-dropdown-transition-leave-active {
  transition: opacity 0.5s, transform 1.5s ease-out;
}

.st-multiselect-dropdown-transition-enter,
.st-multiselect-dropdown-transition-leave-to {
  transform: translateY(-0.625em);
  opacity: 0;
}

.clear-button {
  right: -0.375em;
  top: -0.125em;

  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}

.st-multiselect-filterItem {
  height: 1.875em;
  position: absolute;
  left: 0.625em;
  top: 0.625em;
  bottom: 0.625em;
}

.st-multiselect-filterItem-detail {
  border-radius: 0.9375em;
  padding: 0em 0.75em;
  background-color: #f2efe9;
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #4d4845;
}

.st-multiselect-dropdown-list {
  background-color: #f2efe9;
  padding: 0.1875em 0.8125em;
  height: 1.9375em;
}

.st-multiselect-dropdown-slot {
  font-size: 0.8125em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: #2b241d;
}
</style>
