<template>
  <transition name="modal-fade">
    <div v-focus-trap>
      <div class="modal-mask" ref="mymodal" @click="outsideClose">
        <div role="dialog" aria-modal="true" :aria-labelledby="ariaLabelledby" :aria-describedby="ariaDescribedby" class="modal-base modal-center" :style="'max-width:' + modalMaxWidth + 'em;'" onclick="event.stopPropagation();">
          <st-card :class="classList" :isLoading="isLoading" :width-size="widthSize" :style="style" :error="error" style="pointer-events: auto" :loadingMessage="loadingMessage">
            <div :class="isHidden">
              <slot name="header">
                <i v-if="onClose" class="fas fa-times closeButton" @click="close"></i>
              </slot>

              <slot name="body"></slot>

              <slot name="footer"></slot>
            </div>
          </st-card>
        </div>
      </div>
      <div class="modal-background"></div>
    </div>
  </transition>
</template>

<script>
import { focusTrap } from '../../../focus-trap';
export default {
  name: 'st-modal',
  directives: {
    focusTrap
  },
  props: {
    onClose: {
      type: Function
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    loadingMessage: {
      type: String
    },
    large: {
      type: Boolean,
      default: false
    },
    mega: {
      type: Boolean,
      default: false
    },
    error: {
      type: Object
    },
    width: {
      type: Number,
      default: 0
    },
    disableOutsideClick: {
      type: Boolean,
      default: false
    },
    ariaLabelledby: {
      type: String,
      default: ''
    },
    ariaDescribedby: {
      type: String,
      default: ''
    }
  },

  computed: {
    classList: function () {
      return ['st-modal', this.large && 'lg', this.small && 'sm'];
    },
    widthSize: function () {
      if (this.mega) return 'mega-width';
      if (!this.large) return 'md-width';
      if (this.large) return 'lg-width';
    },
    style: function () {
      if (this.width > 0) {
        return `max-width: ${this.width}em`;
      }
    },
    isHidden: function () {
      return this.isLoading ? 'hidden' : '';
    },
    modalMaxWidth: function () {
      if (this.width > 0) {
        return this.width;
      }
      if (this.mega) return 88.25;
      if (!this.large) return 36;
      if (this.large) return 48.5;
    }
  },

  methods: {
    close: function () {
      if (this.onClose) {
        this.onClose();
      }
    },
    outsideClose: function () {
      if (this.disableOutsideClick || this.isLoading) return;

      if (this.onClose) {
        this.onClose();
      }
    }
  },
  mounted() {
    document.body.classList.add('modal-opened');
    let modalHeight = this.$refs.mymodal.scrollHeight;
    let windowHeight = window.innerHeight;

    //If scrollbar, add padding to body to avoid jitter
    if (modalHeight > windowHeight) document.body.style.paddingRight = '1.0625em';
  },
  beforeDestroy() {
    document.body.classList.remove('modal-opened');
    document.body.style.removeProperty('padding-right');
  }
};
</script>

<style lang="scss" scoped>
.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(249, 248, 245, 0.95);
}

.modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  outline: 0;
  overflow-y: auto;
  display: block;
}

.modal-base {
  margin: 1.75em auto;
  position: relative;
  width: auto;
  pointer-events: none;
  overflow: hidden;
  padding: 2.25em 1.5em;
}

.modal-center {
  min-height: calc(100% - (1.75em * 2));
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.1s linear;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.st-modal {
  margin: 0em 0.3125em 0em 0.3125em;

  &.st-card.lg {
    padding: 0em;
    width: 100%;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    border: none;
    padding: 0em;
    justify-content: center;
  }

  .closeButton {
    color: #9e9692;
    float: right;
    top: 3.6875em;
    right: 1.5em;
    position: absolute;
    width: 1em;
    height: 1em;
  }

  .modal-header {
    padding: 1.5em;
    border-bottom: 0.0625em solid rgba(77, 72, 69, 0.08);

    h3 {
      margin-top: 0em;
      line-height: 1.26;
    }
  }

  .modal-body {
    .row {
      margin-right: 0em;
      margin-left: 0em;
    }
  }

  .modal-footer {
    button {
      margin-top: 0em;
    }

    padding: 0.75em 1em;
    border-top: 0.0625em solid rgba(77, 72, 69, 0.08);
    > :not(:last-child) {
      margin-right: 0.5em;
    }

    > :not(:first-child) {
      margin-left: 0.5em;
    }
  }

  .hidden {
    visibility: hidden;
  }
}

@media (min-width: 700px) {
  .st-modal {
    .modal-footer {
      padding: 1.5em 2em;
    }
  }
}
</style>
