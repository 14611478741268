export default {
  getModel() {
    return {
      numberOfCards: 1,
      firstName: '',
      lastName: '',
      street: '',
      postalCode: '',
      city: '',
      email: ''
    };
  }
};
